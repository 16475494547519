import axios from "../../lib/axiosInstance";

export function createUserInsurance(payload) {
  return axios.post("insurance", payload).then((res) => res.data);
}

export function updateUserInsurance({ id, payload }) {
  return axios.patch(`insurance/${id}`, payload).then((res) => res.data);
}
export function fetchAllUserInsurances(id) {
  return axios.get(`insurance/all/${id}`).then((res) => res.data);
}

export function deleteUserInsurance(id) {
  return axios.delete(`insurance/${id}`).then((res) => res.data);
}
