import React from "react";
import ForgotPassword from "../../views/ForgotPassword";
import ResetPassword from "../../views/ResetPassword";

const ResetPasswordPage = () => {
  return (
    <div className="bg-slate-100 h-screen">
      <ResetPassword />
    </div>
  );
};

export default ResetPasswordPage;
