import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";

import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { updateTask } from "../../../services/api/task";

import dayjs from "dayjs";
import { getTimeZoneAbbreviation } from "../../../utils/getTimeZoneAbbreviation";
import { draftWorkOrder } from "../../../services/api/workOrder";

const UnpublishModal = ({
  unpublishModalOpen,
  setUnpublishModalOpen,
  workOrderId,
  refetchUserAllowedAction,
}) => {
  const dispatch = useDispatch();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const {
    isPending: isMoveDraftLoading,
    error: isMoveDraftError,
    mutateAsync: moveDraftWorkOrderMutation,
  } = useMutation({
    mutationFn: draftWorkOrder,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Work Order Unpublished Successfully",
          type: "success",
        })
      );
    },
  });

  async function handleMoveToDraftButton() {
    try {
      await moveDraftWorkOrderMutation(workOrderId);
      updateWorkOrder();
      refetchUserAllowedAction();
      setUnpublishModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: isMoveDraftError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }
  return (
    <BaseModal
      className="-top-72"
      open={unpublishModalOpen}
      close={() => {
        setUnpublishModalOpen(false);
      }}
      style={{ width: "600px" }}
    >
      <div className="container">
        <h6 className="text-dark border-bottom mb-4 pb-1">
          Are you sure to unpulish this work order from markeplace?
        </h6>
        <p>It will be moved to Draft and you can publish later on.</p>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 mt-3"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-dark"
          fullWidth={false}
          onClick={() => setUnpublishModalOpen(false)}
        >
          <p>Cancel</p>
        </BaseButton>
        <BaseButton
          disabled={isMoveDraftLoading}
          isLoading={isMoveDraftLoading}
          className="py-[5px] px-4 ml-4"
          color="primary"
          fullWidth={false}
          onClick={handleMoveToDraftButton}
        >
          Unpublish
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default UnpublishModal;
