import React from "react";

const ProfileStep = ({ step }) => {
  return (
    <div className="inline mb-4">
      <div className="flex items-center bg-white py-4 px-8 rounded-xl">
        <div className="flex items-center justify-center rounded-full bg-primary/35 h-7 w-7">
          <i class="fa-solid fa-circle-check text-primary text-xl"></i>
        </div>

        <h6 className="text-primary font-normal ml-5">{step}</h6>
      </div>
    </div>
  );
};

export default ProfileStep;
