import React, { useEffect, useRef, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";
import BaseCheckbox from "../../../components/ui/BaseCheckbox";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import {
  createUserInsurance,
  updateUserInsurance,
} from "../../../services/api/insurance";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";

const validationSchema = yup.object({
  file: yup
    .mixed()
    .required("File is required")
    .test(
      "fileSize",
      "File size is too large (Max 5MB)",
      (value) => value && value.size <= 5 * 1024 * 1024 // 5MB limit
    )
    .test(
      "fileType",
      "Unsupported file format (PDF, JPG, PNG allowed)",
      (value) =>
        value &&
        ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
    ),
  companyName: yup
    .string("Enter Company name")
    .required("company name is required"),
  policyNumber: yup
    .string("Enter Policy Number")
    .required("policy number is required"),

  insuranceType: yup.string("").required("Choose the insurance type"),
  coveragePerIncident: yup
    .string("Enter coverage per incident")
    .required("coverage per incident is required"),
  startDate: yup.string().required("effective date is required"),

  endDate: yup.string().required("expiry date is required"),
});

const AddGeneralInsuranceModal = ({
  open,
  close,
  insurance,
  refetchAllUserInsurances,
}) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isInsuranceEdit = insurance ? true : false;

  const {
    isPending: isUserInsuranceLoading,
    error: isUserInsuranceError,
    data: userInsuranceData,
    mutateAsync: createUserInsuranceMutation,
  } = useMutation({
    mutationFn: createUserInsurance,
    enabled: false,
  });

  const {
    isPending: isUpdateUserInsuranceLoading,
    error: isUpdateUserInsuranceError,
    data: updateUserInsuranceData,
    mutateAsync: updateUserInsuranceMutation,
  } = useMutation({
    mutationFn: updateUserInsurance,
    enabled: false,
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      companyName: insurance?.companyName,
      policyNumber: insurance?.policyNumber,
      startDate: insurance?.startDate || "",
      endDate: insurance?.endDate || "",
      coveragePerIncident: insurance?.coveragePerIncident,
      insuranceType: insurance?.insuranceType,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleInsuranceSubmit(values);
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleFileChange = (event) => {
    const file = event.target?.files[0];
    formik.setFieldValue("file", file);
    setSelectedFiles([file?.name]);
  };

  const handleInsuranceSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("file", values.file);
      Object.keys(values).forEach((key) => {
        if (key !== "file") {
          formData.append(key, values[key]);
        }
      });

      if (isInsuranceEdit) {
        await updateUserInsuranceMutation({
          id: insurance?.id,
          payload: formData,
        });
        dispatch(
          showToast({
            message: "Insurance updated successfully",
            type: "success",
          })
        );
        formik.resetForm();
      } else {
        await createUserInsuranceMutation(formData);
        dispatch(
          showToast({
            message: "Insurance added successfully",
            type: "success",
          })
        );

        formik.resetForm();
        refetchAllUserInsurances();
      }

      close(false);
    } catch (error) {
      dispatch(
        showToast({ message: error.response.data.message, type: "error" })
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <BaseModal
        className="-top-[200px] overflow-scroll"
        open={open}
        close={close}
        style={{ width: "1000px" }}
      >
        <div className="py-7 px-12">
          <h6 className="text-dark font-normal border-b pb-1">
            Upload Insurance
          </h6>
          <div className="my-3">
            <p className="font-normal text-base mb-2">
              Add File <span className="font-300 text-sm">(JPG, PDF, PNG)</span>
            </p>
            <div className="w-full flex items-center">
              {/* Hidden File Input */}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file"
                name="file"
                accept=".pdf,.jpg,.png"
                onBlur={formik.handleBlur}
                error={formik.touched.file && Boolean(formik.errors.file)}
                helperText={formik.touched.file && formik.errors.file}
              />

              {/* File Display Section */}
              <div
                style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
                className="min-h-[40px] w-full py-[6.25px] rounded-md flex flex-wrap"
              >
                {formik.values?.file ? (
                  <p className="py-1 ml-2">{formik.values?.file?.name}</p> // Show uploaded file name
                ) : insurance?.asset?.url ? (
                  <a
                    href={insurance.asset.url} // Directly use existing file URL
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    className="py-1 px-6 cursor-pointer block text-blue-500 hover:underline font-300"
                  >
                    {insurance?.asset?.filename || "Download File"}
                  </a>
                ) : (
                  <p className="text-gray-400 py-1 ml-2">No file selected</p>
                )}
              </div>

              {/* Choose File Button */}
              <BaseButton
                variant="outlined"
                color="primary"
                className="w-[126px] py-[7.2px] ml-4"
                onClick={handleButtonClick}
              >
                Choose File
              </BaseButton>
            </div>
          </div>

          <div className="mb-2 mt-3">
            <p className="font-normal text-base mb-2">Policies Included:</p>

            <div className="grid grid-cols-2 gap-x-14 gap-y-2">
              <BaseTextField
                isDefaultField={false}
                label="Insurance Company"
                labelShrink={false}
                optional={false}
                id="companyName"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
              <BaseTextField
                isDefaultField={false}
                label="Policy Number"
                labelShrink={false}
                optional={false}
                id="policyNumber"
                name="policyNumber"
                value={formik.values.policyNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.policyNumber &&
                  Boolean(formik.errors.policyNumber)
                }
                helperText={
                  formik.touched.policyNumber && formik.errors.policyNumber
                }
              />

              <div>
                <p className="font-normal">Effective</p>
                <BaseDatePicker
                  className="border-none"
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={dayjs(formik.values.startDate)}
                  onChange={(event) => {
                    const selectedDate = dayjs(event)
                      .utcOffset(0, true)
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                    formik.setFieldValue("startDate", selectedDate); // Store as string
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && formik.errors.startDate
                  }
                  slotProps={{
                    textField: {
                      sx: {
                        "& .MuiOutlinedInput-root": {
                          border: "none !important",
                          boxShadow: "1px 1px 4px 1px #00000021",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          margin: "8px 1.5px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: "none !important",
                            boxShadow: "none",
                          },
                        "& .MuiInputBase-input": {
                          padding: "10px 14px",
                          fontSize: "13px",
                          height: "100% !important",
                          lineHeight: "1.5",
                        },

                        "& .MuiIconButton-root": {
                          backgroundColor: "white !important",
                          borderLeft: "none !important",
                        },
                      },
                    },
                  }}
                />
              </div>
              <div>
                <p className="font-normal">Expires</p>
                <BaseDatePicker
                  className="border-none"
                  type="date"
                  id="endDate"
                  name="endDate"
                  minDate={
                    formik.values.startDate
                      ? dayjs(formik.values.startDate)
                      : undefined
                  }
                  value={dayjs(formik.values.endDate)}
                  onChange={(event) => {
                    const selectedDate = dayjs(event)
                      .utcOffset(0, true)
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                    formik.setFieldValue("endDate", selectedDate); // Store as string
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  slotProps={{
                    textField: {
                      sx: {
                        "& .MuiOutlinedInput-root": {
                          border: "none !important",
                          boxShadow: "1px 1px 4px 1px #00000021",
                          borderRadius: "5px",
                          backgroundColor: "white",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          margin: "8px 1.5px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: "none !important",
                            boxShadow: "none",
                          },
                        "& .MuiInputBase-input": {
                          padding: "10px 14px",
                          fontSize: "13px",
                          height: "100% !important",
                          lineHeight: "1.5",
                        },

                        "& .MuiIconButton-root": {
                          backgroundColor: "white !important",
                          borderLeft: "none !important",
                        },
                      },
                    },
                  }}
                />
              </div>

              <BaseTextField
                isDefaultField={false}
                label="Coverage per incident"
                labelShrink={false}
                optional={false}
                id="coveragePerIncident"
                name="coveragePerIncident"
                value={formik.values.coveragePerIncident}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.coveragePerIncident &&
                  Boolean(formik.errors.coveragePerIncident)
                }
                helperText={
                  formik.touched.coveragePerIncident &&
                  formik.errors.coveragePerIncident
                }
              />
              <div></div>
              <div>
                <BaseCheckbox
                  label="Public Liability / Professional Indemnity"
                  id="generalLiablity"
                  name="insuranceType"
                  checked={
                    formik.values.insuranceType ===
                    "PUBLIC_LIABILITY_PROFESSIONAL_INDEMNITY"
                  }
                  onChange={() => {
                    formik.setFieldValue(
                      "insuranceType",
                      "PUBLIC_LIABILITY_PROFESSIONAL_INDEMNITY"
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.insuranceType &&
                    Boolean(formik.errors.insuranceType)
                  }
                  errorMsg={
                    formik.touched.insuranceType && formik.errors.insuranceType
                  }
                ></BaseCheckbox>
                <BaseCheckbox
                  label="Other"
                  id="professionalLiablity"
                  name="insuranceType"
                  checked={formik.values.insuranceType === "OTHER"}
                  onChange={() => {
                    formik.setFieldValue("insuranceType", "OTHER");
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.insuranceType &&
                    Boolean(formik.errors.insuranceType)
                  }
                  errorMsg={
                    formik.touched.insuranceType && formik.errors.insuranceType
                  }
                ></BaseCheckbox>
              </div>
              <div>
                <BaseCheckbox
                  label="Worker Compensation"
                  id="workersCompensation"
                  name="insuranceType"
                  checked={
                    formik.values.insuranceType === "WORKER_COMPENSATION"
                  }
                  onChange={() => {
                    formik.setFieldValue(
                      "insuranceType",
                      "WORKER_COMPENSATION"
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.insuranceType &&
                    Boolean(formik.errors.insuranceType)
                  }
                  errorMsg={
                    formik.touched.insuranceType && formik.errors.insuranceType
                  }
                ></BaseCheckbox>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex content-center  bg-white py-6 "
          style={{
            boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          <BaseButton
            className=" px-[28px] py-[5px] bg-light text-primary hover:text-primary"
            variant="outlined"
            color="primary"
            style={{
              marginRight: "16px",
            }}
            onClick={() => close(false)}
          >
            Cancel
          </BaseButton>

          <BaseButton
            isLoading={isUserInsuranceLoading || isUpdateUserInsuranceLoading}
            loaderSize={"22px"}
            loaderColor="primary"
            className="px-[12px] py-[5px]"
            color="primary"
            type="submit"
          >
            {isInsuranceEdit ? "Update" : "Save Changes"}
          </BaseButton>
        </div>
      </BaseModal>
    </form>
  );
};

export default AddGeneralInsuranceModal;
