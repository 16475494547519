import React, { useEffect } from "react";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BaseButton from "../../../components/ui/BaseButton";
import routes from "../../../routes";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { ReactComponent as Check } from "../../../assets/global-icons/checked.svg";
import { useQuery } from "@tanstack/react-query";
import { fetchJobDetail } from "../../../services/api/workOrder";
import { updateWorkOrderDetail } from "../../../store/workOrder/workOrderSlice";
import { useDispatch } from "react-redux";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#DCDCDC",
    borderTopWidth: 7,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#DCDCDC",
  display: "flex",
  height: 22,
  zIndex: 1,
  alignItems: "center",
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  "& .QontoStepIcon-completedIcon": {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  "& .QontoStepIcon-circle": {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: "#DCDCDC",
  },
  "& .QontoStepIcon-activeIcon": {
    width: 34,
    height: 34,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="flex items-center justify-center QontoStepIcon-completedIcon">
          <Check />
        </div>
      ) : active ? (
        <div className="QontoStepIcon-activeIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const steps = ["summary", "scheduling", "payment", "assessment"];

const WorkOrderStepper = ({ isBannerVisible }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const currentStepRoute = location.pathname.split("/")[3]?.toLowerCase();
  const currentStepIndex = steps.indexOf(currentStepRoute);

  const createWorkOrderRoute = routes.find(
    (route) => route.path === "workOrder/create"
  );
  const allSteps = createWorkOrderRoute?.childs || [];

  // Fetch Work Order Data for Edit using React Query
  const {
    isLoading: isWorkOrderDetailsLoading,
    error: isWorkOrderDetailsError,
    data: workOrderDetails,
  } = useQuery({
    queryKey: ["workOrderDetails"],
    queryFn: () => fetchJobDetail(params.id),
    enabled: !!params.id,
  });

  useEffect(() => {
    if (params?.id) {
      const formattedWorkOrder = formatWorkOrderDetails();
      dispatch(updateWorkOrderDetail(formattedWorkOrder));
    } else {
      // dispatch(resetWorkOrderForm());
    }
  }, [workOrderDetails]);

  const formatWorkOrderDetails = () => {
    return {
      summary: {
        overview: {
          title: workOrderDetails?.data.title,
          isExportDisable: workOrderDetails?.data.isExportDisable,
        },
        serviceDescription: {
          description: workOrderDetails?.data.description,
          confidentialInformation:
            workOrderDetails?.data.confidentialInformation,
        },
      },
      payment: {
        pay: {
          payAmount: workOrderDetails?.data.payAmount,
          maxHours: workOrderDetails?.data.maxHours,
        },
      },
    };
  };

  const handleNext = () => {
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex < steps.length) {
      const path = params.id
        ? `/workOrder/edit/${steps[nextStepIndex]}/${params.id}`
        : `/workOrder/create/${steps[nextStepIndex]}`;
      navigate(path);
    }

    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };

  const handleBack = () => {
    const prevStepIndex = currentStepIndex - 1;
    if (prevStepIndex >= 0) {
      const path = params.id
        ? `/workOrder/edit/${steps[prevStepIndex]}/${params.id}`
        : `/workOrder/create/${steps[prevStepIndex]}`;
      navigate(path);
    }
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };

  const handleStep = (stepIndex) => () => {
    const path = params.id
      ? `/workOrder/edit/${steps[stepIndex]}/${params.id}`
      : `/workOrder/create/${steps[stepIndex]}`;
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Stepper
        className={`sticky  bg-[#F6F6F6] z-10 pt-6 pb-2 ${
          isBannerVisible ? "top-[148px]" : "top-[110px]"
        }`}
        activeStep={currentStepIndex}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} onClick={handleStep(index)}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              className="cursor-pointer"
            >
              <p
                className={`font-medium capitalize ${
                  currentStepIndex === index && "text-primary"
                }`}
              >
                {label}{" "}
                {index === 3 ? (
                  <Typography variant="caption">(Optional)</Typography>
                ) : (
                  ""
                )}
              </p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={`px-[2px] pb-5 ${isBannerVisible ? "mt-28" : "mt-16"}`}>
        {allSteps.map((step) => (
          <div key={step.path}>
            {location.pathname.includes(step.path) &&
              React.cloneElement(step.component, { workOrderDetails })}
          </div>
        ))}

        <div
          className={`flex ${
            steps[currentStepIndex] === "summary"
              ? "justify-end"
              : "justify-between"
          }  pt-5`}
        >
          {currentStepIndex !== 0 && (
            <BaseButton
              onClick={handleBack}
              className="w-28 bg-[#EDEDED] pt-2 pb-2 pl-7 pr-7 rounded-bl-lg  rounded-tl-lg rounded-tr-lg rounded-br-none"
            >
              <p>Back</p>
            </BaseButton>
          )}

          {currentStepIndex < steps.length - 1 && (
            <BaseButton
              onClick={handleNext}
              className="w-28 bg-primary  pt-2 pb-2 pl-7 pr-7 rounded-bl-lg  rounded-tl-lg rounded-tr-lg rounded-br-none"
            >
              Next
            </BaseButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkOrderStepper;
