import React from "react";
import ScheduleForm from "../Forms/ScheduleForm";
import LocationForm from "../Forms/LocationForm";
import ContactsForm from "../Forms/ContactsForm";
import TasksForm from "../Forms/TasksForm";

const Scheduling = () => {
  return (
    <div>
      <LocationForm />
      <ScheduleForm />
      <TasksForm />
      <ContactsForm />
    </div>
  );
};

export default Scheduling;
