import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseCard from "../../../../../components/ui/BaseCard";
import AddLicenseAndCertificationModal from "../../../components/AddLicenseAndCertificationModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createCertification,
  deleteUserCertification,
  fetchAllCertifications,
} from "../../../../../services/api/certification";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../store/toast/toastSlice";
import dayjs from "dayjs";

const validationSchema = yup.object({
  file: yup
    .mixed()
    .required("File is required")
    .test(
      "fileSize",
      "File size is too large (Max 5MB)",
      (value) => value && value.size <= 5 * 1024 * 1024 // 5MB limit
    )
    .test(
      "fileType",
      "Unsupported file format (PDF, JPG, PNG allowed)",
      (value) =>
        value &&
        ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
    ),
  qualificationSubTypeId: yup
    .number("")
    .required("Select license or certification"),
  name: yup.string("").required("Certification is required"),
  startDate: yup.string("").required("Select start date"),
  endDate: yup.string("").required("Select end date"),
});

const AddLicenseCertifications = ({ userId }) => {
  const dispatch = useDispatch();
  const [
    isAddLicenseAndCertificationModalOpen,
    setIsAddLicenseAndCertificationModalOpen,
  ] = useState(false);
  const [certifications, setCertifications] = useState([]);

  const {
    isPending: isCreateCertificationLoading,
    error: isCreateCertificationError,
    data: createCertificationData,
    mutateAsync: createCertificationMutation,
  } = useMutation({
    mutationFn: createCertification,
    enabled: false,
  });

  const {
    isPending: isDeletedCertificationApiLoading,
    error: isDeletedCertificationApiError,
    data: deletedCertificationData,
    mutateAsync: deleteUserCertificationMutation,
  } = useMutation({
    mutationFn: deleteUserCertification,
    enabled: false,
  });

  const {
    data: certificationsData,
    isPending: isFetchCertificationsLoading,
    error: isFetchCertificationsError,
    refetch: refetchAllCertifications,
  } = useQuery({
    queryKey: ["certifications"],
    queryFn: () => fetchAllCertifications(userId),
    enabled: !!userId,
  });

  const formik = useFormik({
    initialValues: {
      file: null,
      qualificationSubTypeId: null,
      name: "",
      startDate: null,
      endDate: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleCertificationSubmit(values);
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (certificationsData) {
      setCertifications(certificationsData?.data);
    }
  }, [certificationsData]);
  const handleCertificationSubmit = (values) => {
    handleAddCertification(values);
  };

  const handleAddCertification = async (values) => {
    try {
      const formData = new FormData();
      formData.append("file", values?.file);

      Object.keys(values).forEach((key) => {
        if (key !== "file") {
          const value =
            key === "qualificationSubTypeId"
              ? Number(values[key])
              : values[key]; // ✅ Convert to number
          formData.append(key, value);
        }
      });
      const certificationResponse = await createCertificationMutation(formData);

      dispatch(
        showToast({
          message: "Certification added successfully",
          type: "success",
        })
      );
      setCertifications((prevCertifications) => [
        ...prevCertifications,
        certificationResponse,
      ]);

      formik.resetForm();
      formik.setFieldValue("file", null);

      setIsAddLicenseAndCertificationModalOpen(false);
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleDeleteCertification = async (id) => {
    try {
      await deleteUserCertificationMutation(id);
      refetchAllCertifications();
    } catch (error) {}
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">Add License & Certifications</p>
          <i
            class="fa-solid fa-circle-plus cursor-pointer text-secondary text-xl"
            onClick={() => setIsAddLicenseAndCertificationModalOpen(true)}
          ></i>
        </div>

        {certifications?.map((certification) => (
          <div
            className="flex items-center justify-between px-4 border-b mb-2 pb-2"
            key={certification.id}
          >
            <div>
              <p className="text-sm font-normal">{certification?.name}</p>
              <div className="flex mt-2">
                <p className="font-normal">
                  Issue Date:{" "}
                  <span className="font-300">
                    {dayjs(certification?.startDate).format("DD/MM/YYYY")}
                  </span>
                </p>
                <p className="font-normal ml-6">
                  Expiry Date:{" "}
                  <span className="font-300">
                    {dayjs(certification?.endDate).format("DD/MM/YYYY")}
                  </span>
                </p>
              </div>
              {certification.url && (
                <a
                  href={certification.url}
                  download={"certification"}
                  target="_blank" // ✅ Open in a new tab if needed
                  rel="noopener noreferrer"
                  className="cursor-pointer text-blue-500 !hover:text-blue-500 hover:underline font-300 !hover:font-[300]"
                >
                  Attachment
                </a>
              )}
            </div>
            <i
              class="fa-solid fa-trash-can cursor-pointer"
              onClick={() => handleDeleteCertification(certification.id)}
            ></i>
          </div>
        ))}
      </BaseCard>
      <AddLicenseAndCertificationModal
        isCreateCertificationLoading={isCreateCertificationLoading}
        open={isAddLicenseAndCertificationModalOpen}
        close={setIsAddLicenseAndCertificationModalOpen}
        formik={formik}
      />
    </form>
  );
};

export default AddLicenseCertifications;
