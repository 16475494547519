import React from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";

const AddAboutMeModal = ({
  open,
  close,
  isUpdateUserDetailsLoading,
  formik,
  description,
}) => {
  return (
    <form>
      <BaseModal
        className="-top-[200px] overflow-scroll"
        open={open}
        close={close}
        style={{ width: "1000px" }}
      >
        <div className="py-7 px-12">
          <h6 className="text-dark font-normal border-b pb-1">About</h6>

          <div className=" mt-6">
            <p className="font-normal text-base mb-2">About you</p>
          </div>

          <textarea
            className="shadow-md border-t border-zinc-100	w-full rounded-md h-36 resize-none p-4  focus:outline-none focus:ring-1 focus:ring-orange-400 text-xs font-[300]"
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          ></textarea>
        </div>
        <div
          className="d-flex content-center  bg-white py-6 "
          style={{
            boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          <BaseButton
            className=" px-[28px] py-[5px] bg-light text-primary hover:text-primary"
            variant="outlined"
            color="primary"
            style={{
              marginRight: "16px",
            }}
            onClick={() => {
              formik.setFieldValue("description", description);

              close(false);
            }}
          >
            Cancel
          </BaseButton>

          <BaseButton
            loaderSize="22px"
            isLoading={isUpdateUserDetailsLoading}
            color="primary"
            className="py-[5px] px-[20px]"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Save Changes
          </BaseButton>
        </div>
      </BaseModal>
    </form>
  );
};

export default AddAboutMeModal;
