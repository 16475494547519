import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const WorkExperience = ({ category, vendor, services }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-16  rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Work Experience</h6>
        <i className="fa-solid fa-pencil ml-3"></i>
      </div>
      <div>
        <div className="pt-2 px-3 rounded-md flex  mb-2.5 border-b pb-5">
          <p className="font-normal min-w-40">Technology Stacks:</p>

          <div className="flex flex-wrap gap-2">
            {category?.category && (
              <p className="font-light bg-[#F6F6F6] rounded-sm  px-3 py-[1px]  w-fit">
                {category?.category}
              </p>
            )}
          </div>
        </div>

        <div className="pt-2 px-3 rounded-md flex  mb-2.5  border-b pb-5">
          <p className="font-normal min-w-40">Vendor:</p>

          <div className="flex flex-wrap gap-2">
            {category?.vendor?.name && (
              <p className="font-light bg-[#F6F6F6] rounded-sm  px-3 py-[1px]  w-fit">
                {category?.vendor?.name}
              </p>
            )}
          </div>
        </div>

        <div className="pt-2 px-3 rounded-md flex  mb-2.5">
          <p className="font-normal min-w-40">Products:</p>

          <div className="flex flex-wrap gap-2">
            {category?.vendor?.services?.map((service, index) => (
              <p
                className="font-light bg-[#F6F6F6] rounded-sm  px-3 py-[1px]  w-fit"
                key={index}
              >
                {service?.subCategory}
              </p>
            ))}
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default WorkExperience;
