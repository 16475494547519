import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { brandFontSize } from "../../utils/variables";

export const SidebarItem = ({
  path,
  onClick,
  text,
  icon,
  activeIcon,
  childs,
  isShow,
  isShowClient,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname.split("/")[1] === path;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);
  const itemRef = useRef(null);
  const { authRole } = useAuth();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      itemRef.current &&
      !itemRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
      setTimeout(() => setIsVisible(false), 300); // Match this duration with the CSS transition duration
    }
  };

  const handleItemClick = (e) => {
    if (childs) {
      e.preventDefault();
      if (isDropdownOpen) {
        setIsDropdownOpen(false);
        setTimeout(() => setIsVisible(false), 300); // Match this duration with the CSS transition duration
      } else {
        setIsVisible(true);
        setTimeout(() => setIsDropdownOpen(true), 10);
      }
    }
  };

  const handleChildItemClick = (parentPath, childPath) => {
    const absolutePath = parentPath + "/" + childPath;
    navigate(absolutePath);
    setIsVisible(false);
    setIsDropdownOpen(false);
  };

  // Determine if the item should be visible based on user type and visibility flags
  const shouldShowItem =
    (isShow && isShowClient == true && authRole === "CLIENT") ||
    (isShow && isShowClient == false && authRole === "FREELANCER");

  return (
    <>
      {shouldShowItem && (
        <div className="relative" ref={dropdownRef}>
          <div ref={itemRef}>
            <Link className="" to={path} onClick={onClick}>
              <div
                className="flex flex-col justify-center items-center py-3 border-b border-zinc-300"
                onClick={handleItemClick}
              >
                {isActive ? activeIcon : icon}
                <p
                  className={`  text-[${brandFontSize}] ${
                    isActive && "text-primary"
                  }`}
                >
                  {text}
                </p>
              </div>
            </Link>
          </div>
          {childs && isVisible && (
            <div
              className={`absolute top-2 left-[86px] bg-white  rounded-lg w-52 shadow-lg py-1 px-2 transition-all duration-300 ease-in-out transform ${
                isDropdownOpen ? "opacity-100 scale-100" : "opacity-0 scale-95"
              }`}
              ref={dropdownRef}
            >
              {childs.map((child, index) => (
                <div
                  onClick={() => handleChildItemClick(path, child.path)}
                  key={index}
                  className={`cursor-pointer border-zinc-300 py-3 px-4 ${
                    childs.length - 1 === index ? "" : "border-b"
                  }
                   
                  
                  `}
                >
                  <p
                    className={`text-sm ${
                      child.path === location.pathname.split("/")[2] &&
                      "text-primary"
                    }`}
                  >
                    {child.text}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
