import React, { useEffect, useRef } from "react";
import { Avatar, Skeleton } from "@mui/material";
import dayjs from "dayjs";

function LeftBubble({ message, createdAt }) {
  return (
    <div className="flex mb-4 w-[900px]">
      <Avatar src="https://img.freepik.com/free-photo/front-view-smiley-business-man_23-2148479583.jpg?t=st=1725259431~exp=1725263031~hmac=698528c51f80409024274dce1d7606f1b669320d2d11a09e75f7fa4f32a81b56&w=1380" />
      <div>
        <div
          style={{ boxShadow: "0px 0px 18px 0px rgba(0, 0, 0, 0.07)" }}
          className="flex bg-white rounded-lg px-4 py-3 ml-4"
        >
          <p className="mr-6 cursor-pointer">{message}</p>
          <i class="fa-solid fa-ellipsis cursor-pointer"></i>
        </div>
        <p className="mt-2 mr-6 px-4">{dayjs(createdAt).format("LT")}</p>
      </div>
    </div>
  );
}
function RightBubble({ message, createdAt }) {
  return (
    <div className="flex justify-end mb-4 !w-[900px]">
      <div>
        <div className="flex bg-[#EAF0FF] rounded-lg px-4 py-3 mr-4 ">
          <i class="fa-solid fa-ellipsis cursor-pointer"></i>
          <p className="ml-6 cursor-default">{message}</p>
        </div>
        <p className="mt-2 ml-6 pl-4 text-right">
          {dayjs(createdAt).format("LT")}
        </p>
      </div>
      <Avatar src="https://img.freepik.com/free-photo/young-builder-man-wearing-construction-uniform-safety-helmet-smiles_141793-33731.jpg?t=st=1738827316~exp=1738830916~hmac=243a572400af232b9471f1b988d1a1766cff478d0ae5debacfb4dab07dba8f37&w=2000" />
    </div>
  );
}

const Chat = ({
  sendMessage,
  handleMessage,
  messages,
  message,
  authUser,
  selectedUser,
  isLoading = true,
}) => {
  const textareaRef = useRef(null);

  const chatRef = useRef(null);
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents line break
      if (message.trim()) {
        sendMessage();
      }
    }
  };

  return (
    <div className="bg-[#FDFDFF] flex flex-col h-[600px] max-h-[600px] justify-between">
      <div
        className="overflow-y-auto"
        ref={chatRef}
        style={{ scrollBehavior: "smooth" }}
      >
        <div className="flex items-center shadow-sm bg-white border-b-[1px] h-[70px] sticky top-0 z-10 w-full px-4">
          <Avatar src="https://img.freepik.com/free-photo/front-view-smiley-business-man_23-2148479583.jpg?t=st=1725259431~exp=1725263031~hmac=698528c51f80409024274dce1d7606f1b669320d2d11a09e75f7fa4f32a81b56&w=1380" />
          <h5 className="font-normal text-dark ml-4 capitalize text-lg">
            {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
          </h5>
        </div>
        <div className="grid justify-items-start py-4 px-10">
          {isLoading
            ? Array(5)
                .fill()
                .map((_, index) =>
                  index % 2 === 0 ? (
                    <LeftBubbleSkeleton key={index} />
                  ) : (
                    <div className="flex justify-end w-full">
                      <RightBubbleSkeleton key={index} />
                    </div>
                  )
                )
            : messages?.length > 0 &&
              messages?.map((message) =>
                authUser?.id === message?.senderId ? (
                  <div>
                    <LeftBubble
                      message={message.message}
                      authUser={message.sender}
                      key={message.id}
                      createdAt={message?.createdAt}
                    />
                  </div>
                ) : (
                  <div className="flex justify-end w-full">
                    <RightBubble
                      message={message.message}
                      authUser={message.sender}
                      key={message.id}
                      createdAt={message?.createdAt}
                    />
                  </div>
                )
              )}
        </div>
      </div>

      <div className="py-4 mx-10 flex items-center relative">
        <textarea
          ref={textareaRef}
          placeholder="Type a Message"
          value={message}
          className="w-full text-light h-14 text-[13px] max-h-[300px] overflow-hidden resize-none px-4 py-4 border border-[#D6E2FF] rounded-lg"
          onChange={handleMessage}
          onInput={() => {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
          }}
          onKeyDown={handleKeyDown}
        />
        <i
          className="fa-solid fa-paper-plane text-white text-1xl mr-3 cursor-pointer absolute right-0 bg-primary py-3 px-3 rounded-xl"
          onClick={sendMessage}
        ></i>
      </div>
    </div>
  );
};

export default Chat;

export const LeftBubbleSkeleton = () => {
  return (
    <div className="flex mb-4 w-[900px]">
      <Skeleton variant="circular" width={40} height={40}>
        <Avatar />
      </Skeleton>
      <div>
        <div
          style={{ boxShadow: "0px 0px 18px 0px rgba(0, 0, 0, 0.07)" }}
          className="flex bg-white rounded-lg px-4 py-3 ml-4"
        >
          <Skeleton variant="text" width={700} height={20} />
          <Skeleton
            variant="circular"
            width={20}
            height={20}
            className="ml-3"
          />
        </div>
        <Skeleton variant="text" width={50} height={15} className="ml-4 mt-2" />
      </div>
    </div>
  );
};

export const RightBubbleSkeleton = () => {
  return (
    <div className="flex justify-end mb-4 !w-[900px]">
      <div>
        <div className="flex bg-[#EAF0FF] rounded-lg px-4 py-3 mr-4 ">
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width={700} height={20} className="ml-3" />
        </div>
        <Skeleton
          variant="text"
          width={50}
          height={15}
          className="ml-6 mt-2 text-right"
        />
      </div>
      <Skeleton variant="circular" width={40} height={40}>
        <Avatar />
      </Skeleton>
    </div>
  );
};
