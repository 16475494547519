import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Language = ({ languages = [] }) => {
  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Languages</h6>
      </div>

      <div className={`flex flex-wrap mt-5 overflow-hidden`}>
        {languages?.map((language, index) => (
          <p
            key={index}
            className="bg-light py-1 px-4 rounded-md mr-4 mb-2 cursor-pointer whitespace-nowrap"
          >
            {language?.language}
          </p>
        ))}
      </div>
    </BaseCard>
  );
};

export default Language;
