import React, { useContext } from "react";
import "./style.css";
import WorkOrderStepper from "./WorkOrderStepper";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import CreateWorkOrderHeader from "./CreateWorkOrderHeader";
import { LayoutContext } from "../../../context/LayoutContext";

const CreateWorkOrder = () => {
  const { isBannerVisible } = useContext(LayoutContext);
  return (
    <div className="bg-[#F6F6F6] h-full">
      <CreateWorkOrderHeader isBannerVisible={isBannerVisible} />
      <BaseWrapper>
        <WorkOrderStepper isBannerVisible={isBannerVisible} />
      </BaseWrapper>
    </div>
  );
};

export default CreateWorkOrder;
