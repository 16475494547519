import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import BaseButton from "../../components/ui/BaseButton";
import BaseSelect from "../../components/ui/BaseSelect";
import BaseCard from "../../components/ui/BaseCard";
import BaseTextField from "../../components/ui/BaseTextField";
import { companySignup } from "../../services/api";
import BaseCheckbox from "../../components/ui/BaseCheckbox";
import { useDispatch } from "react-redux";
import { showToast } from "../../store/toast/toastSlice";

const validationSchema = yup.object({
  firstName: yup
    .string("Enter your first name")
    .required("First name is required"),
  lastName: yup
    .string("Enter your last name")
    .required("Last name is required"),
  name: yup
    .string("Enter your company name")
    .required("Company name is required"),
  phoneNumber: yup
    .string("Enter your phone number")
    .required("Phone number is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  companyAnnualRevenue: yup
    .string("Enter your annual revenue")
    .required("Company annual revenue is required"),
  noOfEmployees: yup
    .string("Enter your total employees ")
    .required("This field is required"),
  technicianFrequency: yup
    .string("Enter this field")
    .required("This field is required"),
  technicianUrgency: yup
    .string("Enter this field")
    .required("This field is required"),
  referralSource: yup
    .string("Enter your source")
    .required("Source is required"),
  isAgree: yup
    .boolean()
    .oneOf([true], "You must agree to proceed")
    .default(false),
});

const CompanySignupForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isPending: isSignupLoading,
    status,
    error,
    mutateAsync: signupMutation,
  } = useMutation({
    mutationFn: companySignup,
    onSuccess: (data) => {
      otpSentSuccessfully(data);
    },
    onError: (error) => {
      if (error?.response?.status === 409) {
        const email = encodeURIComponent(formik.values.email);

        navigate(`/otp?email=${email}`);
      }
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      name: "",
      phoneNumber: "",
      email: "",
      password: "",
      companyAnnualRevenue: "",
      noOfEmployees: "",
      technicianFrequency: "",
      technicianUrgency: "",
      referralSource: "",
      isAgree: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        phoneNumber: values.phoneNumber.toString(),
      };
      signupMutation(payload);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const otpSentSuccessfully = (user) => {
    const email = encodeURIComponent(formik.values.email);

    navigate(`/otp?email=${email}`);
  };

  return (
    <>
      <div className="grid grid-cols-6 mx-7">
        <div></div>
        <div className="col-span-4 z-20">
          <BaseCard className="p-8">
            <p className="text-sm text-gray-950">NEW COMPANIES</p>
            <h2 className="mt-3">Find Trusted, Local IT Experts Instantly</h2>
            <p className="mt-7 mb-16 leading-normal">
              If you're a business in need of skilled IT technicians, fill out
              the form below to publish your work orders and find skilled <br />{" "}
              technicians in your desired area. If you are a
              <span className="font-semibold"> technician</span> looking for{" "}
              <span className="font-semibold"> work</span>, then{" "}
              <Link to="/technician-signup">
                <span className="text-secondary font-semibold underline underline-offset-[6px]">
                  {" "}
                  Sign Up here.
                </span>
              </Link>
            </p>

            <form>
              <div className="grid grid-cols-3 gap-x-8 gap-y-8">
                <BaseTextField
                  required
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <BaseTextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />

                <BaseTextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Company Name"
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <BaseTextField
                  fullWidth
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone number"
                  value={formik.values.phoneNumber}
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />

                <BaseTextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <BaseTextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <div className="col-span-3 flex items-center my-3">
                  <p>
                    <span className="mr-4">Company Information</span>
                  </p>
                  <hr className="flex-grow" />
                </div>

                <BaseSelect
                  required
                  fullHeight={false}
                  label="What's your company's annual revenue?"
                  options={[
                    { label: "Less then $10M", value: "10M" },
                    { label: "$10M - $24M", value: "10M-24M" },
                    { label: "$500M+", value: "500M+" },
                  ]}
                  name="companyAnnualRevenue"
                  value={formik.values.companyAnnualRevenue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.companyAnnualRevenue &&
                    Boolean(formik.errors.companyAnnualRevenue)
                  }
                  helpertext={
                    formik.touched.companyAnnualRevenue &&
                    formik.errors.companyAnnualRevenue
                  }
                />

                <BaseSelect
                  fullHeight={false}
                  required
                  label="How many employees does your company have?"
                  options={[
                    { label: "1-10", value: "1_10" },
                    { label: "11-24", value: "11_24" },
                    { label: "25-50", value: "25_50" },
                  ]}
                  name="noOfEmployees"
                  value={formik.values.noOfEmployees}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.noOfEmployees &&
                    Boolean(formik.errors.noOfEmployees)
                  }
                  helpertext={
                    formik.touched.noOfEmployees && formik.errors.noOfEmployees
                  }
                />
                <BaseSelect
                  fullHeight={false}
                  required
                  label="How often do you need technicians?"
                  options={[
                    { label: "Daily", value: "daily" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Monthly", value: "monthly" },
                  ]}
                  name="technicianFrequency"
                  value={formik.values.technicianFrequency}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.technicianFrequency &&
                    Boolean(formik.errors.technicianFrequency)
                  }
                  helpertext={
                    formik.touched.technicianFrequency &&
                    formik.errors.technicianFrequency
                  }
                />

                <BaseSelect
                  fullHeight={false}
                  required
                  label="How soon do you need technicians?"
                  options={[
                    { label: "Today", value: "today" },
                    { label: "This Week", value: "this_week" },
                    { label: "This Month", value: "this_month" },
                  ]}
                  name="technicianUrgency"
                  value={formik.values.technicianUrgency}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.technicianUrgency &&
                    Boolean(formik.errors.technicianUrgency)
                  }
                  helpertext={
                    formik.touched.technicianUrgency &&
                    formik.errors.technicianUrgency
                  }
                />

                <BaseTextField
                  required
                  fullWidth
                  id="referralSource"
                  name="referralSource"
                  className="col-span-2"
                  label="Where did you first hear about us?"
                  value={formik.values.referralSource}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.referralSource &&
                    Boolean(formik.errors.referralSource)
                  }
                  helperText={
                    formik.touched.referralSource &&
                    formik.errors.referralSource
                  }
                />

                <div className="col-span-3">
                  <p className="text-sm font-semibold">Terms of service</p>
                  <BaseCheckbox
                    name="isAgree"
                    checked={formik.values.isAgree}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.isAgree && Boolean(formik.errors.isAgree)
                    }
                    errorMsg={formik.touched.isAgree && formik.errors.isAgree}
                    label={
                      <p className="text-sm">
                        I agree to Tech Domain's{" "}
                        <Link className="underline underline-offset-2">
                          terms of service
                        </Link>
                      </p>
                    }
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <div>
                  <BaseButton
                    isLoading={isSignupLoading}
                    onClick={formik.handleSubmit}
                    className="mt-7"
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    <p className="text-sm font-semibold	font-semibold text-white px-7">
                      Submit
                    </p>
                  </BaseButton>
                </div>
              </div>
            </form>
          </BaseCard>
        </div>
      </div>
    </>
  );
};

export default CompanySignupForm;
