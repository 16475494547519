import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import useAuth from "../../../../hooks/useAuth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  fetchChatUsers,
  fetchOrCreateChatRoom,
  fetchPrivateChatMessages,
} from "../../../../services/api/message";
import Chat from "./components/Chat";
import ChatUsers from "./components/ChatUsers";
import { fetchUserDetails } from "../../../../services/api/user";
import { useSocket } from "../../../../context/SocketContext";
import { useSelector } from "react-redux";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const ChatTab = () => {
  const socket = useSocket();
  const queryParams = new URLSearchParams(window.location.search);
  const queryClient = useQueryClient();

  const { id: workOrderId, userId } = useParams();
  const engineerId = parseInt(queryParams.get("engineerId"));
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const { auth, authRole } = useAuth();
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [message, setMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [chatUsers, setChatUsers] = useState([]);
  const [isWindowActive, setIsWindowActive] = useState(true);

  // Fetch chat users dynamically based on role
  const {
    isLoading: isChatUsersLoading,
    refetch: refetchChatUsers,
    data: usersData,
  } = useQuery({
    queryKey: ["chatUsers", workOrderId, engineerId],
    queryFn: () => fetchChatUsers(workOrderId),
    enabled: !!workOrderId,
  });

  const {
    isLoading: isEngineerLoading,
    data: userData,
    refetch: refetchUserDetails,
  } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => fetchUserDetails(engineerId),
    enabled: !!engineerId,
  });

  const {
    isLoading: isMessagesLoading,
    error,
    data: privateChatMessagesData,
    refetch: refetchPrivateChatMessages,
  } = useQuery({
    queryKey: ["privateChatMessagesData", chatRoomId],
    queryFn: () => fetchPrivateChatMessages(chatRoomId),
    enabled: !!chatRoomId,
  });

  const {
    isPending: isForgotPasswordLoading,
    error: isForgotPasswordError,
    data: forgotPasswordData,
    mutateAsync: fetchOrCreateChatRoomMutation,
  } = useMutation({
    mutationFn: fetchOrCreateChatRoom,

    onError: (error) => {
      console.error("Error fetching chat room:", error.message);
    },
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsWindowActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  /* useEffect(() => {
    if (!socket) return;

    const messageListener = (msg) => {
      console.log("📩 Received message:", msg);

      // ✅ If message is from the currently selected chat user and window is active
      if (selectedUser && msg.senderId === selectedUser.id && isWindowActive) {
        console.log(
          "in if ",
          selectedUser,
          msg,
          selectedUser.id,
          isWindowActive
        );
        setMessages((prevMessages) => [...prevMessages, msg]);

        // ✅ Mark messages as read immediately
        socket.emit("markAsRead", { chatRoomId, userId: auth.user.id });

        // ✅ Reset unread count for this chat room
        setUnreadMessages((prev) => ({
          ...prev,
          [chatRoomId]: 0,
        }));
      } else {
        console.log("here");
        // If the user is not actively in the chat, update unread messages count
        setUnreadMessages((prev) => ({
          ...prev,
          [msg.chatRoomId]: (prev[msg.chatRoomId] || 0) + 1,
        }));
      }

      updateChatUsersList(msg);
    };

    socket.off("receiveMessage").on("receiveMessage", messageListener);

    return () => {
      socket.off("receiveMessage", messageListener);
    };
  }, [socket, chatRoomId, selectedUser, isWindowActive]); */

  useEffect(() => {
    if (!socket || !chatRoomId || !isWindowActive) return;

    socket.emit("markAsRead", { chatRoomId, userId: auth.user.id });

    // Reset unread counter for the current chat room
    setUnreadMessages((prev) => ({
      ...prev,
      [chatRoomId]: 0,
    }));
  }, [isWindowActive, chatRoomId]);

  useEffect(() => {
    if (!socket) return;

    const newChatUserListener = (data) => {
      console.log("🔔 New chat user received:", data);

      setChatUsers((prevUsers) => {
        if (prevUsers.some((user) => user.id === data.id)) {
          return prevUsers; // Prevent duplicates
        }
        return [...prevUsers, data]; // ✅ Add new user dynamically
      });
    };

    socket.off("newChatUser").on("newChatUser", newChatUserListener);

    return () => {
      socket.off("newChatUser", newChatUserListener);
    };
  }, [socket]);

  useEffect(() => {
    if (!usersData) return;
    setChatUsers(usersData);

    const unreadCounts = usersData.reduce((acc, user) => {
      acc[user.chatRoomId] = user.unreadCount; // Store unread count from backend
      return acc;
    }, {});

    setUnreadMessages(unreadCounts);
  }, [usersData]);

  useEffect(() => {
    if (!socket) return;

    const unreadMessageListener = (data) => {
      updateChatUsersList(data);
      setUnreadMessages((prev) => ({
        ...prev,
        [data.chatRoomId]: (prev[data.chatRoomId] || 0) + 1,
      }));
    };

    socket.on("unreadMessage", unreadMessageListener);

    return () => {
      socket.off("unreadMessage", unreadMessageListener);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    const messageListener = (msg) => {
      if (selectedUser && msg.senderId === selectedUser.id && isWindowActive) {
        setMessages((prevMessages) => [...prevMessages, msg]);

        // ✅ Mark messages as read immediately
        socket.emit("markAsRead", { chatRoomId, userId: auth.user.id });

        // ✅ Reset unread count for this chat room
        setUnreadMessages((prev) => ({
          ...prev,
          [chatRoomId]: 0,
        }));
      } else if (msg.senderId === auth.user.id && isWindowActive) {
        console.log("here");
        setMessages((prevMessages) => [...prevMessages, msg]);
        socket.emit("markAsRead", { chatRoomId, userId: auth.user.id });
        // If the user is not actively in the chat, update unread messages count
        setUnreadMessages((prev) => ({
          ...prev,
          [chatRoomId]: 0,
        }));
      } else {
        setUnreadMessages((prev) => ({
          ...prev,
          [msg.chatRoomId]: (prev[msg.chatRoomId] || 0) + 1,
        }));
      }
      updateChatUsersList(msg);
    };

    socket.off("receiveMessage").on("receiveMessage", messageListener);

    if (!chatRoomId) return;

    console.log("🔗 Emitting joinChat event for chatRoomId:", chatRoomId);

    let payload = {
      workOrderId: parseInt(workOrderId),
      receiverId: selectedUser.id,
      senderId: auth.user.id,
    };
    socket.emit("joinChat", payload, (ack) => {
      console.log("✅ joinChat event acknowledged by server:", ack);
    });

    return () => {
      console.log("🚪 Leaving chat room:", chatRoomId);
      socket.off("receiveMessage", messageListener);
    };
  }, [socket, chatRoomId, selectedUser, isWindowActive]);

  useEffect(() => {
    setMessages(privateChatMessagesData?.data);
  }, [privateChatMessagesData]);

  useEffect(() => {
    if (engineerId && userData) {
      openChat(userData);
      setChatUsers((prevUsers) => {
        if (prevUsers.some((user) => user.id === userData.id)) {
          return prevUsers;
        }

        return [userData, ...prevUsers];
      });
    }
  }, [engineerId, userData]);

  const refetchUserDetailsWithId = async (userId) => {
    try {
      const userData = await fetchUserDetails(userId);
      queryClient.setQueryData(["userDetails", userId], userData);
      return userData;
    } catch (error) {
      console.error("Failed to refetch user details:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (authRole === "FREELANCER" && workOrderDetail?.createdById) {
      refetchUserDetailsWithId(workOrderDetail.createdById)
        .then((userData) => {
          openChat(userData);
        })
        .catch((error) => {
          console.error("Failed to refetch user details:", error);
        });
    }
  }, [authRole, workOrderDetail?.createdById]);

  useEffect(() => {
    return () => {
      if (socket && chatRoomId) {
        console.log(`🚪 User is leaving chat room: ${chatRoomId}`);
        socket.emit("leaveChat", { chatRoomId, userId: auth.user.id });
      }
    };
  }, [chatRoomId]);

  const openChat = async (user) => {
    // 🔴 Leave the previous chat room before switching
    if (socket && chatRoomId) {
      socket.emit("leaveChat", { chatRoomId, userId: auth.user.id });
    }
    setSelectedUser(user);

    let chatRoomToUse = user.chatRoomId;

    // ✅ If no chatRoomId exists, fetch or create one
    if (!chatRoomToUse) {
      try {
        const payload = {
          workOrderId: parseInt(workOrderId),
          receiverId: user.id,
          senderId: auth.user.id,
        };

        const chatRoomData = await fetchOrCreateChatRoomMutation(payload);
        chatRoomToUse = chatRoomData.data.id;

        console.log("user in if", chatRoomToUse, user);
        // ✅ Ensure chatRoomId is updated before emitting event
      } catch (error) {
        console.error("❌ Error opening chat:", error);
        return;
      }
    }
    setChatRoomId(chatRoomToUse);

    // ✅ Emit event to join chat (only for the user opening it)
    if (socket) {
      socket.emit("joinChat", {
        chatRoomId: chatRoomToUse,
        senderId: auth.user.id,
        receiverId: user.id,
      });
    }

    // ✅ Reset unread messages count for this chat room immediately
    setUnreadMessages((prev) => ({
      ...prev,
      [chatRoomToUse]: 0, // Set unread count to 0
    }));
  };

  const updateChatUsersList = (msg) => {
    setChatUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) =>
        user.chatRoomId === msg.chatRoomId
          ? {
              ...user,
              latestMessage: {
                message: msg.message,
                senderId: msg.senderId,
                createdAt: msg.createdAt,
              },
              unreadCount:
                msg.senderId !== auth.user.id
                  ? (prevUsers.find((u) => u.chatRoomId === msg.chatRoomId)
                      ?.unreadCount || 0) + 1
                  : 0, // ✅ Reset unread count if the logged-in user sent the message
            }
          : user
      );

      // ✅ Sort users by the most recent message timestamp
      return updatedUsers.sort((a, b) => {
        const dateA = a.latestMessage?.createdAt
          ? new Date(a.latestMessage.createdAt).getTime()
          : 0;
        const dateB = b.latestMessage?.createdAt
          ? new Date(b.latestMessage.createdAt).getTime()
          : 0;
        return dateB - dateA; // ✅ Sort descending (most recent first)
      });
    });
  };

  // Send message
  const sendMessage = () => {
    if (message.trim() && chatRoomId) {
      socket.emit("sendMessage", {
        chatRoomId,
        senderId: auth?.user.id,
        message,
        workOrderId: parseInt(workOrderId),
      });

      setMessage("");
    }
  };

  return (
    <div className="grid grid-cols-12 h-screen">
      {authRole === "CLIENT" && (
        <div className="col-span-3">
          <ChatUsers
            isLoading={isChatUsersLoading}
            chatUsers={chatUsers}
            openChat={openChat}
            selectedUser={selectedUser}
            unreadMessages={unreadMessages}
          />
        </div>
      )}

      <div className={authRole === "CLIENT" ? "col-span-9" : "col-span-12"}>
        {selectedUser ? (
          <Chat
            isLoading={isMessagesLoading}
            selectedUser={selectedUser}
            sendMessage={sendMessage}
            messages={messages}
            message={message}
            handleMessage={(e) => setMessage(e.target.value)}
            authUser={auth?.user}
          />
        ) : (
          <div className="flex flex-col justify-center items-center h-[500px]">
            <h6 className="font-normal">No Conversation Selected</h6>
            <p>Choose a message from the list to get started.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatTab;
