import React, { useState } from "react";
import BaseButton from "../../../components/ui/BaseButton";
import AddGeneralInsuranceModal from "../components/AddGeneralInsuranceModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteUserInsurance,
  fetchAllUserInsurances,
} from "../../../services/api/insurance";
import dayjs from "dayjs";
import { InsuranceType } from "../../../utils/enum";

const Insurance = ({ userId }) => {
  const [isGeneralInsuranceOpen, setIsGeneralInsuranceOpen] = useState(false);

  const {
    isPending: isDeletedInsuranceApiLoading,
    error: isDeletedInsuranceApiError,
    data: deletedInsuranceData,
    mutateAsync: deleteUserInsuranceMutation,
  } = useMutation({
    mutationFn: deleteUserInsurance,
    enabled: false,
  });

  const {
    data: insurances,
    isPending: isInsurancesLoading,
    error: isInsurancesError,
    refetch: refetchAllUserInsurances,
  } = useQuery({
    queryKey: ["insurances"],
    queryFn: () => fetchAllUserInsurances(userId),
    enabled: !!userId,
  });

  const handleDeleteInsurance = async (id) => {
    try {
      await deleteUserInsuranceMutation(id);
      refetchAllUserInsurances();
    } catch (error) {}
  };

  return (
    <section className="py-4 px-5 ">
      <div className="flex justify-end">
        <BaseButton
          color="primary"
          variant="outlined"
          className="py-[7px] px-[10px]"
          onClick={() => setIsGeneralInsuranceOpen(true)}
        >
          Upload Policy
        </BaseButton>
      </div>

      {insurances?.data?.map((insurance) => (
        <div className="flex justify-between items-center" key={insurance.id}>
          <div className="mb-4 border-b pb-3 w-full">
            <p className="font-normal text-sm">
              {InsuranceType[insurance?.insuranceType]}
            </p>
            <div>
              <p className="font-normal">
                Company Name:{" "}
                <span className="font-300">{insurance?.companyName}</span>
              </p>

              <p className="font-normal">
                Policy Number:{" "}
                <span className="font-300">{insurance?.policyNumber}</span>
              </p>
              <div className="flex">
                <p className="font-normal">
                  Start Date:{" "}
                  <span className="font-300">
                    {dayjs(insurance?.startDate).format("DD/MM/YYYY")}
                  </span>
                </p>
                <p className="font-normal ml-8">
                  End Date:{" "}
                  <span className="font-300">
                    {dayjs(insurance?.endDate).format("DD/MM/YYYY")}
                  </span>
                </p>
              </div>
              {insurance.url && (
                <a
                  key={insurance.id}
                  href={insurance.url}
                  download={"insurance"}
                  target="_blank" // ✅ Open in a new tab if needed
                  rel="noopener noreferrer"
                  className="cursor-pointer text-blue-500 !hover:text-blue-500 hover:underline font-300 !hover:font-[300]"
                >
                  Attachment
                </a>
              )}
            </div>
          </div>
          <i
            class="fa-solid fa-trash-can cursor-pointer"
            onClick={() => handleDeleteInsurance(insurance.id)}
          ></i>
        </div>
      ))}

      <AddGeneralInsuranceModal
        refetchAllUserInsurances={refetchAllUserInsurances}
        open={isGeneralInsuranceOpen}
        close={setIsGeneralInsuranceOpen}
      />
    </section>
  );
};

export default Insurance;
