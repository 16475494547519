import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { TextField, InputAdornment } from "@mui/material";

const SkillSet = ({ skills = [] }) => {
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const MAX_ITEMS = 8;

  const filteredSkills = skills?.filter((skill) =>
    (skill?.subCategory).toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Skill Set</h6>
      </div>

      {/* Search Input */}
      <TextField
        placeholder="Search"
        className="bg-light rounded-md py-1.5 w-64"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="fa-solid fa-search text-[#B0B0B0]"></i>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "transparent" },
            "&:hover fieldset": { borderColor: "transparent" },
            "&.Mui-focused fieldset": { borderColor: "transparent" },
          },
        }}
      />

      <div
        className={`flex flex-wrap mt-5 overflow-hidden ${
          expanded ? "h-auto" : ""
        }`}
      >
        {(expanded ? filteredSkills : filteredSkills.slice(0, MAX_ITEMS)).map(
          (skill, index) => (
            <p
              key={index}
              className="bg-light py-1 px-4 rounded-md mr-4 mb-2 cursor-pointer whitespace-nowrap"
            >
              {skill?.subCategory}
            </p>
          )
        )}
      </div>

      {/* View More / View Less Button */}
      {filteredSkills.length > MAX_ITEMS && (
        <p
          className="text-primary cursor-pointer mt-2"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "View Less" : "View More"}
        </p>
      )}
    </BaseCard>
  );
};

export default SkillSet;
