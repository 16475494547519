import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseCard from "../../../components/ui/BaseCard";
import BaseTextField from "../../../components/ui/BaseTextField";
import { createWorkOrder, signup } from "../../../services/api";

import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";

const validationSchema = yup.object({
  // title: yup.string("Enter your first name").required("First name is required"),
});

const ShipmentsForm = () => {
  const {
    status,
    error,
    mutateAsync: createWorkOrderMutation,
  } = useMutation({
    mutationFn: createWorkOrder,
    onSuccess: (data) => {},
  });

  const formik = useFormik({
    initialValues: {
      trackingNumber: "",
      trackingDetails: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // createWorkOrderMutation(values);
    },
  });

  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-1 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">
          Shipments <span className="text-sm">(optional)</span>
        </h6>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2 gap-y-4">
          <div className="col-span-2 grid grid-cols-2 gap-y-4 bg-[#F6F6F6] px-5 pt-5 pb-12 rounded-lg">
            <div className="mr-10">
              <BaseTextField
                fullWidth
                fullHeight={false}
                id="title"
                name="title"
                label="Carrier Tracking Number"
                optional={false}
                labelShrink={false}
                className="col-span-4"
                // value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.title && Boolean(formik.errors.title)}
                // helpertext={formik.touched.title && formik.errors.title}
                isDefaultField={false}
              />
            </div>

            <div>
              <BaseTextField
                fullWidth
                fullHeight={false}
                id="title"
                name="title"
                label="Details"
                optional={false}
                labelShrink={false}
                className="col-span-4"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.title && Boolean(formik.errors.title)}
                // helpertext={formik.touched.title && formik.errors.title}
                isDefaultField={false}
              />
            </div>

            <div className="mr-10">
              <BaseSelect
                fullHeight={false}
                fullWidth
                label="Carrier"
                labelShrink={false}
                required={true}
                options={[]}
                name="clientId"
                value={formik.values.clientId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.clientId && Boolean(formik.errors.clientId)
                }
                helpertext={formik.touched.clientId && formik.errors.clientId}
                isDefaultField={false}
              />
            </div>

            <div>
              <BaseSelect
                fullHeight={false}
                fullWidth
                label="Destination"
                labelShrink={false}
                required={true}
                options={[]}
                name="clientId"
                value={formik.values.clientId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.clientId && Boolean(formik.errors.clientId)
                }
                helpertext={formik.touched.clientId && formik.errors.clientId}
                isDefaultField={false}
              />
            </div>
          </div>

          <div className="flex items-center cursor-pointer">
            <PlusIcon />
            <p className="text-secondary ml-2"> Add Shipment</p>
          </div>
        </div>
      </form>
    </BaseCard>
  );
};

export default ShipmentsForm;
