import axios from "../../lib/axiosInstance";

export function createEducation(payload) {
  return axios.post("education", payload).then((res) => res.data);
}
export function fetchUserEducationList(id) {
  return axios.get(`education/all/${id}`).then((res) => res.data);
}
export function fetchUserEducation(id) {
  return axios.get(`education/${id}`).then((res) => res.data);
}
export function deleteUserEducation(id) {
  return axios.delete(`education/${id}`).then((res) => res.data);
}

export function updateUserEducation(payload) {
  return axios
    .patch(`education/${payload.id}`, payload)
    .then((res) => res.data);
}
