import React, { useContext, useEffect, useState } from "react";
import ProfileDetailsHeader from "./ProfileDetailsHeader";
import BaseWrapper from "../../components/ui/BaseWrapper";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
  brandFontFamily,
  brandInputTextColor,
  brandPrimaryColor,
} from "../../utils/variables";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Profile from "./Profile";
import routes from "../../routes";
import { LayoutContext } from "../../context/LayoutContext";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useQuery } from "@tanstack/react-query";
import { fetchUserDetails } from "../../services/api/user";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    height: "38px",
    width: "230px",
    fontWeight: 300,
    fontSize: "16px",
    boxShadow: "0px -1px 8px 0px rgba(0, 0, 0, 0.09)",

    borderRadius: "6px",
    // padding: 0,
    margin: "20px 0",
    background: "white",
    marginRight: theme.spacing(1),
    color: brandInputTextColor,
    fontFamily: brandFontFamily,
    "&:hover": {
      /* color: "#40a9ff",
          opacity: 1, */
    },
    "&.Mui-selected": {
      color: brandPrimaryColor,
      fontWeight: 400,
      borderBottom: `1px solid ${brandPrimaryColor}`,
    },
    "&.Mui-focusVisible": {
      //   backgroundColor: "#d1eaff",
    },
  })
);

const TABS = [
  {
    label: "Profile",
    component: <Profile />,
    // allowedRoles: ["CLIENT", "FREELANCER"],
  },
  {
    label: "Private Feedback",
    component: <h1>Profile</h1>,
    // allowedRoles: ["CLIENT", "FREELANCER"],
  },
  {
    label: "Notes",
    component: <h1>Profile</h1>,
    // allowedRoles: ["CLIENT"],
  },
  {
    label: "Availability",
    component: <h1>Engagement</h1>,
    // allowedRoles: ["CLIENT"],
  },
  {
    label: "Recommendations",
    component: <h1>History</h1>,
    // allowedRoles: ["CLIENT"],
  },
  {
    label: "Messages",
    component: <h1>Profile</h1>,
    // allowedRoles: ["CLIENT", "FREELANCER"],
  },
];

const ProfileDetails = () => {
  const { isBannerVisible } = useContext(LayoutContext);
  const [value, setValue] = useState(0);

  const { id: userId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isPending: isUserLoading,
    error: isUserError,
    refetch: refetchMe,
    data: userData,
  } = useQuery({
    queryKey: ["userData", userId], // Unique query key for caching
    queryFn: () => fetchUserDetails(userId),
    retry: 2,
    enabled: !!userId,
  });

  const userProfileRoute = routes.find(
    (route) => route.path === "/user/details"
  );

  const allTabs = userProfileRoute?.childs || [];

  useEffect(() => {
    const currentTabIndex = allTabs.findIndex((tab) =>
      location.pathname.includes(tab.path.split("/:id")[0])
    );
    if (currentTabIndex !== -1) {
      setValue(currentTabIndex);
    }
  }, [location.pathname, allTabs]);

  const handleChange = (event, newValue) => {
    const selectedTab = allTabs[newValue];
    navigate(`/user/details/${selectedTab.path.replace(":id", userId)}`); // Replace with dynamic ID if necessary
    setValue(newValue);
  };

  return (
    <div className="bg-light h-full">
      <div
        className={`border-b border-zinc-300 py-6 sticky  bg-white ${
          isBannerVisible ? "top-[95px]" : "top-[40px]"
        }`}
        style={{ zIndex: 9 }}
      >
        <BaseWrapper>
          <ProfileDetailsHeader user={userData} />
        </BaseWrapper>
      </div>
      <BaseWrapper
        className={`relative ${isBannerVisible ? "top-24" : "top-10"}`}
      >
        <Tabs
          TabIndicatorProps={{
            style: {
              display: "none", // Hide the indicator
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {allTabs.map(
            (tab, index) => (
              // tab.allowedRoles.includes(authRole) && (
              <CustomTab
                key={index}
                label={tab.text}
                {...a11yProps(index)}
                sx={{
                  minHeight: "38px",
                  marginRight: "40px",
                }}
              />
            )
            // )
          )}
        </Tabs>

        {allTabs.map((tab, index) => {
          return (
            <CustomTabPanel value={value} index={index} key={index}>
              {React.cloneElement(tab.component, {
                user: userData,
              })}
            </CustomTabPanel>
          );
        })}
      </BaseWrapper>
    </div>
  );
};

export default ProfileDetails;
