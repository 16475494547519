import React, { useContext, useEffect, useState } from "react";
import useUserLocation from "../../hooks/useUserLocation";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchFreelancerJobs,
  fetchJobsCount,
  findJobs,
} from "../../services/api/workOrder";
import FindJobTable from "./FindJobTable";
import FindJobListingHeader from "./FindJobListingHeader";
import { logout } from "../../utils/helpers";
import BaseLoader from "../../components/ui/BaseLoader";
import { setJobEngineerFilters } from "../../store/filters/filtersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useWorkOrderSocket } from "../../hooks/useWorkOrderSocket";
import { LayoutContext } from "../../context/LayoutContext";

const FindJob = () => {
  const { isBannerVisible } = useContext(LayoutContext);
  const { jobs, isAvailableJobsLoading, jobsFilters, setJobsFilters } =
    useWorkOrderSocket();
  const engineerFilters = useSelector((state) => state.filters.value);
  const [freelancerJobs, setFreelancerJobs] = useState([]);

  const dispatch = useDispatch();

  const {
    userCurrentLocation,
    error: locationError,
    isLoading: isLocationLoading,
  } = useUserLocation();

  const {
    isLoading: isJobsCountLoading,
    error: isJobsCountError,
    data: jobsCount,
    refetch: reFetchJobsCount,
  } = useQuery({
    queryKey: ["jobsCount"],
    queryFn: () =>
      fetchJobsCount({
        latitude: engineerFilters?.coverageAreaCoordinates?.latitude,
        longitude: engineerFilters?.coverageAreaCoordinates?.longitude,
        radius: engineerFilters?.coverageAreaRadius,
      }),
    enabled: !!engineerFilters?.coverageAreaCoordinates.latitude,
  });

  const {
    isLoading: isWorkOrderLoading,
    error: isWorkOrderError,
    data: jobsData,
    refetch: fetchJobs,
  } = useQuery({
    queryKey: ["findJobs"],
    queryFn: () =>
      findJobs({
        latitude: engineerFilters?.coverageAreaCoordinates?.latitude,
        longitude: engineerFilters?.coverageAreaCoordinates?.longitude,
        radius: engineerFilters?.coverageAreaRadius, // Example radius of 5 km
      }),
    enabled: false,
  });

  const {
    isLoading: isFreelancerJobsLoading,
    error: isFreelancerJobsError,
    data: freealncerjobsData,
    mutateAsync: fetchFreelancerJobsMutation,
  } = useMutation({
    mutationFn: fetchFreelancerJobs,
    enabled: false,
  });

  if (isWorkOrderError?.response?.status === 401) {
    logout();
  }

  useEffect(() => {
    reFetchJobsCount();
    if (jobsFilters.status === "AVAILABLE") {
      setFreelancerJobs(jobs || []);
    } else {
      fetchFreelancerJobsApi();
    }
  }, [jobsFilters, jobs]);

  useEffect(() => {
    // Determine the latitude and longitude to use
    const latitude =
      engineerFilters?.coverageAreaCoordinates?.latitude ||
      userCurrentLocation?.latitude;
    const longitude =
      engineerFilters?.coverageAreaCoordinates?.longitude ||
      userCurrentLocation?.longitude;

    const payload = {
      filterName: "coverageAreaCoordinates",
      data: {
        latitude: latitude,
        longitude: longitude,
      },
    };
    dispatch(setJobEngineerFilters(payload));
  }, [userCurrentLocation]);

  const handleRadiusChange = (newRadius) => {
    const payload = {
      filterName: "coverageAreaRadius",
      data: newRadius,
    };
    dispatch(setJobEngineerFilters(payload));
  };
  const handleLocationSearch = (address) => {
    const payload = {
      filterName: "coverageAreaCoordinates",
      data: {
        latitude: address?.latitude,
        longitude: address?.longitude,
      },
    };

    dispatch(setJobEngineerFilters(payload));
    dispatch(
      setJobEngineerFilters({
        filterName: "coverageAreaSearchText",
        data: address.completeAddress,
      })
    );
  };

  const fetchFreelancerJobsApi = async () => {
    try {
      const response = await fetchFreelancerJobsMutation({
        ...jobsFilters,
        latitude: engineerFilters?.coverageAreaCoordinates?.latitude,
        longitude: engineerFilters?.coverageAreaCoordinates?.longitude,
      });
      setFreelancerJobs(response?.data);
    } catch (error) {}
  };

  return (
    <>
      <BaseLoader
        isLoading={
          isAvailableJobsLoading || isFreelancerJobsLoading || isLocationLoading
        }
      />
      <div
        className={`bg-[#F6F6F6] h-screen pt-4 fixed w-[95%] ${
          isBannerVisible ? "top-[91px]" : "top-[56px]"
        }`}
      >
        <FindJobListingHeader
          radius={engineerFilters?.coverageAreaRadius}
          handleRadiusChange={handleRadiusChange}
          fetchJobs={fetchJobs}
          handleLocationSearch={handleLocationSearch}
          selectedLocation={engineerFilters?.coverageAreaSearchText}
        />
        <FindJobTable
          jobs={freelancerJobs}
          setJobsFilters={setJobsFilters}
          jobsFilters={jobsFilters}
          jobsCount={jobsCount?.data}
        />
      </div>
    </>
  );
};

export default FindJob;
