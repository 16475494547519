import { useState, useEffect } from "react";
import axios from "axios";

// ✅ Move API key handling outside to prevent re-renders
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const useUserLocation = () => {
  const [locationDetails, setLocationDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [retry, setRetry] = useState(0); // ✅ Track retry attempts

  useEffect(() => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by this browser.");
      setLoading(false);
      return;
    }

    const fetchLocation = () => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          if (!GOOGLE_API_KEY) {
            setError("Google API Key is missing. Please check your .env file.");
            setLoading(false);
            return;
          }

          try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
            );

            if (response.data.results.length > 0) {
              const location = response.data.results[0];
              const addressComponents = location.address_components;

              const details = {
                formattedAddress: location.formatted_address,
                city:
                  addressComponents.find((component) =>
                    component.types.includes("locality")
                  )?.long_name || "N/A",
                state:
                  addressComponents.find((component) =>
                    component.types.includes("administrative_area_level_1")
                  )?.long_name || "N/A",
                country:
                  addressComponents.find((component) =>
                    component.types.includes("country")
                  )?.long_name || "N/A",
                postalCode:
                  addressComponents.find((component) =>
                    component.types.includes("postal_code")
                  )?.long_name || "N/A",
                latitude,
                longitude,
              };

              setLocationDetails(details);
            } else {
              setLocationDetails({
                formattedAddress: "Address not found",
                city: "N/A",
                state: "N/A",
                country: "N/A",
                postalCode: "N/A",
                latitude,
                longitude,
              });
            }
          } catch (err) {
            console.error("Error fetching location data:", err);
            setError("Error fetching location data: " + err.message);
          } finally {
            setLoading(false);
          }
        },
        (err) => {
          console.error("Geolocation error:", err);
          if (err.code === 2) {
            setError(
              "Location services are enabled, but the device cannot fetch your position. Try again later."
            );
            if (retry < 2) {
              setTimeout(() => setRetry(retry + 1), 3000); // ✅ Retry after 3 seconds
            }
          } else if (err.code === 1) {
            setError(
              "Location permission denied. Please allow location access."
            );
          } else {
            setError("Geolocation error: " + err.message);
          }
          setLoading(false);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 } // ✅ Increase timeout and disable caching
      );
    };

    fetchLocation();
  }, [retry]); // ✅ Retry if POSITION_UNAVAILABLE

  return { userCurrentLocation: locationDetails, error, loading };
};

export default useUserLocation;
