import React, { useState } from "react";
import ProfileStep from "./ProfileStep";
import Accordion from "@mui/material/Accordion";
import * as yup from "yup";
import { useFormik } from "formik";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./style.css";
import VerifyIdentity from "../../Forms/VerifyIdentity";
import TaxInformation from "../../Forms/TaxInformation";
import BillingRates from "../../Forms/BillingRates";
import BankDetails from "../../Forms/BankDetails";
import Insurance from "../../Forms/Insurance";
import ProfileDetails from "../../Forms/ProfileDetails";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";

const validationSchema = yup.object({
  businessNumber: yup
    .number("Enter your Business Number")
    .required("Business Number is required"),

  paypalEmail: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),

  wiseEmail: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),

  nameOnAccount: yup
    .string("Enter your name on Account")
    .required("Account name is required"),

  accountNumber: yup
    .number("Enter your Account Number")
    .required("Account Number is required"),

  iban: yup.string("Enter your IBAN").required("IBAN is required"),
  ifsc: yup.number("Enter your IFSC").required("IFSC is required"),
  swiftCode: yup
    .string("Enter your Swift Code")
    .required("Swift Code is required"),
  bsb: yup.number("Enter your BSB").required("BSB is required"),
  sortCode: yup
    .number("Enter your Sort Code")
    .required("Sort Code is required"),

  routingNumber: yup
    .number("Enter your Routing Number")
    .required("Routing Number is required"),

  type: yup
    .string("Enter your Account Type")
    .required("Account Type is required"),

  institutionNumber: yup
    .number("Enter your Institution Number")
    .required("Institution Number is required"),

  transitNumber: yup
    .number("Enter your Transit Number")
    .required("Transit Number is required"),
  address: yup
    .string("Enter Branch Address")
    .required("Branch Address is required"),
});
const CompleteProfileSteps = () => {
  const { userData, refetchMe, isUserLoading } = useCurrentUser();
  const [expanded, setExpanded] = useState("panel1");
  const formik = useFormik({
    initialValues: {
      businessNumber: "",
      paypalEmail: "",
      wiseEmail: "",
      nameOnAccount: "",
      accountNumber: null,
      iban: "",
      ifsc: "",
      swiftCode: "",
      bsb: null,
      sortCode: null,
      routingNumber: null,
      type: "",
      institutionNumber: null,
      transitNumber: null,
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="grid grid-cols-12 gap-12 mt-5">
      <div className="col-span-3 flex flex-col">
        <ProfileStep step="Verify your Identity" />
        <ProfileStep step="Tax Information" />
        <ProfileStep step="Billing rates" />
        {/* <ProfileStep step="Bank Details" /> */}
        <ProfileStep step="Insurance" />
        <ProfileStep step="Profile Details" />
      </div>
      <section className="col-span-9">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className={`rounded-xl mb-4 shadow`}
          sx={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            sx={{
              boxShadow: "none",
            }}
            expandIcon={
              <i
                className={`fa-solid fa-chevron-down ${
                  expanded === "panel1" && "text-white "
                }`}
              ></i>
            }
            aria-controls="panel1-content"
            id="panel1-header"
            className={`rounded-xl py-1 px-8`}
          >
            <h6 className={`font-normal`}>Verify your Identity</h6>
          </AccordionSummary>
          <AccordionDetails>
            <VerifyIdentity
              refetchMe={refetchMe}
              address={userData?.personalAddress}
              identityDocuments={userData?.identityDocuments}
              isUserLoading={isUserLoading}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className={`rounded-xl shadow`}
          sx={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            sx={{
              boxShadow: "none",
            }}
            expandIcon={
              <i
                className={`fa-solid fa-chevron-down ${
                  expanded === "panel2" && "text-white "
                }`}
              ></i>
            }
            aria-controls="panel2-content"
            id="panel2-header"
            className={`rounded-xl py-1 px-8`}
          >
            <h6 className={`font-normal`}>Tax Information</h6>
          </AccordionSummary>
          <AccordionDetails>
            <TaxInformation
              taxInformation={userData?.australianBusinessNumber}
              reFetchUser={refetchMe}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className={`rounded-xl shadow`}
          sx={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            sx={{
              boxShadow: "none",
            }}
            expandIcon={
              <i
                className={`fa-solid fa-chevron-down ${
                  expanded === "panel3" && "text-white "
                }`}
              ></i>
            }
            aria-controls="panel3-content"
            id="panel3-header"
            className={`rounded-xl py-1 px-8`}
          >
            <h6 className={`font-normal`}>Billing rates</h6>
          </AccordionSummary>
          <AccordionDetails>
            <BillingRates userBillingRates={userData?.billingRates} />
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className={`rounded-xl shadow`}
          sx={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            sx={{
              boxShadow: "none",
            }}
            expandIcon={
              <i
                className={`fa-solid fa-chevron-down ${
                  expanded === "panel4" && "text-white "
                }`}
              ></i>
            }
            aria-controls="panel4-content"
            id="panel4-header"
            className={`rounded-xl py-1 px-8`}
          >
            <h6 className={`font-normal`}>Bank Details</h6>
          </AccordionSummary>
          <AccordionDetails>
            <BankDetails />
          </AccordionDetails>
        </Accordion> */}

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className={`rounded-xl shadow`}
          sx={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            sx={{
              boxShadow: "none",
            }}
            expandIcon={
              <i
                className={`fa-solid fa-chevron-down ${
                  expanded === "panel5" && "text-white "
                }`}
              ></i>
            }
            aria-controls="panel5-content"
            id="panel5-header"
            className={`rounded-xl py-1 px-8`}
          >
            <h6 className={`font-normal`}>Insurance</h6>
          </AccordionSummary>
          <AccordionDetails>
            <Insurance userId={userData?.id} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          className={`rounded-xl shadow`}
          sx={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            sx={{
              boxShadow: "none",
            }}
            expandIcon={
              <i
                className={`fa-solid fa-chevron-down ${
                  expanded === "panel6" && "text-white "
                }`}
              ></i>
            }
            aria-controls="panel6-content"
            id="panel6-header"
            className={`rounded-xl py-1 px-8`}
          >
            <h6 className={`font-normal`}>Profile Details</h6>
          </AccordionSummary>
          <AccordionDetails>
            <ProfileDetails userData={userData} refetchMe={refetchMe} />
          </AccordionDetails>
        </Accordion>
      </section>
    </div>
  );
};

export default CompleteProfileSteps;
