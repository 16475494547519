import { useEffect } from "react";
import { useSocket } from "../context/SocketContext";
import useAuth from "./useAuth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchUserNotifications,
  markNotificationAsRead,
  markNotificationAsReadAll,
} from "../services/api/notifications";

export function useNotificationsSocket() {
  const { auth } = useAuth();
  const socket = useSocket();
  const queryClient = useQueryClient();

  const { data: notifications } = useQuery({
    queryKey: ["notifications", auth.user.id],
    queryFn: () => fetchUserNotifications(auth.user.id),
    enabled: !!auth.user.id,
  });

  useEffect(() => {
    if (socket) {
      socket.on("notification", (newNotification) => {
        console.log("new notification ", newNotification);
        queryClient.setQueryData(["notifications", auth.user.id], (old) => {
          const oldNotifications = old?.data ? old?.data : old;
          const exists = oldNotifications?.some(
            (n) => n.id === newNotification.id
          );
          if (exists) return oldNotifications;
          return [newNotification, ...oldNotifications];
        });
      });
      return () => socket.off("notification");
    }
  }, [socket, queryClient, auth.user.id]);

  const markAsRead = useMutation({
    mutationFn: (id) => markNotificationAsRead(id),
    onSuccess: (_, id) => {
      queryClient.setQueryData(["notifications", auth.user.id], (old) =>
        old.map((n) => (n.id === id ? { ...n, isRead: true } : n))
      );
    },
  });

  const markAllAsRead = useMutation({
    mutationFn: () => markNotificationAsReadAll(),
    onSuccess: () => {
      queryClient.setQueryData(
        auth.user?.id ? ["notifications", auth.user.id] : ["notifications"],
        (old = []) =>
          old?.map(
            (n) => (n.type !== "MESSAGE" ? { ...n, isRead: true } : n) // ✅ Mark only non-MESSAGE notifications
          )
      );
    },
  });

  const chatNotifications =
    (Array.isArray(notifications)
      ? notifications
      : notifications?.data || []
    ).filter((n) => n.type === "MESSAGE") || [];
  const otherNotifications =
    (Array.isArray(notifications)
      ? notifications
      : notifications?.data || []
    ).filter((n) => n.type !== "MESSAGE") || [];

  const unreadNotificationCount =
    (Array.isArray(notifications)
      ? notifications
      : notifications?.data || []
    ).filter((n) => !n.isRead && n.type !== "MESSAGE").length || 0;

  const unreadMessageCount =
    (Array.isArray(notifications)
      ? notifications
      : notifications?.data || []
    ).filter((n) => !n.isRead && n.type === "MESSAGE").length || 0;

  return {
    notifications: otherNotifications,
    chatNotifications,
    markAsRead,
    markAllAsRead,
    unreadNotificationCount,
    unreadMessageCount,
  };
}
