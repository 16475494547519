import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { updateTask } from "../../../services/api/task";

import MarkCompleteModal from "../MarkCompleteModal";
import { setCloseOutNotesModalOpen } from "../../../store/modalsManagement/modalsManagementSlice";

const CloseoutNotesModal = ({
  closeOutNotesModalOpen,
  markCompleteModalOpen,
  setMarkCompleteModalOpen,
  setEngineerFeedbackModalOpen,
  tasks,
}) => {
  const [closeNotes, setCloseNotes] = useState("");
  const dispatch = useDispatch();

  const {
    isLoading: isCloseOutNotesLoading,
    error: isCloseOutNotesError,
    data: checkOutTimeLogData,
    mutateAsync: updateTaskMutation,
  } = useMutation({
    mutationFn: updateTask,
    enabled: false,
  });

  const handleCloseNotes = async () => {
    try {
      const task = tasks?.find((task) => task.taskType === "CLOSE_NOTES");
      // Create the payload with correct latitude and longitude
      let payload = {
        id: task.completedTask.id,
        text: closeNotes,
      };

      const response = await updateTaskMutation(payload);

      // updateWorkOrder();
    } catch (error) {
      console.error("Error during check-in:", error);
      // Handle the error if necessary
    }
  };

  const handleNoteChange = (e) => {
    setCloseNotes(e.target.value);
  };

  return (
    <>
      <BaseModal
        open={closeOutNotesModalOpen}
        close={() => {}}
        style={{ width: "700px" }}
      >
        <div className="container">
          <h6 className="text-dark border-bottom mb-4 pb-1">
            Enter Closeout Notes
          </h6>
          <p className="italic">
            Please write a summary about the work done or any issues faced
            during the task execution.
          </p>

          <textarea
            className="shadow border-t border-zinc-100	w-full rounded-md h-44 resize-none p-4 mt-3 focus:outline-none focus:ring-1 focus:ring-orange-400 text-xs font-[300] text-dark"
            onChange={(e) => handleNoteChange(e)}
          ></textarea>
        </div>
        <div
          className="flex items-center justify-center bg-white py-6 px-5 mt-1"
          style={{
            boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
          }}
        >
          <BaseButton
            className="py-[5px] px-2 bg-[#E8E8E8] text-dark"
            fullWidth={false}
            onClick={() => dispatch(setCloseOutNotesModalOpen(false))}
          >
            <p>Cancel</p>
          </BaseButton>
          <BaseButton
            className="py-[5px] px-4 ml-4"
            color="primary"
            fullWidth={false}
            onClick={() => {
              if (
                closeNotes.length < 1 ||
                closeNotes == " " ||
                closeNotes == null
              ) {
                return;
              }
              setMarkCompleteModalOpen(true);
              dispatch(setCloseOutNotesModalOpen(false));
            }}
          >
            Save
          </BaseButton>
        </div>
      </BaseModal>
      <MarkCompleteModal
        markCompleteModalOpen={markCompleteModalOpen}
        setMarkCompleteModalOpen={setMarkCompleteModalOpen}
        handleCloseNotes={handleCloseNotes}
        setCloseNotes={setCloseNotes}
        setEngineerFeedbackModalOpen={setEngineerFeedbackModalOpen}
        tasks={tasks}
      />
    </>
  );
};

export default CloseoutNotesModal;
