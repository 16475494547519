import React, { useEffect, useState } from "react";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseButton from "../../../components/ui/BaseButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { createOrUpdateUserBillingRates } from "../../../services/api/user";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";

const validationSchema = yup.object({
  paymentType: yup
    .string("Select your pay type")
    .required("Pay type is required"),
  currency: yup.string("Select currency").required("currency is required"),
  rate: yup.number("Enter your rate").required("Rate is required"),
});

const BillingRates = ({ userBillingRates }) => {
  const dispatch = useDispatch();
  const [billingRates, setBillingRates] = useState([]);
  const {
    isPending: isUserBillingRatesLoading,
    error: isUserBillingRatesError,
    data: userBillingRatesData,
    mutateAsync: createOrUpdateUserBillingRatesMutation,
  } = useMutation({
    mutationFn: createOrUpdateUserBillingRates,
    enabled: false,
  });
  const formik = useFormik({
    initialValues: {
      paymentType: "HOURLY",
      currency: "AUD",
      rate: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setBillingRates((prev) => {
        const existingIndex = prev.findIndex(
          (item) => item.paymentType === values.paymentType
        );

        if (existingIndex !== -1) {
          // Update the existing rate
          const updatedRates = [...prev];
          updatedRates[existingIndex] = values;
          return updatedRates;
        }

        // Add new rate if type doesn't exist
        return [...prev, values];
      });
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    setBillingRates(userBillingRates);
  }, [userBillingRates]);

  const handleUserBillingRatesSubmit = async () => {
    try {
      const userAddressResponse = await createOrUpdateUserBillingRatesMutation({
        billingRates,
      });
      dispatch(
        showToast({
          message: "Billing rates added successfully",
          type: "success",
        })
      );
    } catch (error) {}
  };

  return (
    <form>
      <section className="py-4 px-5">
        <div className="grid grid-cols-2 gap-x-12 gap-y-4">
          <BaseSelect
            className="h-0"
            labelShrink={false}
            label="Type"
            required={true}
            isDefaultField={false}
            fullHeight={false}
            fullWidth
            options={[
              {
                label: "Hourly",
                value: "HOURLY",
              },
              {
                label: "Full Day",
                value: "FULL_DAY",
              },
              {
                label: "Half Day",
                value: "HALF_DAY",
              },
            ]}
            id="paymentType"
            name="paymentType"
            value={formik.values.paymentType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.paymentType && Boolean(formik.errors.paymentType)
            }
            helperText={formik.touched.paymentType && formik.errors.paymentType}
          />
          <BaseSelect
            className="h-0"
            labelShrink={false}
            label="Currency"
            required={true}
            isDefaultField={false}
            fullHeight={false}
            fullWidth
            options={[
              {
                label: "AUD",
                value: "AUD",
              },
              {
                label: "USD",
                value: "USD",
              },
              {
                label: "EUR",
                value: "EUR",
              },
              {
                label: "GBP",
                value: "GBP",
              },
              {
                label: "INR",
                value: "INR",
              },
              {
                label: "NZD",
                value: "NZD",
              },
              {
                label: "CAD",
                value: "CAD",
              },
            ]}
            id="currency"
            name="currency"
            onChange={formik.handleChange}
            value={formik.values.currency}
            onBlur={formik.handleBlur}
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            helperText={formik.touched.currency && formik.errors.currency}
          />
          <BaseTextField
            labelShrink={false}
            type="number"
            label={
              formik.values.paymentType === "HOURLY"
                ? "Hourly Rate"
                : formik.values.paymentType === "FULL_DAY"
                ? "Full Day"
                : formik.values.paymentType === "HALF_DAY"
                ? "Half Day"
                : ""
            }
            optional={false}
            isDefaultField={false}
            id="rate"
            name="rate"
            value={formik.values.rate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.rate && Boolean(formik.errors.rate)}
            helperText={formik.touched.rate && formik.errors.rate}
          />
        </div>

        {billingRates?.length > 0 && (
          <div className="bg-light py-4 px-5 grid grid-cols-2 gap-6 rounded-md mt-6">
            {billingRates?.map((rate) => (
              <div className="flex items-center bg-white  px-4 py-2 rounded-md">
                <p className="min-w-20 font-normal text-primary">
                  {rate.paymentType === "HOURLY"
                    ? "Hourly Rate"
                    : rate.paymentType === "FULL_DAY"
                    ? "Full Day"
                    : rate.paymentType === "HALF_DAY"
                    ? "Half Day"
                    : ""}
                </p>
                <div className="border-t border-dotted border-gray-500  w-full"></div>
                <p className="min-w-16   text-right text-primary">
                  {rate.rate} {rate.currency}
                </p>
              </div>
            ))}
          </div>
        )}

        <div className="flex justify-end mt-5">
          <BaseButton
            color="primary"
            variant="outlined"
            className="py-[4px] px-[28px] mr-4"
            onClick={formik.handleSubmit}
          >
            Apply
          </BaseButton>

          <BaseButton
            loaderSize={"22px"}
            isLoading={isUserBillingRatesLoading}
            color="primary"
            className="py-[5px] px-[38px]"
            disabled={billingRates?.length < 1}
            onClick={handleUserBillingRatesSubmit}
          >
            Save
          </BaseButton>
        </div>
      </section>
    </form>
  );
};

export default BillingRates;
