import React, { useEffect, useState } from "react";

import BaseCard from "../../../components/ui/BaseCard";
import BaseSelect from "../../../components/ui/BaseSelect";
import { useDispatch, useSelector } from "react-redux";
import { updateWorkOrderForm } from "../../../store/workOrder/workOrderSlice";
import { useQuery } from "@tanstack/react-query";
import {
  fetchCategories,
  fetchSubCategories,
  fetchVendors,
} from "../../../services/api/category";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";

const WorkExperienceForm = () => {
  const dispatch = useDispatch();
  const workExperience = useSelector(
    (state) => state.workOrder.summary.workExperience
  );
  const {
    isLoading: IsCategoriesLoading,
    error: isCategoriesError,
    data: categoriesData,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  const {
    isLoading: IsVendorsLoading,
    error: isVendorsError,
    data: vendorsData,
  } = useQuery({
    queryKey: ["vendorsData", workExperience?.categoryId], // Add categoryId to the queryKey
    queryFn: () => fetchVendors(workExperience?.categoryId),
    enabled: !!workExperience?.categoryId,
  });

  const {
    isLoading: IsSubCategoriesLoading,
    error: isSubCategoriesError,
    data: subCategoriesData,
  } = useQuery({
    queryKey: ["subCategories", workExperience?.vendorId], // Add vendorId to the queryKey
    queryFn: () => fetchSubCategories(workExperience?.vendorId),
    enabled: !!workExperience?.vendorId,
  });

  const formattedCategories = categoriesData?.data?.map((category) => ({
    label: category.category,
    value: category.id,
  }));

  const formattedSubCategories = subCategoriesData?.data?.map(
    (subCategory) => ({
      label: subCategory.subCategory,
      value: subCategory.id,
    })
  );

  const formattedVendors = vendorsData?.data?.map((vendor) => ({
    label: vendor.name,
    value: vendor.id,
  }));

  /* useEffect(() => {
    console.log("running");
    dispatch(
      updateWorkOrderForm({
        step: "summary",
        data: {
          workExperience: {
            workOrderServices: [],
          },
        },
      })
    );
  }, [workExperience?.vendorId, workExperience?.categoryId]); */

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    if (
      (name === "vendorId" && workExperience.vendorId !== value) ||
      (name === "categoryId" && workExperience.categoryId !== value)
    ) {
      console.log("here");
      dispatch(
        updateWorkOrderForm({
          step: "summary",
          data: {
            workExperience: {
              vendorId: null,
              workOrderServices: [],
            },
          },
        })
      );
    }
    dispatch(
      updateWorkOrderForm({
        step: "summary",
        data: {
          workExperience: {
            [name]: value,
          },
        },
      })
    );
  };

  // console.log("services", workExperience?.workOrderServices);

  return (
    <BaseCard className="pt-4 pb-10 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Work Experience</h6>
      </div>
      <div className="grid grid-cols-2">
        <div className="mr-10">
          <BaseAutoSelect
            className="mb-3"
            placeholder="Select type of work..."
            fullHeight={false}
            required={true}
            labelShrink={false}
            label="Technology Stacks"
            options={formattedCategories}
            name="categoryId"
            isDefaultField={false}
            value={workExperience?.categoryId}
            onChange={handleChange}
          />
        </div>

        <div>
          <BaseAutoSelect
            className="mb-2"
            labelShrink={false}
            label="Vendors"
            placeholder="Add additional types of work..."
            fullHeight={false}
            options={formattedVendors}
            required={true}
            name="vendorId"
            value={workExperience?.vendorId}
            isDefaultField={false}
            onChange={handleChange}
          />
        </div>

        <div className="mr-10">
          <BaseAutoSelect
            disableCloseOnSelect
            infoIcon={false}
            label="Products"
            fullHeight={false}
            labelShrink={false}
            required={true}
            placeholder="Add services..."
            options={formattedSubCategories}
            name="workOrderServices"
            multiple
            value={workExperience?.workOrderServices}
            isDefaultField={false}
            onChange={handleChange}
          />
        </div>
      </div>
    </BaseCard>
  );
};

export default WorkExperienceForm;
