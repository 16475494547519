import { useState, useEffect, useContext } from "react";
import { LayoutContext } from "../../context/LayoutContext";
import BaseButton from "../ui/BaseButton";
import { useNavigate } from "react-router-dom";

const NotificationBar = () => {
  const navigate = useNavigate();
  const { setBannerVisible } = useContext(LayoutContext);

  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    setBannerVisible(showBanner);
  }, [showBanner, setBannerVisible]);

  if (!showBanner) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-[40px] z-50 bg-secondary text-white flex items-center justify-between px-12">
      <span
        className="animate-pulse
"
      >
        {" "}
        🚀 Important update: New features added!
      </span>

      <BaseButton
        size="small"
        className="text-dark font-normal rounded-lg py-[4px] px-[10px] bg-white"
        onClick={() => {
          navigate("/completeYourProfile");
          //   setShowBanner(false);
        }}
      >
        Check it out
      </BaseButton>
    </div>
  );
};

export default NotificationBar;
