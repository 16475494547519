import React from "react";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseButton from "../../../components/ui/BaseButton";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { updateUserDetails } from "../../../services/api/user";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import BaseCheckbox from "../../../components/ui/BaseCheckbox";

const validationSchema = yup.object({
  isAustralian: yup.boolean().required("its required"),
  australianBusinessNumber: yup
    .string("Enter your Business Number")
    .notRequired(),
});

const TaxInformation = ({ taxInformation, reFetchUser }) => {
  const dispatch = useDispatch();
  const {
    isPending: isUpdateUserDetailsLoading,
    error: isUpdateUserDetailsError,
    data: updatedUserDetails,
    mutateAsync: updateUserDetailsMutation,
  } = useMutation({
    mutationFn: updateUserDetails,
    enabled: false,
  });

  const formik = useFormik({
    initialValues: {
      isAustralian: taxInformation ? true : false,
      australianBusinessNumber: taxInformation,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUpdateUserDetails();
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleUpdateUserDetails = async () => {
    try {
      const userAddressResponse = await updateUserDetailsMutation({
        australianBusinessNumber: formik.values.australianBusinessNumber,
      });
      dispatch(
        showToast({
          message: "Added successfully",
          type: "success",
        })
      );
      reFetchUser();
    } catch (error) {}
  };

  console.log("formik", formik.values);

  return (
    <form>
      <section className="py-4 px-5">
        <BaseCheckbox
          label={
            <p className="font-normal text-base">Australian Contractors</p>
          }
          id="isAustralian"
          name="isAustralian"
          checked={formik.values.isAustralian}
          onChange={() =>
            formik.setFieldValue("isAustralian", !formik.values.isAustralian)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.isAustralian && Boolean(formik.errors.isAustralian)
          }
          errorMsg={formik.touched.isAustralian && formik.errors.isAustralian}
        ></BaseCheckbox>
        <div className="ml-[34px] mb-4">
          <p>
            As per Australian taxation office( ATO)- Any contractor in Australia
            must have registered business to work as contractor. You can be
            either sole trader, trust or a corporate Pty Ltd company. You must
            provide your ABN ( Australian Business Number) to start working on
            Tech Domain Portal. Tech Domain will be paying contractor under your
            ABN. Contractor will be responsible for their tax or super payment
            to ATO. Tech Domain will not hold/deduct any tax from your payment. 
          </p>
          {formik?.values?.isAustralian && (
            <div className="grid grid-cols-2 gap-12 mt-3">
              <BaseTextField
                labelShrink={false}
                label={
                  <p className="text-primary">Australian Business Number</p>
                }
                optional={false}
                isDefaultField={false}
                id="australianBusinessNumber"
                name="australianBusinessNumber"
                value={formik.values.australianBusinessNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.australianBusinessNumber &&
                  Boolean(formik.errors.australianBusinessNumber)
                }
                helperText={
                  formik.touched.australianBusinessNumber &&
                  formik.errors.australianBusinessNumber
                }
              />
            </div>
          )}
        </div>

        <BaseCheckbox
          label={
            <p className="font-normal text-base">
              Foreign Contractor Acknowledgement
            </p>
          }
          id="isNotAustralian"
          name="isAustralian"
          checked={!formik.values.isAustralian}
          onChange={() =>
            formik.setFieldValue("isAustralian", !formik.values.isAustralian)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.isAustralian && Boolean(formik.errors.isAustralian)
          }
          errorMsg={formik.touched.isAustralian && formik.errors.isAustralian}
        ></BaseCheckbox>
        <p className="mb-1 ml-[34px]">
          I declare that I am exempt from Australian Tax withholding or ABN
          requirement because:
        </p>

        <div className="ml-2 ml-12">
          <p>1. I am not an Australian Citizen</p>
          <p>
            2. I will be performing all of services outside of Australia for
            which I will receive payment from Tech Domain. 
          </p>
        </div>
        <div className="flex justify-end mt-5">
          <BaseButton
            loaderSize="22px"
            isLoading={isUpdateUserDetailsLoading}
            color="primary"
            className="py-[5px] px-[38px]"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Save
          </BaseButton>
        </div>
      </section>
    </form>
  );
};

export default TaxInformation;
