import axios from "../../lib/axiosInstance";

export function createWorkOrder(payload) {
  return axios.post("workOrders", payload).then((res) => res.data);
}

export function fetchUserWorkOrders(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.status) {
    queryParams.append("status", payload.status);
  }

  return axios
    .get("workOrders", { params: queryParams })
    .then((res) => res.data);
}

export function findJobs(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.latitude) {
    queryParams.append("latitude", payload.latitude);
  }
  if (payload.longitude) {
    queryParams.append("longitude", payload.longitude);
  }
  if (payload.radius) {
    queryParams.append("radius", payload.radius);
  }

  return axios
    .get("workOrders/findJobs", { params: queryParams })
    .then((res) => res.data);
}
export function fetchJobDetail(id) {
  return axios.get(`workOrders/${id}`).then((res) => res.data);
}

export function fetchFreelancerJobs(payload) {
  return axios.post(`workOrders/engineer`, payload).then((res) => res.data);
}

export function fetchJobsCount(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.latitude) {
    queryParams.append("latitude", payload.latitude);
  }
  if (payload.longitude) {
    queryParams.append("longitude", payload.longitude);
  }
  if (payload.radius) {
    queryParams.append("radius", payload.radius);
  }
  return axios
    .get(`workOrders/jobsCount`, { params: queryParams })
    .then((res) => res.data);
}

export function updateWorkOrderApi(payload) {
  return axios
    .patch(`workOrders/${payload.id}`, payload)
    .then((res) => res.data);
}

export function republishWorkOrderApi(workOrderId) {
  return axios
    .get(`workOrders/republish/${workOrderId}`)
    .then((res) => res.data);
}

export function updateWorkOrderSubStatus(payload) {
  return axios
    .patch(`workOrders/${payload.id}/subStatus`, payload)
    .then((res) => res.data);
}

export function markWorkOrderComplete(id) {
  return axios.get(`workOrders/${id}/complete`).then((res) => res.data);
}

export function markWorkOrderInComplete(payload) {
  return axios.post(`workOrders/incomplete`, payload).then((res) => res.data);
}

export function approveWorkOrder(id) {
  return axios.get(`workOrders/${id}/approve`).then((res) => res.data);
}

export function fetchWorkOrderPaymetDetails(id) {
  return axios.get(`workOrders/estimatedPayment/${id}`).then((res) => res.data);
}

export function draftWorkOrder(id) {
  return axios.patch(`workOrders/draft/${id}`).then((res) => res.data);
}

export function fetchProvidersCount(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.latitude) {
    queryParams.append("latitude", payload.latitude);
  }
  if (payload.longitude) {
    queryParams.append("longitude", payload.longitude);
  }
  if (payload.radius) {
    queryParams.append("radius", payload.radius);
  }
  if (payload.workOrderId) {
    queryParams.append("workOrderId", payload.workOrderId);
  }
  return axios
    .get(`workOrders/providersCount`, { params: queryParams })
    .then((res) => res.data);
}

export function fetchUserAllowedAction(id) {
  return axios.get(`workOrders/actions/allowed/${id}`).then((res) => res.data);
}

export function cancelWorkOrderByEngineer(payload) {
  return axios
    .post(`workOrders/engineer/cancel/${payload?.id}`, payload)
    .then((res) => res.data);
}

export function cancelWorkOrderByClient(payload) {
  return axios
    .post(`workOrders/client/cancel/${payload?.id}`, payload)
    .then((res) => res.data);
}

export function deleteWorkOrder(payload) {
  return axios
    .delete(`workOrders/${payload?.id}`, payload)
    .then((res) => res.data);
}
