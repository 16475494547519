import React, { useEffect, useRef } from "react";

const BaseDropdown = ({
  children,
  open,
  setOpen,
  className,
  style,
  closeOnOutsideClick = true,
  ...props
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!closeOnOutsideClick) return;

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [closeOnOutsideClick, setOpen]);

  return (
    <>
      {open && (
        <div
          ref={dropdownRef}
          onClick={(e) => e.stopPropagation()}
          {...props}
          style={style}
          className={`${className}  absolute top-4 left-[0px] bg-white  rounded-lg shadow-lg py-1 px-2 transition-all z-10 duration-300 ease-in-out transform ${
            open ? "opacity-100 scale-100" : "opacity-0 scale-95"
          }`}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default BaseDropdown;
