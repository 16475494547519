import React, { useEffect, useState } from "react";
import BaseWrapper from "../../components/ui/BaseWrapper";
import dayjs from "dayjs";
import WorkOrderStatus from "../../components/WorkOrderStatus";
import BaseDataTable from "../../components/ui/BaseDataTable";
import FilterChip from "../../components/FilterChip";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseRating from "../../components/ui/BaseRating";
import BaseButton from "../../components/ui/BaseButton";
import BaseCheckbox from "../../components/ui/BaseCheckbox";
import { Menu, MenuItem } from "@mui/material";
import { getTimeZoneAbbreviation } from "../../utils/getTimeZoneAbbreviation";
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const FindJobTable = ({ jobs, setJobsFilters, jobsCount, jobsFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultFilter = searchParams.get("filter") || "AVAILABLE"; // Default to "AVAILABLE"
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setJobsFilters({
      status:
        defaultFilter === "COUNTER"
          ? "PENDING"
          : defaultFilter === "REQUESTED"
          ? "PENDING"
          : defaultFilter === "ALL"
          ? ""
          : defaultFilter,
      isCounter: defaultFilter === "COUNTER" ? true : false,
    });
  }, []);

  const handleNavigate = (id) => {
    navigate(`/workOrder/details/${id}`);
  };

  const handleFilterClick = (filter, isCounter) => {
    const updatedActiveFilter =
      filter === "PENDING" && isCounter === true
        ? "COUNTER"
        : filter === "PENDING" && isCounter === false
        ? "REQUESTED"
        : filter;

    const updatedApiFilter = filter === "ALL" ? "" : filter;

    setJobsFilters({
      status: updatedApiFilter === "ALL" ? "" : updatedApiFilter,
      ...(isCounter !== undefined && { isCounter }), // Conditionally add isCounter
    });
    setSearchParams({
      filter: updatedActiveFilter,
    });
  };

  const handleContextMenu = (event, item) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
    setSelectedWorkOrder(item);
  };

  const handleClose = () => {
    setContextMenu(null);
    setSelectedWorkOrder(null);
  };

  const data = jobs || [];
  const columnWidths = {
    ID: "206px",
    "Job Title": "312px",
    Location: "18%",
    "Type of work": "14%",
    Schedule: "18%",
    Pay: "18%",
    "Type of work": "12%",
    Distance: "14%",
    Company: "14%",
    "Buyer Rating": "18%",
    Requests: "14%",
    Status: "15%",
    "Incident ID": "10%",
    // "Additional Column": "70%",
  };
  const COLUMNS = [
    {
      label: (
        <div className="flex items-center">
          <BaseCheckbox />
          <p className="text-sm font-normal text-dark cursor-pointer ml-4">
            ID
          </p>
        </div>
      ),
      renderCell: (item) => (
        <div className="flex items-center w-[206px]">
          <BaseCheckbox />
          <p
            className="table-font-size font-normal text-primary cursor-pointer ml-4"
            onClick={() => handleNavigate(item.id)}
            onContextMenu={(e) => handleContextMenu(e, item)}
          >
            {item.referenceId}
          </p>
        </div>
      ),
    },
    {
      label: "Incident ID",
      renderCell: (item) => (
        <p
          className={`table-font-size truncate max-w-64 overflow-hidden whitespace-nowrap`}
        >
          {item?.incidentId}
        </p>
      ),
    },
    {
      label: "Job Title",
      renderCell: (item) => (
        // <BaseTooltip
        //   position="top"
        //   interactive={true}
        //   content={<p className="text-white">{`${item.title}`}</p>}
        // >
        <p
          className="table-font-size truncate max-w-64 overflow-hidden whitespace-nowrap text-primary cursor-pointer"
          onClick={() => handleNavigate(item.id)}
          onContextMenu={(e) => handleContextMenu(e, item)}
        >
          {item.title}
        </p>
        // </BaseTooltip>
      ),
      // pinLeft: true,
      // sort: { sortKey: "TITLE" },
    },
    {
      label: "Location",
      renderCell: (item) => (
        <p className="table-font-size cursor-default">{`${item.address.addressLine1}, ${item.address.country}`}</p>
      ),
      resize: true,
      // sort: { sortKey: "LOCATION" },
    },
    {
      label: "Status",
      renderCell: (item) => (
        <div className="flex items-center">
          <WorkOrderStatus
            className="!py-[1.8px] !w-[70px] !text-[11px]"
            status={
              item.status === "PUBLISHED" ||
              jobsFilters?.status === "AVAILABLE" ||
              jobsFilters?.status === "ASSIGNED" ||
              jobsFilters?.status === "INPROGRESS" ||
              jobsFilters?.status === "WORKDONE" ||
              jobsFilters?.status === "INCOMPLETE" ||
              jobsFilters?.status === "APPROVED" ||
              jobsFilters?.status === "ROUTED" ||
              jobsFilters?.status === "COUNTER" ||
              jobsFilters?.status === "REQUESTED" ||
              jobsFilters?.status === ""
                ? /* item?.offers?.status === "CANCELLED"
                  ? item?.offers?.status
                  : */ item?.offers?.status === "DECLINED"
                  ? item?.offers?.status
                  : item?.offers?.status === "ROUTED"
                  ? item?.offers?.status
                  : (item?.offers?.status === "PENDING" ||
                      item?.offers?.status === "REJECTED") &&
                    item?.offers?.isCounter
                  ? "COUNTER"
                  : (item?.offers?.status === "PENDING" ||
                      item?.offers?.status === "REJECTED") &&
                    !item?.offers?.isCounter
                  ? "REQUESTED"
                  : item.status
                : null
            }
          />
          {item.status !== "CANCELLED" && item.subStatus && (
            /* || 
           item?.offers?.status === "CANCELLED" */

            <i className="fa-solid fa-arrow-right-long mx-2 text-primary"></i>
          )}
          {item.status !== "CANCELLED" && item.subStatus && (
            // || item?.offers?.status === "CANCELLED"
            <div className="ml-1">
              <WorkOrderStatus
                status={
                  item.subStatus
                  //  || item?.offers?.status
                }
                className="!py-[1.5px] !w-[80px] !text-[9px]"
              />
            </div>
          )}
          {/* {item.status === "INPROGRESS" && (
            <div className="ml-1">
              <WorkOrderStatus
                status={
                  item?.tasks?.find((task) => task.taskType === "CHECK_OUT")
                    ?.completedTask?.isCompleted
                    ? "CHECK_OUT"
                    : "CHECK_IN"
                }
              />
            </div>
          )} */}
        </div>
      ),
      // sort: { sortKey: "STATUS" },
    },
    {
      label: "Type of work",
      renderCell: (item) => (
        <p className="table-font-size">{`${
          item?.category?.category || "not specified"
        }`}</p>
      ),
      // sort: { sortKey: "LOCATION" },
    },

    {
      label: "Schedule",
      renderCell: (item) => (
        <div>
          <p className={`text-primary text-xs`}>
            {item.scheduleType === "HARD_START"
              ? "Hard Time"
              : item.scheduleType === "WORK_BETWEEN"
              ? "Work Between"
              : ""}
          </p>
          <p className={`table-font-size`}>
            {dayjs
              .utc(item.orderStartDate)
              .tz(item.timeZone)
              .format("MM/DD/YYYY")}

            <span> at </span>

            {dayjs.utc(item.orderStartTime).tz(item.timeZone).format("hh:mm A")}
            <span> ({getTimeZoneAbbreviation(item.timeZone)})</span>
          </p>
        </div>
      ),
      // sort: { sortKey: "SCHEDULE" },
    },
    {
      label: "Pay",
      renderCell: (item) => (
        <div>
          {item.paymentType === "HOURLY" && (
            <p className={`table-font-size`}>
              {item?.minimumHours?.toFixed(2)}hours @ ${item.hourlyRate || 0}/hr
            </p>
          )}
          <p className=" text-xs">
            {item.paymentType === "HOURLY" && "hourly"}
            <span className="ml-1 text-primary"> Payment Terms</span>
          </p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Distance",
      renderCell: (item) => (
        <div>
          {item?.distance && (
            <p className="table-font-siz">{item?.distance?.toFixed(2)} km</p>
          )}
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Company",
      renderCell: (item) => (
        <div>
          <p className="table-font-size">
            {item?.createdBy?.company?.name || ""}
          </p>
        </div>
      ),

      // sort: { sortKey: "PAY" },
    },
    {
      label: "Buyer Rating",
      renderCell: (item) => (
        <div className="flex items-center justify-center border border-primary w-20 py-1 rounded-full">
          <BaseRating style={{ fontSize: "13px" }} />
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Requests",
      renderCell: (item) => (
        <p className="table-font-size">{item?.totalOffers || 0}</p>
      ),
      // sort: { sortKey: "PAY" },
    },

    /* 
    

    
    {
      label: "Pay",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
  
    
    
  

     */
  ];

  return (
    <BaseWrapper>
      <div className="py-4 flex">
        <FilterChip
          filter="Available"
          count={jobsCount?.AVAILABLE || 0}
          active={jobsFilters?.status === "AVAILABLE" ? true : false}
          onClick={() => handleFilterClick("AVAILABLE")}
        />

        <div className="ml-4">
          <FilterChip
            filter="Routed"
            count={jobsCount?.ROUTED || 0}
            active={jobsFilters?.status === "ROUTED" ? true : false}
            onClick={() => {
              handleFilterClick("ROUTED");
            }}
          />
        </div>
        <div className="ml-4">
          <FilterChip
            filter="Countered"
            count={jobsCount?.COUNTERED || 0}
            active={
              jobsFilters?.status === "PENDING" &&
              jobsFilters?.isCounter === true
                ? true
                : false
            }
            onClick={() => {
              handleFilterClick("PENDING", true);
            }}
          />
        </div>
        <div className="ml-4">
          <FilterChip
            filter="Requested"
            count={jobsCount?.REQUESTED || 0}
            active={
              jobsFilters?.status === "PENDING" &&
              jobsFilters?.isCounter === false
                ? true
                : false
            }
            onClick={() => {
              handleFilterClick("PENDING", false);
            }}
          />
        </div>

        <div className="ml-4">
          <FilterChip
            filter="Assigned"
            count={jobsCount?.ASSIGNED || 0}
            active={jobsFilters?.status === "ASSIGNED" ? true : false}
            onClick={() => {
              handleFilterClick("ASSIGNED");
            }}
          />
        </div>
        <div className="ml-4">
          <FilterChip
            filter="Inprogress"
            count={jobsCount?.INPROGRESS || 0}
            active={jobsFilters?.status === "INPROGRESS" ? true : false}
            onClick={() => {
              handleFilterClick("INPROGRESS");
            }}
          />
        </div>
        <div className="ml-4">
          <FilterChip
            filter="Work Done"
            count={jobsCount?.WORKDONE || 0}
            active={jobsFilters?.status === "WORKDONE" ? true : false}
            onClick={() => {
              handleFilterClick("WORKDONE");
            }}
          />
        </div>
        <div className="ml-4">
          <FilterChip
            filter="Incomplete"
            count={jobsCount?.INCOMPLETE || 0}
            active={jobsFilters?.status === "INCOMPLETE" ? true : false}
            onClick={() => {
              handleFilterClick("INCOMPLETE");
            }}
          />
        </div>
        <div className="ml-4">
          <FilterChip
            filter="Approved/Paid"
            count={jobsCount?.APPROVED || 0}
            active={jobsFilters?.status === "APPROVED" ? true : false}
            onClick={() => {
              handleFilterClick("APPROVED");
            }}
          />
        </div>

        <div className="ml-4">
          <FilterChip
            filter="All"
            count={jobsCount?.ALL || 0}
            active={jobsFilters?.status === "" ? true : false}
            onClick={() => {
              handleFilterClick("ALL");
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        {jobsFilters?.status === "ROUTED" && (
          <>
            <BaseButton
              variant="outlined"
              color="primary"
              className="!p-0 h-8 w-28 mr-4"
              fullWidth={false}
            >
              Declined
            </BaseButton>
            <BaseButton
              variant="outlined"
              color="primary"
              className="!p-0 h-8 w-28 mr-4"
              fullWidth={false}
            >
              Accept
            </BaseButton>
          </>
        )}
        {(jobsFilters?.status === "COUNTER" ||
          jobsFilters?.status === "REQUESTED") && (
          <BaseButton
            variant="outlined"
            color="primary"
            className="!p-0 h-8 w-28 mr-4"
            fullWidth={false}
          >
            Withdraw
          </BaseButton>
        )}

        <BaseButton
          variant="outlined"
          color="primary"
          className="!p-0 h-8 w-28"
          fullWidth={false}
        >
          Export
        </BaseButton>

        {jobsFilters?.status === "ASSIGNED" && (
          <BaseButton
            variant="outlined"
            color="primary"
            className="!p-0 h-8 w-36 ml-4"
            fullWidth={false}
          >
            Cancel Assignment
          </BaseButton>
        )}
      </div>

      <BaseDataTable
        columns={COLUMNS}
        nodes={data}
        columnWidths={columnWidths}
        lastColFix={false}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            handleNavigate(selectedWorkOrder.id);
            handleClose();
          }}
        >
          <p>Open</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(`/workOrder/details/${selectedWorkOrder.id}`, "_blank");
            handleClose();
          }}
        >
          <p>Open in New Tab</p>
        </MenuItem>
      </Menu>
    </BaseWrapper>
  );
};

export default FindJobTable;
