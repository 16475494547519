import React from "react";
import BaseButton from "../../../components/ui/BaseButton";
import BaseModal from "../../../components/ui/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { withdrawOffer } from "../../../services/api/offers";
import { showToast } from "../../../store/toast/toastSlice";
import { useMutation } from "@tanstack/react-query";
import ScheduleCard, { SchedulePublishedCard } from "../ScheduleCard";
import LocationCard from "../LocationCard";
import RateCard from "../RateCard";
import LocationMap from "../TabsCard/LocationMap";
import { updateTask } from "../../../services/api/task";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { updateWorkOrderSubStatus } from "../../../services/api/workOrder";

const WOConfirmationModal = ({
  woConfirmationModalOpen,
  setWOConfirmationModalOpen,
  refetchUserAllowedAction,
  woConfirmationModalData,
  tasks,
}) => {
  const { updateWorkOrder } = useUpdateWorkOrder();
  const dispatch = useDispatch();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const {
    isPending: isWithdrawOfferLoading,
    status,
    error: isWithdrawOfferError,
    mutateAsync: withdrawOfferMutation,
  } = useMutation({
    mutationFn: withdrawOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Offer withdrawn Successfully",
          type: "success",
        })
      );
      refetchUserAllowedAction();
    },
  });

  const {
    isPending: isUpdatingSubStatusLoading,
    error: isUpdatingSubStatusError,
    mutateAsync: updateWorkOrderSubStatusMutation,
  } = useMutation({
    mutationFn: updateWorkOrderSubStatus,
  });

  const {
    isLoading: isTaskUpdateLoading,
    error: isTaskUpdateError,
    data: updatedTaskData,
    mutateAsync: updateTaskMutation,
  } = useMutation({
    mutationFn: updateTask,
    enabled: false,
  });

  const handleReadyToGo = async () => {
    const task = tasks?.find((task) => task.taskType === "START_TIME");
    try {
      let payload = {
        id: task.completedTask?.id,
        isCompleted: true,
      };

      const updatedTask = await updateTaskMutation(payload);

      /* updateWorkOrder();
      refetchUserAllowedAction(); */
      setWOConfirmationModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleWithdrawButton = async () => {
    try {
      await withdrawOfferMutation(workOrderDetail?.id);
      setWOConfirmationModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: isWithdrawOfferError?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleSubStatusButton = async (subStatus) => {
    try {
      const payload = {
        id: workOrderDetail.id,
        subStatus: subStatus,
      };
      const workOrderSubStatusResponse = await updateWorkOrderSubStatusMutation(
        payload
      );
      // refetchUserAllowedAction();
      /* 
      updateWorkOrder(); */
      dispatch(
        showToast({
          message: workOrderSubStatusResponse?.message,
          type: "success",
        })
      );
    } catch (error) {}
  };

  const handleActionTypeButton = async () => {
    const actionType = woConfirmationModalData?.actionType?.toLowerCase();
    if (actionType === "withdraw") {
      await handleWithdrawButton();
    } else if (actionType === "ready to go") {
      await handleReadyToGo();
    } else if (actionType === "confirmed") {
      await handleSubStatusButton("CONFIRMED");
    }

    setWOConfirmationModalOpen(false);
  };

  return (
    <BaseModal
      style={{
        width: "1000px",
        top: "440px",
      }}
      open={woConfirmationModalOpen}
      close={() => {}}
    >
      <div className="container bg-[#F6F6F6]">
        <h6
          className="text-dark border-bottom"
          style={{ marginBottom: "24px", paddingBottom: "4px" }}
        >
          {woConfirmationModalData.title}
        </h6>

        <div className="grid grid-cols-3 gap-12 mt-6">
          <RateCard />
          <SchedulePublishedCard
            title={
              workOrderDetail?.scheduleType === "WORK_BETWEEN"
                ? "Work Between"
                : "Hard Time"
            }
            scheduleType={workOrderDetail?.scheduleType}
            startDate={workOrderDetail?.orderStartDate}
            startTime={workOrderDetail?.orderStartTime}
            timeZone={workOrderDetail?.address?.timeZone}
          />
          <LocationCard location={workOrderDetail?.address} />
        </div>

        <div className="mt-6">
          <LocationMap location={workOrderDetail?.address} />
        </div>
      </div>
      <div className="d-flex content-center  bg-white py-6">
        <BaseButton
          className="request-button bg-light"
          style={{
            marginRight: "16px",
            color: "#686868",
            width: "150px",
          }}
          onClick={() => setWOConfirmationModalOpen(false)}
        >
          Cancel
        </BaseButton>

        <BaseButton
          loaderSize={24}
          loaderColor="primary"
          isLoading={
            isUpdatingSubStatusLoading ||
            isWithdrawOfferLoading ||
            isTaskUpdateLoading
          }
          disabled={
            isUpdatingSubStatusLoading ||
            isWithdrawOfferLoading ||
            isTaskUpdateLoading
          }
          className="request-button"
          color="primary"
          style={{
            width: "150px",
          }}
          onClick={handleActionTypeButton}
        >
          {woConfirmationModalData.actionType}
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default WOConfirmationModal;
