import React, { useRef, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseTextField from "../../../../../components/ui/BaseTextField";
import BaseCard from "../../../../../components/ui/BaseCard";
import BaseButton from "../../../../../components/ui/BaseButton";
import AddLanguagesModal from "../../../components/AddLanguagesModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addLanguagesToUser,
  deleteUserLanguage,
  fetchAllUserLanguages,
} from "../../../../../services/api/language";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../store/toast/toastSlice";

const validationSchema = yup.object({
  languages: yup
    .array() // ✅ Define as an array
    .of(yup.string().required("Each language is required")) // ✅ Ensure each item is a string
    .min(1, "At least one language is required") // ✅ Ensure at least one selection
    .required("Languages selection is required"), // ✅ Ensure the array itself exists
});

const AddLanguage = () => {
  const dispatch = useDispatch();
  const [isAddLanguageModalOpen, setIsAddLanguageModalOpen] = useState(false);
  const {
    isPending: isLanguagesLoading,
    error: isLanguagesError,
    data: languagesData,
    refetch: refetchAllUserLanguages,
  } = useQuery({
    queryKey: ["languages"],
    queryFn: fetchAllUserLanguages,
  });

  const {
    isPending: isDeletedLanguageApiLoading,
    error: isDeletedLanguageApiError,
    data: deletedLanguageData,
    mutateAsync: deleteUserLanguageMutation,
  } = useMutation({
    mutationFn: deleteUserLanguage,
    enabled: false,
  });

  const {
    isPending: isAddLanguageLoading,
    error: isAddLanguageError,
    data: addedLanguageData,
    mutateAsync: addLanguagesToUserMutation,
  } = useMutation({
    mutationFn: addLanguagesToUser,
    enabled: false,
  });

  const formik = useFormik({
    initialValues: {
      languages: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddLanguages();
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleAddLanguages = async () => {
    try {
      const userAddedLanguagesResponse = await addLanguagesToUserMutation(
        formik.values
      );
      dispatch(
        showToast({
          message: "Equipment added successfully",
          type: "success",
        })
      );
      refetchAllUserLanguages();
      setIsAddLanguageModalOpen(false);
      formik.resetForm();
    } catch (error) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleDeleteLanguage = async (id) => {
    try {
      await deleteUserLanguageMutation(id);
      refetchAllUserLanguages();
    } catch (error) {}
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">Language</p>
          <i
            class="fa-solid fa-circle-plus cursor-pointer text-secondary text-xl"
            onClick={() => setIsAddLanguageModalOpen(true)}
          ></i>
        </div>

        <div className="flex flex-wrap gap-4">
          {languagesData?.data?.map((language) => (
            <div className="px-4 py-1 bg-light rounded-sm flex items-center">
              <p className="lowercase">{language?.language}</p>
              <i
                className="fa-solid fa-xmark ml-2 cursor-pointer hover:text-blue-500"
                onClick={() => handleDeleteLanguage(language.id)}
              ></i>
            </div>
          ))}
        </div>
      </BaseCard>
      <AddLanguagesModal
        open={isAddLanguageModalOpen}
        close={setIsAddLanguageModalOpen}
        formik={formik}
      />
    </form>
  );
};

export default AddLanguage;
