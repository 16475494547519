import { useQuery } from "@tanstack/react-query";
import { fetchCountries } from "../services/api/country";

// Custom Hook
export const useCountries = () => {
  const {
    data: countries,
    isPending: isCountriesLoading,
    error: isCountriesError,
  } = useQuery({
    queryKey: ["countries"],
    queryFn: fetchCountries,
  });

  const formattedCountriesData = countries?.data?.map((country) => {
    return {
      label: country?.name?.common,
      value: country?.cca2,
    };
  });

  const getCountryLabel = (value) => {
    const country = formattedCountriesData?.find(
      (c) => c.value === value || c.label.toLowerCase() === value.toLowerCase()
    );
    return country ? country.label : null; // Return label or null if not found
  };

  return { countries: formattedCountriesData, getCountryLabel };
};
