import React, { useRef, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";
import { useQuery } from "@tanstack/react-query";
import {
  fetchCategories,
  fetchSubCategories,
  fetchVendors,
} from "../../../services/api/category";

const AddSkillsModal = ({ open, close, formik }) => {
  const {
    isLoading: IsCategoriesLoading,
    error: isCategoriesError,
    data: categoriesData,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  const {
    isLoading: IsVendorsLoading,
    error: isVendorsError,
    data: vendorsData,
  } = useQuery({
    queryKey: ["vendorsData", formik.values.categoryId], // ✅ Use formik.values.categoryId directly
    queryFn: () => fetchVendors(formik.values.categoryId),
    enabled: !!formik.values.categoryId,
  });

  const {
    isLoading: IsSubCategoriesLoading,
    error: isSubCategoriesError,
    data: subCategoriesData,
  } = useQuery({
    queryKey: ["subCategories", formik?.values.vendorId], // Add vendorId to the queryKey
    queryFn: () => fetchSubCategories(formik?.values.vendorId),
    enabled: !!formik?.values.vendorId,
  });

  const formattedCategories = categoriesData?.data?.map((category) => ({
    label: category.category,
    value: category.id,
  }));

  const formattedVendors = vendorsData?.data?.map((vendor) => ({
    label: vendor.name,
    value: vendor.id,
  }));

  const formattedSubCategories = subCategoriesData?.data?.map(
    (subCategory) => ({
      label: subCategory.subCategory,
      value: subCategory.id,
    })
  );

  return (
    <form>
      <BaseModal
        className="-top-[200px] overflow-scroll"
        open={open}
        close={close}
        style={{ width: "1000px" }}
      >
        <div className="py-7 px-12">
          <h6 className="text-dark font-normal border-b pb-1">Add Skills</h6>

          <div className="grid grid-cols-2 gap-x-8 mt-4">
            <BaseAutoSelect
              className="mb-3"
              placeholder="Select type"
              fullHeight={false}
              required={true}
              labelShrink={false}
              label="Type"
              options={formattedCategories}
              id="categoryId"
              name="categoryId"
              isDefaultField={false}
              defaultValue={formik.values.categoryId}
              value={formik.values.categoryId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.categoryId && Boolean(formik.errors.categoryId)
              }
              helperText={formik.touched.categoryId && formik.errors.categoryId}
            />
            <BaseAutoSelect
              className="mb-3"
              placeholder="Select Vendor"
              fullHeight={false}
              required={true}
              labelShrink={false}
              label="Vendor"
              options={formattedVendors}
              isDefaultField={false}
              id="vendorId"
              name="vendorId"
              defaultValue={formik.values.vendorId}
              value={formik.values.vendorId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.vendorId && Boolean(formik.errors.vendorId)}
              helperText={formik.touched.vendorId && formik.errors.vendorId}
            />
            <BaseAutoSelect
              className="mb-3"
              placeholder="Select Skills"
              fullHeight={false}
              required={true}
              labelShrink={false}
              label="Skills"
              isDefaultField={false}
              options={formattedSubCategories}
              multiple
              disableCloseOnSelect
              id="subCategoryIds"
              name="subCategoryIds"
              defaultValue={formik.values.subCategoryIds}
              value={formik.values.subCategoryIds}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.subCategoryIds &&
                Boolean(formik.errors.subCategoryIds)
              }
              helperText={
                formik.touched.subCategoryIds && formik.errors.subCategoryIds
              }
            />
          </div>
        </div>
        <div
          className="d-flex content-center bg-white py-6 mt-6"
          style={{
            boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          <BaseButton
            className="px-[28px] py-[5px] bg-light text-primary !hover:text-primary"
            variant="outlined"
            color="primary"
            style={{
              marginRight: "16px",
            }}
            onClick={() => close(false)}
          >
            Cancel
          </BaseButton>

          <BaseButton
            loaderSize={24}
            loaderColor="primary"
            className="px-[12px] py-[5px]"
            color="primary"
            onClick={formik.handleSubmit}
          >
            Save Changes
          </BaseButton>
        </div>
      </BaseModal>
    </form>
  );
};

export default AddSkillsModal;
