import React, { useEffect, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseCard from "../../../components/ui/BaseCard";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchWorkOrderPaymetDetails } from "../../../services/api/workOrder";
import {
  acceptCounterOffer,
  fetchUserCounterOffer,
} from "../../../services/api/offers";
import { calculateNewEstimatedHourlyTotal } from "../../../utils/helpers";
import BaseButton from "../../../components/ui/BaseButton";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import BaseLoader from "../../../components/ui/BaseLoader";

const ReviewCounterOfferModal = ({
  reviewCounterOfferModalOpen,
  setReviewCounterOfferModalOpen,
  updateWorkOrderDetailPageUI,
  freelancerId,
  offerId,
  handleUpdateWorkOrderStatusButton,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [counterOfferData, setCounterOfferData] = useState({
    expenses: [],
    totalWorkOrderCost: "",
  });
  const {
    isLoading: isWorkOrderPaymentLoading,
    error,
    data: workOrderPaymentData,
  } = useQuery({
    queryFn: () => fetchWorkOrderPaymetDetails(id),
    enabled: !!id,
    queryKey: ["workOrderPaymentData"],
  });

  const {
    isLoading: isUserCounterOfferDataLoading,
    error: isUserCounterOfferDataError,
    data: userCounterOfferData,
  } = useQuery({
    queryKey: ["userCounterOfferData"],
    queryFn: () => fetchUserCounterOffer({ id, userId: freelancerId }),
    enabled: !!id && !!freelancerId,
  });

  const {
    isLoading: isAcceptCounterOfferLoading,
    error: isAcceptCounterOfferError,
    mutateAsync: acceptCounterOfferMutation,
  } = useMutation({
    mutationFn: acceptCounterOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Counter Offer Accepted Successfully",
          type: "success",
        })
      );
    },
  });

  useEffect(() => {
    const workOrderExpenses = workOrderPaymentData?.data?.expenses || [];
    const userCounterExpenses = userCounterOfferData?.data?.expenses || [];
    setCounterOfferData({
      expenses: [...workOrderExpenses, ...userCounterExpenses],
      totalWorkOrderCost: calculateNewEstimatedHourlyTotal(
        [...workOrderExpenses, ...userCounterExpenses],
        userCounterOfferData?.data?.hourlyRate,
        userCounterOfferData?.data?.minimumHours
      ),
    });
  }, [userCounterOfferData, workOrderPaymentData]);

  const handleAcceptCounterOfferButton = async () => {
    try {
      await acceptCounterOfferMutation(offerId);
      updateWorkOrderDetailPageUI();
      setReviewCounterOfferModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  const handleDeclineCounterOfferButton = async () => {
    await handleUpdateWorkOrderStatusButton(
      freelancerId,
      parseInt(id),
      "REJECTED"
    );
    updateWorkOrderDetailPageUI();
    setReviewCounterOfferModalOpen(false);
  };
  return (
    <>
      <BaseLoader
        isLoading={isWorkOrderPaymentLoading || isUserCounterOfferDataLoading}
        zIndex={101}
      />
      <BaseModal
        style={{
          width: "900px",
        }}
        className="-top-60"
        open={reviewCounterOfferModalOpen}
        close={() => setReviewCounterOfferModalOpen(false)}
      >
        <div className="bg-[#F6F6F6]">
          <div className="container ">
            <h6 className="text-dark border-bottom pb-1">Counter Offer</h6>
            <BaseCard elevation={0} className="mt-5 pt-2 pb-4 px-5">
              <h6 className="text-dark border-bottom pb-1">Current Pay</h6>
              <div className="mt-2">
                <h6 className="mt-2 text-dark">Labour</h6>
                <div className="flex items-center mt-4 mb-2">
                  <p className="mr-5">Hourly Rate</p>
                  <div className="border-t border-dotted border-gray-500  flex-grow"></div>

                  <p className="ml-3 font-normal">
                    $
                    {parseFloat(
                      workOrderPaymentData?.data?.hourlyRate
                    )?.toFixed(2)}
                  </p>
                </div>
                <div className="flex items-center mb-2">
                  <p className="mr-5">Minimum Payable Hours</p>
                  <div className="border-t border-dotted border-gray-500  flex-grow"></div>

                  <p className="ml-3 font-normal">
                    {workOrderPaymentData?.data?.minimumHours} hrs
                  </p>
                </div>
              </div>

              <h6 className="text-dark">Expenses</h6>

              {workOrderPaymentData?.data.expenses?.length > 0 &&
                workOrderPaymentData?.data.expenses?.map((expense, index) => (
                  <div className="flex items-center mt-2 mb-3" key={index}>
                    <p className="mr-5">
                      {expense?.expenseType} ({expense?.description})
                    </p>
                    <div className="border-t border-dotted border-gray-500  flex-grow"></div>
                    <div className="flex items-center bg-light py-1 px-3 rounded-md ml-5">
                      {expense.isApproved ? (
                        <i className="fa-solid fa-check"></i>
                      ) : (
                        <i className="fa-solid fa-xmark"></i>
                      )}
                      <p className="ml-2 font-normal">
                        {expense?.addedBy?.role?.role === "CLIENT"
                          ? "Buyer"
                          : "Provider"}
                        : ${expense?.amount?.toFixed(2) || 0}
                      </p>
                    </div>
                  </div>
                ))}
              <div className="mt-6">
                <div className="flex justify-between bg-primary px-4 py-1 rounded-md">
                  <h6 className="text-white">Total Estimate</h6>

                  <h6 className={`text-white`}>
                    $
                    {workOrderPaymentData?.data?.totalWorkOrderCost?.toFixed(2)}
                  </h6>
                </div>
              </div>
            </BaseCard>

            <BaseCard elevation={0} className="mt-5 pt-2 pb-4 px-5">
              <h6 className="text-dark border-bottom pb-1">Counter Offer</h6>
              {(userCounterOfferData?.data?.hourlyRate ||
                userCounterOfferData?.data?.minimumHours) && (
                <div className="mt-2">
                  <h6 className="mt-2 text-dark">Labour</h6>
                  <div className="flex items-center mt-4 mb-2">
                    <p className="mr-5">Hourly Rate</p>
                    <div className="border-t border-dotted border-gray-500  flex-grow"></div>

                    <p className="ml-3 font-normal">
                      $
                      {parseFloat(
                        userCounterOfferData?.data?.hourlyRate || 0
                      )?.toFixed(2)}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="mr-5">Minimum Payable Hours</p>
                    <div className="border-t border-dotted border-gray-500  flex-grow"></div>

                    <p className="ml-3 font-normal">
                      {userCounterOfferData?.data?.minimumHours || 0} hrs
                    </p>
                  </div>
                </div>
              )}

              <h6 className="text-dark mt-2">Expenses</h6>

              {counterOfferData?.expenses?.length > 0 &&
                counterOfferData?.expenses?.map((expense, index) => (
                  <div className="flex items-center mt-2 mb-3" key={index}>
                    <p className="mr-5">
                      {expense?.expenseType} ({expense?.description})
                    </p>
                    <div className="border-t border-dotted border-gray-500  flex-grow"></div>
                    <div className="flex items-center bg-light py-1 px-3 rounded-md ml-5">
                      {expense.isApproved ? (
                        <i className="fa-solid fa-check"></i>
                      ) : (
                        <i className="fa-solid fa-xmark"></i>
                      )}
                      <p className="ml-2 font-normal">
                        {expense?.addedBy?.role?.role === "CLIENT"
                          ? "Buyer"
                          : "Provider"}
                        : ${expense?.amount?.toFixed(2) || 0}
                      </p>
                    </div>
                  </div>
                ))}
              <div className="mt-6">
                <div className="flex justify-between bg-secondary px-4 py-1 rounded-md">
                  <h6 className="text-white">Total Estimate</h6>

                  <h6 className={`text-white`}>
                    $
                    {parseFloat(counterOfferData?.totalWorkOrderCost)?.toFixed(
                      2
                    )}
                  </h6>
                </div>
              </div>
            </BaseCard>
          </div>

          <div
            className="flex justify-center py-8 shadow-xl"
            style={{
              boxShadow: "0px 0px 14px 5px rgba(0, 0, 0, 0.07)",
            }}
          >
            <BaseButton
              fullWidth={false}
              className="py-[4px] mr-6 bg-zinc-200 text-zinc-600 px-[30px]"
              color="primary"
              onClick={() => setReviewCounterOfferModalOpen(false)}
            >
              Close
            </BaseButton>
            <BaseButton
              loaderSize="24px"
              fullWidth={false}
              className="py-[4px] mr-6 bg-zinc-200 text-zinc-600 px-[30px]"
              variant=""
              color="primary"
              onClick={handleDeclineCounterOfferButton}
            >
              Decline
            </BaseButton>
            <BaseButton
              loaderSize="24px"
              isLoading={isAcceptCounterOfferLoading}
              fullWidth={false}
              className="py-[5px] min-w-36"
              color="primary"
              onClick={handleAcceptCounterOfferButton}
            >
              Accept & Assign
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default ReviewCounterOfferModal;
