import React, { useCallback, useState } from "react";
import BaseWrapper from "../../components/ui/BaseWrapper";
import BaseDropdown from "../../components/ui/BaseDropdown";
import BaseRadio from "../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";
import SearchLocation from "../../components/SearchLocation/SearchLocation";
import BaseRange from "../../components/ui/BaseRange";
import BaseButton from "../../components/ui/BaseButton";

const FindJobListingHeader = ({
  handleRadiusChange,
  handleLocationSearch,
  fetchJobs,
  radius,
  selectedLocation,
}) => {
  const [isConverageArea, setIsConverageArea] = useState(false);
  const [isTypesOfJob, setIsTypesOfJob] = useState(false);

  const handleClickTypesOfJob = useCallback(() => {
    setIsTypesOfJob((prev) => !prev);
  }, []);

  const handleClickCoverageArea = useCallback(() => {
    setIsConverageArea((prev) => !prev);
  }, []);

  const handleApplyButton = () => {
    fetchJobs();
    setIsConverageArea(false);
  };
  return (
    <BaseWrapper>
      <div className="bg-white rounded-lg shadow-md">
        <div className="grid grid-cols-7 p-3 items-center ">
          <div className="flex col-span-2">
            <div
              className="bg-[#EAF1FF] rounded-md p-2 flex items-center justify-center relative w-[196px] cursor-pointer mr-6"
              onClick={handleClickTypesOfJob}
            >
              <i className="fa-solid fa-location-dot text-primary"></i>
              <p className="text-primary text-sm  mx-3">Types Of Jobs</p>
              <i className="fa-solid fa-caret-down text-primary"></i>
              {false && (
                <BaseDropdown
                  open={isTypesOfJob}
                  onClick={(e) => e.stopPropagation()}
                  className="w-[416px] top-[50px] shadow-none"
                  style={{ boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)" }}
                >
                  <div className="p-3">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="HOURLY"
                      name="radio-buttons-group"
                    >
                      <BaseRadio
                        label="On Site Job"
                        value="HOURLY"
                        name="payType"
                        // onChange={handleChange}
                      />
                      <div className="mb-6">
                        <SearchLocation label="" />
                        <p className="text-sm font-normal mt-1">
                          Country, City, state or zip
                        </p>
                      </div>

                      <div className="mb-6">
                        <BaseRange min={0} max={256} />
                        <p className="text-sm font-normal">
                          Coverae Area (256 km)
                        </p>
                      </div>

                      <BaseRadio
                        label="Remote Work"
                        value="REMOTE"
                        name="payType"
                        // onChange={handleChange}
                      />
                    </RadioGroup>
                  </div>
                  <div className="flex justify-center mt-3 mb-6">
                    <BaseButton className="bg-[#E5E5E5] text-[#8F8F8F] text-xs w-[104px] h-8 font-normal mr-5">
                      Cancel
                    </BaseButton>
                    <BaseButton className="text-xs w-[104px] h-8 font-normal">
                      Apply
                    </BaseButton>
                  </div>
                </BaseDropdown>
              )}
            </div>
            <div
              className="bg-[#EAF1FF] rounded-md p-2 flex items-center justify-center relative w-[272px] cursor-pointer"
              onClick={handleClickCoverageArea}
            >
              <i className="fa-solid fa-location-dot text-primary"></i>
              <p className="text-primary text-sm  mx-3">
                Coverage Area ({radius} Km)
              </p>
              <i className="fa-solid fa-caret-down text-primary"></i>
              <BaseDropdown
                closeOnOutsideClick={false}
                setOpen={setIsConverageArea}
                open={isConverageArea}
                className="w-[416px] top-[50px] shadow-none"
                style={{ boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)" }}
              >
                <div className="p-3">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="HOURLY"
                    name="radio-buttons-group"
                  >
                    <BaseRadio
                      label="On Site Job"
                      value="HOURLY"
                      name="payType"
                      // onChange={handleChange}
                    />
                    <div className="mb-6">
                      <SearchLocation
                        label=""
                        onPlaceSelected={handleLocationSearch}
                        defaultValue={selectedLocation}
                      />
                      <p className="text-sm font-normal mt-1">
                        Country, City, state or zip
                      </p>
                    </div>

                    <div className="mb-6">
                      <BaseRange
                        min={0}
                        max={500}
                        defaultValue={radius}
                        onChange={(e) => handleRadiusChange(e.target.value)}
                      />
                      <p className="text-sm font-normal">
                        Coverae Area ({radius} km)
                      </p>
                    </div>

                    <BaseRadio
                      label="Remote Work"
                      value="REMOTE"
                      name="payType"
                      // onChange={handleChange}
                    />
                  </RadioGroup>
                </div>
                <div className="flex justify-center mt-3 mb-6">
                  <BaseButton
                    className="bg-[#E5E5E5] text-[#8F8F8F] text-xs w-[104px] h-8 font-normal mr-5"
                    onClick={() => setIsConverageArea(false)}
                  >
                    Cancel
                  </BaseButton>
                  <BaseButton
                    className="text-xs w-[104px] h-8 font-normal"
                    onClick={handleApplyButton}
                  >
                    Apply
                  </BaseButton>
                </div>
              </BaseDropdown>
            </div>
          </div>

          <div className="col-span-5 flex  items-center justify-end">
            <div>
              {/*    <BaseButton
                variant="contained"
                color="primary"
                className="pt-2 pb-2"
                //   onClick={handleNewWorkOrder}
              >
                <p className="text-white">Create New Work Order</p>
              </BaseButton> */}
            </div>
          </div>
        </div>
      </div>
    </BaseWrapper>
  );
};

export default FindJobListingHeader;
