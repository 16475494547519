import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { fetchUserBackgroundVerificationDocs } from "../../../services/api/user";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const BackgroundVerification = () => {
  const { id: userId } = useParams();
  const {
    data: userBackgroundVerificationData,
    refetch: refetchUserBackgroundVerificationDocs,
  } = useQuery({
    queryKey: ["userBackgroundVerificationData"],
    queryFn: () => fetchUserBackgroundVerificationDocs(userId),
    enabled: !!userId,
  });

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark font-normal">Background Verification</h6>
      </div>

      <div className="grid grid-cols-3 gap-x-8">
        <BaseCard className="shadow-md mb-4 ">
          <div className="flex items-center justify-between py-2 px-4">
            <p className="font-normal">Education</p>
            <i
              className={`fa-solid fa-circle-check text-red-600 ${
                userBackgroundVerificationData?.data?.education &&
                "text-secondary"
              }`}
            ></i>
          </div>
        </BaseCard>
        <BaseCard className="shadow-md mb-4">
          <div className="flex items-center justify-between py-2 px-4">
            <p className="font-normal">Working Rights</p>
            <i
              className={`fa-solid fa-circle-check text-red-600 ${
                userBackgroundVerificationData?.data?.workingRights &&
                "text-secondary"
              }`}
            ></i>
          </div>
        </BaseCard>
      </div>
    </BaseCard>
  );
};

export default BackgroundVerification;
