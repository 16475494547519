import React from "react";
import Otp from "../views/Otp";

const OtpPage = () => {
  return (
    <div className="bg-slate-100 h-screen">
      <Otp />
    </div>
  );
};

export default OtpPage;
