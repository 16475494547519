import { Avatar, Badge, Skeleton } from "@mui/material";
import React from "react";
const ChatUsers = ({
  chatUsers,
  openChat,
  selectedUser,
  isLoading,
  unreadMessages,
}) => {
  return (
    <div className="bg-white h-[600px] border-r overflow-scroll">
      <div className="bg-white flex justify-between items-center shadow-sm  border-b-[1px] h-[70px] sticky top-0 z-10 w-full px-4">
        <h5 className="text-lg font-normal text-dark">Chat</h5>
        <i className="fa-solid fa-magnifying-glass"></i>
      </div>

      {isLoading ? (
        Array(6)
          .fill()
          .map((_, index) => <ChatUserSkeleton key={index} />)
      ) : chatUsers?.length > 0 ? (
        chatUsers.map((user) => (
          <ChatUser
            key={user?.id}
            activeChat={selectedUser?.id === user.id}
            onClick={() => openChat(user)}
            firstName={user?.firstName}
            lastName={user?.lastName}
            unreadCount={unreadMessages[user?.chatRoomId] || 0}
            latestMessage={user?.latestMessage?.message}
          />
        ))
      ) : (
        <p>No messages yet.</p>
      )}
    </div>
  );
};

export default ChatUsers;

const ChatUser = ({
  onClick,
  activeChat,
  firstName,
  lastName,
  unreadCount,
  latestMessage,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center mx-4 p-3 my-4 cursor-pointer ${
        !activeChat && "ml-5"
      } ${activeChat && "rounded-md border-l-4 border-[0.5px] border-primary"}`}
    >
      <Avatar src="https://img.freepik.com/free-photo/front-view-smiley-business-man_23-2148479583.jpg?t=st=1725259431~exp=1725263031~hmac=698528c51f80409024274dce1d7606f1b669320d2d11a09e75f7fa4f32a81b56&w=1380" />
      <div className="flex items-center justify-between w-full">
        <div className="ml-3">
          <p className="text-sm font-medium capitalize">{`${firstName} ${lastName}`}</p>
          <p className="w-56 whitespace-nowrap overflow-hidden text-ellipsis text-xs">
            {latestMessage}
          </p>
        </div>

        <Badge
          className=""
          badgeContent={unreadCount}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "#D9F3FF",
              color: "#0F40AE",
            },
          }}
        ></Badge>
      </div>
    </div>
  );
};

const ChatUserSkeleton = () => {
  return (
    <div className="flex items-center mx-4 p-3 my-4 cursor-pointer rounded-md border-l-4 border-[0.5px] border-gray-300">
      {/* Avatar Skeleton */}
      <Skeleton variant="circular">
        <Avatar />
      </Skeleton>

      <div className="ml-3 w-full">
        {/* Name Skeleton */}
        <Skeleton variant="text" width={120} height={20} />
        {/* Message Preview Skeleton */}
        <Skeleton variant="text" width={180} height={15} />
      </div>

      {/* Badge Skeleton */}
      <Skeleton variant="circular" width={24} height={24} className="ml-5">
        <Badge />
      </Skeleton>
    </div>
  );
};
