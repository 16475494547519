import React from "react";
import BaseDropdown from "../../../components/ui/BaseDropdown";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useNotificationsSocket } from "../../../hooks/useNotificationsSocket";

const Notifications = ({
  isNotificationDropdownOpen,
  setIsNotificationDropdownOpen,
}) => {
  const navigate = useNavigate();
  const { notifications, markAsRead, markAllAsRead, unreadNotificationCount } =
    useNotificationsSocket();

  return (
    <BaseDropdown
      setOpen={setIsNotificationDropdownOpen}
      open={isNotificationDropdownOpen}
      className="w-[526px] top-[40px] shadow-none !-left-[310px] !px-0 !py-0 "
      style={{
        boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="max-h-[500px] overflow-scroll relative rounded-lg">
        <div className="flex items-center justify-between border-b p-3 top-0 sticky bg-white z-10 ">
          <p className="text-base text-dark font-normal">Notifications</p>
          {unreadNotificationCount > 0 && (
            <p
              className="text-primary cursor-pointer"
              onClick={() => markAllAsRead.mutate()}
            >
              Mark all as read
            </p>
          )}
        </div>
        {notifications?.map((notification) => (
          <Notification
            key={notification?.id}
            type={notification.type}
            createdAt={dayjs(notification.createdAt).fromNow()}
            title={notification.title}
            content={notification.content}
            isRead={notification.isRead}
            onClick={() => {
              markAsRead.mutate(notification.id);
              navigate(notification?.url);
              setIsNotificationDropdownOpen(false);
            }}
          />
        ))}
      </div>
    </BaseDropdown>
  );
};

export default Notifications;

const Notification = ({ type, createdAt, content, title, isRead, onClick }) => {
  const renderAvatar = (type) => {
    switch (type) {
      case "WORK_ORDER_PUBLISHED":
        return (
          <Avatar sx={{ bgcolor: "#FF5F002A" }}>
            <i class="fa-solid fa-clipboard-list text-secondary"></i>
          </Avatar>
        );
      case "WORK_ORDER_ASSIGNED":
        return (
          <Avatar sx={{ bgcolor: "#2561A92A" }}>
            <i className="fa-solid fa-briefcase text-primary !text-[#2561A9]"></i>
          </Avatar>
        );
      case "WORK_ORDER_UNASSIGNED":
        return (
          <Avatar sx={{ bgcolor: "#9C27B02A" }}>
            <i className="fa-solid fa-briefcase text-primary !text-[#9C27B0]"></i>
          </Avatar>
        );
      case "WORK_ORDER_READY_TO_GO":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );
      case "WORK_ORDER_CHECK_IN":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );
      case "WORK_ORDER_CHECK_OUT":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );
      case "WORK_ORDER_ON_THE_WAY":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );
      case "WORK_ORDER_RUNNING_LATE":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );
      case "WORK_ORDER_WORK_DONE":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );
      case "REQUESTED":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );

      case "COUNTER":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );

      case "ROUTED":
        return (
          <Avatar sx={{ bgcolor: "#2196F32A" }}>
            <i className="fa-solid fa-briefcase text-primary !text-[#2196F3]"></i>
          </Avatar>
        );
      case "ACCEPT":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );
      case "WITHDRAW":
        return (
          <Avatar src="https://img.freepik.com/free-photo/headshot-attractive-curly-youngster-looks-seriously-camera_176532-8126.jpg?t=st=1740135135~exp=1740138735~hmac=62ab211897c5d72a398c49e36e51d40869e38756ee8918854d4465ec4d9413b5&w=2000"></Avatar>
        );

      case "system":
        return <i className="fa-solid fa-cog text-primary"></i>;
      case "offer":
        return <i className="fa-solid fa-handshake text-primary"></i>;
      default:
        return <i className="fa-solid fa-bell text-primary"></i>;
    }
  };

  const getTitle = (type) => {
    switch (type) {
      case "WORK_ORDER_PUBLISHED":
        return "New Work Order Published:";
      case "WORK_ORDER_ASSIGNED":
        return "🎉 Congratulations!";
      case "WORK_ORDER_UNASSIGNED":
        return "Work Order Unassigned:";
      case "WORK_ORDER_READY_TO_GO":
        return "Ready to Go:";
      case "WORK_ORDER_CHECK_IN":
        return "Engineer Checked In:";
      case "WORK_ORDER_CHECK_OUT":
        return "Engineer Checked Out:";
      case "WORK_ORDER_ON_THE_WAY":
        return "Engineer On The Way:";
      case "WORK_ORDER_RUNNING_LATE":
        return "Engineer Running Late:";
      case "WORK_ORDER_WORK_DONE":
        return "Work Completed:";
      case "REQUESTED":
        return "Offer Requested:";
      case "COUNTER":
        return "Counter Offer Received:";
      case "ROUTED":
        return "Work Order Routed:";
      case "WITHDRAW":
        return "Offer Withdrawn:";
      case "ACCEPT":
        return "Work Order Routed:";
      case "system":
        return "System Notification:";
      case "offer":
        return "New Offer Sent:";
      default:
        return "Notification:";
    }
  };

  return (
    <div
      className={`flex px-4 py-5 border-b cursor-pointer ${
        !isRead && "bg-light"
      }`}
      onClick={onClick}
    >
      {renderAvatar(type)}
      <div className="ml-3 w-full">
        <div className="flex w-full justify-between">
          <p className="font-normal text-dark">{title} </p>
          <p className="text-[10px] text-[#A5A5A5]">{createdAt}</p>
        </div>
        <p className="text-xs">{content}</p>
      </div>
    </div>
  );
};
