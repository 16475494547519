import React, { useState } from "react";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material/";
import BaseButton from "../../../components/ui/BaseButton";
import * as yup from "yup";
import { useFormik } from "formik";

const validationSchema = yup.object({
  bankType: yup.string().required("Bank Type is required"),

  paypalEmail: yup
    .string()
    .email("Enter a valid email")
    .when("bankType", (bankType, schema) =>
      bankType === "PAYPAL"
        ? schema.required("Email is required")
        : schema.notRequired()
    ),

  wiseEmail: yup
    .string()
    .email("Enter a valid email")
    .when("bankType", (bankType, schema) =>
      bankType === "WISE"
        ? schema.required("Email is required")
        : schema.notRequired()
    ),

  nameOnAccount: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Account name is required")
        : schema.notRequired()
    ),

  accountNumber: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Account Number is required")
        : schema.notRequired()
    ),

  iban: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("IBAN is required")
        : schema.notRequired()
    ),

  ifsc: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("IFSC is required")
        : schema.notRequired()
    ),

  swiftCode: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Swift Code is required")
        : schema.notRequired()
    ),

  bsb: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("BSB is required")
        : schema.notRequired()
    ),

  sortCode: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Sort Code is required")
        : schema.notRequired()
    ),

  routingNumber: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Routing Number is required")
        : schema.notRequired()
    ),

  type: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Account Type is required")
        : schema.notRequired()
    ),

  institutionNumber: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Institution Number is required")
        : schema.notRequired()
    ),

  transitNumber: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Transit Number is required")
        : schema.notRequired()
    ),

  address: yup
    .string()
    .when("bankType", (bankType, schema) =>
      bankType === "BANK"
        ? schema.required("Branch Address is required")
        : schema.notRequired()
    ),
});

const BankDetails = () => {
  const formik = useFormik({
    initialValues: {
      bankType: "BANK",
      paypalEmail: "",
      wiseEmail: "",
      nameOnAccount: "",
      accountNumber: null,
      iban: "",
      ifsc: "",
      swiftCode: "",
      bsb: null,
      sortCode: null,
      routingNumber: null,
      type: "",
      institutionNumber: null,
      transitNumber: null,
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true, // ✅ Ensures validation runs on component mount
  });

  const handleChange = (e) => {
    formik.setFieldValue("bankType", e.target.value);
  };

  /*   const handleSubmit = (event) => {
    console.log("triggered", formik.values);
    formik.handleSubmit();
  }; */

  return (
    <form>
      <section className="py-4 px-5">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={formik.values.bankType}
          value={formik.values.bankType} // Bind Formik state
          name="bankType"
        >
          <div>
            <BaseRadio
              className="h-6"
              label={<p className="font-normal text-sm">PayPal</p>}
              value="PAYPAL"
              name="bankType"
              onChange={handleChange}
            />
            <div className="ml-[26px] mb-2">
              <p>
                Due to PayPal's deposit window, delay of funds may be possible.
              </p>

              {formik.values.bankType === "PAYPAL" && (
                <div className="grid grid-cols-2 mt-3">
                  <BaseTextField
                    labelShrink={false}
                    label={<p className="text-primary">Paypal Email Address</p>}
                    optional={false}
                    isDefaultField={false}
                    id="paypalEmail"
                    name="paypalEmail"
                    value={formik.values.paypalEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paypalEmail &&
                      Boolean(formik.errors.paypalEmail)
                    }
                    helperText={
                      formik.touched.paypalEmail && formik.errors.paypalEmail
                    }
                  />
                </div>
              )}
            </div>
          </div>

          <BaseRadio
            className="h-6"
            label={<p className="font-normal text-sm">Wise</p>}
            value="WISE"
            name="bankType"
            onChange={handleChange}
          />

          <div className="ml-[26px] mb-2">
            <p>Due to Wise deposit window, delay of funds may be possible.</p>

            {formik.values.bankType === "WISE" && (
              <div className="grid grid-cols-2 mt-3">
                <BaseTextField
                  labelShrink={false}
                  label={<p className="text-primary">Wise Email Address</p>}
                  optional={false}
                  isDefaultField={false}
                  id="wiseEmail"
                  name="wiseEmail"
                  value={formik.values.wiseEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.wiseEmail && Boolean(formik.errors.wiseEmail)
                  }
                  helperText={
                    formik.touched.wiseEmail && formik.errors.wiseEmail
                  }
                />
              </div>
            )}
          </div>

          <BaseRadio
            className="h-6"
            label={<p className="font-normal text-sm">Bank Details</p>}
            value="BANK"
            name="bankType"
            onChange={handleChange}
          />

          <div className="ml-[26px] mb-2">
            <p>
              The funds will be deposited in to your bank account in your local
              currency. The exchange rate will be based on the market rate that
              Tech Domain is quoted at the time of payment processing.
            </p>

            {formik.values.bankType === "BANK" && (
              <div>
                <div className="flex items-center my-3">
                  <h6 className="w-64 font-normal">Account Information </h6>
                  <hr className="w-full" />
                </div>

                <div className="grid grid-cols-3 mt-3 gap-x-12 gap-y-3">
                  <BaseTextField
                    labelShrink={false}
                    label="Name on Account"
                    optional={false}
                    isDefaultField={false}
                    id="nameOnAccount"
                    name="nameOnAccount"
                    value={formik.values.nameOnAccount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nameOnAccount &&
                      Boolean(formik.errors.nameOnAccount)
                    }
                    helperText={
                      formik.touched.nameOnAccount &&
                      formik.errors.nameOnAccount
                    }
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="Account No."
                    optional={false}
                    isDefaultField={false}
                    id="accountNumber"
                    name="accountNumber"
                    value={formik.values.accountNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountNumber &&
                      Boolean(formik.errors.accountNumber)
                    }
                    helperText={
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber
                    }
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="IBAN Number"
                    optional={false}
                    isDefaultField={false}
                    id="iban"
                    name="iban"
                    value={formik.values.iban}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.iban && Boolean(formik.errors.iban)}
                    helperText={formik.touched.iban && formik.errors.iban}
                  />

                  <BaseTextField
                    labelShrink={false}
                    label="IFSC Number"
                    optional={false}
                    isDefaultField={false}
                    id="ifsc"
                    name="ifsc"
                    value={formik.values.ifsc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.ifsc && Boolean(formik.errors.ifsc)}
                    helperText={formik.touched.ifsc && formik.errors.ifsc}
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="Swift code/BIC"
                    optional={false}
                    isDefaultField={false}
                    id="swiftCode"
                    name="swiftCode"
                    value={formik.values.swiftCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.swiftCode &&
                      Boolean(formik.errors.swiftCode)
                    }
                    helperText={
                      formik.touched.swiftCode && formik.errors.swiftCode
                    }
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="BSB"
                    optional={false}
                    isDefaultField={false}
                    id="bsb"
                    name="bsb"
                    value={formik.values.bsb}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.bsb && Boolean(formik.errors.bsb)}
                    helperText={formik.touched.bsb && formik.errors.bsb}
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="Sort Code"
                    optional={false}
                    isDefaultField={false}
                    id="sortCode"
                    name="sortCode"
                    value={formik.values.sortCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.sortCode && Boolean(formik.errors.sortCode)
                    }
                    helperText={
                      formik.touched.sortCode && formik.errors.sortCode
                    }
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="Routing Number"
                    optional={false}
                    isDefaultField={false}
                    id="routingNumber"
                    name="routingNumber"
                    value={formik.values.routingNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.routingNumber &&
                      Boolean(formik.errors.routingNumber)
                    }
                    helperText={
                      formik.touched.routingNumber &&
                      formik.errors.routingNumber
                    }
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="Account type"
                    optional={false}
                    isDefaultField={false}
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="Institution Number"
                    optional={false}
                    isDefaultField={false}
                    id="institutionNumber"
                    name="institutionNumber"
                    value={formik.values.institutionNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.institutionNumber &&
                      Boolean(formik.errors.institutionNumber)
                    }
                    helperText={
                      formik.touched.institutionNumber &&
                      formik.errors.institutionNumber
                    }
                  />
                  <BaseTextField
                    labelShrink={false}
                    label="Transit Number"
                    optional={false}
                    isDefaultField={false}
                    id="transitNumber"
                    name="transitNumber"
                    value={formik.values.transitNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.transitNumber &&
                      Boolean(formik.errors.transitNumber)
                    }
                    helperText={
                      formik.touched.transitNumber &&
                      formik.errors.transitNumber
                    }
                  />
                  <div className="col-span-3">
                    <BaseTextField
                      labelShrink={false}
                      label="Branch Address"
                      optional={false}
                      isDefaultField={false}
                      id="address"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </RadioGroup>
        <div className="flex justify-end mt-5">
          <BaseButton
            color="primary"
            className="py-[5px] px-[38px]"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Save
          </BaseButton>
        </div>
      </section>
    </form>
  );
};

export default BankDetails;
