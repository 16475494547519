import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseCard from "../../../../../components/ui/BaseCard";
import AddEmpolymentHistoryModal from "../../../components/AddEmpolymentHistoryModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createEmployment,
  deleteEmploymentHistory,
  fetchEmploymentHistoryList,
} from "../../../../../services/api/employment";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../store/toast/toastSlice";
import { useCountries } from "../../../../../hooks/useCountries";
import dayjs from "dayjs";

const validationSchema = yup.object({
  companyName: yup
    .string("Enter Institution Name")
    .required("Company Name is required"),
  designation: yup
    .string("Enter designation")
    .required("Designation is required"),
  country: yup.string().required("Country is required"),
  city: yup.string().required("city is required"),
  startDate: yup.string().required("start date is required"),
  endDate: yup.string().required("end date is required"),
});

const AddEmployment = ({ userId }) => {
  const { getCountryLabel } = useCountries();
  const dispatch = useDispatch();
  const [isAddEmploymentModalOpen, setIsAddEmploymentModalOpen] =
    useState(false);

  const [employmentHistoryList, setEmploymentHistoryList] = useState([]);

  const {
    isPending: isDeletedEmploymentApiLoading,
    error: isDeletedEmploymentApiError,
    data: deletedEmploymentData,
    mutateAsync: deleteEmploymentHistoryMutation,
  } = useMutation({
    mutationFn: deleteEmploymentHistory,
    enabled: false,
  });

  const {
    isPending: isCreateEmploymentLoading,
    error: isCreateEmploymentError,
    mutateAsync: createEmploymentMutation,
  } = useMutation({
    mutationFn: createEmployment,
    enabled: false,
  });

  const {
    data: employmentListData,
    isPending: isFetchEmploymentListLoading,
    error: isFetchEmploymentListError,
    refetch: refetchEmploymentHistoryList,
  } = useQuery({
    queryKey: ["employmentHistoryList", userId],
    queryFn: () => fetchEmploymentHistoryList(userId),
    enabled: !!userId,
  });

  const formik = useFormik({
    initialValues: {
      companyName: null,
      designation: null,
      country: null,
      city: null,
      startDate: null,
      endDate: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleEducationSubmit();
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (employmentListData) {
      setEmploymentHistoryList(employmentListData?.data);
    }
  }, [employmentListData]);

  const handleAddEmployment = async () => {
    try {
      const employmentResponse = await createEmploymentMutation(formik.values);
      dispatch(
        showToast({
          message: "Employment added successfully",
          type: "success",
        })
      );
      setEmploymentHistoryList((prevEmployment) => [
        ...prevEmployment,
        employmentResponse,
      ]);

      setIsAddEmploymentModalOpen(false);
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleEducationSubmit = () => {
    /* if (isEdit) {
      handleEditEducation();
    } else { */
    handleAddEmployment();
    // }
  };

  const handleDeleteEmployment = async (id) => {
    try {
      await deleteEmploymentHistoryMutation(id);
      setEmploymentHistoryList((prevEmployment) =>
        prevEmployment.filter((item) => item.id !== id)
      );
    } catch (error) {}
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">Employment History</p>
          <i
            class="fa-solid fa-circle-plus cursor-pointer text-secondary text-xl"
            onClick={() => {
              formik.setValues({
                companyName: null,
                designation: null,
                country: null,
                city: null,
                startDate: null,
                endDate: null,
              });
              setIsAddEmploymentModalOpen(true);
            }}
          ></i>
        </div>
        {employmentHistoryList?.map((employment) => (
          <div
            className="mx-4 flex justify-between items-center border-b pb-3"
            key={employment.id}
          >
            <div>
              <p className="font-normal mb-1">
                Company Name:{" "}
                <span className="font-300">{employment.companyName}</span>
              </p>
              <p className="font-normal mb-1">
                Designation:{" "}
                <span className="font-300">{employment.designation}</span>
              </p>
              <p className="font-normal mb-1">
                Country:{" "}
                <span className="font-300">
                  {getCountryLabel(employment.country)}
                </span>
              </p>
              <div className="flex mb-1">
                <p className="font-normal">
                  Start Date:{" "}
                  <span className="font-300">
                    {dayjs(employment.startDate).format("DD/MM/YYYY")}
                  </span>
                </p>
                <p className="font-normal ml-8">
                  End Date:{" "}
                  <span className="font-300">
                    {dayjs(employment.endDate).format("DD/MM/YYYY")}
                  </span>
                </p>
              </div>
              {employment.description && (
                <div>
                  <p className="font-normal">Experience:</p>
                  <p className="text-justify">{employment.description}</p>
                </div>
              )}
            </div>
            <i
              class="fa-solid fa-trash-can cursor-pointer"
              onClick={() => handleDeleteEmployment(employment.id)}
            ></i>
          </div>
        ))}
      </BaseCard>
      <AddEmpolymentHistoryModal
        formik={formik}
        open={isAddEmploymentModalOpen}
        close={setIsAddEmploymentModalOpen}
      />
    </form>
  );
};

export default AddEmployment;
