import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

import {
  brandFontFamily,
  brandFontSize,
  brandInputTextColor,
} from "../../../utils/variables";

const CustomBaseSelect = styled(Select)(({ isDefaultField, error }) => ({
  "& .MuiSelect-select": {
    color: brandInputTextColor,
    fontFamily: brandFontFamily,
    fontSize: brandFontSize,
    padding: "14px",
    fontWeight: 300,
  },
  /* "& .MuiOutlinedInput-notchedOutline": {
    ...(!isDefaultField && {
      border: !error && "none !important",
      boxShadow: "1px 1px 4px 1px #00000021",
    }),
  }, */

  "& .MuiOutlinedInput-notchedOutline": {
    ...(!isDefaultField && {
      border: "none",
      boxShadow: "1px 1px 4px 1px #00000021",
    }),
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1.5px solid ${error ? "red" : "orange"}`,
    boxShadow: "none", // Remove shadow if desired
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "orange", // Change label color to orange on focus
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  color: brandInputTextColor,
  fontSize: brandFontSize, // Customize the label size
  fontWeight: 400,
  fontFamily: brandFontFamily,
  marginBottom: "6px",
  display: "flex",
  alignItems: "center",
}));

const BaseSelect = ({
  value,
  labelShrink = true,
  label,
  options,
  multiple,
  className,
  onChange,
  required,
  fullHeight = true,
  placeholder = "",
  infoIcon = false,
  isDefaultField = true,
  error,
  errorMsg,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  const handleDelete = (chipToDelete) => {
    const newValue = selectedValue.filter((item) => item !== chipToDelete);
    setSelectedValue(newValue);
  };

  const content = (
    <div>
      {labelShrink ? (
        <InputLabel
          shrink={labelShrink}
          style={{
            fontWeight: 400,
            fontSize: "19px",
            transform: "translate(12px, -10px) scale(0.75)",
            backgroundColor: "#fff", // Optional: to ensure background color doesn't affect the label
            padding: "0 4px", // Ensures padding from both left and right
          }}
          id={`select-label-${label}`}
        >
          {label}
        </InputLabel>
      ) : (
        <CustomInputLabel shrink={labelShrink}>
          {label}

          {!required && (
            <span className="font-light italic ml-1">– Optional</span>
          )}
          {infoIcon && (
            <div className="ml-1 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M8.70601 0C7.2444 0 5.89481 0.362305 4.65724 1.08691C3.45167 1.80087 2.49149 2.75991 1.77669 3.96404C1.05121 5.20013 0.688477 6.54545 0.688477 8C0.688477 9.45455 1.05121 10.7999 1.77669 12.036C2.49149 13.2401 3.45167 14.1991 4.65724 14.9131C5.89481 15.6377 7.24173 16 8.69801 16C10.1543 16 11.5012 15.6377 12.7388 14.9131C13.9444 14.1991 14.9045 13.2401 15.6193 12.036C16.3448 10.7999 16.7075 9.45455 16.7075 8C16.7075 6.54545 16.3448 5.20013 15.6193 3.96404C14.9045 2.75991 13.9444 1.80087 12.7388 1.08691C11.5012 0.362305 10.157 0 8.70601 0ZM8.22592 11.0609C8.24726 11.0609 8.26326 11.0609 8.27393 11.0609C8.52998 11.0609 8.76469 11.045 8.97807 11.013C9.04208 11.013 9.09009 11.029 9.12209 11.0609C9.1541 11.0929 9.1381 11.1409 9.07408 11.2048C8.39129 11.7376 7.85785 12.004 7.47378 12.004C7.21773 12.004 6.99902 11.9081 6.81765 11.7163C6.72163 11.5778 6.66029 11.4339 6.63362 11.2847C6.60694 11.1355 6.60428 10.997 6.62561 10.8691C6.7003 10.6454 6.93501 10.1605 7.32975 9.41459C7.42577 9.20147 7.57513 8.89777 7.77783 8.5035C8.01255 8.05594 8.14591 7.77889 8.17791 7.67233C8.01788 7.71495 7.76717 7.86946 7.42577 8.13586C7.40443 8.16783 7.37509 8.18382 7.33775 8.18382C7.30041 8.18382 7.28174 8.16783 7.28174 8.13586C7.23907 8.09324 7.23907 8.04529 7.28174 7.99201C7.88986 7.15018 8.40729 6.72927 8.83404 6.72927C9.02607 6.72927 9.20211 6.80919 9.36214 6.96903C9.65019 7.22478 9.71421 7.64036 9.55418 8.21578C9.43682 8.64202 9.18077 9.18015 8.78603 9.83017C8.31661 10.5441 8.1299 10.9544 8.22592 11.0609ZM9.77822 6.48951C9.47949 6.48951 9.22345 6.38561 9.01007 6.17782C8.7967 5.97003 8.69001 5.71429 8.69001 5.41059C8.69001 5.10689 8.7967 4.85115 9.01007 4.64336C9.22345 4.43556 9.48216 4.33167 9.78622 4.33167C10.0903 4.33167 10.3463 4.43556 10.5544 4.64336C10.7624 4.85115 10.8664 5.10423 10.8664 5.4026C10.8664 5.70097 10.7597 5.95671 10.5464 6.16983C10.333 6.38295 10.0769 6.48951 9.77822 6.48951Z"
                  fill="#0062A8"
                />
              </svg>
            </div>
          )}
        </CustomInputLabel>
      )}
      <CustomBaseSelect
        isDefaultField={isDefaultField}
        style={{
          minHeight: fullHeight ? "100%" : "40px",
          background: "#fff",
        }}
        className={`rounded-md$ ${className}`}
        fullWidth
        error={error}
        required
        {...props}
        displayEmpty
        renderValue={(selected) => {
          if (multiple) {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {selectedValue?.map((value) => {
                  const option = options?.find((opt) => opt.value === value);
                  return (
                    <div
                      key={value}
                      className="flex items-center bg-[#e8e8e8] rounded-2xl px-2 py-0.5 cursor-pointer"
                    >
                      <i
                        className="fa-solid fa-xmark cursor-pointer"
                        onClick={() => handleDelete(value)}
                        onMouseDown={(event) => event.stopPropagation()}
                      ></i>
                      <p className="ml-1">{option ? option.label : value}</p>
                    </div>
                  );
                })}
              </Box>
            );
          } else {
            const selectedOption = options?.find(
              (opt) => opt.value === selected
            );
            return selectedOption ? selectedOption.label : placeholder;
          }
        }}
        labelId={`select-label-${label}`}
        id={`select-${label}`}
        value={selectedValue}
        onChange={(e) => onChange(e)}
        label={labelShrink ? label : null}
        multiple={multiple}
        {...(multiple && {
          renderValue: (selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selectedValue?.map((value) => {
                // Find the option object with the matching value
                const option = options?.find(
                  (option) => option.value === value
                );
                // Display the label of the matched option
                return (
                  <div className="flex items-center bg-[#e8e8e8] rounded-2xl px-2 py-0.5 cursor-pointer">
                    <i
                      className="fa-solid fa-xmark cursor-pointer"
                      onClick={() => handleDelete(value)}
                      onMouseDown={(event) => event.stopPropagation()} // Prevent focus
                    ></i>
                    <p className="ml-1">{option ? option.label : value}</p>
                    {/* <Chip key={value} label={option ? option.label : value} /> */}
                  </div>
                );
              })}
            </Box>
          ),
        })}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{
              fontSize: brandFontSize,
              fontFamily: brandFontFamily,
              color: brandInputTextColor,
              fontWeight: 300,
            }}
          >
            <div>
              {multiple && (
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={selectedValue?.includes(option.value)}
                />
              )}
              {option.label}
            </div>
          </MenuItem>
        ))}
      </CustomBaseSelect>
      {error && (
        <div className="flex justify-between mt-1.5">
          {error && <p className="text-red-500 w-full">{errorMsg}</p>}
        </div>
      )}
    </div>
  );

  return labelShrink ? <FormControl fullWidth>{content}</FormControl> : content;
};

export default BaseSelect;
