import React, { useRef, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseTextField from "../../../../../components/ui/BaseTextField";
import BaseCard from "../../../../../components/ui/BaseCard";
import BaseButton from "../../../../../components/ui/BaseButton";
import AddSkillsModal from "../../../components/AddSkillsModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addSkillsToUser,
  deleteUserSkill,
  fetchUserSkills,
} from "../../../../../services/api/user";
import { showToast } from "../../../../../store/toast/toastSlice";
import { useDispatch } from "react-redux";

const validationSchema = yup.object({
  categoryId: yup.string("").required("required"),
  vendorId: yup.string("").required("required"),
  subCategoryIds: yup
    .array()
    .of(yup.string().required("Sub-category is required"))
    .min(1, "At least one sub-category is required")
    .required("Sub-category selection is required"),
});

const AddSkills = () => {
  const dispatch = useDispatch();
  const [isAddSkillsModalOpen, setIsAddSkillsModalOpen] = useState(false);
  const {
    isPending: isAddSkillsLoading,
    error: isAddSkillsError,
    data: addedSkillsData,
    mutateAsync: addSkillsToUserMutation,
  } = useMutation({
    mutationFn: addSkillsToUser,
    enabled: false,
  });

  const {
    isPending: isSkillDeletedApiLoading,
    error: isSkillDeletedApiError,
    data: deletedSkillData,
    mutateAsync: deleteUserSkillMutation,
  } = useMutation({
    mutationFn: deleteUserSkill,
    enabled: false,
  });

  const {
    isPending: isUserSkillsLoading,
    error: isUserSkillsError,
    refetch: refetchUserSkills,
    data: userSkillsData,
  } = useQuery({
    queryKey: ["userSkillsData"],
    queryFn: fetchUserSkills,
    retry: 2,
  });

  const formik = useFormik({
    initialValues: {
      categoryId: null,
      vendorId: null,
      subCategoryIds: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddUserSkills();
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleAddUserSkills = async () => {
    try {
      const userAddedSkillsResponse = await addSkillsToUserMutation({
        subCategoryIds: formik.values.subCategoryIds,
      });
      dispatch(
        showToast({
          message: "Skills added successfully",
          type: "success",
        })
      );
      refetchUserSkills();
      setIsAddSkillsModalOpen(false);
      formik.resetForm();
    } catch (error) {}
  };

  const handleDeleteUserSkill = async (id) => {
    try {
      await deleteUserSkillMutation(id);
      refetchUserSkills();
    } catch (error) {}
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">Add Skills</p>
          <i
            class="fa-solid fa-circle-plus cursor-pointer text-secondary text-xl"
            onClick={() => setIsAddSkillsModalOpen(true)}
          ></i>
        </div>

        <div className="flex flex-wrap gap-4">
          {userSkillsData?.data?.map((skill) => (
            <div className="flex items-center px-4 py-1 bg-light rounded-sm">
              <p key={skill.id}>
                {skill.category}/{skill.vendor}/{skill?.subCategory}
              </p>
              <i
                className="fa-solid fa-xmark ml-2 cursor-pointer hover:text-blue-500"
                onClick={() => handleDeleteUserSkill(skill.id)}
              ></i>
            </div>
          ))}
        </div>
      </BaseCard>
      <AddSkillsModal
        close={setIsAddSkillsModalOpen}
        open={isAddSkillsModalOpen}
        formik={formik}
      />
    </form>
  );
};

export default AddSkills;
