import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const About = ({ description }) => {
  return (
    <BaseCard className="py-4 px-16 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">About</h6>
      </div>
      <p>{description}</p>
    </BaseCard>
  );
};

export default About;
