import axios from "../../lib/axiosInstance";

export function addLanguagesToUser(payload) {
  return axios.post("language", payload).then((res) => res.data);
}

export function fetchAllUserLanguages(payload) {
  return axios.get("language", payload).then((res) => res.data);
}

export function deleteUserLanguage(id) {
  return axios.delete(`language/${id}`).then((res) => res.data);
}
