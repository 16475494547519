import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useQuery } from "@tanstack/react-query";
import { fetchUserQualifications } from "../../../services/api/user";
import { useParams } from "react-router-dom";
import { fetchEmploymentHistoryList } from "../../../services/api/employment";
import { fetchUserEducationList } from "../../../services/api/education";
import dayjs from "dayjs";
import { EducationType } from "../../../utils/enum";

const Education = () => {
  const { id: userId } = useParams();

  const {
    data: userEducationListData,
    isPending: isFetchEducationListLoading,
    error: isFetchEducationListError,
  } = useQuery({
    queryKey: ["userEducationList"],
    queryFn: () => fetchUserEducationList(userId),
    enabled: !!userId,
  });

  console.log("educaiton", userEducationListData);

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Education</h6>
      </div>

      {userEducationListData?.data?.map((education) => (
        <div key={education.id}>
          <div className="flex justify-between items-center">
            <div>
              <h6 className="font-normal mb-1">
                Institution Name:{" "}
                <span className="font-300">{education?.institutionName}</span>
              </h6>
              <p className="font-normal text-sm capitalize mb-1">
                Degree:{" "}
                <span className="text-sm font-300">
                  {EducationType[education?.educationType]}
                </span>
              </p>
              <div className="flex">
                <p className="font-normal text-sm">
                  Start Date:{" "}
                  <span className="font-300">
                    {dayjs(education?.startDate).format("DD/MM/YYYY")}
                  </span>
                </p>
                <p className="font-normal ml-8 text-sm">
                  Start Date:{" "}
                  <span className="font-300">
                    {dayjs(education?.endDate).format("DD/MM/YYYY")}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="border-b my-4"></div>
        </div>
      ))}
    </BaseCard>
  );
};

export default Education;
