import React from "react";
import About from "./About";
import BackgroundVerification from "./BackgroundVerification";
import SkillSet from "./SkillSet";
import Equipments from "./Equipments";
import Language from "./Language";
import Employment from "./Employment";
import Education from "./Education";
import LicenseCertification from "./LicenseCertification";
import Rating from "./Rating";
import Reliability from "./Reliability";

const Profile = ({ user }) => {
  console.log("user", user);
  return (
    <div className="grid grid-cols-1">
      <div>
        <About description={user?.description} />
        <Rating />
        <Reliability />
        <SkillSet skills={user?.skills} />
        <Equipments />
        <BackgroundVerification />
        <LicenseCertification />
        <Education />
        <Employment />
        <Language languages={user?.languages} />
      </div>
    </div>
  );
};

export default Profile;
