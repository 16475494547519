import axios from "../../lib/axiosInstance";

export function fetchPrivateChatMessages(id) {
  return axios.get(`chats/${id}`).then((res) => res.data);
}
export function fetchChatUsers(id) {
  return axios.get(`chats/users/${id}`).then((res) => res.data);
}

// Fetch existing or create a new chat room
export function fetchOrCreateChatRoom(payload) {
  return axios.post(`chats/room`, payload);
}
