import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Sum = ({ payment, status, authRole, userAllowedActions }) => {
  console.log("userAllowedActions", userAllowedActions);
  return (
    <BaseCard className="pt-4 pb-6 px-56 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Sum</h6>
      </div>

      <section>
        <BaseCard className="mt-2 p-4 shadow-md" elevation={0}>
          {status !== "CANCELLED" && (
            <div className="flex items-center mb-2">
              <p className="min-w-40 font-normal">Work Order Cost</p>
              <div className="border-t border-dotted border-gray-500  w-full"></div>
              <p className="min-w-14 ml-5 font-normal">
                ${payment?.totalWorkOrderCost?.toFixed(2)}
              </p>
            </div>
          )}

          {status === "CANCELLED" &&
            userAllowedActions?.isWorkOrderCancelByClient && (
              <div className="flex items-center mb-2">
                <p className="min-w-40 font-normal">Cancellation fee</p>
                <div className="border-t border-dotted border-[#F44336]  flex-grow"></div>
                <p className={`min-w-14 ml-5 font-normal ${"text-[#F44336]"}`}>
                  ${payment?.penalty?.toFixed(2)}
                </p>
              </div>
            )}

          <div className="flex items-center mb-2">
            <p className="min-w-40 font-normal">Platform Service fee</p>
            <div className="border-t border-dotted border-primary  flex-grow"></div>
            <p className={`min-w-14 ml-5 font-normal ${"text-primary"}`}>
              {status === "WORKDONE" ||
              status === "APPROVED" ||
              (status === "CANCELLED" &&
                userAllowedActions.isWorkOrderCancelByClient)
                ? `${
                    authRole === "FREELANCER" ? "–" : ""
                  }$${payment?.serviceFee?.toFixed(2)}`
                : "Pending Assignment"}
            </p>
          </div>
          {/* <div className="flex items-center mb-2">
            <p className="min-w-40">Tax%</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">$0</p>
          </div> */}
        </BaseCard>
        <div
          className={`flex items-center mt-6 justify-between ${
            status === "CANCELLED" ? "bg-[#F44336]" : "bg-primary"
          } px-3 py-1 rounded-md`}
        >
          {status === "WORKDONE" ||
          status === "APPROVED" ||
          (status === "CANCELLED" &&
            userAllowedActions.isWorkOrderCancelByClient) ||
          status === "PAID" ? (
            <h6 className="font-normal text-white">Grand Total</h6>
          ) : (
            <h6 className="font-normal text-white">Total Estimate</h6>
          )}

          <h6 className="font-normal text-white">
            $
            {status === "WORKDONE" || status === "APPROVED"
              ? payment?.grandTotal?.toFixed(2)
              : status === "CANCELLED" &&
                userAllowedActions.isWorkOrderCancelByEngineer
              ? 0
              : payment?.totalWorkOrderCost?.toFixed(2)}
          </h6>
        </div>

        <div className="mt-2 justify-between  py-1 rounded-md">
          <p
            className={
              status === "CANCELLED" ? "text-[#F44336]" : "text-primary"
            }
          >
            <span className="font-normal">Please note: </span>
            {status === "CANCELLED"
              ? userAllowedActions?.isWorkOrderCancelByClient
                ? `The above calculations include a cancellation fee, which is 20% of the total labour cost, along with the applicable service fee. These charges are calculated purely based on the cancellation fee and service fee and do not include any additional costs or expenses. Please note that these figures are provided for reference only.`
                : "No payment will be processed as the this work Order has been cancelled by Service Provider."
              : `The above
calculations are provided to give you an estimated idea of the work
            order cost. The actual cost will be determined upon completion of
            the work order, based on the actual time spent.`}
          </p>
        </div>
      </section>
    </BaseCard>
  );
};

export default Sum;
