import React from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseCard from "../../../components/ui/BaseCard";
import BaseTextField from "../../../components/ui/BaseTextField";
import { useDispatch, useSelector } from "react-redux";

import BaseCheckbox from "../../../components/ui/BaseCheckbox";
import {
  updateWorkOrderForm,
  updateWorkOrderFormValidation,
} from "../../../store/workOrder/workOrderSlice";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";

const OverviewForm = () => {
  const dispatch = useDispatch();
  const overview = useSelector((state) => state.workOrder.summary.overview);
  const workOrderFormValidation = useSelector(
    (state) => state.workOrder.workOrderFormValidation
  );

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    dispatch(
      updateWorkOrderForm({
        step: "summary",
        data: {
          overview: {
            [name]: type === "checkbox" ? checked : value,
          },
        },
      })
    );

    if (name === "title" && value) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "title",
          data: false,
        })
      );
    } else if (name === "title" && !value) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "title",
          data: true,
        })
      );
    }
  };

  return (
    <BaseCard className="py-4 px-16 mt-1 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Overview</h6>
      </div>
      <form>
        <div className="grid grid-cols-2 gap-y-3 items-start">
          <div className="mr-10 ">
            <BaseTextField
              error={workOrderFormValidation?.title}
              errorMsg="WO Title is required"
              fullWidth
              fullHeight={false}
              id="title"
              name="title"
              label="Work Order Title"
              hint="(A clear title helps providers understand your project)"
              optional={false}
              labelShrink={false}
              className="col-span-4"
              onChange={handleChange}
              value={overview?.title}
              /*  onBlur={() => {
                dispatch(
                  updateWorkOrderFormValidation({
                    fieldName: "title",
                    data: false,
                  })
                );
              }} */
              isDefaultField={false}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <div>
            <BaseTextField
              fullHeight={false}
              fullWidth
              labelShrink={false}
              id="incidentId"
              name="incidentId"
              label="Incident ID"
              className="col-span-4"
              optional={false}
              value={overview?.incidentId}
              onChange={handleChange}
              isDefaultField={false}
              inputProps={{ maxLength: 30 }}
            />
          </div>

          <div className="mr-10">
            <BaseAutoSelect
              isDefaultField={false}
              fullHeight={false}
              fullWidth
              label="Client"
              labelShrink={false}
              placeholder="No clients"
              required={false}
              name="clientId"
              value={overview?.clientId}
              options={[]}
              onChange={handleChange}
            />
          </div>

          <div>
            <BaseAutoSelect
              placeholder="No projects"
              required={false}
              name="projectId"
              labelShrink={false}
              label="Project"
              fullHeight={false}
              isDefaultField={false}
              options={[]}
              value={overview?.projectId || []}
              onChange={handleChange}
            />
          </div>

          <div className="mr-10">
            <BaseAutoSelect
              fullHeight={false}
              fullWidth
              labelShrink={false}
              id="templateId"
              name="templateId"
              label="Template"
              className="col-span-4"
              placeholder="No templates"
              value={overview?.template || []}
              onChange={handleChange}
              options={[]}
              isDefaultField={false}
            />
          </div>

          <div className="col-span-2 flex items-center">
            <span className="text-sm font-normal">Work order options</span>
            <div className="ml-4">
              <BaseCheckbox
                label="Disable export button"
                name="isExportDisable"
                checked={overview?.isExportDisable}
                onChange={handleChange}
              />
            </div>

            <div className="ml-4">
              <BaseCheckbox
                label="Disable counter offers"
                name="isCounterOffersDisable"
                checked={overview?.isCounterOffersDisable}
                onChange={handleChange}
              />
            </div>
            <div className="ml-4">
              <BaseCheckbox
                label="Require GPS during check in and check out"
                name="isGpsRequire"
                checked={overview?.isGpsRequire}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </BaseCard>
  );
};

export default OverviewForm;
