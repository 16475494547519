import { useQuery } from "@tanstack/react-query";
import { me } from "../services/api";

// Custom Hook
export const useCurrentUser = (userId) => {
  const {
    isPending: isUserLoading,
    error: isUserError,
    refetch: refetchMe,
    data: userData,
  } = useQuery({
    queryKey: ["userData"], // Unique query key for caching
    queryFn: me,
    retry: 2, // Retry fetching twice on failure
  });

  return { userData, refetchMe, isUserLoading };
};
