import React, { useRef, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";
import { useQuery } from "@tanstack/react-query";
import {
  fetchQualificationTypes,
  fetchSubQualificationTypes,
} from "../../../services/api/qualification";

const AddEquipmentModal = ({
  open,
  close,
  formik,
  isAddQualificationLoading,
}) => {
  const {
    data: qualificationsData,
    isPending: isQualificationsLoading,
    error: isQualificationsError,
  } = useQuery({
    queryKey: ["qualifications"],
    queryFn: fetchQualificationTypes,
  });

  const equipmentQualification = qualificationsData?.data?.find(
    (q) => q.name === "Equipment"
  );

  const {
    data: subQualificationsData,
    isPending: isSubQualificationsLoading,
    error: isSubQualificationsError,
  } = useQuery({
    queryKey: ["subQualifications", equipmentQualification?.id],
    queryFn: () => fetchSubQualificationTypes(equipmentQualification?.id),
    enabled: !!equipmentQualification?.id, // ✅ Only fetch when equipmentId exists
  });

  const formattedSubQualifications = subQualificationsData?.data?.map(
    (subQualification) => {
      return {
        label: subQualification?.name,
        value: subQualification?.id,
      };
    }
  );

  return (
    <BaseModal
      className="-top-[200px] overflow-scroll"
      open={open}
      close={close}
      style={{ width: "1000px" }}
    >
      <div className="py-7 px-12">
        <h6 className="text-dark font-normal border-b pb-1">Add Equipments</h6>

        <BaseAutoSelect
          isDefaultField={false}
          placeholder="Select Equipment"
          className="mt-6"
          options={formattedSubQualifications}
          multiple={true}
          disableCloseOnSelect
          id="qualificationIds"
          name="qualificationIds"
          value={formik.values.qualificationIds}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.qualificationIds &&
            Boolean(formik.errors.qualificationIds)
          }
          helperText={
            formik.touched.qualificationIds && formik.errors.qualificationIds
          }
        />
      </div>
      <div
        className="d-flex content-center bg-white py-6 mt-6"
        style={{
          boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className=" px-[28px] py-[5px] bg-light text-primary hover:text-primary"
          variant="outlined"
          color="primary"
          style={{
            marginRight: "16px",
          }}
          onClick={() => close(false)}
        >
          Cancel
        </BaseButton>

        <BaseButton
          isLoading={isAddQualificationLoading}
          loaderSize={"22px"}
          loaderColor="primary"
          className="px-[12px] py-[5px]"
          color="primary"
          onClick={formik.handleSubmit}
        >
          Save Changes
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default AddEquipmentModal;
