import React, { useEffect, useRef, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";
import { useCountries } from "../../../hooks/useCountries";
import { useMutation } from "@tanstack/react-query";
import { fetchCities } from "../../../services/api/country";
import dayjs from "dayjs";

const AddEmpolymentHistoryModal = ({ open, close, formik }) => {
  const { countries, getCountryLabel } = useCountries();
  const {
    isPending: isCitiesLoading,
    error: isCitiesError,
    data: cities,
    mutateAsync: fetchCitiesMutation,
  } = useMutation({
    mutationFn: fetchCities,
    enabled: false,
  });

  useEffect(() => {
    const fetchCities = async () => {
      if (!formik.values.country) return;

      const countryFullName = getCountryLabel(formik.values.country);
      if (countryFullName) {
        await fetchCitiesMutation({ country: countryFullName });
      }
    };

    fetchCities();
  }, [formik.values.country]);

  const handleCountryChange = async (event) => {
    const selectedCountry = event.target.label; // Get the selected country
    formik.handleChange(event); // Update Formik's state

    if (selectedCountry) {
      try {
        const payload = { country: selectedCountry };
        const citiesData = await fetchCitiesMutation(payload);
      } catch (error) {}
    }
  };

  const formattedCitiesData = cities?.data?.map((city) => {
    return {
      label: city,
      value: city,
    };
  });

  return (
    <BaseModal
      className="-top-[200px] overflow-scroll"
      open={open}
      close={close}
      style={{ width: "1000px" }}
    >
      <div className="py-7 px-12 ">
        <h6 className="text-dark font-normal border-b pb-1">
          Employement History
        </h6>

        <div className="grid grid-cols-2 gap-x-10 gap-y-2 mt-4">
          <BaseTextField
            label="Company Name"
            isDefaultField={false}
            labelShrink={false}
            optional={false}
            id="companyName"
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          />

          <BaseTextField
            label="Designation"
            isDefaultField={false}
            labelShrink={false}
            optional={false}
            id="designation"
            name="designation"
            value={formik.values.designation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.designation && Boolean(formik.errors.designation)
            }
            helperText={formik.touched.designation && formik.errors.designation}
          />

          <BaseAutoSelect
            labelShrink={false}
            label="Country"
            required={true}
            isDefaultField={false}
            id="country"
            name="country"
            options={countries}
            value={formik.values.country}
            onChange={handleCountryChange}
            onBlur={formik.handleBlur}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
          <BaseAutoSelect
            labelShrink={false}
            label="City"
            required={true}
            isDefaultField={false}
            options={formattedCitiesData}
            id="city"
            name="city"
            defaultValue={formik.values.city}
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />

          <div>
            <p className="font-normal">Start Date</p>
            <BaseDatePicker
              className="border-none"
              type="date"
              id="startDate"
              name="startDate"
              value={dayjs(formik.values.startDate)}
              onChange={(event) => {
                const selectedDate = dayjs(event)
                  .utcOffset(0, true)
                  .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                formik.setFieldValue("startDate", selectedDate); // Store as string
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      border: "none !important",
                      boxShadow: "1px 1px 4px 1px #00000021",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      margin: "8px 1.5px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none !important",
                        boxShadow: "none",
                      },
                    "& .MuiInputBase-input": {
                      padding: "10px 14px",
                      fontSize: "13px",
                      height: "100% !important",
                      lineHeight: "1.5",
                    },

                    "& .MuiIconButton-root": {
                      backgroundColor: "white !important",
                      borderLeft: "none !important",
                    },
                  },
                },
              }}
            />
          </div>
          <div>
            <p className="font-normal">End Date</p>
            <BaseDatePicker
              className="border-none"
              type="date"
              id="endDate"
              name="endDate"
              minDate={
                formik.values.startDate
                  ? dayjs(formik.values.startDate)
                  : undefined
              }
              value={dayjs(formik.values.endDate)}
              onChange={(event) => {
                const selectedDate = dayjs(event)
                  .utcOffset(0, true)
                  .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                formik.setFieldValue("endDate", selectedDate); // Store as string
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      border: "none !important",
                      boxShadow: "1px 1px 4px 1px #00000021",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      margin: "8px 1.5px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none !important",
                        boxShadow: "none",
                      },
                    "& .MuiInputBase-input": {
                      padding: "10px 14px",
                      fontSize: "13px",
                      height: "100% !important",
                      lineHeight: "1.5",
                    },

                    "& .MuiIconButton-root": {
                      backgroundColor: "white !important",
                      borderLeft: "none !important",
                    },
                  },
                },
              }}
            />
          </div>
          <div className="col-span-2">
            <p className="font-normal mb-2">Description</p>
            <textarea
              className="shadow-md border-t border-zinc-100	w-full rounded-md h-32 resize-none p-4  focus:outline-none focus:ring-1 focus:ring-orange-400 text-xs font-[300] "
              id="description"
              name="description"
              defaultValue={formik.values.description}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            ></textarea>
          </div>
        </div>
      </div>

      <div
        className="d-flex content-center bg-white py-6 mt-6"
        style={{
          boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className=" px-[28px] py-[5px] bg-light text-primary hover:text-primary"
          variant="outlined"
          color="primary"
          style={{
            marginRight: "16px",
          }}
          onClick={() => {
            close(false);
            // setSelectedUserEducation(null);
          }}
        >
          Cancel
        </BaseButton>

        <BaseButton
          loaderSize={24}
          loaderColor="primary"
          className="px-[20px] py-[5px]"
          color="primary"
          onClick={formik.handleSubmit}
        >
          Save Changes
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default AddEmpolymentHistoryModal;
