import React, { useState } from "react";
import BaseTextField from "../../../../components/ui/BaseTextField";
import { useDispatch, useSelector } from "react-redux";
import {
  updateWorkOrderForm,
  updateWorkOrderFormValidation,
} from "../../../../store/workOrder/workOrderSlice";

const HourlyPay = () => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.workOrder.payment.pay);
  const workOrderFormValidation = useSelector(
    (state) => state.workOrder.workOrderFormValidation
  );

  const [isMinimumHourError, setIsMinimumHourError] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "minimumHours" && parseInt(value) < 1) {
      setIsMinimumHourError(true);
    } else {
      setIsMinimumHourError(false);
    }
    dispatch(
      updateWorkOrderForm({
        step: "payment",
        data: {
          pay: {
            [name]: value,
          },
        },
      })
    );

    if (name === "minimumHours" && value) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "minimumHours",
          data: false,
        })
      );
    }
    if (name === "minimumHours" && (!value || parseInt(value) === 0)) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "minimumHours",
          data: true,
        })
      );
    }

    if (name === "hourlyRate" && value >= 20) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "hourlyRate",
          data: false,
        })
      );
    }
    if (name === "hourlyRate" && !value) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "hourlyRateErrorMsg",
          data: "Hourly Rate is required",
        })
      );
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "hourlyRate",
          data: true,
        })
      );
    }
  };

  return (
    <div className="grid grid-cols-2 gap-y-4 mt-3">
      <div className="mr-10">
        <BaseTextField
          onInput={(e) => (e.target.value = e.target.value.replace(/-/g, ""))}
          error={workOrderFormValidation.hourlyRate}
          errorMsg={workOrderFormValidation.hourlyRateErrorMsg}
          fullWidth
          fullHeight={false}
          id="hourlyRate"
          name="hourlyRate"
          label="Hourly Rate"
          optional={false}
          labelShrink={false}
          value={payment.hourlyRate}
          onChange={handleChange}
          isDefaultField={false}
          type="number"
          bottomRightMsg="The hourly rate should be no less than $20 per hour"
        />
      </div>
      <div>
        <BaseTextField
          onInput={(e) => (e.target.value = e.target.value.replace(/-/g, ""))}
          error={workOrderFormValidation.minimumHours}
          errorMsg="Minimum 1 hour is required"
          fullWidth
          fullHeight={false}
          id="minimumHours"
          name="minimumHours"
          label="Minimum Hours"
          optional={false}
          labelShrink={false}
          required={true}
          value={payment.minimumHours}
          onChange={handleChange}
          isDefaultField={false}
          /* onBlur={() => {
            dispatch(
              updateWorkOrderFormValidation({
                fieldName: "minimumHours",
                data: false,
              })
            );
          }} */
          type="number"
          bottomRightMsg="Minimum Payable Hours"
        />
      </div>
    </div>
  );
};

export default HourlyPay;
