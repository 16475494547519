import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchAllCertifications } from "../../../services/api/certification";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

const LicenseCertification = () => {
  const { id: userId } = useParams();
  const {
    data: certificationsData,
    isPending: isFetchCertificationsLoading,
    error: isFetchCertificationsError,
    refetch: refetchAllCertifications,
  } = useQuery({
    queryKey: ["certifications", userId],
    queryFn: () => fetchAllCertifications(userId),
    enabled: !!userId,
  });
  console.log("certificationsData", certificationsData);
  const columns = [
    {
      field: "name",
      headerName: "License / Certification",
      flex: 1,
      renderCell: (params) => <p className="text-primary">{params.value}</p>,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: "startDate",
      headerName: "Issue date",
      flex: 0.5,
      renderCell: (params) => <p>{dayjs(params.value).format("MM/DD/YYYY")}</p>,
    },
    {
      field: "endDate",
      headerName: "Expiry Date",
      flex: 1,
      renderCell: (params) => <p>{dayjs(params.value).format("MM/DD/YYYY")}</p>,
    },
  ];

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark font-normal">License & Certification</h6>
      </div>

      <DataGrid
        rows={certificationsData?.data}
        rowHeight={38}
        columns={columns}
        loading={isFetchCertificationsLoading}
        disableColumnSorting
        disableColumnMenu
        isCellEditable={(params) => false}
        hideFooter
        sx={{
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "#f9f9f9",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f9f9f9",
          },
        }}
      />
    </BaseCard>
  );
};

export default LicenseCertification;
