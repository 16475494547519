import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
  brandFontFamily,
  brandInputTextColor,
  brandPrimaryColor,
} from "../../utils/variables";
import useAuth from "../../hooks/useAuth";
import BaseWrapper from "../../components/ui/BaseWrapper";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import routes from "../../routes";
import { useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    height: "34px",
    width: "170px",
    fontWeight: 300,
    fontSize: "15px",
    boxShadow: "0px -1px 8px 0px rgba(0, 0, 0, 0.09)",

    borderRadius: "6px",
    // padding: 0,
    margin: "14px 0",
    background: "white",
    marginRight: theme.spacing(1),
    color: brandInputTextColor,
    fontFamily: brandFontFamily,
    "&:hover": {
      /* color: "#40a9ff",
        opacity: 1, */
    },
    "&.Mui-selected": {
      color: brandPrimaryColor,
      fontWeight: 400,
      borderBottom: `1px solid ${brandPrimaryColor}`,
    },
    "&.Mui-focusVisible": {
      //   backgroundColor: "#d1eaff",
    },
  })
);

const WorkJobTabs = () => {
  const { id } = useParams();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { authRole } = useAuth(); // Example: authRole = "CLIENT"
  const [value, setValue] = useState(0);

  // Extract WorkOrder route and its childs
  const workOrderRoute = routes.find((route) => route.path === "/workOrder");

  const allTabs = workOrderRoute?.childs || [];

  // Filter tabs based on the user's role
  const filteredTabs = allTabs?.filter(
    (tab) =>
      tab?.roles?.includes(authRole) &&
      tab?.statuses?.includes(workOrderDetail.status)
  );

  // Set initial tab based on the current URL
  useEffect(() => {
    const currentTabIndex = filteredTabs.findIndex((tab) =>
      location.pathname.includes(tab.path.split("/:id")[0])
    );
    if (currentTabIndex !== -1) {
      setValue(currentTabIndex);
    }
  }, [location.pathname, filteredTabs]);

  const handleChange = (event, newValue) => {
    const selectedTab = filteredTabs[newValue];
    navigate(`/workOrder/${selectedTab.path.replace(":id", id)}`); // Replace with dynamic ID if necessary
    setValue(newValue);
  };
  return (
    <BaseWrapper>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            TabIndicatorProps={{
              style: {
                display: "none", // Hide the indicator
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {filteredTabs.map((tab, index) => (
              <CustomTab
                key={index}
                label={tab.text}
                {...a11yProps(index)}
                sx={{
                  minHeight: "34px",
                  marginRight: "30px",
                }}
              />
            ))}
          </Tabs>
        </Box>
        {filteredTabs.map((tab, index) => (
          <CustomTabPanel value={value} index={index} key={index}>
            {tab.component || <Box>No Component Defined</Box>}
          </CustomTabPanel>
        ))}
      </Box>
    </BaseWrapper>
  );
};

export default WorkJobTabs;
