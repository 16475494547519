import React from "react";
import BaseWrapper from "../../components/ui/BaseWrapper";
import BaseInnerWrapper from "../../components/ui/BaseInnerWrapper";
import Header from "./components/Header";
import CompleteProfileSteps from "./components/CompleteProfileSteps";

const CompleteYourProfile = () => {
  return (
    <div className="bg-light h-screen overflow-scroll scroll-smooth">
      <Header />

      <div className="mx-40">
        <CompleteProfileSteps />
      </div>
    </div>
  );
};

export default CompleteYourProfile;
