import React from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";
import dayjs from "dayjs";

import BaseSelect from "../../../components/ui/BaseSelect";

const AddEducationModal = ({
  open,
  close,
  formik,
  setSelectedUserEducation,
  isEdit,
}) => {
  if (!open) return null;

  return (
    <BaseModal
      className="-top-[200px] overflow-scroll"
      open={open}
      close={close}
      style={{ width: "1000px" }}
    >
      <div className="py-7 px-12">
        <h6 className="text-dark font-normal border-b pb-1">Add Education</h6>

        <div className="mb-2 mt-3">
          <div className="grid grid-cols-2 gap-x-14 gap-y-2">
            <BaseTextField
              isDefaultField={false}
              label="Institution Name"
              labelShrink={false}
              optional={false}
              id="institutionName"
              name="institutionName"
              value={formik.values.institutionName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.institutionName &&
                Boolean(formik.errors.institutionName)
              }
              helperText={
                formik.touched.institutionName && formik.errors.institutionName
              }
            />
            <div>
              <BaseSelect
                className="h-0"
                labelShrink={false}
                required={true}
                isDefaultField={false}
                fullHeight={false}
                fullWidth
                options={[
                  {
                    label: "Masters",
                    value: "MASTERS_DEGREE",
                  },
                  {
                    label: "Bachelor",
                    value: "BACHELORS_DEGREE",
                  },
                  {
                    label: "High School",
                    value: "HIGH_SCHOOL",
                  },
                  {
                    label: "Ceritification",
                    value: "CERTIFICATION",
                  },
                ]}
                label="Education Type"
                id="educationType"
                name="educationType"
                defaultValue={formik.values.educationType}
                value={formik.values.educationType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.educationType &&
                  Boolean(formik.errors.educationType)
                }
                helperText={
                  formik.touched.educationType && formik.errors.educationType
                }
              />
            </div>
            <div>
              <p className="font-normal">Start Date</p>
              <BaseDatePicker
                className="border-none"
                type="date"
                id="startDate"
                name="startDate"
                value={dayjs(formik.values.startDate)}
                onChange={(event) => {
                  const selectedDate = dayjs(event)
                    .utcOffset(0, true)
                    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

                  formik.setFieldValue("startDate", selectedDate); // Store as string
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
                slotProps={{
                  textField: {
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        border: "none !important",
                        boxShadow: "1px 1px 4px 1px #00000021",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        margin: "8px 1.5px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "none !important",
                          boxShadow: "none",
                        },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                        fontSize: "13px",
                        height: "100% !important",
                        lineHeight: "1.5",
                      },

                      "& .MuiIconButton-root": {
                        backgroundColor: "white !important",
                        borderLeft: "none !important",
                      },
                    },
                  },
                }}
              />
            </div>
            <div>
              <p className="font-normal">End Date</p>
              <BaseDatePicker
                className="border-none"
                type="date"
                id="endDate"
                name="endDate"
                value={dayjs(formik.values.endDate)}
                onChange={(event) => {
                  const selectedDate = dayjs(event)
                    .utcOffset(0, true)
                    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                  formik.setFieldValue("endDate", selectedDate); // Store as string
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
                slotProps={{
                  textField: {
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        border: "none !important",
                        boxShadow: "1px 1px 4px 1px #00000021",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        margin: "8px 1.5px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "none !important",
                          boxShadow: "none",
                        },
                      "& .MuiInputBase-input": {
                        padding: "10px 14px",
                        fontSize: "13px",
                        height: "100% !important",
                        lineHeight: "1.5",
                      },

                      "& .MuiIconButton-root": {
                        backgroundColor: "white !important",
                        borderLeft: "none !important",
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex content-center  bg-white py-6 "
        style={{
          boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="px-[28px] py-[5px] bg-light text-primary hover:text-primary"
          variant="outlined"
          color="primary"
          style={{
            marginRight: "16px",
          }}
          onClick={() => {
            close(false);
            setSelectedUserEducation(null);
          }}
        >
          Cancel
        </BaseButton>

        <BaseButton
          loaderSize={24}
          loaderColor="primary"
          className="px-[12px] py-[5px]"
          color="primary"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Save Changes"}
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default AddEducationModal;
