import axios from "../../lib/axiosInstance";

export function createCertification(payload) {
  return axios.post("certifications", payload).then((res) => res.data);
}

export function fetchAllCertifications(id) {
  return axios.get(`certifications/${id}`).then((res) => res.data);
}

export function deleteUserCertification(id) {
  return axios.delete(`certifications/${id}`).then((res) => res.data);
}
