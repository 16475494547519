import React from "react";
import BaseTooltip from "../../components/ui/BaseTooltip";
import { Avatar } from "@mui/material";

const ProfileDetailsHeader = ({ user }) => {
  const openGooglMapLocation = () => {
    window.open(
      "https://maps.google.com?q=" +
        user?.personalAddress?.latitude +
        "," +
        user?.personalAddress?.longitude
    );
  };

  return (
    <div className="flex items-center col-span-2 ">
      <Avatar
        src={user?.picture?.url}
        className={`rounded-xl h-[100px] w-[100px]`}
        variant="square"
      ></Avatar>
      <div className="ml-5">
        <h6 className="font-normal capitalize">
          {user?.firstName} {user?.lastName}{" "}
          <span className="text-base">ID: {user?.id}</span>
        </h6>
        <div className="flex mt-3">
          {user?.personalAddress?.addressLine1 && (
            <BaseTooltip
              position="top"
              interactive={true}
              content={
                <p className="text-white">{`${user?.personalAddress?.addressLine1}`}</p>
              }
            >
              <div
                className="flex bg-[#E7FFF4] py-1 px-3 rounded-md mr-6 cursor-pointer"
                onClick={openGooglMapLocation}
              >
                <i className="fa-solid fa-location-dot text-[#147648] text-sm"></i>
                <p className="text-[#147648] text-sm ml-2 truncate max-w-64 overflow-hidden whitespace-nowrap">
                  {user?.personalAddress?.addressLine1}
                </p>
              </div>
            </BaseTooltip>
          )}

          <div className="flex bg-[#F1FDFF] py-1 px-3 rounded-md mr-6">
            <i className="fa-solid fa-clock-rotate-left text-[#35BDCF] text-sm"></i>
            <p className="text-[#35BDCF] text-sm ml-2">Job History</p>
          </div>
          <div className="flex bg-[#ECF4FF] py-1 px-3 rounded-md mr-6 cursor-default">
            <i className="fa-solid fa-file-invoice text-[#3573CF] text-sm"></i>
            <p className="text-[#3573CF] text-sm ml-2">
              {user?.jobsCompleted} Jobs Completed
            </p>
          </div>
          <div className="flex bg-[#F8FFED] py-1 px-3 rounded-md">
            <i className="fa-solid fa-suitcase text-[#74A525] text-sm"></i>
            <p className="text-[#74A525] text-sm ml-2">
              {user?.numberOfClients} Clients
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsHeader;
