import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { ReactComponent as Logo } from "../../assets/logo-stack.svg";

const ForgotPassword = () => {
  return (
    <div className="grid grid-cols-12 gap-28 w-full pt-32">
      <div className="col-span-3"></div>
      <div className="col-span-6">
        <div className="flex justify-center mb-6">
          <Logo />
        </div>
        <ForgotPasswordForm />
      </div>
      <div className="col-span-3"></div>
    </div>
  );
};

export default ForgotPassword;
