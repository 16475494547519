import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  summary: {
    overview: {
      templateId: null,
      title: "",
      clientId: null,
      projectId: null,
      isExportDisable: false,
      isCounterOffersDisable: false,
      isGpsRequire: false,
      incidentId: "",
    },
    serviceDescription: {
      description: "",
      confidentialInformation: "",
    },
    workExperience: {
      categoryId: null,
      vendorId: null,
      workOrderServices: [],
    },
  },
  scheduling: {
    schedule: {
      scheduleType: "HARD_START",
      orderStartDate: "",
      orderStartTime: "",
      orderEndDate: null,
      orderEndTime: null,
      timeZone: "",
    },
    location: {
      addressId: "",
      displayName: "",
      locationType: "RESIDENTIAL",
      country: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      longitude: "",
      latitude: "",
      saveForFuture: false,
      isRemote: false,
      type: "EXACT_LOCATION",
    },
    contacts: {
      workOrderManagerId: "",
      contacts: [
        {
          id: Date.now(),
          name: "",
          position: "",
          phone: "",
          email: "",
          note: "",
        },
      ],
    },
    qualifications: [
      {
        id: Date.now(),
        qualificationTypeId: null,
        qualificationSubTypes: [],
      },
    ],
  },
  payment: {
    pay: {
      paymentTerms: "15 days",
      paymentType: "HOURLY",
      hourlyRate: null,
      minimumHours: null,
      fixedAmount: 0,
    },

    expenses: [
      {
        id: Date.now(),
        expenseType: "",
        description: "",
        amount: "",
      },
    ],

    tasks: [
      {
        step: "Prep",
        taskType: "START_TIME",
        emailsToNotify: [],
        title: "Set start time",
      },
      {
        taskType: "ON_THE_WAY",
        emailsToNotify: [],
        title: "On the Way",
      },
      {
        taskType: "RUNNING_LATE",
        emailsToNotify: [],
        title: "Running Late",
      },
      {
        taskType: "MARK_COMPLETE",
        emailsToNotify: [],
        title: "Mark Complete",
      },

      {
        step: "On Site",

        taskType: "CHECK_IN",
        emailsToNotify: [],
        title: "Check In",
      },
      {
        taskType: "CHECK_OUT",
        emailsToNotify: [],
        title: "Check Out",
      },
      {
        step: "Post",

        taskType: "CLOSE_NOTES",
        emailsToNotify: [],
        title: "Enter closeout notes",
      },
    ],
  },
  workOrderFormValidation: {
    title: false,
    orderStartDate: false,
    addressLine1: false,
    addressId: false,
    hourlyRateErrorMsg: "",
    minimumHours: false,
  },
  workOrderDetails: {},
  subQualificationOptions: {},
};

const workOrderSlice = createSlice({
  name: "workOrderSlice",
  initialState,
  reducers: {
    updateWorkOrderForm: (state, action) => {
      const { step, data } = action.payload;

      // Ensure that the state for the given step exists
      if (state[step]) {
        // Iterate through each key in the data object
        Object.keys(data).forEach((key) => {
          if (state[step][key]) {
            // Merge nested objects while preserving existing values
            state[step][key] = {
              ...state[step][key],
              ...data[key],
            };
          } else {
            // If the key is not a nested object, update it directly
            state[step] = {
              ...state[step],
              ...data,
            };
          }
        });
      }
    },
    updateWorkOrderFormValidation: (state, action) => {
      const { fieldName, data } = action.payload;
      state.workOrderFormValidation[fieldName] = data;
    },
    setWorkOrderDetail: (state, action) => {
      state.workOrderDetails = action.payload;
    },
    updateWorkOrderDetail: (state, action) => {
      state.summary = action.payload.summary;
      state.payment = action.payload.payment;
    },
    resetWorkOrderForm: () => initialState, // Reset state to initialState
    addNotification: (state) => {
      state.payment.tasks.push({
        id: Date.now(),
        taskType: "",
        emailsToNotify: [],
      });
    },
    removeNotification: (state, action) => {
      const { type, id } = action.payload;

      let filteredTask = state.payment.tasks.find(
        (task) => task.taskType === type
      );

      if (filteredTask) {
        filteredTask.emailsToNotify = filteredTask.emailsToNotify.filter(
          (email) => email.id !== id
        );
      }
    },
    addEmailToNotify: (state, action) => {
      const { type } = action.payload;

      const filteredTask = state.payment.tasks.find(
        (task) => task.taskType === type
      );

      if (filteredTask) {
        filteredTask.emailsToNotify.push({ id: Date.now(), email: "" });
      }
    },
    updateEmail: (state, action) => {
      const { taskType, id, email } = action.payload;

      // Find the task that matches the taskType
      const taskIndex = state.payment.tasks.findIndex(
        (task) => task.taskType === taskType
      );

      if (taskIndex !== -1) {
        // Find the email in emailsToNotify by its id and update its value
        const emailIndex = state.payment.tasks[
          taskIndex
        ].emailsToNotify.findIndex((item) => item.id === id);
        if (emailIndex !== -1) {
          state.payment.tasks[taskIndex].emailsToNotify[emailIndex].email =
            email;
        }
      }
    },
    taskHandleChange: (state, action) => {
      const { id, value, field } = action.payload;

      // Find the task by its ID
      const task = state.payment?.tasks?.find((task) => task.id === id);
      if (task) {
        // Check field and update the corresponding task field
        switch (field) {
          case "phone":
            task.phone = value;
            break;
          case "reason":
            task.reason = value;
            break;
          case "signatureFrom":
            task.signatureFrom = value;
            break;
          case "description":
            task.description = value;
            break;
          default:
            break;
        }
      }
    },
    addCustomTask: (state, action) => {
      const { step } = action.payload;

      // Create a custom task
      const newTask = {
        id: new Date().getTime(), // or any unique ID generation logic
        taskType: "CUSTOM", // Set taskType to "custom"
        emailsToNotify: [],
        title: "Select a task",
      };
      state.payment.tasks.unshift(newTask);

      // uncommnet the changes to add the add on specified step
      /*  // Find the last task for the specified step
      const lastTaskIndex = state.payment.tasks
        .map((task, index) => ({ task, index }))
        .filter(({ task }) => task.step === step)
        .map(({ index }) => index)
        .pop(); // Get the index of the last task with the same step

      if (lastTaskIndex !== undefined) {
        // Insert the new task right after the last task with the same step
        state.payment.tasks.splice(state.payment.tasks.length - 1, 0, newTask);
      } else {
        // If no tasks with the same step, add the task at the end
        state.payment.tasks.push(newTask);
      } */
    },
    removeCustomTask: (state, action) => {
      console.log(action.payload);
      state.payment.tasks = state.payment.tasks.filter(
        (task) => task.id !== action.payload
      );
    },
    updateTaskType: (state, action) => {
      const { id, newTask } = action.payload;

      // Find the task by ID and update its taskType
      const taskIndex = state.payment.tasks.findIndex((task) => task.id === id);

      if (taskIndex !== -1) {
        state.payment.tasks[taskIndex] = newTask;
      }
    },
    addContact: (state) => {
      const newContact = {
        id: Date.now(),
        name: "",
        position: "",
        phone: "",
        email: "",
        note: "",
      };
      state.scheduling.contacts.contacts.push(newContact);
    },
    updateContact: (state, action) => {
      const { index, field, value } = action.payload;
      state.scheduling.contacts.contacts[index][field] = value;
    },
    removeContact: (state, action) => {
      state.scheduling.contacts.contacts =
        state.scheduling.contacts.contacts.filter(
          (contact) => contact.id !== action.payload
        );
    },

    addExpense: (state) => {
      const newExpense = {
        id: Date.now(),
        expenseType: "",
        description: "",
        amount: "",
      };
      state.payment.expenses.push(newExpense);
    },
    updateExpense: (state, action) => {
      const { index, field, value } = action.payload;
      state.payment.expenses[index][field] = value;
    },
    removeExpense: (state, action) => {
      state.payment.expenses = state.payment.expenses.filter(
        (expense) => expense.id !== action.payload
      );
    },

    addQualification: (state) => {
      const newQualification = {
        id: Date.now(),
        qualificationTypeId: null,
        qualificationSubTypes: [],
      };
      state.scheduling.qualifications.push(newQualification);
    },

    updateQualification: (state, action) => {
      const { index, field, value } = action.payload;
      if (state.scheduling.qualifications[index]) {
        // Reset sub-qualifications for this qualification
        state.scheduling.qualifications[index].qualificationSubTypes = [];
      }

      state.scheduling.qualifications[index][field] = value;
    },

    removeQualification: (state, action) => {
      state.scheduling.qualifications = state.scheduling.qualifications.filter(
        (qualification) => qualification.id !== action.payload
      );
    },

    updateSubQualificationOptions: (state, action) => {
      const { qualificationTypeId, options } = action.payload;
      state.subQualificationOptions[qualificationTypeId] = options;
    },
  },
});

export const {
  updateWorkOrderForm,
  setWorkOrderDetail,
  updateWorkOrderDetail,
  resetWorkOrderForm,
  addNotification,
  removeNotification,
  addEmailToNotify,
  updateTaskType,
  updateEmail,
  addCustomTask,
  taskHandleChange,
  addContact,
  updateContact,
  removeContact,
  addQualification,
  updateQualification,
  removeQualification,
  updateSubQualificationOptions,
  addExpense,
  updateExpense,
  removeExpense,
  updateWorkOrderFormValidation,
  removeCustomTask,
} = workOrderSlice.actions;
export default workOrderSlice.reducer;
