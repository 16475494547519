import React, { useEffect, useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PendingAssignment from "../ProvidersTab/PendingAssignment";
import NearbyFreelancers from "../ProvidersTab/NearbyFreelancers";
import { fetchProvidersCount } from "../../../services/api/workOrder";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TAB_MAPPING = [
  "nearby",
  "talentPool",
  "workedWith",
  "pending",
  "assigned",
  "declined",
  "hidden",
];

const Providers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = useState(["PENDING"]);
  const [value, setValue] = useState(0);
  const [radius, setRadius] = useState(50);
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  const {
    isLoading: isProvidersCountLoading,
    error: isProvidersCountError,
    data: providersCount,
    refetch: refetchProvidersCount,
  } = useQuery({
    queryKey: ["providersCount"],
    queryFn: () =>
      fetchProvidersCount({
        latitude: workOrderDetail?.address?.latitude,
        longitude: workOrderDetail?.address?.longitude,
        radius: radius,
        workOrderId: workOrderDetail?.id,
      }),
    enabled: !!workOrderDetail,
  });

  useEffect(() => {
    refetchProvidersCount();
  }, [radius]);

  useEffect(() => {
    // Initialize tab and filters from URL query parameter
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");

    if (tab && TAB_MAPPING.includes(tab)) {
      const tabIndex = TAB_MAPPING.indexOf(tab);
      setValue(tabIndex); // Set the active tab

      // Set filters based on the active tab
      switch (tab) {
        case "pending":
          setFilters(["PENDING", "ROUTED"]);
          break;
        case "assigned":
          setFilters(["ASSIGNED"]);
          break;
        case "declined":
          setFilters(["DECLINED"]);
          break;
        case "hidden":
          setFilters(["REJECTED"]);
          break;
        default:
          setFilters(["PENDING"]); // Default to Pending tab
          break;
      }
    } else {
      // Default to "nearby" tab if no query parameter is present
      setValue(0);
      setFilters(["PENDING"]);
      const searchParams = new URLSearchParams(location.search);
      const isMessagesRoute = location.pathname.includes("/messages");

      // Prevent overriding query parameters on the messages route
      if (!isMessagesRoute && !searchParams.has("tab")) {
        searchParams.set("tab", "nearby");
        navigate(`?${searchParams.toString()}`, { replace: true });
      }
    }

    // Trigger API call to fetch data with the selected filters
    refetchProvidersCount();
  }, [location.search, location.pathname, navigate, refetchProvidersCount]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`?tab=${TAB_MAPPING[newValue]}`, { replace: true });
  };

  return (
    <BaseCard className="py-4 px-12 mt-3 rounded-lg " elevation={1}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="text-sm font-normal px-0 mr-7 min-w-14 pb-0"
            label={
              <div className="flex items-center">
                <p className={`text-sm ${value === 0 && "text-primary"}`}>
                  Nearby
                </p>
                <p
                  className={`min-h-5 min-w-5  text-center  pt-[1px] rounded text-xs ml-3 ${
                    value === 0 ? "bg-primary text-white" : "bg-[#E4E4E4]"
                  }`}
                >
                  {providersCount?.data?.NEARBY || 0}
                </p>
              </div>
            }
            {...a11yProps(0)}
          />

          <Tab
            className="text-sm font-normal px-0 mr-7 min-w-14 pb-0"
            label={
              <div className="flex items-center ">
                <p className={`text-sm ${value === 1 && "text-primary"}`}>
                  Talent Pool
                </p>
                <p
                  className={`min-h-5 min-w-5  text-center  pt-[1px] rounded text-xs ml-3 ${
                    value === 1 ? "bg-primary text-white" : "bg-[#E4E4E4]"
                  }`}
                >
                  0
                </p>
              </div>
            }
            {...a11yProps(1)}
          />

          <Tab
            className="text-sm font-normal px-0 mr-7 min-w-14 pb-0"
            label={
              <div className="flex items-center ">
                <p className={`text-sm ${value === 2 && "text-primary"}`}>
                  Worked With
                </p>
                <p
                  className={`min-h-5 min-w-5  text-center  pt-[1px] rounded text-xs ml-3 ${
                    value === 2 ? "bg-primary text-white" : "bg-[#E4E4E4]"
                  }`}
                >
                  0
                </p>
              </div>
            }
            {...a11yProps(2)}
          />
          <Tab
            className="text-sm font-normal px-0 mr-7 min-w-14 pb-0"
            label={
              <div className="flex items-center">
                <p className={`text-sm ${value === 3 && "text-primary"}`}>
                  Pending Assignments
                </p>
                <p
                  className={`min-h-5 min-w-5  text-center  pt-[1px] rounded text-xs ml-3 ${
                    value === 3 ? "bg-primary text-white" : "bg-[#E4E4E4]"
                  }`}
                >
                  {providersCount?.data?.PENDING_ASSIGNMENT || 0}
                </p>
              </div>
            }
            {...a11yProps(3)}
            onClick={() => {
              setFilters(["PENDING", "ROUTED"]);
            }}
          />
          <Tab
            className="text-sm font-normal px-0 mr-7 min-w-14 pb-0"
            label={
              <div className="flex items-center ">
                <p className={`text-sm ${value === 4 && "text-primary"}`}>
                  Assigned
                </p>
                <p
                  className={`min-h-5 min-w-5  text-center  pt-[1px] rounded text-xs ml-3 ${
                    value === 4 ? "bg-primary text-white" : "bg-[#E4E4E4]"
                  }`}
                >
                  {providersCount?.data?.ASSIGNED || 0}
                </p>
              </div>
            }
            {...a11yProps(4)}
            onClick={() => {
              setFilters(["ASSIGNED", "PAID"]);
            }}
          />
          <Tab
            className="text-sm font-normal px-0 mr-7 min-w-14 pb-0"
            label={
              <div className="flex items-center ">
                <p className={`text-sm ${value === 5 && "text-primary"}`}>
                  Decline
                </p>
                <p
                  className={`min-h-5 min-w-5  text-center  pt-[1px] rounded text-xs ml-3 ${
                    value === 5 ? "bg-primary text-white" : "bg-[#E4E4E4]"
                  }`}
                >
                  {providersCount?.data?.DECLINED || 0}
                </p>
              </div>
            }
            {...a11yProps(5)}
            onClick={() => {
              setFilters(["DECLINED"]);
            }}
          />

          <Tab
            className="text-sm font-normal px-0 mr-7 min-w-14 pb-0"
            label={
              <div className="flex items-center">
                <p className={`text-sm ${value === 6 && "text-primary"}`}>
                  Hidden
                </p>
                <p
                  className={`min-h-5 min-w-5  text-center  pt-[1px] rounded text-xs ml-3 ${
                    value === 6 ? "bg-primary text-white" : "bg-[#E4E4E4]"
                  }`}
                >
                  {providersCount?.data?.REJECTED || 0}
                </p>
              </div>
            }
            {...a11yProps(6)}
            onClick={() => {
              setFilters(["REJECTED"]);
            }}
          />
        </Tabs>
      </Box>
      <div className="mt-5 overflow-x-auto overflow-y-auto rounded-lg">
        <CustomTabPanel value={value} index={0}>
          <NearbyFreelancers
            filters={filters}
            radius={radius}
            refetchProvidersCount={refetchProvidersCount}
            setRadius={setRadius}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {/*  <PendingAssignment
            workOrderStatus={workOrderDetail.status}
            workOrderSubStatus={workOrderDetail.subStatus}
            filters={filters}
            radius={radius}
            setRadius={setRadius}
            refetchProvidersCount={refetchProvidersCount}
          /> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {/* <PendingAssignment
            workOrderStatus={workOrderDetail.status}
            workOrderSubStatus={workOrderDetail.subStatus}
            filters={filters}
            radius={radius}
            setRadius={setRadius}
            refetchProvidersCount={refetchProvidersCount}
          /> */}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <PendingAssignment
            workOrderStatus={workOrderDetail.status}
            workOrderSubStatus={workOrderDetail.subStatus}
            filters={filters}
            radius={radius}
            setRadius={setRadius}
            refetchProvidersCount={refetchProvidersCount}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <PendingAssignment
            workOrderStatus={workOrderDetail.status}
            workOrderSubStatus={workOrderDetail.subStatus}
            filters={filters}
            radius={radius}
            setRadius={setRadius}
            refetchProvidersCount={refetchProvidersCount}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <PendingAssignment
            workOrderStatus={workOrderDetail.status}
            workOrderSubStatus={workOrderDetail.subStatus}
            filters={filters}
            radius={radius}
            setRadius={setRadius}
            refetchProvidersCount={refetchProvidersCount}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <PendingAssignment
            workOrderStatus={workOrderDetail.status}
            workOrderSubStatus={workOrderDetail.subStatus}
            filters={filters}
            radius={radius}
            setRadius={setRadius}
            refetchProvidersCount={refetchProvidersCount}
          />
        </CustomTabPanel>
      </div>
    </BaseCard>
  );
};

export default Providers;
