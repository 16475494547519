import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseCard from "../../../../../components/ui/BaseCard";
import AddEducationModal from "../../../components/AddEducationModal";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { showToast } from "../../../../../store/toast/toastSlice";
import {
  createEducation,
  deleteUserEducation,
  fetchUserEducationList,
  updateUserEducation,
} from "../../../../../services/api/education";
import dayjs from "dayjs";
import { EducationType } from "../../../../../utils/enum";

const validationSchema = yup.object({
  institutionName: yup
    .string("Enter Institution Name")
    .required("institution name is required"),
  educationType: yup
    .string("Enter Education Type")
    .required("education type is required"),
  startDate: yup.string().required("start date is required"),
  endDate: yup.string().required("end date is required"),
});

const AddEducation = ({ userId }) => {
  const dispatch = useDispatch();
  const [isAddEducationModalOpen, setIsAddEducationModalOpen] = useState(false);
  const [userEducation, setUserEducation] = useState([]);
  const [selectedUserEducation, setSelectedUserEducation] = useState();
  const isEdit = selectedUserEducation ? true : false;

  const {
    isPending: isEducationLoading,
    error: isEducationError,
    data: educationData,
    mutateAsync: createEducationMutation,
  } = useMutation({
    mutationFn: createEducation,
    enabled: false,
  });

  const {
    isPending: isUpdateEducationLoading,
    error: isUpdateEducationError,
    data: updatedEducationData,
    mutateAsync: updateEducationMutation,
  } = useMutation({
    mutationFn: updateUserEducation,
  });

  const {
    isPending: isDeleteEducationLoading,
    error: isDeleteEducationError,
    data: educationDeleteData,
    mutateAsync: deleteEducationMutation,
  } = useMutation({
    mutationFn: deleteUserEducation,
    enabled: false,
  });

  const {
    data: userEducationListData,
    isPending: isFetchEducationListLoading,
    error: isFetchEducationListError,
  } = useQuery({
    queryKey: ["userEducationList"],
    queryFn: () => fetchUserEducationList(userId),
    enabled: !!userId,
  });

  const formik = useFormik({
    initialValues: {
      institutionName: selectedUserEducation?.institutionName,
      educationType: selectedUserEducation?.educationType,
      startDate: selectedUserEducation?.startDate || null,
      endDate: selectedUserEducation?.endDate || null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleEducationSubmit();
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (userEducationListData) {
      setUserEducation(userEducationListData?.data);
    }
  }, [userEducationListData]);

  const handleAddEducation = async () => {
    try {
      const educationResponse = await createEducationMutation(formik.values);
      dispatch(
        showToast({
          message: "Education added successfully",
          type: "success",
        })
      );
      setUserEducation((prevEducation) => [
        ...prevEducation,
        educationResponse,
      ]);
      formik.resetForm();
      setIsAddEducationModalOpen(false);
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleEditEducation = async () => {
    try {
      const educationResponse = await updateEducationMutation({
        ...formik.values,
        id: selectedUserEducation.id,
      });
      dispatch(
        showToast({
          message: "Education updated successfully",
          type: "success",
        })
      );
      setUserEducation((prevEducation) =>
        prevEducation.map((education) =>
          education.id === educationResponse.id ? educationResponse : education
        )
      );
      formik.resetForm();
      setIsAddEducationModalOpen(false);
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleEducationSubmit = () => {
    if (isEdit) {
      handleEditEducation();
    } else {
      handleAddEducation();
    }
  };

  const editEducationButtonClicked = (education) => {
    try {
      formik.setValues({
        institutionName: education?.institutionName,
        educationType: education?.educationType,
        startDate: education?.startDate,
        endDate: education?.endDate,
      });
      setSelectedUserEducation(education);
      setIsAddEducationModalOpen(true);
    } catch (error) {}
  };

  const handleEducationDelete = async (id) => {
    try {
      const deleteEducationResponse = await deleteEducationMutation(id);

      const filteredUserEducation = userEducation.filter(
        (education) => education.id !== id
      );
      setUserEducation(filteredUserEducation);
      dispatch(
        showToast({
          message: "Education deleted successfully",
          type: "success",
        })
      );
    } catch (error) {}
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">Education</p>
          <i
            class="fa-solid fa-circle-plus cursor-pointer text-secondary text-xl"
            onClick={() => setIsAddEducationModalOpen(true)}
          ></i>
        </div>
        {userEducation?.map((education) => (
          <div key={education.id}>
            <div className="px-4 flex justify-between items-center">
              <div>
                <p className="font-normal">
                  Institution Name:{" "}
                  <span className="font-300">{education?.institutionName}</span>
                </p>
                <p className="font-normal">
                  Education Type:{" "}
                  <span className="font-300">
                    {EducationType[education?.educationType]}
                  </span>
                </p>
                <div className="flex">
                  <p className="font-normal">
                    Start Date:{" "}
                    <span className="font-300">
                      {dayjs(education?.startDate).format("DD/MM/YYYY")}
                    </span>
                  </p>
                  <p className="font-normal ml-8">
                    Start Date:{" "}
                    <span className="font-300">
                      {dayjs(education?.endDate).format("DD/MM/YYYY")}
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex">
                <i
                  class="fa-solid fa-trash-can mr-4 cursor-pointer"
                  onClick={() => handleEducationDelete(education.id)}
                ></i>
                <i
                  class="fa-solid fa-pencil cursor-pointer"
                  onClick={() => editEducationButtonClicked(education)}
                ></i>
              </div>
            </div>
            <div className="border-b my-4 mx-4"></div>
          </div>
        ))}
      </BaseCard>

      <AddEducationModal
        open={isAddEducationModalOpen}
        close={setIsAddEducationModalOpen}
        formik={formik}
        setSelectedUserEducation={setSelectedUserEducation}
        isEdit={isEdit}
      />
    </form>
  );
};

export default AddEducation;
