import React, { useEffect, useState } from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseCard from "../../../components/ui/BaseCard";
import BaseTextField from "../../../components/ui/BaseTextField";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import BaseCheckbox from "../../../components/ui/BaseCheckbox";
import BaseRadio from "../../../components/ui/BaseRadio";
import { RadioGroup } from "@mui/material";
import {
  updateWorkOrderForm,
  updateWorkOrderFormValidation,
} from "../../../store/workOrder/workOrderSlice";
import SearchLocation from "../../../components/SearchLocation/SearchLocation";
import { fetchLocations } from "../../../services/api/location";

const LocationForm = () => {
  const dispatch = useDispatch();
  const [locationType, setLocationType] = useState("EXACT_LOCATION");
  const location = useSelector((state) => state.workOrder.scheduling.location);
  const workOrderFormValidation = useSelector(
    (state) => state.workOrder.workOrderFormValidation
  );

  const [selectedLocation, setSelectedLocation] = useState("");
  const {
    isLoading: IsLocationsLoading,
    error: isLocationsError,
    data: locationsData,
  } = useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
    enabled: locationType === "SAVED_LOCATION",
  });

  const formattedLocation = locationsData?.data?.map((location) => ({
    label: location.displayName,
    value: location.id,
    address: location.addressLine1,
    timeZone: location.timeZone,
  }));

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          location: {
            [name]: type === "checkbox" ? checked : value,
          },
        },
      })
    );
    if (name === "addressId" && value) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "addressId",
          data: false,
        })
      );
    } else if (name === "addressId" && !value) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "addressId",
          data: true,
        })
      );
    }
  };

  useEffect(() => {
    if (locationType === "SAVED_LOCATION") {
      dispatch(
        updateWorkOrderForm({
          step: "scheduling",
          data: {
            schedule: {
              timeZone: selectedLocation?.timeZone || "",
            },
          },
        })
      );
    } else {
      dispatch(
        updateWorkOrderForm({
          step: "scheduling",
          data: {
            schedule: {
              timeZone: location.timeZone,
            },
          },
        })
      );
    }
  }, [locationType]);

  useEffect(() => {
    if (location.addressId) {
      const filteredLocation = formattedLocation?.find(
        (address) => location.addressId === address.value
      );

      dispatch(
        updateWorkOrderForm({
          step: "scheduling",
          data: {
            schedule: {
              timeZone: filteredLocation?.timeZone || "",
            },
          },
        })
      );
      setSelectedLocation(filteredLocation);
    }
  }, [location.addressId]);

  const handlePlaceSelected = (selectedAddress) => {
    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          location: {
            country: selectedAddress.country,
            addressLine1: selectedAddress.completeAddress,
            city: selectedAddress.city,
            state: selectedAddress.state,
            zipCode: selectedAddress.zipCode,
            longitude: selectedAddress.longitude,
            latitude: selectedAddress.latitude,
            timeZone: selectedAddress.timeZone,
          },
        },
      })
    );

    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          schedule: {
            timeZone: selectedAddress.timeZone,
          },
        },
      })
    );
  };

  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-1 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4">
        <h6 className="mb-2 text-dark">Location</h6>
      </div>
      <div className="mb-4">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={location.type}
          name="radio-buttons-group"
          onChange={(e) => {
            setLocationType(e.target.value);

            dispatch(
              updateWorkOrderForm({
                step: "scheduling",
                data: {
                  location: {
                    type: e.target.value,
                  },
                },
              })
            );
          }}
        >
          <div>
            <BaseRadio label="Specify exact address" value="EXACT_LOCATION" />

            <BaseRadio label="Use saved location" value="SAVED_LOCATION" />

            <BaseRadio label="Remote (no address)" value="REMOTE" />
          </div>
        </RadioGroup>
      </div>
      <div>
        {location.type === "EXACT_LOCATION" && (
          <div className="grid grid-cols-2 gap-y-4">
            <div className="mr-10 mt-2">
              <BaseTextField
                fullWidth
                fullHeight={false}
                label="Display name"
                hint="(The display name will be shared with the assigned provider)"
                optional={false}
                labelShrink={false}
                className="col-span-4"
                value={location.displayName}
                id="displayName"
                name="displayName"
                onChange={handleChange}
                isDefaultField={false}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <div>
              <BaseSelect
                fullHeight={false}
                fullWidth
                label="Location type"
                labelShrink={false}
                placeholder="Location type"
                required={true}
                isDefaultField={false}
                options={[
                  { label: "Commercial", value: "COMMERCIAL" },
                  { label: "Residential", value: "RESIDENTIAL" },
                ]}
                id="locationType"
                name="locationType"
                value={location.locationType}
                onChange={handleChange}
              />
            </div>
            <div className="mr-10">
              <SearchLocation onPlaceSelected={handlePlaceSelected} />
            </div>

            <div>
              <BaseTextField
                fullHeight={false}
                fullWidth
                label="Country"
                labelShrink={false}
                optional={false}
                /* options={[
              {
                label: "United States of America",
                value: "United States of America",
              },
            ]} */
                id="country"
                name="country"
                value={location.country}
                onChange={handleChange}
                isDefaultField={false}
              />
            </div>

            <div className="mr-10">
              <BaseTextField
                fullWidth
                fullHeight={false}
                label="Address Line"
                optional={false}
                labelShrink={false}
                id="addressLine1"
                name="addressLine1"
                value={location.addressLine1}
                onChange={handleChange}
                isDefaultField={false}
              />
            </div>

            <div>
              <BaseTextField
                fullWidth
                fullHeight={false}
                label="City"
                optional={false}
                labelShrink={false}
                id="city"
                name="city"
                value={location.city}
                onChange={handleChange}
                isDefaultField={false}
              />
            </div>

            <div className="mr-10">
              <BaseTextField
                label="State"
                fullHeight={false}
                labelShrink={false}
                optional={false}
                //   options={[{ label: "No project", value: null }]}
                id="state"
                name="state"
                value={location.state}
                onChange={handleChange}
                isDefaultField={false}
              />
            </div>

            <div>
              <BaseTextField
                fullWidth
                fullHeight={false}
                label="Zip Code"
                optional={false}
                labelShrink={false}
                isDefaultField={false}
                id="zipCode"
                name="zipCode"
                value={location.zipCode}
                onChange={handleChange}
              />
            </div>

            <div className="col-span-2">
              <BaseCheckbox
                label="Save this location for future use"
                id="saveForFuture"
                name="saveForFuture"
                checked={location.saveForFuture}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        {location.type === "SAVED_LOCATION" && (
          <div className="grid grid-cols-2 gap-y-4">
            <div className="col-span-2 mr-10 mt-2">
              <BaseSelect
                error={workOrderFormValidation.addressId}
                errorMsg={"Location is required"}
                fullWidth
                fullHeight={false}
                label="Saved Location"
                required={true}
                labelShrink={false}
                value={location.addressId}
                id="addressId"
                name="addressId"
                onChange={handleChange}
                options={formattedLocation}
                isDefaultField={false}
              />
            </div>
            {selectedLocation && (
              <div>
                <p className="font-medium">
                  {selectedLocation.label}:{" "}
                  <span className="font-light	">{selectedLocation.address}</span>
                </p>
              </div>
            )}
          </div>
        )}
        {location.type === "REMOTE" && (
          <div className="grid grid-cols-2 gap-y-4">
            <p>This work order is to be completed remotely</p>
          </div>
        )}
      </div>
    </BaseCard>
  );
};

export default LocationForm;
