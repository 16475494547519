import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseCard from "../../../../../components/ui/BaseCard";
import AddEquipmentModal from "../../../components/AddEquipmentModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addQualificationsToUser,
  deleteUserQualification,
  fetchUserQualifications,
} from "../../../../../services/api/user";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../store/toast/toastSlice";

const validationSchema = yup.object({
  qualificationIds: yup
    .array() // ✅ Ensure it's an array
    .of(yup.number().integer().positive()) // ✅ Each item must be a positive number
    .min(1, "At least one qualification is required") // ✅ Ensure at least one value
    .required("Qualification selection is required"),
});

const AddEquipments = ({ userId }) => {
  const dispatch = useDispatch();
  const [isAddEquipmentModalOpen, setIsAddEquipmentModalOpen] = useState(false);

  const {
    isPending: isAddQualificationLoading,
    error: isAddQualificationError,
    data: addedQualificationsData,
    mutateAsync: addQualificationsToUserMutation,
  } = useMutation({
    mutationFn: addQualificationsToUser,
    enabled: false,
  });

  const {
    isPending: isDeletedQualificationApiLoading,
    error: isDeletedQualificationApiError,
    data: deletedQualificationsData,
    mutateAsync: deleteUserQualificationMutation,
  } = useMutation({
    mutationFn: deleteUserQualification,
    enabled: false,
  });

  const {
    isPending: isUserQualificationsLoading,
    error: isUserQualificationsError,
    refetch: refetchUserQualifications,
    data: userQualificationsData,
  } = useQuery({
    queryKey: ["userQualificationsData"],
    queryFn: () => fetchUserQualifications(userId),
    retry: 2,
    enabled: !!userId,
  });

  const formik = useFormik({
    initialValues: {
      qualificationIds: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddUserQualifications();
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleAddUserQualifications = async () => {
    try {
      const userAddedQualificationsResponse =
        await addQualificationsToUserMutation(formik.values);
      dispatch(
        showToast({
          message: "Equipment added successfully",
          type: "success",
        })
      );
      refetchUserQualifications();
      setIsAddEquipmentModalOpen(false);
      formik.resetForm();
    } catch (error) {}
  };

  const handleDeleteUserQualification = async (id) => {
    try {
      const userDeletedQualificationsResponse =
        await deleteUserQualificationMutation(id);
      dispatch(
        showToast({
          message: "Equipment deleted successfully",
          type: "success",
        })
      );
      refetchUserQualifications();
      setIsAddEquipmentModalOpen(false);
      formik.resetForm();
    } catch (error) {}
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">Add Equipments</p>
          <i
            class="fa-solid fa-circle-plus cursor-pointer text-secondary text-xl"
            onClick={() => setIsAddEquipmentModalOpen(true)}
          ></i>
        </div>

        <div className="flex flex-wrap gap-4">
          {userQualificationsData?.data?.map((qualification) => (
            <div className="flex items-center px-4 py-1 bg-light rounded-sm cursor-default">
              <p key={qualification?.id}>{qualification?.name}</p>
              <i
                className="fa-solid fa-xmark ml-2 cursor-pointer hover:text-blue-500"
                onClick={() => handleDeleteUserQualification(qualification.id)}
              ></i>
            </div>
          ))}
        </div>
      </BaseCard>

      <AddEquipmentModal
        open={isAddEquipmentModalOpen}
        close={setIsAddEquipmentModalOpen}
        formik={formik}
        isAddQualificationLoading={isAddQualificationLoading}
      />
    </form>
  );
};

export default AddEquipments;
