import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import {
  createTimeLog,
  fetchWorkOrderTimeLogs,
} from "../../../services/api/timeLog";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import BaseModal from "../../../components/ui/BaseModal";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";
import BaseButton from "../../../components/ui/BaseButton";
import { useParams } from "react-router-dom";
import { handleGetLocation } from "../../../utils/helpers";
import useAuth from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";

const TimeLogs = () => {
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const { authRole } = useAuth();
  const { id: workOrderId } = useParams();
  const dispatch = useDispatch();

  const {
    isLoading: isCreateTimeLogLoading,
    error: isCreateTimeLogError,
    data: createdTimeLogData,
    mutateAsync: createTimeLogMutation,
  } = useMutation({
    mutationFn: createTimeLog,
    enabled: false,
  });
  const { updateWorkOrder } = useUpdateWorkOrder();
  const [isAddTimeModalOpen, setIsAddTimeModalOpen] = useState(false);
  const [timeLogFormData, setTimeLogFormData] = useState({
    checkInTime: null,
    checkInDate: null,
    checkOutTime: null,
    checkOutDate: null,
  });
  const [newHours, setNewHours] = useState(0);

  const totalHours =
    workOrderDetail?.timeLogs?.reduce(
      (acc, timeLog) => acc + +timeLog.totalHours,
      0 // Initial value for the accumulator
    ) || null;

  const handleChange = (name, e) => {
    setTimeLogFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: e,
      };

      // Calculate total hours if both check-in and check-out are set
      if (
        updatedData.checkInDate &&
        updatedData.checkInTime &&
        updatedData.checkOutDate &&
        updatedData.checkOutTime
      ) {
        const checkInDateTime = dayjs(
          `${updatedData.checkInDate} ${updatedData.checkInTime}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        const checkOutDateTime = dayjs(
          `${updatedData.checkOutDate} ${updatedData.checkOutTime}`,
          "YYYY-MM-DD HH:mm:ss"
        );

        const hoursDifference = checkOutDateTime.diff(
          checkInDateTime,
          "hour",
          true
        ); // true for decimal hours
        setNewHours(hoursDifference);
      }

      return updatedData;
    });
  };

  const handleSaveButton = async () => {
    if (authRole !== "CLIENT") {
      dispatch(
        showToast({
          type: "error",
          message: "Only Work Order owner can add time.",
        })
      );
      return;
    }
    const location = await handleGetLocation();
    try {
      let payload = {
        checkInTime: `${timeLogFormData.checkInDate} ${timeLogFormData.checkInTime}`,
        checkOutTime: `${timeLogFormData.checkOutDate} ${timeLogFormData.checkOutTime}`,
        longitude: location.longitude,
        latitude: location.latitude,
        workOrderId: parseInt(workOrderId),
      };
      await createTimeLogMutation(payload);
      updateWorkOrder();
      setIsAddTimeModalOpen(false);
    } catch (error) {}
  };

  return (
    <BaseCard className="pt-4 pb-6 px-56  rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Time Log</h6>
      </div>

      <div>
        {totalHours && (
          <p className="font-normal mb-3">
            {totalHours?.toFixed(2)} Total hours
          </p>
        )}
        {workOrderDetail?.timeLogs?.map((timeLog) => (
          <TimeLog
            key={timeLog.id}
            hours={timeLog.totalHours}
            checkInTime={timeLog.checkInTime}
            checkInDistance={timeLog.checkInDistance}
            checkInLoggedBy={`${timeLog.checkInLoggedBy?.firstName} ${timeLog.checkInLoggedBy?.lastName}`}
            checkOutTime={timeLog.checkOutTime}
            checkOutDistance={timeLog.checkOutDistance}
            checkOutLoggedBy={`${timeLog.checkOutLoggedBy?.firstName} ${timeLog.checkOutLoggedBy?.lastName}`}
          />
        ))}
      </div>

      {authRole === "CLIENT" && (
        <div className="flex items-center mt-4 cursor-pointer">
          <i className="fa-solid fa-plus text-secondary"></i>
          <p
            className="text-secondary text-sm ml-1"
            onClick={() => setIsAddTimeModalOpen(true)}
          >
            {" "}
            Add Time
          </p>
        </div>
      )}

      <BaseModal
        open={isAddTimeModalOpen}
        close={() => setIsAddTimeModalOpen(true)}
      >
        <div style={{ padding: "24px 40px" }}>
          <h3
            style={{
              fontSize: "18px",
              paddingBottom: "8px",
              borderBottom: "1px solid #DFDFDF",
              color: "#2C2C2C",
            }}
          >
            Add Time Log
          </h3>
          <h3
            className="font-500"
            style={{
              margin: "14px 0px",
              fontSize: "18px",
              paddingBottom: "8px",
              color: "#686868",
            }}
          >
            {newHours?.toFixed(2)} Hours
          </h3>
          <div>
            <p>Check In</p>
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <BaseDatePicker
                  type="time"
                  name="checkInTime"
                  // value={dayjs(schedule.orderStartDate)}
                  onChange={(e) =>
                    handleChange("checkInTime", dayjs(e).format("hh:mm:ss"))
                  }
                />
              </div>
              <p
                style={{
                  margin: "0 14px",
                }}
              >
                on
              </p>
              <div style={{ width: "100%" }}>
                <BaseDatePicker
                  type="date"
                  name="checkInDate"
                  // value={dayjs(schedule.orderStartDate)}
                  onChange={(e) =>
                    handleChange("checkInDate", dayjs(e).format("YYYY-MM-DD"))
                  }
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p>Check Out</p>
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <BaseDatePicker
                  type="time"
                  name="checkOutTime"
                  // value={dayjs(schedule.orderStartDate)}
                  onChange={(e) =>
                    handleChange("checkOutTime", dayjs(e).format("hh:mm:ss"))
                  }
                />
              </div>
              <p
                style={{
                  margin: "0 14px",
                }}
              >
                on
              </p>
              <div style={{ width: "100%" }}>
                <BaseDatePicker
                  type="date"
                  name="checkOutDate"
                  // value={dayjs(schedule.orderStartDate)}
                  onChange={(e) =>
                    handleChange("checkOutDate", dayjs(e).format("YYYY-MM-DD"))
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "40px",
            padding: "24px 0",
            boxShadow: "0px 0px 14px 4px rgba(0, 0, 0, 0.07)",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginRight: "12px" }}>
              <BaseButton
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  padding: "5px 28px",
                }}
                variant="outlined"
                color="primary"
                onClick={() => setIsAddTimeModalOpen(false)}
              >
                Cancel
              </BaseButton>
            </div>
            <div style={{ marginLeft: "12px" }}>
              <BaseButton
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  padding: "6px 30px",
                }}
                color="primary"
                onClick={handleSaveButton}
              >
                Save
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseModal>
    </BaseCard>
  );
};

function TimeLog({
  hours,
  checkInTime,
  checkInDistance,
  checkInLoggedBy,
  checkOutTime,
  checkOutDistance,
  checkOutLoggedBy,
}) {
  return (
    <div className="bg-[#F6F6F6] rounded-md py-2.5 px-6 mt-2">
      {checkOutTime && (
        <p className="text-dark border-b w-full pb-1">
          {hours?.toFixed(2)} hours
        </p>
      )}
      <div className="grid grid-cols-7 items-center py-3">
        <div className="col-span-3">
          <p className="text-dark mb-1">
            {`${dayjs(checkInTime).format("MM/DD/YYYY")} at ${dayjs(
              checkInTime
            ).format("h:mm A")}`}
          </p>
          <p className="text-xs mb-1">
            <span className="text-primary">
              {checkInDistance?.toFixed(2)} km
            </span>
             from site
          </p>
          <p className="text-xs">
            Logged IN by 
            <span className="text-primary">{checkInLoggedBy} </span>
            {`${dayjs(checkInTime).format("MM/DD/YYYY")} at ${dayjs(
              checkInTime
            ).format("h:mm A")}`}
          </p>
        </div>
        {checkOutTime && (
          <i className="fa-solid fa-arrow-right-long text-2xl"></i>
        )}
        {checkOutTime && (
          <div className="col-span-3">
            <p className="text-dark mb-1">
              {`${dayjs(checkOutTime).format("MM/DD/YYYY")} at ${dayjs(
                checkOutTime
              ).format("h:mm A")}`}
            </p>
            <p className="text-xs mb-1">
              <span className="text-primary">
                {checkOutDistance?.toFixed(2)} km
              </span>
               from site
            </p>
            <p className="text-xs">
              Logged OUT by 
              <span className="text-primary">{checkOutLoggedBy} </span>
              {`${dayjs(checkOutTime).format("MM/DD/YYYY")} at ${dayjs(
                checkOutTime
              ).format("h:mm A")}`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeLogs;
