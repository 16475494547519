import axios from "../../lib/axiosInstance";

export function companySignup(payload) {
  return axios.post("auth/signup", payload).then((res) => res.data);
}

export function login(payload) {
  return axios.post("auth/login", payload).then((res) => res.data);
}

export function verifyOtp(payload) {
  return axios.post(`otps/verify`, payload).then((res) => res.data);
}

export function resendOtpApi(email) {
  console.log(email);
  return axios.get(`otps/resend/${email}`).then((res) => res.data);
}

export function forgotPassword(payload) {
  return axios.post(`/auth/forgotPassword`, payload).then((res) => res.data);
}

export function resetPassword(payload) {
  return axios.put(`/auth/resetPassword`, payload).then((res) => res.data);
}
