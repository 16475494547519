import CompleteYourProfilePage from "./pages/CompleteYourProfilePage";
import FindJobPage from "./pages/FindJobPages/FindJobPage";
import JobDetailsPage from "./pages/FindJobPages/JobDetailsPage";
import HomePage from "./pages/HomePage";
import ProfileDetailPage from "./pages/ProfilePages/ProfileDetailPage";
import CreateWorkOrderPage from "./pages/WorkOrderPages/CreateWorkOrderPage";
import WorkOrderPage from "./pages/WorkOrderPages/WorkOrderPage";
import Profile from "./views/ProfileDetails/Profile";
import ChatTab from "./views/WorkJobDetails/Tabs/ChatTab";

import DetailsTab from "./views/WorkJobDetails/Tabs/DetailsTab";
import HistoryTab from "./views/WorkJobDetails/Tabs/HistoryTab";
import PayTab from "./views/WorkJobDetails/Tabs/PayTab";
import ProvidersTab from "./views/WorkJobDetails/Tabs/ProvidersTab";
import Assessment from "./views/WorkOrder/Steps/Assessment";
import Scheduling from "./views/WorkOrder/Steps/Scheduling";
import Summary from "./views/WorkOrder/Steps/Summary";
import WorkPay from "./views/WorkOrder/Steps/WorkPay";

const routes = [
  {
    module: "WORK_ORDERS",
    path: "workOrder/create",
    component: <CreateWorkOrderPage />,
    childs: [
      { text: "Summary", path: "summary", component: <Summary /> },
      { text: "Scheduling", path: "scheduling", component: <Scheduling /> },
      { text: "Payment", path: "payment", component: <WorkPay /> },
      { text: "Assessment", path: "assessment", component: <Assessment /> },
    ],
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    path: "workOrder/edit",
    component: <CreateWorkOrderPage />,
    childs: [
      { path: "summary/:id" },
      { path: "scheduling/:id" },
      { path: "payment/:id" },
      { path: "assessment/:id" },
    ],
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    text: "Work Order",
    path: "workOrders",

    icon: <i className="fa-solid fa-briefcase text-[26px]"></i>,
    activeIcon: (
      <i className="fa-solid fa-briefcase text-[26px] text-primary"></i>
    ),
    isShow: true,
    isShowClient: true,
    childs: [
      {
        text: "Work Board",
        path: "workBoard",
        component: <WorkOrderPage />,
      },
      {
        text: "Projects",
        path: "projects",
      },
      {
        text: "Deliverables",
        path: "deliverables",
      },
      {
        text: "Client Request",
        path: "client-request",
      },
    ],
    requiredAccess: { isRead: true },
  },
  {
    module: "JOBS",
    text: "Job",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "jobs",
    component: <FindJobPage />,
    isShow: true,
    isShowClient: false,
    requiredAccess: { isRead: true },
  },
  {
    module: "JOBS",
    text: "Job Details",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "/workOrder",
    component: <JobDetailsPage />,
    isShow: false,
    requiredAccess: { isRead: true },
    childs: [
      {
        text: "Details",
        path: "details/:id",
        component: <DetailsTab />,
        roles: ["CLIENT", "FREELANCER"],
        statuses: [
          "DRAFT",
          "PUBLISHED",
          "ASSIGNED",
          "INPROGRESS",
          "WORKDONE",
          "INCOMPLETE",
          "APPROVED",
          "PAID",
          "CANCELLED",
        ],
      },
      {
        text: "Payment",
        path: "payment/:id",
        component: <PayTab />,
        roles: ["CLIENT", "FREELANCER"],
        statuses: [
          "DRAFT",
          "PUBLISHED",
          "ASSIGNED",
          "INPROGRESS",
          "WORKDONE",
          "INCOMPLETE",
          "APPROVED",
          "PAID",
          "CANCELLED",
        ],
      },
      {
        text: "Providers",
        path: "providers/:id",
        component: <ProvidersTab />,
        roles: ["CLIENT"],
        statuses: [
          "DRAFT",
          "PUBLISHED",
          "ASSIGNED",
          "INPROGRESS",
          "WORKDONE",
          "INCOMPLETE",
          "APPROVED",
          "PAID",
          "CANCELLED",
        ],
      },
      {
        text: "Messages",
        path: "messages/:id",
        component: <ChatTab />,
        roles: ["CLIENT", "FREELANCER"],
        statuses: [
          "PUBLISHED",
          "ASSIGNED",
          "INPROGRESS",
          "WORKDONE",
          "INCOMPLETE",
          "APPROVED",
          "PAID",
          "CANCELLED",
        ],
      },
      {
        text: "Engagement",
        path: "engagement/:id",
        component: <h1>Engagement</h1>,
        roles: ["CLIENT"],
        statuses: [
          "DRAFT",
          "PUBLISHED",
          "ASSIGNED",
          "INPROGRESS",
          "WORKDONE",
          "INCOMPLETE",
          "APPROVED",
          "PAID",
          "CANCELLED",
        ],
      },
      {
        text: "History",
        path: "history/:id",
        component: <HistoryTab />,
        roles: ["CLIENT"],
        statuses: [
          "DRAFT",
          "PUBLISHED",
          "ASSIGNED",
          "INPROGRESS",
          "WORKDONE",
          "INCOMPLETE",
          "APPROVED",
          "PAID",
          "CANCELLED",
        ],
      },
    ],
  },
  {
    module: "",
    path: "completeYourProfile",
    component: <CompleteYourProfilePage />,
    isShow: false,
    isCustomFullPage: true,
    requiredAccess: { isRead: true },
  },
  {
    text: "Field Force",
    icon: <i className="fa-solid fa-people-group text-3xl"></i>,
    module: "WORK_ORDERS",
    text: "Work Force",
    path: "workForce",
    icon: <i className="fa-solid fa-users-gear text-[26px]"></i>,
    activeIcon: (
      <i className="fa-solid fa-users-gear text-[26px] text-primary"></i>
    ),

    component: <>Tech Force</>,
    isShow: true,
    isShowClient: true,
    childs: [
      {
        text: "Talent",
        path: "talent",
      },
      {
        text: "Recruitment",
        path: "recruitment",
      },
      {
        text: "Schedule",
        path: "schedule",
      },
    ],
  },
  {
    module: "WORK_ORDERS",
    text: "Manage",
    path: "manage",
    icon: <i className="fa-solid fa-list-check text-[26px]"></i>,
    activeIcon: (
      <i className="fa-solid fa-list-check text-[26px] text-primary"></i>
    ),

    component: <>Management</>,
    isShow: true,
    isShowClient: true,
    childs: [
      {
        text: "Clients",
        path: "clients",
      },
      {
        text: "Templates",
        path: "templates",
      },
      {
        text: "Custom Fields",
        path: "customFields",
      },
      {
        text: "Tags",
        path: "tags",
      },
      {
        text: "Selection rules",
        path: "selectionRules",
      },
      {
        text: "Locations",
        path: "locations",
      },
      {
        text: "Documents",
        path: "documents",
      },
      {
        text: "import Spreadsheets",
        path: "importSpreadsheets",
      },
      {
        text: "Provider tags",
        path: "providerTags",
      },
      {
        text: "Smart Audit",
        path: "smartAudit",
      },
      {
        text: "Provider Assessment",
        path: "providerAssessment",
      },
      {
        text: "Revisit & Incomplete Reasons",
        path: "revisitAndIncompleteReasons",
      },
    ],
  },
  {
    module: "WORK_ORDERS",
    text: "Integration",
    path: "integration",
    icon: <i className="fa-solid fa-microchip text-[26px]"></i>,
    activeIcon: (
      <i className="fa-solid fa-microchip text-[26px] text-primary"></i>
    ),
    component: <>Integration</>,
    isShow: true,
    isShowClient: true,
    childs: [
      {
        text: "Field Services",
        path: "fieldServices",
      },
      {
        text: "Storage",
        path: "storage",
      },
    ],
  },
  {
    module: "WORK_ORDERS",
    text: "Insights",
    path: "insights",
    icon: <i className="fa-solid fa-chart-pie text-[26px]"></i>,
    activeIcon: (
      <i className="fa-solid fa-chart-pie text-[26px] text-primary"></i>
    ),
    component: <>Insights</>,
    isShow: true,
    isShowClient: true,
    childs: [
      {
        text: "Executive dashboard",
        path: "executiveDashboard",
      },
      {
        text: "Work Activity",
        path: "workActivity",
      },
      {
        text: "Utilization",
        path: "utilization",
      },
      {
        text: "Company rating",
        path: "companyRating",
      },
      {
        text: "MarketSmart Insights",
        path: "marketSmartInsights",
      },
    ],
  },
  {
    module: "USERS",
    text: "Profile Detail",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "/user/details",
    isShow: false,
    isShowClient: false,
    requiredAccess: { isRead: true },
    component: <ProfileDetailPage />,
    childs: [
      {
        text: "Profile",
        path: "profile/:id",
        component: <Profile />,
        roles: ["CLIENT", "FREELANCER"],
      },
      {
        text: "Availability",
        path: "availability/:id",
        component: <h1>Availability</h1>,
        roles: ["CLIENT", "FREELANCER"],
      },
      {
        text: "Notes",
        path: "notes/:id",
        component: <h1>Notes</h1>,
        roles: ["CLIENT"],
      },
      {
        text: "Messages",
        path: "messages/:id",
        component: <h1>Messages</h1>,
        roles: ["CLIENT", "FREELANCER"],
      },
      {
        text: "Recommendations",
        path: "recommendations/:id",
        component: <h1>Recommendations</h1>,
        roles: ["CLIENT"],
      },
      {
        text: "Private Feedback",
        path: "privateFeedback/:id",
        component: <h1>Private Feedback</h1>,
        roles: ["CLIENT"],
      },
    ],
  },
  /*  {
    module: "USERS",
    text: "Profile Detail",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "/profile/details",
    component: <ProfileDetailPage />,
    isShow: false,
    isShowClient: false,
    requiredAccess: { isRead: true },
  }, */
];
export default routes;
