import dayjs from "dayjs";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

export function getTimeZoneAbbreviation(tz = "UTC") {
  try {
    const dateInTz = dayjs().tz(tz);

    // Check if the timezone is invalid
    if (!dateInTz.isValid() || dateInTz.format() === "Invalid Date") {
      throw new Error("Invalid Timezone");
    }

    const timezoneAbbr = dateInTz.format("zzz"); // Get the abbreviation

    return timezoneAbbr
      .split(" ") // Split by spaces
      .map((word) => word[0]) // Take the first letter of each word
      .join("") // Join to form abbreviation
      .toUpperCase(); // Ensure uppercase
  } catch (error) {
    console.error(`Error: ${error.message}`);
    return "N/A"; // Return fallback if timezone is invalid
  }
}
