import { Avatar } from "@mui/material";
import React from "react";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

const Header = () => {
  const { userData } = useCurrentUser();
  return (
    <div className="bg-white">
      <div className="flex items-center justify-between py-2 mx-40">
        <h6>Complete your Tech Domain profile</h6>
        <h6 className="font-normal">Having trouble? Visit our Help Center</h6>
      </div>

      <div className="border-slate-300	border-y py-4 ">
        <div className="mx-40 flex items-center">
          <Avatar
            className="rounded-xl"
            sx={{ width: 90, height: 90 }}
            variant="square"
            src={userData?.picture?.url}
          />
          <div className="ml-6">
            <h5 className="font-normal capitalize">
              Welcome, {`${userData?.firstName} ${userData?.lastName}`}
            </h5>
            <h6>Here's what we need to complete your account setup.</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
