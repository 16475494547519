import axios from "../../lib/axiosInstance";

export function fetchUserNotifications(id) {
  return axios.get(`notifications`).then((res) => res.data);
}

export function markNotificationAsRead(id) {
  return axios.patch(`notifications/read/${id}`);
}

export function markNotificationAsReadAll(id) {
  return axios.patch(`notifications/readAll`);
}
