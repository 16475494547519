import React from "react";
import ForgotPassword from "../../views/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <div className="bg-slate-100 h-screen">
      <ForgotPassword />
    </div>
  );
};

export default ForgotPasswordPage;
