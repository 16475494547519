import axios from "../../lib/axiosInstance";

export function createEmployment(payload) {
  return axios.post("employment", payload).then((res) => res.data);
}
export function fetchEmploymentHistoryList(id) {
  return axios.get(`employment/${id}`).then((res) => res.data);
}
export function fetchEmploymentHistory(id) {
  return axios.get(`employment/${id}`).then((res) => res.data);
}
export function deleteEmploymentHistory(id) {
  return axios.delete(`employment/${id}`).then((res) => res.data);
}

export function updateEmploymentHistory(payload) {
  return axios
    .patch(`employment/${payload.id}`, payload)
    .then((res) => res.data);
}
