import React, { useContext } from "react";

import WorkOrderListingHeader from "./WorkOrderListingHeader";

import WorkOrderTable from "./WorkOrderTable";
import { LayoutContext } from "../../../context/LayoutContext";

const WorkOrderList = () => {
  const { isBannerVisible } = useContext(LayoutContext);
  return (
    <div
      className={`bg-[#F6F6F6] h-screen fixed w-[95%] transition-all ${
        isBannerVisible ? "top-[94px]" : "top-[56px]"
      }`}
    >
      <WorkOrderListingHeader />
      <WorkOrderTable />
    </div>
  );
};

export default WorkOrderList;
