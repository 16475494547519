import React, { useEffect, useRef, useState } from "react";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchCities } from "../../../services/api/country";
import BaseButton from "../../../components/ui/BaseButton";
import * as yup from "yup";
import { useFormik } from "formik";
import { createOrUpdateUserAddress, me } from "../../../services/api/user";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import BaseLoader from "../../../components/ui/BaseLoader";
import { useCountries } from "../../../hooks/useCountries";
import {
  deleteDigitalAsset,
  uploadIdDocuments,
} from "../../../services/api/digitalAsset";

const validationSchema = yup.object({
  addressLine1: yup
    .string("Enter your address")
    .required("Address Line 1 is required"),
  addressLine2: yup.string("Enter your address").notRequired(),
  country: yup
    .string("Enter your country name")
    .required("Country is required"),
  city: yup.string("Enter your city name").required("City is required"),
  state: yup.string("Enter your state").required("State is required"),
  zipCode: yup.string("Enter your Zip Code").required("Zip Code is required"),
});

const VerifyIdentity = ({
  address,
  isUserLoading,
  refetchMe,
  identityDocuments,
}) => {
  const { countries, getCountryLabel } = useCountries();

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const {
    isPending: isUploadingIdentityLoading,
    error: isUploadingIdentityError,
    data: uploadedIdentityData,
    mutateAsync: uploadIdDocumentsMutation,
  } = useMutation({
    mutationFn: uploadIdDocuments,
    enabled: false,
  });

  const {
    isPending: isDeletedDigitalAssetApiLoading,
    error: isDeletedDigitalAssetApiError,
    data: deletedDigitalAsset,
    mutateAsync: deleteDigitalAssetMutation,
  } = useMutation({
    mutationFn: deleteDigitalAsset,
    enabled: false,
  });

  const {
    isPending: isCitiesLoading,
    error: isCitiesError,
    data: cities,
    mutateAsync: fetchCitiesMutation,
  } = useMutation({
    mutationFn: fetchCities,
    enabled: false,
  });

  const {
    isPending: isUserAddressLoading,
    error: isUserAddressError,
    data: userAddressData,
    mutateAsync: createOrUpdateUserAddressMutation,
  } = useMutation({
    mutationFn: createOrUpdateUserAddress,
    enabled: false,
  });

  const formik = useFormik({
    initialValues: {
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      country: address?.country,
      city: address?.city,
      state: address?.state,
      zipCode: address?.zipCode,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit();
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    const fetchCities = async () => {
      if (!formik.values.country) return;

      const countryFullName = getCountryLabel(formik.values.country);
      if (countryFullName) {
        await fetchCitiesMutation({ country: countryFullName });
      }
    };

    fetchCities();
  }, [formik.values.country]);

  /*  useEffect(() => {
    if (!identityDocuments || identityDocuments.length === 0) {
      setSelectedFiles([]); 
      return;
    }

    setSelectedFiles(
      identityDocuments.map((file) => ({
        name: file.filename || "Unknown File", 
        url: file.url || "#",
      }))
    );
  }, [identityDocuments]); */

  const handleCountryChange = async (event) => {
    const selectedCountry = event.target.label; // Get the selected country
    formik.handleChange(event); // Update Formik's state

    if (selectedCountry) {
      try {
        const payload = { country: selectedCountry };
        const citiesData = await fetchCitiesMutation(payload);
      } catch (error) {}
    }
  };

  const handleSubmit = async () => {
    try {
      const userAddressResponse = await createOrUpdateUserAddressMutation(
        formik.values
      );
      dispatch(
        showToast({
          message: "Address added successfully",
          type: "success",
        })
      );
    } catch (error) {}
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files); // ✅ Convert to array of file objects
    if (!files.length) return; // ✅ Prevent empty uploads

    setSelectedFiles((prevFiles) => [
      ...files.map((file) => ({
        name: file.name || "Unknown File",
        url: URL.createObjectURL(file), // ✅ Temporary URL for preview
      })),
    ]);

    try {
      const formData = new FormData();
      files.forEach((file) => formData.append("documents", file)); // ✅ Append files to FormData

      // ✅ Ensure `uploadIdDocumentsMutation` supports FormData
      const identityResponse = await uploadIdDocumentsMutation(formData);

      dispatch(
        showToast({
          message: "Identity updated successfully",
          type: "success",
        })
      );

      refetchMe();
      setSelectedFiles([]);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const formattedCitiesData = cities?.data?.map((city) => {
    return {
      label: city,
      value: city,
    };
  });

  return (
    <form>
      {isUserLoading && <BaseLoader isLoading={isUserLoading} />}
      <section className="py-4 px-5">
        <p className="text-primary mb-1">
          National Id/Driving Lisense/Passport
        </p>
        <div className="w-full flex items-center">
          {/* Hidden File Input */}
          <input
            type="file"
            ref={fileInputRef}
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

          {/* File Display Section */}
          <div
            style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
            className="min-h-[40px] w-full py-[6.25px] rounded-md flex flex-wrap gap-2 pl-2"
          >
            {selectedFiles?.length > 0 ? (
              selectedFiles?.map((file, index) => (
                <a
                  key={index}
                  href={file.url} // ✅ Ensure this is a valid URL
                  download={file.name} // ✅ Set correct filename for download
                  target="_blank" // ✅ Open in a new tab if needed
                  rel="noopener noreferrer"
                  className="bg-[#E9E9EB] rounded-[4px] py-1 px-6 cursor-pointer block text-blue-500 !hover:text-blue-500 hover:underline font-300 !hover:font-[300]"
                >
                  {file?.name}
                </a>
              ))
            ) : (
              <p className="text-gray-400 py-1 ml-2">No file selected</p>
            )}
          </div>

          {/* Choose File Button */}
          <BaseButton
            loaderSize={22}
            isLoading={isUploadingIdentityLoading}
            variant="outlined"
            color="primary"
            className="w-[124px] py-[7.2px] ml-4"
            onClick={handleButtonClick}
          >
            Choose File
          </BaseButton>
        </div>
        <div className="flex flex-wrap gap-2 mt-3">
          {identityDocuments?.length > 0 &&
            identityDocuments?.map((file, index) => (
              <div className="flex items-center bg-[#E9E9EB] py-1 px-4 rounded-[4px]">
                <a
                  key={index}
                  href={file.url} // ✅ Ensure this is a valid URL
                  download={file.name} // ✅ Set correct filename for download
                  target="_blank" // ✅ Open in a new tab if needed
                  rel="noopener noreferrer"
                  className="  cursor-pointer block  hover:text-blue-500 font-300"
                >
                  {file?.filename}
                </a>
                <i
                  className="fa-solid fa-xmark ml-2 cursor-pointer hover:text-blue-500"
                  onClick={() => {
                    deleteDigitalAssetMutation(file.id);
                    refetchMe();
                  }}
                ></i>
              </div>
            ))}
        </div>
        <h6 className="font-normal !text-dark mt-3">Your Home Address</h6>
        <div className="grid grid-cols-2 gap-12 mt-3">
          <BaseTextField
            labelShrink={false}
            label="Address Line 1"
            optional={false}
            isDefaultField={false}
            required
            fullWidth
            id="addressLine1"
            name="addressLine1"
            value={formik.values.addressLine1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)
            }
            helperText={
              formik.touched.addressLine1 && formik.errors.addressLine1
            }
          />
          <BaseTextField
            labelShrink={false}
            label="Address Line 2"
            optional={true}
            isDefaultField={false}
            id="addressLine2"
            name="addressLine2"
            value={formik.values.addressLine2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)
            }
            helperText={
              formik.touched.addressLine2 && formik.errors.addressLine2
            }
          />
        </div>
        <div className="grid grid-cols-4 gap-12 mt-3">
          <BaseAutoSelect
            labelShrink={false}
            label="Country"
            required={true}
            isDefaultField={false}
            id="country"
            name="country"
            options={countries}
            value={formik.values.country}
            onChange={handleCountryChange}
            onBlur={formik.handleBlur}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
          <BaseAutoSelect
            labelShrink={false}
            label="City"
            required={true}
            isDefaultField={false}
            options={formattedCitiesData}
            id="city"
            name="city"
            defaultValue={formik.values.city}
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />

          <BaseTextField
            labelShrink={false}
            label="State"
            optional={false}
            isDefaultField={false}
            id="state"
            name="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
          <BaseTextField
            labelShrink={false}
            label="Zip Code"
            optional={false}
            isDefaultField={false}
            id="zipCode"
            name="zipCode"
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
        </div>

        <div className="flex justify-end mt-5">
          <BaseButton
            loaderSize="22px"
            isLoading={isUserAddressLoading}
            color="primary"
            className="py-[5px] px-[38px]"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Save
          </BaseButton>
        </div>
      </section>
    </form>
  );
};

export default VerifyIdentity;
