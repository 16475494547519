import React from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";

const languageOptions = [
  { label: "English", value: "ENGLISH" },
  { label: "Urdu", value: "URDU" },
  { label: "French", value: "FRENCH" },
];

const AddLanguagesModal = ({ open, close, formik }) => {
  return (
    <BaseModal
      className="-top-[200px] overflow-scroll"
      open={open}
      close={close}
      style={{ width: "1000px" }}
    >
      <div className="py-7 px-12">
        <h6 className="text-dark font-normal border-b pb-1">Select Language</h6>

        <p className="mt-4">
          Your types of work will determine which work orders are available to
          you. Select at least one.
        </p>

        <BaseAutoSelect
          isDefaultField={false}
          placeholder="Select Language"
          className="mt-6"
          options={languageOptions}
          multiple={true}
          disableCloseOnSelect
          id="languages"
          name="languages"
          value={formik.values.languages}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.languages && Boolean(formik.errors.languages)}
          helperText={formik.touched.languages && formik.errors.languages}
        />
      </div>
      <div
        className="d-flex content-center bg-white py-6 mt-6"
        style={{
          boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="px-[28px] py-[5px] bg-light text-primary !hover:text-primary"
          variant="outlined"
          color="primary"
          style={{
            marginRight: "16px",
          }}
          onClick={() => close(false)}
        >
          Cancel
        </BaseButton>

        <BaseButton
          loaderSize={24}
          loaderColor="primary"
          className="px-[12px] py-[5px]"
          color="primary"
          onClick={formik.handleSubmit}
        >
          Save Changes
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default AddLanguagesModal;
