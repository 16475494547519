import React from "react";

const WorkOrderStatus = ({
  status,
  textSize = "text-xs",
  width = "w-20",
  className,
}) => {
  const getStatus = () => {
    if (status === "PUBLISHED") {
      return (
        <p
          className={`${className} text-white ${width} bg-secondary text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Published
        </p>
      );
    } else if (status === "DRAFT") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#C0B023] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Drafted
        </p>
      );
    } else if (status === "REQUESTED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#9C27B0] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Requested
        </p>
      );
    } else if (status === "ROUTED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#2196F3] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Routed
        </p>
      );
    } else if (status === "COUNTER") {
      return (
        <p
          className={`${className} text-dark ${width} bg-[#FFC107] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Countered
        </p>
      );
    } else if (status === "DECLINED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#F44336] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Declined
        </p>
      );
    } else if (status === "REJECTED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#F44336] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Rejected
        </p>
      );
    } else if (status === "ASSIGNED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#2561A9] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Assigned
        </p>
      );
    } else if (status === "CONFIRM") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#5D7464] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Confirmed
        </p>
      );
    } else if (status === "NOT_CONFIRMED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#745b81] text-center  w-24 rounded-[5px] ${textSize} cursor-default`}
        >
          Not Confirmed
        </p>
      );
    } else if (status === "CONFIRMED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#71A534] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Confirmed
        </p>
      );
    } else if (status === "INPROGRESS") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#FF9800] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          In Progress
        </p>
      );
    } else if (status === "WORKDONE") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#295E80] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Work Done
        </p>
      );
    } else if (status === "INCOMPLETE") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#F44336] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Incomplete
        </p>
      );
    } else if (status === "CANCELLED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#F44336] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Cancelled
        </p>
      );
    } else if (status === "APPROVED") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#268E2A] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Approved
        </p>
      );
    } else if (status === "PAID") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#0F40AE] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Paid
        </p>
      );
    } else if (status === "READY_TO_GO") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#02AABF] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Ready to go
        </p>
      );
    } else if (status === "CHECK_IN") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#0074A1] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Checked In
        </p>
      );
    } else if (status === "CHECK_OUT") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#673AB7] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Checked Out
        </p>
      );
    } else if (status === "ON_THE_WAY") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#2196F3] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          On the way
        </p>
      );
    } else if (status === "RUNNING_LATE") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#F44336] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Running Late
        </p>
      );
    } else if (status === "Not Ready") {
      return (
        <p
          className={`${className} text-white ${width} bg-[#794CDA] text-center  rounded-[5px] ${textSize} cursor-default`}
        >
          Not Ready
        </p>
      );
    }
  };
  return getStatus();
};

export default WorkOrderStatus;
