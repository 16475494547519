import React, { useEffect } from "react";
import { routeOffer } from "../../../services/api/offers";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { showToast } from "../../../store/toast/toastSlice";
import BaseButton from "../../../components/ui/BaseButton";
import { Avatar } from "@mui/material";
import BaseRating from "../../../components/ui/BaseRating";
import { fetchNearbyFreelancers } from "../../../services/api/user";
import BaseSelect from "../../../components/ui/BaseSelect";
import { setWorkOrderDetail } from "../../../store/workOrder/workOrderSlice";
import { useNavigate } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";

const NearbyFreelancers = ({ radius, setRadius, refetchProvidersCount }) => {
  const { updateWorkOrder } = useUpdateWorkOrder();
  const navigate = useNavigate();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const dispatch = useDispatch();

  const {
    isLoading: isNearbyLoading,
    error: isNearbyError,
    data: nearbyFreelancersData,
    refetch: refetchNearbyFreelancers,
  } = useQuery({
    queryKey: ["nearbyFreelancers"],
    queryFn: () =>
      fetchNearbyFreelancers({
        radius,
        latitude: workOrderDetail?.address?.latitude,
        longitude: workOrderDetail?.address?.longitude,
        workOrderId: workOrderDetail?.id,
      }),
    enabled: false,
  });

  const {
    // status,
    isPending: isRouteLoading,
    error: isRouteError,
    mutateAsync: routeOfferMutation,
  } = useMutation({
    mutationFn: routeOffer,
    onSuccess: (data) => {
      //Updating the work order details in store so we don't have to fetch the latest details again from backend
      dispatch(
        setWorkOrderDetail({
          ...workOrderDetail,
          offersCount: {
            ...workOrderDetail.offersCount,
            routed: workOrderDetail.offersCount.routed + 1,
          },
        })
      );
      dispatch(
        showToast({
          message: "Job Routed Successfully",
          type: "success",
        })
      );
    },
  });

  useEffect(() => {
    refetchNearbyFreelancers();
  }, [radius]);

  async function handleRouteButton(userId) {
    try {
      let payload = { workOrderId: workOrderDetail.id, userId };
      const routedResponse = await routeOfferMutation(payload);
      refetchNearbyFreelancers();
      refetchProvidersCount();
      updateWorkOrder();
    } catch (error) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  const handleRadiusChange = (e) => {
    setRadius(e.target.value);
  };

  const data = nearbyFreelancersData?.data || [];

  const COLUMNS = [
    ...(!["DRAFT", "APPROVED", "INPROGRESS"].includes(workOrderDetail.status)
      ? [
          {
            label: "Action",
            renderCell: (item) => (
              <div className="flex overflow-auto h-10 items-center">
                <BaseButton
                  loaderSize={18}
                  loaderColor="primary"
                  isLoading={isRouteLoading}
                  style={{
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  className="bg-white hover:bg-primary hover:text-white text-primary mr-3 ml-0.5 w-[70px]"
                  onClick={() => handleRouteButton(item.id)}
                >
                  Route
                </BaseButton>
              </div>
            ),
          },
        ]
      : []),
    {
      label: "ID",
      renderCell: (item) => <p className="text-primary">{item?.id}</p>,
    },
    {
      label: "Name",
      renderCell: (item) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(`/profile/details/${item.id}`)}
        >
          <Avatar src="/broken-image.jpg" sx={{ width: 30, height: 30 }} />
          <div className="ml-2">
            <p className="text-primary capitalize">
              {item?.firstName} {item?.lastName}
            </p>
            <p className="text-xs">{item?.phoneNumber}</p>
          </div>
        </div>
      ),
    },
    {
      label: "Private Feedback",
      renderCell: (item) => <p>not enough data</p>,
    },
    {
      label: "Ratings",
      renderCell: (item) => (
        <div className="flex items-center justify-center border border-primary w-20 py-1 rounded-full">
          <BaseRating style={{ fontSize: "13px" }} />
        </div>
      ),
    },
    {
      label: "Jobs",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="text-primary font-normal">336 </p>
          <p className="text-xs ml-1">Marketplace</p>
        </div>
      ),
    },
    {
      label: "Distance",
      renderCell: (item) => (
        <div className="flex items-center">
          <i className="fa-solid fa-car text-xs"></i>
          <p className="text-primary ml-2">
            {item?.address.distance_km.toFixed(2)} Km
          </p>
        </div>
      ),
    },
    {
      label: "Ranking",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="ml-1">Points 374/530</p>
        </div>
      ),
    },
    {
      label: "Cancelation",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="ml-1">13%</p>
        </div>
      ),
    },
    {
      label: "Timeliness",
      renderCell: (item) => (
        <div className="flex items-center">
          <p className="ml-1">85%</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mb-3 flex items-center">
        <div className="flex items-center w-[250px]">
          <p className="mr-2 mt-1">Distance: </p>
          <BaseSelect
            className="w-[150px] rounded-lg "
            fullHeight={true}
            fullWidth
            labelShrink={false}
            required={true}
            id="workOrderManagerId"
            name="workOrderManagerId"
            value={radius}
            onChange={handleRadiusChange}
            options={[
              {
                label: "50 km",
                value: 50,
              },
              {
                label: "100 km",
                value: 100,
              },
              {
                label: "150 km",
                value: 150,
              },
            ]}
          />
        </div>
        <div className="flex items-center w-[400px]">
          <p className="mr-2">Providers:</p>
          <p className="ml-2 bg-[#EFF4FF] px-5 py-1 rounded-md">
            {data?.length}
          </p>
        </div>
      </div>
      <div className="overflow-x-auto">
        {/* <div className="min-w-full "> */}
        <table
          className="min-w-max  border-collapse rounded-lg border border-gray-300 relative"
          style={{ tableLayout: "auto !important" }}
        >
          <div>
            <thead className="table !important w-full">
              <tr>
                {COLUMNS.map((column, index) => (
                  <th
                    key={index}
                    className={`px-3 py-2 border-r border-[#8C8C8C] last:border-r-0 text-left text-sm font-normal text-[#686868] overflow-auto ${
                      column.width
                        ? "w-[" + column.width + "]"
                        : "min-w-[200px]"
                    }`}
                    style={{
                      borderBottomWidth: "1px",
                      borderBottomColor: "#DEDEDE",
                    }}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table w-full">
              {data.map((item) => (
                <tr
                  key={item.id}
                  className="border-b last:border-b-0 text-[13px]"
                >
                  {COLUMNS.map((column, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={`px-2 py-1  ${
                        column.width
                          ? "min-w-[" + column.width + "]"
                          : "min-w-[200px]"
                      }`}
                    >
                      {column.renderCell(item)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>
    </>
  );
};

export default NearbyFreelancers;
