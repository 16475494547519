import React, { useEffect, useState } from "react";
import Providers from "../TabsCard/Providers";

import BaseCard from "../../../components/ui/BaseCard";
import BaseButton from "../../../components/ui/BaseButton";
import { brandPrimaryColor } from "../../../utils/variables";

const ProvidersTab = () => {
  return (
    <div className="h-screen">
      <BaseCard elevation={0} className="border py-2 px-10 rounded-lg">
        <div>
          <BaseButton
            style={{
              border: `1px solid ${brandPrimaryColor}`,
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
            fullWidth={false}
            className="hover:bg-primary bg-white hover:text-white text-primary  min-w-[100px]"
          >
            Route
          </BaseButton>
          <BaseButton
            style={{
              border: `1px solid ${brandPrimaryColor}`,
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
            fullWidth={false}
            className="hover:bg-primary bg-white hover:text-white text-primary  min-w-[130px] ml-5"
          >
            Export
          </BaseButton>
          <BaseButton
            style={{
              border: `1px solid ${brandPrimaryColor}`,
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
            fullWidth={false}
            className="hover:bg-primary bg-white hover:text-white text-primary   w-[180px] ml-5"
          >
            Invite to Recruitment
          </BaseButton>
        </div>
        <div>{/* <BaseTextField /> */}</div>
      </BaseCard>
      <Providers />
    </div>
  );
};

export default ProvidersTab;
