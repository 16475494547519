import React from "react";
import AddProfilePicture from "./Forms/AddProfilePicture";
import AddAboutMe from "./Forms/AddAboutMe";
import AddEducation from "./Forms/AddEducation";
import AddBackgroundVerifications from "./Forms/AddBackgroundVerifications";
import AddSkills from "./Forms/AddSkills";
import AddEquipments from "./Forms/AddEquipments";
import AddLanguage from "./Forms/AddLanguage";
import AddLicenseCertifications from "./Forms/AddLicenseCertifications";
import AddEmployment from "./Forms/AddEmployment";

const ProfileDetails = ({ userData, refetchMe }) => {
  return (
    <div>
      <AddProfilePicture
        refetchMe={refetchMe}
        profilePicture={userData?.picture}
      />
      <AddAboutMe description={userData?.description} />
      <AddEducation userId={userData?.id} />
      <AddBackgroundVerifications userId={userData?.id} />
      {/* <AddTypeOfWork /> */}
      <AddEquipments userId={userData?.id} />
      <AddLicenseCertifications userId={userData?.id} />
      <AddSkills />
      <AddEmployment userId={userData?.id} />
      <AddLanguage />
    </div>
  );
};

export default ProfileDetails;
