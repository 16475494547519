import React, { useRef, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseTextField from "../../../../../components/ui/BaseTextField";
import BaseCard from "../../../../../components/ui/BaseCard";
import BaseButton from "../../../../../components/ui/BaseButton";
import { uploadProfilePicture } from "../../../../../services/api/digitalAsset";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../store/toast/toastSlice";

const AddProfilePicture = ({ refetchMe, profilePicture }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    isPending: isUploadingProfilePictureLoading,
    error: isUploadingProfilePictureError,
    data: uploadedProfilePictureData,
    mutateAsync: uploadProfilePictureMutation,
  } = useMutation({
    mutationFn: uploadProfilePicture,
    enabled: false,
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      try {
        const profilePictureResponse = await uploadProfilePictureMutation(file);
        dispatch(
          showToast({
            message: "profile picture updated successfully",
            type: "success",
          })
        );
        refetchMe();
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4">
          <p className="text-base font-normal">Add a Profile Picture</p>
        </div>
        <div className="w-full flex items-center mb-6">
          {/* Hidden File Input */}
          <input
            type="file"
            ref={fileInputRef}
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

          {/* File Display Section */}
          <div
            style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
            className=" w-full py-[10px] rounded-md flex flex-wrap"
          >
            {profilePicture ? (
              <a
                href={profilePicture?.url} // The existing profile picture URL
                download
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer block text-blue-500 hover:underline font-300 !hover:font-[300] ml-3"
              >
                {profilePicture?.filename}
              </a>
            ) : // If no existing profile picture, show the selected file after upload
            selectedFile ? (
              <p className="text-gray-400 py-1 ml-2">{selectedFile.name}</p>
            ) : (
              <p className="text-gray-400 py-1 ml-2">No file selected</p>
            )}
          </div>

          {/* Choose File Button */}
          <BaseButton
            loaderSize={22}
            isLoading={isUploadingProfilePictureLoading}
            variant="outlined"
            color="primary"
            className="w-[123px] py-[7.2px] ml-4"
            onClick={handleButtonClick}
          >
            Choose File
          </BaseButton>
        </div>
      </BaseCard>
    </form>
  );
};

export default AddProfilePicture;
