import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useParams } from "react-router-dom";
import BaseRating from "../../../components/ui/BaseRating";

const Rating = () => {
  const { id: userId } = useParams();

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark font-normal">Rating</h6>
      </div>
      <div>
        <div className="flex items-center">
          <BaseRating rating={3} size="medium" />
          <p className="ml-3">Market Place</p>
          <p className="text-primary ml-3 cursor-pointer">See All 41 Ratings</p>
        </div>

        <div className="flex items-center mt-6">
          <p className="text-secondary font-normal mr-4">Endorsements:</p>
          <div className="flex flex-wrap gap-8">
            <div className="flex bg-light px-3 py-1.5 rounded-md">
              <p>Communication</p>
              <p className="ml-8">07</p>
            </div>
            <div className="flex bg-light px-3 py-1.5 rounded-md">
              <p>Professionalism</p>
              <p className="ml-8">09</p>
            </div>
            <div className="flex bg-light px-3 py-1.5 rounded-md">
              <p>Problem solving</p>
              <p className="ml-8">08</p>
            </div>
            <div className="flex bg-light px-3 py-1.5 rounded-md">
              <p>Preparedness</p>
              <p className="ml-8">06</p>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default Rating;
