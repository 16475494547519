// src/context/SocketContext.js
import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import useAuth from "../hooks/useAuth";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { auth, authRole } = useAuth();
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!auth?.user?.id) return;

    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
      auth: { userId: auth.user.id, role: authRole },
    });

    newSocket.emit("subscribeUser", { userId: auth.user.id });

    newSocket.on("connect", () => {
      console.log(`✅ Connected to WebSocket: ${newSocket.id}`);
      newSocket.emit("joinRoom", `user_${auth.user.id}`); // Join room after connection
    });

    newSocket.on("disconnect", () => {
      console.log("❌ WebSocket Disconnected");
    });

    newSocket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [auth]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
