import axios from "axios";

export function fetchCountries() {
  return axios.get("https://restcountries.com/v3.1/all");
}
export function fetchCities(payload) {
  return axios
    .post(`https://countriesnow.space/api/v0.1/countries/cities`, payload)
    .then((res) => res.data);
}
