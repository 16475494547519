import React, { useContext, useState } from "react";

import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import BaseDropdown from "../../components/ui/BaseDropdown";
import useAuth from "../../hooks/useAuth";
import { navigateWithReload } from "../../utils/helpers";
import { useNotificationsSocket } from "../../hooks/useNotificationsSocket";
import { Badge } from "@mui/material";
import Notifications from "./components/Notifications";
import MessageNotifications from "./components/MessageNotifications";
import { LayoutContext } from "../../context/LayoutContext";
import { useCurrentUser } from "../../hooks/useCurrentUser";

const TopBar = () => {
  const { userData } = useCurrentUser();
  const { isBannerVisible } = useContext(LayoutContext);

  const navigate = useNavigate();
  const { unreadNotificationCount, unreadMessageCount } =
    useNotificationsSocket();
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const [
    isMessageNotificationDropdownOpen,
    setIsMessageNotificationDropdownOpen,
  ] = useState(false);

  return (
    // <div className="flex border-b border-zinc-300 fixed top-0 bg-white z-30 w-[95%] py-2">
    <div
      className={`fixed w-[95%] bg-white border-b border-zinc-300 z-40 flex items-center justify-between px-4 py-2 transition-all ${
        isBannerVisible ? "mt-[38px]" : "mt-0"
      }`}
    >
      <div className="w-full content-center ">
        <div className="flex items-center h-full">
          <div className="px-4">
            <i className="fa-solid fa-magnifying-glass text-lg"></i>
          </div>

          <input className="focus:outline-none h-full w-full p-1" />
        </div>
      </div>

      <div className="flex items-center justify-end mr-10 relative">
        <div className="flex items-center h-full border-r border-zinc-300 pr-4 cursor-default">
          <i className="fa-solid fa-dollar-sign"></i>
          <p className="text-sm ml-0.5 font-normal">50.00</p>
        </div>
        <div className="px-4">
          <i className="fa-solid fa-circle-question text-[#636B88] text-xl"></i>
        </div>
        <div className="px-4">
          <Badge
            onClick={(e) => {
              e.stopPropagation();
              setIsMessageNotificationDropdownOpen((prev) => !prev);
            }}
            className="cursor-pointer"
            color="secondary"
            size="small"
            badgeContent={unreadMessageCount}
            sx={{
              ".MuiBadge-badge": {
                minWidth: "10px",
                height: "18px",
                fontSize: "12px",
              },
            }}
          >
            <i className="fa-solid fa-envelope text-[#636B88] text-xl"></i>
          </Badge>
          <MessageNotifications
            isMessageNotificationDropdownOpen={
              isMessageNotificationDropdownOpen
            }
            setIsMessageNotificationDropdownOpen={
              setIsMessageNotificationDropdownOpen
            }
          />
        </div>
        <div className="px-4 ">
          <Badge
            onClick={(e) => {
              e.stopPropagation();
              setIsNotificationDropdownOpen((prev) => !prev);
            }}
            className="cursor-pointer"
            color="secondary"
            size="small"
            badgeContent={unreadNotificationCount}
            sx={{
              ".MuiBadge-badge": {
                minWidth: "10px",
                height: "18px",
                fontSize: "12px",
              },
            }}
          >
            <i className="fa-solid fa-bell text-xl text-[#636B88]"></i>
          </Badge>
          <Notifications
            isNotificationDropdownOpen={isNotificationDropdownOpen}
            setIsNotificationDropdownOpen={setIsNotificationDropdownOpen}
          />
        </div>
        <div
          className="py-2 pl-3"
          onClick={(e) => {
            e.stopPropagation();
            setIsProfileDropdownOpen((prev) => !prev);
          }}
        >
          <Avatar
            className="cursor-pointer"
            src={userData?.profilePicture}
            sx={{ width: 24, height: 24 }}
          />
          <BaseDropdown
            setOpen={setIsProfileDropdownOpen}
            open={isProfileDropdownOpen}
            className="w-[236px] top-[40px] shadow-none left-[30px] "
            style={{
              boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div className="hover:bg-[#F6F6F6] py-2 px-1  cursor-pointer border-b rounded-t-lg">
              <p className="capitalize">
                {userData?.firstName} {userData?.lastName}
                <span className="text-sm font-medium"> ID: {userData?.id}</span>
              </p>
            </div>
            <div
              className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b"
              onClick={() => {
                navigate(`/user/details/profile/${userData?.id}`);

                setIsProfileDropdownOpen(false);
              }}
            >
              <p className="capitalize">My Profile</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Package Overview</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Company Setting</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Help & Support</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Developer Center</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Legal</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Privary Policy</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Send Feedback</p>
            </div>
            <div className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer border-b">
              <p className="capitalize">Insecure FAQS</p>
            </div>
            <div
              className="hover:bg-[#F6F6F6]	 py-3 px-1 cursor-pointer rounded-b-lg"
              onClick={() => {
                window.localStorage.clear();
                navigateWithReload("/company-signup");
              }}
            >
              <p className="capitalize">Log Out</p>
            </div>
          </BaseDropdown>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
