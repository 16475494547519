import React, { useEffect, useRef, useState } from "react";
import BaseCard from "../../../../../components/ui/BaseCard";
import AddAboutMeModal from "../../../components/AddAboutMeModal";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { updateUserDetails } from "../../../../../services/api/user";
import { showToast } from "../../../../../store/toast/toastSlice";

const validationSchema = yup.object({
  description: yup
    .string("Enter About me")
    .notRequired("About me not required"),
});

const AddAboutMe = ({ description }) => {
  const [isAddAboutMeModalOpen, setIsAddAboutMeModalOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    isPending: isUpdateUserDetailsLoading,
    error: isUpdateUserDetailsError,
    data: updatedUserDetails,
    mutateAsync: updateUserDetailsMutation,
  } = useMutation({
    mutationFn: updateUserDetails,
    enabled: false,
  });

  const formik = useFormik({
    initialValues: {
      description,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUpdateUserDetails();
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  /* useEffect(() => {
    if (isAddAboutMeModalOpen === false) {
      console.log("in if");
      descriptionClone = description;
    }
  }, [isAddAboutMeModalOpen]); */

  const handleUpdateUserDetails = async () => {
    try {
      const userAddressResponse = await updateUserDetailsMutation(
        formik.values
      );
      dispatch(
        showToast({
          message: "Added successfully",
          type: "success",
        })
      );
      setIsAddAboutMeModalOpen(false);
    } catch (error) {}
  };

  return (
    <>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">About Me</p>
          <i
            class="fa-solid fa-pencil cursor-pointer"
            onClick={() => setIsAddAboutMeModalOpen(true)}
          ></i>
        </div>
        <p className="px-4">{formik.values.description}</p>
      </BaseCard>

      <AddAboutMeModal
        open={isAddAboutMeModalOpen}
        close={setIsAddAboutMeModalOpen}
        formik={formik}
        description={description}
        isUpdateUserDetailsLoading={isUpdateUserDetailsLoading}
      />
    </>
  );
};

export default AddAboutMe;
