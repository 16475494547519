import React, { useContext, useEffect, useState } from "react";
import WorkJobDetailsHeader from "../../WorkJobDetails/WorkJobDetailsHeader";
import ActionBar from "../../WorkJobDetails/ActionBar";
import WorkJobTabs from "../../WorkJobDetails/WorkJobTabs";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchJobDetail,
  fetchUserAllowedAction,
} from "../../../services/api/workOrder";
import { useDispatch } from "react-redux";
import { setWorkOrderDetail } from "../../../store/workOrder/workOrderSlice";
import BaseLoader from "../../../components/ui/BaseLoader";
import BaseNotFound from "../../../components/ui/BaseNotFound";
import { useWorkOrderSocket } from "../../../hooks/useWorkOrderSocket";
import { navigateWithReload } from "../../../utils/helpers";
import { LayoutContext } from "../../../context/LayoutContext";

const JobDetails = () => {
  const { isBannerVisible } = useContext(LayoutContext);
  const { id } = useParams();
  const location = useLocation(); // Capture location changes

  const { workOrderUpdates, userAllowedActionUpdates, accessRevoked } =
    useWorkOrderSocket(id, () => {
      navigateWithReload(`/workOrder/details/${id}`); // Redirect user to work order list if access is revoked
    });

  const [userAllowedAction, setUserAllowedAction] = useState();
  const dispatch = useDispatch();
  const {
    isLoading: isjobDetailDataLoading,
    error: isjobDetailDataError,
    data: jobDetailData,
    refetch: refetchJobDetail,
  } = useQuery({
    queryKey: ["jobDetail", id],
    queryFn: () => fetchJobDetail(id),
    enabled: !!id,
    staleTime: 0,
  });

  const {
    isLoading: isAllowedActionLoading,
    error: isAllowedActionError,
    data: userAllowedActionData,
    refetch: refetchUserAllowedAction,
  } = useQuery({
    queryKey: ["fetchUserAllowedAction", id],
    queryFn: () => fetchUserAllowedAction(id),
    staleTime: 0,
  });

  useEffect(() => {
    setUserAllowedAction(userAllowedActionData?.data);
  }, [userAllowedActionData]);

  useEffect(() => {
    if (id) {
      refetchJobDetail();
      refetchUserAllowedAction();
    }
  }, [id, location.pathname]);

  // Update Redux state with job details
  useEffect(() => {
    if (jobDetailData?.data) {
      dispatch(setWorkOrderDetail(jobDetailData?.data));
    }
  }, [jobDetailData, dispatch]);

  useEffect(() => {
    if (accessRevoked) {
      console.log(`🛑 Hiding Work Order ${id} due to access revocation.`);
      navigateWithReload(`/workOrder/details/${id}`);
      return;
    }

    if (workOrderUpdates) {
      dispatch(setWorkOrderDetail(workOrderUpdates));
      setUserAllowedAction(userAllowedActionUpdates);
    }
  }, [workOrderUpdates, id, accessRevoked]);

  return (
    <>
      <BaseLoader
        isLoading={isjobDetailDataLoading || isAllowedActionLoading}
      />
      {isjobDetailDataError || isjobDetailDataLoading ? (
        <BaseNotFound message={isjobDetailDataError?.response?.data?.message} />
      ) : (
        <div className="relative ">
          <div
            className={`sticky transition-all  z-20 ${
              isBannerVisible ? "top-[94px]" : "top-[56px]"
            }`}
          >
            <WorkJobDetailsHeader
              refetchUserAllowedAction={refetchUserAllowedAction}
              userAllowedAction={userAllowedAction}
            />

            <ActionBar
              refetchUserAllowedAction={refetchUserAllowedAction}
              userAllowedAction={userAllowedAction}
            />
          </div>

          <div
            className={`relative bg-light h-full pb-3 transition-all ${
              isBannerVisible ? "top-[90px]" : "top-[theme(height.14)]"
            }`}
          >
            <WorkJobTabs />
          </div>
        </div>
      )}
    </>
  );
};

export default JobDetails;
