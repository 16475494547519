import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useQuery } from "@tanstack/react-query";
import { fetchUserQualifications } from "../../../services/api/user";
import { useParams } from "react-router-dom";
import { fetchEmploymentHistoryList } from "../../../services/api/employment";

const Employment = () => {
  const { id: userId } = useParams();

  const {
    data: employmentListData,
    isPending: isFetchEmploymentListLoading,
    error: isFetchEmploymentListError,
  } = useQuery({
    queryKey: ["employmentHistoryList", userId],
    queryFn: () => fetchEmploymentHistoryList(userId),
    enabled: !!userId,
  });

  console.log("employmentListData", employmentListData);

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Employment</h6>
      </div>

      {employmentListData?.data?.map((employment) => (
        <div className="border-b pb-2">
          <h6 className="font-normal mb-1">{employment?.companyName}</h6>
          <p className="font-normal text-sm capitalize mb-1">
            {employment?.designation}
          </p>
          <p className="mb-1">
            {employment?.city}, {employment?.country}
          </p>
          <p className="text-xs">{employment?.description}</p>
        </div>
      ))}
    </BaseCard>
  );
};

export default Employment;
