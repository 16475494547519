import React, { useState } from "react";
import BaseCard from "../../../../../components/ui/BaseCard";
import AddBackgroundVerificationModal from "../../../components/AddBackgroundVerificationModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchUserBackgroundVerificationDocs } from "../../../../../services/api/user";
import { deleteDigitalAsset } from "../../../../../services/api/digitalAsset";

const AddBackgroundVerifications = ({ userId }) => {
  const [
    isAddBackgrounVerificationModalOpen,
    setIsAddBackgrounVerificationModalOpen,
  ] = useState(false);

  const {
    data: userBackgroundVerificationData,
    refetch: refetchUserBackgroundVerificationDocs,
  } = useQuery({
    queryKey: ["userBackgroundVerificationData"],
    queryFn: () => fetchUserBackgroundVerificationDocs(userId),
    enabled: !!userId,
  });

  const {
    isPending: isDeletedDigitalAssetApiLoading,
    error: isDeletedDigitalAssetApiError,
    data: deletedDigitalAsset,
    mutateAsync: deleteDigitalAssetMutation,
  } = useMutation({
    mutationFn: deleteDigitalAsset,
    enabled: false,
  });

  const handleBackgroundVerificationDelete = async (id) => {
    try {
      await deleteDigitalAssetMutation(id);
      await refetchUserBackgroundVerificationDocs();
    } catch (error) {}
  };

  return (
    <form>
      <BaseCard
        className="py-3 px-4 mt-3 border rounded-lg shadow-sm"
        elevation={0}
      >
        <div className="bg-light rounded-sm py-2 px-4 mb-4 flex items-center justify-between">
          <p className="text-base font-normal">Background Verifications</p>
          <i
            class="fa-solid fa-circle-plus cursor-pointer text-secondary text-xl"
            onClick={() => setIsAddBackgrounVerificationModalOpen(true)}
          ></i>
        </div>

        <div className="flex">
          {userBackgroundVerificationData?.data?.education && (
            <div className="flex bg-[#E9E9EB] rounded-[4px] py-1 px-6  items-center">
              <a
                href={userBackgroundVerificationData?.data?.education.url} // ✅ Ensure this is a valid URL
                download="education" // ✅ Set correct filename for download
                target="_blank" // ✅ Open in a new tab if needed
                rel="noopener noreferrer"
                className="cursor-pointer block !hover:text-blue-500 hover:underline font-300 !hover:font-[300] mr-3"
              >
                Education
              </a>
              <i
                className="fa-solid fa-xmark ml-2 cursor-pointer hover:text-blue-500"
                onClick={() =>
                  handleBackgroundVerificationDelete(
                    userBackgroundVerificationData?.data?.education.id
                  )
                }
              ></i>
            </div>
          )}
          {userBackgroundVerificationData?.data?.workingRights && (
            <div className="flex bg-[#E9E9EB] rounded-[4px] py-1 px-6  items-center ml-3">
              <a
                href={userBackgroundVerificationData?.data?.workingRights?.url} // ✅ Ensure this is a valid URL
                download="education" // ✅ Set correct filename for download
                target="_blank" // ✅ Open in a new tab if needed
                rel="noopener noreferrer"
                className="cursor-pointer block !hover:text-blue-500 hover:underline font-300 !hover:font-[300] mr-3"
              >
                Working Rights
              </a>
              <i
                className="fa-solid fa-xmark ml-2 cursor-pointer hover:text-blue-500"
                onClick={() =>
                  handleBackgroundVerificationDelete(
                    userBackgroundVerificationData?.data?.workingRights.id
                  )
                }
              ></i>
            </div>
          )}
        </div>
      </BaseCard>
      <AddBackgroundVerificationModal
        userBackgroundVerificationData={userBackgroundVerificationData}
        open={isAddBackgrounVerificationModalOpen}
        close={setIsAddBackgrounVerificationModalOpen}
        refetchUserBackgroundVerificationDocs={
          refetchUserBackgroundVerificationDocs
        }
      />
    </form>
  );
};

export default AddBackgroundVerifications;
