import React, { useEffect, useState } from "react";

import * as yup from "yup";
import BaseButton from "../../components/ui/BaseButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resendOtpApi, verifyOtp } from "../../services/api/auth";
import { useMutation, useQuery } from "@tanstack/react-query";
import { showToast } from "../../store/toast/toastSlice";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie";
import SuccessCheck from "../../assets/animations/success-check.json";
import OTP from "../OtpModal/Otp";
import { setAuthUser } from "../../store/authUser/authUserSlice";
import { navigateWithReload, setDataInLocalStorage } from "../../utils/helpers";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessCheck,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const TIMER = 300;

const schemaEmail = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

const OtpForm = () => {
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(TIMER);
  const [isTimerEnded, setIsTimerEnded] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const email = decodeURIComponent(queryParams.get("email"));

  const {
    isPending: isVerifyOtpLoading,
    status,
    error,
    mutateAsync: otpMutation,
  } = useMutation({
    mutationFn: verifyOtp,
    onSuccess: (data) => otpVerifiedSuccessfully(data),
  });

  const {
    isPending: isResendOtpLoading,
    error: isResendOtpError,
    data: otpResendData,
    refetch: refetchOtpResendApi,
  } = useQuery({
    queryKey: ["resendOtpApi"],
    queryFn: () => resendOtpApi(email),
    enabled: false,
  });

  useEffect(() => {
    const savedEndTime = localStorage.getItem("otpEndTime");
    const isTimerEndedStored = localStorage.getItem("isTimerEnded") === "true";
    const currentTime = Math.floor(Date.now() / 1000);

    if (isTimerEndedStored) {
      setIsTimerEnded(true);
      setTimeLeft(0);
    } else if (savedEndTime) {
      const remainingTime = savedEndTime - currentTime;
      if (remainingTime > 0) {
        setTimeLeft(remainingTime);
        setIsTimerEnded(false);
      } else {
        localStorage.removeItem("otpEndTime");
        localStorage.setItem("isTimerEnded", "true");
        setIsTimerEnded(true);
        setTimeLeft(0);
      }
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          localStorage.removeItem("otpEndTime");
          localStorage.setItem("isTimerEnded", "true");
          setIsTimerEnded(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]); // Add timeLeft as a dependency to re-trigger the effect

  const resendOtp = async () => {
    try {
      const otpResendResponse = await refetchOtpResendApi(email);
      const currentTime = Math.floor(Date.now() / 1000);
      const newEndTime = currentTime + TIMER; // Reset timer for 30 seconds
      localStorage.setItem("otpEndTime", newEndTime);
      localStorage.removeItem("isTimerEnded");

      setTimeLeft(30); // Reset timer UI
      setIsTimerEnded(false); // Reset timer state
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const otpVerifiedSuccessfully = (response) => {
    if (otp === "") return;
    setIsOtpVerified(true);
    setDataInLocalStorage("user", response.data.user);
    setDataInLocalStorage("accessToken", response.data.accessToken);

    dispatch(
      setAuthUser({
        user: response.data.user,
        accessToken: response.data.accessToken,
      })
    );
    const routeTo =
      response.data.user.role.role === "CLIENT"
        ? "/workOrders/workBoard"
        : "/jobs";

    navigateWithReload(routeTo);
    setTimeout(() => {
      navigateWithReload(routeTo);
    }, 7000);
  };

  const verifyButtonClicked = async () => {
    try {
      if (isTimerEnded) {
        dispatch(
          showToast({
            position: "top-center",
            message: "OTP has expired, Please resend OTP to try again.",
            style: {
              width: "400px",
            },
          })
        );
        return;
      }
      const isEmailVerified = await schemaEmail.validate({ email });

      // Ensure OTP is entered
      if (!otp || otp.length !== 6) {
        dispatch(
          showToast({
            position: "top-center",
            message: "Please enter a valid 6-digit OTP.",
            style: {
              width: "400px",
            },
          })
        );
        return;
      }

      const responseOtpVerification = await otpMutation({ code: otp, email });
    } catch (error) {
      const errorMessage = Array.isArray(error?.response?.data?.message)
        ? error?.response?.data?.message[0]
        : error?.response?.data?.message || "Please enter a valid email";

      dispatch(
        showToast({
          position: "top-center",
          message: errorMessage,
          type: "error",
        })
      );
    }
  };

  return (
    <form>
      <div className="bg-white rounded-lg py-8 px-20 shadow-lg">
        {isOtpVerified ? (
          <div>
            <Lottie options={defaultOptions} height={150} width={300} />

            <p className="text-base text-center mb-4 px-20">
              OTP successfully verified! Redirecting...
            </p>
          </div>
        ) : (
          <div>
            <h4 className="font-500 text-center mb-2">OTP Verification</h4>
            <p className="text-base text-center ">
              We have sent a One-Time Password (OTP) code to the email
            </p>
            <p className="text-base font-medium	 mb-6 text-center">{email}</p>
            <div className="flex justify-center mb-3">
              <OTP value={otp} onChange={setOtp} length={6} />
            </div>
            <div className="flex flex-col items-center">
              <BaseButton
                loaderSize="20px"
                isLoading={isVerifyOtpLoading}
                disabled={!otp || otp.length !== 6}
                className="mt-5 text-sm py-[10px] rounded-md"
                fullWidth={true}
                onClick={verifyButtonClicked}
              >
                Verify
              </BaseButton>

              {isTimerEnded ? (
                <p
                  className="text-secondary mt-5 text-sm font-medium cursor-pointer text-center"
                  onClick={resendOtp}
                >
                  Resend?
                </p>
              ) : (
                <p className="text-secondary mt-5 text-sm cursor-default text-center">
                  {formatTime(timeLeft)}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default OtpForm;
