import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    coverageAreaSearchText: null,
    coverageAreaRadius: 20,
    coverageAreaType: null,
    coverageAreaCoordinates: {
      longitude: null,
      latitude: null,
    },
  },
};

const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState,
  reducers: {
    setJobEngineerFilters: (state, action) => {
      const { filterName, data } = action.payload;
      state.value[filterName] = data;
    },
  },
});

export const { setJobEngineerFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
