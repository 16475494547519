import axios from "../../lib/axiosInstance";

export function me(payload) {
  return axios.get("users/me", payload).then((res) => res.data);
}

export function fetchNearbyFreelancers(payload) {
  const queryParams = new URLSearchParams();

  // Include only non-empty filters in the query parameters
  if (payload.latitude) {
    queryParams.append("latitude", payload.latitude);
  }
  if (payload.longitude) {
    queryParams.append("longitude", payload.longitude);
  }
  if (payload.radius) {
    queryParams.append("radius", payload.radius);
  }
  if (payload.workOrderId) {
    queryParams.append("workOrderId", payload.workOrderId);
  }

  return axios
    .get("users/nearby", { params: queryParams })
    .then((res) => res.data);
}

export function fetchUserDetails(id) {
  return axios.get(`users/${id}`).then((res) => res.data);
}

export function fetchUserQualifications(id) {
  return axios.get(`users/qualifications/${id}`).then((res) => res.data);
}
export function fetchUserSkills() {
  return axios.get("users/skills").then((res) => res.data);
}

export function addQualificationsToUser(payload) {
  return axios.post("users/qualifications", payload).then((res) => res.data);
}
export function fetchUserBackgroundVerificationDocs(id) {
  return axios
    .get(`users/backgroundVerification/${id}`)
    .then((res) => res.data);
}

export function addSkillsToUser(payload) {
  return axios.post("users/skills", payload).then((res) => res.data);
}

export function deleteUserSkill(id) {
  return axios.delete(`users/skills/${id}`).then((res) => res.data);
}
export function deleteUserQualification(id) {
  return axios.delete(`users/qualifications/${id}`).then((res) => res.data);
}

export function createOrUpdateUserAddress(payload) {
  return axios.patch("users/address", payload).then((res) => res.data);
}
export function updateUserDetails(payload) {
  return axios.patch("users", payload).then((res) => res.data);
}
export function createOrUpdateUserBillingRates(payload) {
  return axios.patch("users/billingRates", payload).then((res) => res.data);
}
