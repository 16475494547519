import React, { useState } from "react";

import BaseButton from "../../../components/ui/BaseButton";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { createWorkOrder } from "../../../services/api";
import { showToast } from "../../../store/toast/toastSlice";
import { navigateWithReload } from "../../../utils/helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateWorkOrderApi } from "../../../services/api/workOrder";
import {
  resetWorkOrderForm,
  taskHandleChange,
  updateWorkOrderFormValidation,
} from "../../../store/workOrder/workOrderSlice";
import BaseLoader from "../../../components/ui/BaseLoader";
const WorkOrderHeader = ({ isBannerVisible }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDataValidated, setIsDataValidated] = useState(false);
  const params = useParams();
  const isEdit = location.pathname.split("/")[2]?.toLowerCase() === "edit";
  const workOrder = useSelector((state) => state.workOrder);

  const {
    isPending: isPublishLoading,
    status,
    error,
    mutateAsync: workOrderMutation,
  } = useMutation({
    mutationFn: createWorkOrder,
    onSuccess: (data) => {},
  });

  const {
    isPending: isUpdatingLoading,
    error: isUpdatingError,
    mutateAsync: updateWorkOrderApiMutation,
  } = useMutation({
    mutationFn: updateWorkOrderApi,
    onSuccess: (data) => {},
  });

  const handleWorkOrderUpdateOrPublish = async (isDraft) => {
    if (isEdit) {
      editWorkWork();
    } else {
      handleWorkOrderPublish(isDraft);
    }
  };

  const editWorkWork = async () => {
    const payload = {
      id: params.id,
      ...workOrder.summary.overview,
      ...workOrder.summary.serviceDescription,
      ...workOrder.payment.pay,
    };
    await updateWorkOrderApiMutation(payload);
    dispatch(
      showToast({
        message: "Work Order Updated",
        type: "success",
      })
    );
    dispatch(resetWorkOrderForm());

    navigate(`/workOrders/workBoard`);

    try {
    } catch (error) {
      dispatch(
        showToast({
          message: error.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const handleWorkOrderPublish = async (isDraft) => {
    try {
      if (!isWorkOrderFormValidated()) {
        return;
      }
      const payload = formatWorkOrderPayload(isDraft);
      await workOrderMutation(payload);
      dispatch(
        showToast({
          message: isDraft ? "Work Order Drafted" : "Work Order Published",
          type: isDraft ? "info" : "success",
        })
      );

      dispatch(resetWorkOrderForm());

      navigate(`/workOrders/workBoard`);
    } catch (error) {
      if (error.response?.status === 401) {
        window.localStorage.clear();
        navigateWithReload("company-signup");
      }
      if (typeof error.response?.data?.message === "string") {
        dispatch(
          showToast({
            message: error.response?.data?.message,
            type: "error",
          })
        );

        return;
      }

      for (let i = 0; i < error?.response?.data?.message.length; i++) {
        const errorMessage = error?.response?.data.message[i];
        dispatch(
          showToast({
            message: errorMessage,
            type: "error",
          })
        );
      }
    }
  };

  const isWorkOrderFormValidated = () => {
    if (workOrder.payment.tasks.length > 0) {
      workOrder.payment.tasks.forEach((task) => {
        if (task.taskType === "CUSTOM") {
          return false;
        }
      });
    }

    if (workOrder.summary.overview.title === "") {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "title",
          data: true,
        })
      );

      dispatch(
        showToast({
          message: "Title is required",
          type: "error",
        })
      );

      navigate(`/workOrder/create/summary`);
      return false;
    }
    if (workOrder.scheduling.location.type === "EXACT_LOCATION") {
      if (
        workOrder.scheduling.location.latitude === "" ||
        workOrder.scheduling.location.longitude === ""
      ) {
        dispatch(
          updateWorkOrderFormValidation({
            fieldName: "addressLine1",
            data: true,
          })
        );
        dispatch(
          showToast({
            message: "Address is required",
            type: "error",
          })
        );

        navigate(`/workOrder/create/scheduling`);
        return false;
      }
    } else if (workOrder.scheduling.location.type === "SAVED_LOCATION") {
      if (workOrder.scheduling.location.addressId === "") {
        dispatch(
          updateWorkOrderFormValidation({
            fieldName: "addressId",
            data: true,
          })
        );

        dispatch(
          showToast({
            message: "Address is required",
            type: "error",
          })
        );

        navigate(`/workOrder/create/scheduling`);
        return false;
      }
    }
    if (
      workOrder.payment.pay.hourlyRate === null ||
      workOrder.payment.pay.hourlyRate === ""
    ) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "hourlyRateErrorMsg",
          data: "Hourly rate is required",
        })
      );
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "hourlyRate",
          data: true,
        })
      );
      dispatch(
        showToast({
          message: "Hourly rate is required",
          type: "error",
        })
      );

      navigate(`/workOrder/create/payment`);
      return false;
    }
    if (workOrder.payment.pay.hourlyRate < 20) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "hourlyRateErrorMsg",
          data: "Hourly rate cannot be less than $20",
        })
      );
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "hourlyRate",
          data: true,
        })
      );
      dispatch(
        showToast({
          message: "Hourly rate cannot be less than $20",
          type: "error",
        })
      );

      navigate(`/workOrder/create/payment`);
      return false;
    }
    if (
      workOrder.payment.pay.minimumHours === null ||
      workOrder.payment.pay.minimumHours === "" ||
      workOrder.payment.pay.minimumHours < 1
    ) {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "minimumHours",
          data: true,
        })
      );
      dispatch(
        showToast({
          message: "Minimum 1 hour is required",
          type: "error",
        })
      );

      navigate(`/workOrder/create/payment`);
      return false;
    } else if (workOrder.scheduling.schedule.orderStartDate === "") {
      dispatch(
        updateWorkOrderFormValidation({
          fieldName: "orderStartDate",
          data: true,
        })
      );

      dispatch(
        showToast({
          message: "Schedule is required.",
          type: "error",
        })
      );

      navigate(`/workOrder/create/scheduling`);
      return false;
    }

    return true;
  };

  const isPublishedButtonDisabled = () => {
    if (workOrder.summary.overview.title === "") {
      return false;
    }
    if (workOrder.scheduling.location.type === "EXACT_LOCATION") {
      if (
        workOrder.scheduling.location.latitude === "" ||
        workOrder.scheduling.location.longitude === ""
      ) {
        return false;
      }
    } else if (workOrder.scheduling.location.type === "SAVED_LOCATION") {
      if (workOrder.scheduling.location.addressId === "") {
        return false;
      }
    }
    if (
      workOrder.payment.pay.hourlyRate === null ||
      workOrder.payment.pay.hourlyRate === ""
    ) {
      return false;
    }
    if (workOrder.payment.pay.hourlyRate < 20) {
      return false;
    }
    if (
      workOrder.payment.pay.minimumHours === null ||
      workOrder.payment.pay.minimumHours === "" ||
      workOrder.payment.pay.minimumHours < 1
    ) {
      return false;
    }
    if (
      workOrder.scheduling.schedule.orderStartDate === "" ||
      workOrder.scheduling.schedule.orderStartTime === ""
    ) {
      return false;
    }
    if (workOrder.payment.tasks.length > 0) {
      let error = true;
      workOrder.payment.tasks.forEach((task) => {
        if (task.taskType === "CUSTOM") {
          console.log("here error");
          error = false;
        }
      });

      return error;
    }

    return true;
  };

  const formatWorkOrderPayload = (isDraft) => {
    const formatedWorkOrderServices =
      workOrder.summary.workExperience?.workOrderServices.map((service) => {
        return { serviceId: service };
      });

    let location =
      workOrder.scheduling.location.type === "SAVED_LOCATION"
        ? { addressId: workOrder.scheduling.location.addressId }
        : workOrder.scheduling.location.type === "REMOTE"
        ? { isRemote: true }
        : { ...workOrder.scheduling.location, addressId: null };

    const payload = {
      ...workOrder.summary.overview,

      incidentId: workOrder.summary.overview.incidentId,
      ...workOrder.summary.serviceDescription,
      categoryId: workOrder.summary.workExperience.categoryId,
      vendorId: workOrder.summary.workExperience.vendorId,
      workOrderServices: formatedWorkOrderServices,
      ...workOrder.scheduling.schedule,
      timeZone: workOrder.scheduling.schedule.timeZone,
      ...location,
      qualificationSubTypes: formatWorkOrderQualifications(
        workOrder.scheduling.qualifications
      ),
      expenses: formatWorkOrderExpenses(workOrder.payment.expenses),
      paymentType: workOrder.payment.pay.paymentType,
      paymentTerms: workOrder.payment.pay.paymentTerms,
      hourlyRate: parseFloat(workOrder.payment.pay?.hourlyRate),
      minimumHours: parseInt(workOrder.payment.pay?.minimumHours),

      isDraft,
      tasks: formatWorkOrderTasks(workOrder.payment.tasks),
      contacts: formatWorkOrderContacts(workOrder.scheduling.contacts.contacts),
      workOrderManagerId: parseInt(
        workOrder.scheduling.contacts.workOrderManagerId
      ),
    };

    return payload;
  };

  const formatWorkOrderTasks = (tasksList) => {
    return tasksList.map((task) => {
      const {
        taskType,
        emailsToNotify,
        phone,
        reason,
        signatureFrom,
        title,
        ...rest
      } = task;
      const sanitizeEmails = emailsToNotify.map((emailObj) => emailObj.email);

      return {
        taskType,
        phone,
        reason,
        signatureFrom,
        title,
        emailsToNotify: sanitizeEmails,
      };
    });
  };

  const formatWorkOrderContacts = (contacts) => {
    return contacts
      ?.filter((contact) => contact.phoneNumber)
      .map((contact) => {
        const { name, phoneNumber, note, email, position } = contact;
        return {
          name,
          phoneNumber,
          note,
          email,
          position,
        };
      });
  };

  const formatWorkOrderExpenses = (expenses) => {
    return expenses
      ?.filter((expense) => expense.expenseType)
      ?.map((expense) => {
        const { expenseType, description, amount } = expense;
        return {
          expenseType,
          description,
          amount: parseFloat(amount),
        };
      });
  };

  const formatWorkOrderQualifications = (qualifications) => {
    const allSubTypes = qualifications
      .map((qualification) => qualification.qualificationSubTypes)
      .flat(); // Flatten the nested arrays

    return allSubTypes; // Return the flattened array
  };

  return (
    <div
      className={`border-b border-zinc-300 bg-white  sticky z-10 ${
        isBannerVisible ? "top-[94px]" : "top-[56px]"
      }`}
    >
      <BaseLoader isLoading={isPublishLoading} />
      <BaseWrapper>
        <div className="grid grid-cols-7 py-[7px] items-center ">
          <div className="col-span-2">
            <h3 className="font-light">
              {isEdit ? "Edit Work Order" : "New Work Order"}
            </h3>
          </div>
          <div className="col-span-5 flex  items-center justify-end">
            {/* <div className="flex items-center justify-between bg-[#F6F6F6] p-3 rounded-lg w-52 mr-4 ">
              <BaseSwitch />
              <p>Advanced Fields</p>
            </div> */}
            {/* <div className="mr-4">
              <BaseButton
                variant="contained"
                color="primary"
                style={{
                  border: "1px solid #0F40AE",
                }}
                className="bg-[#F6F6F6] pt-2 pb-2"
              >
                <p className="text-primary text-sm">Auto Dispatch</p>
              </BaseButton>
            </div> */}
            {!isEdit && (
              <div className="mr-4">
                <BaseButton
                  onClick={() => {
                    handleWorkOrderPublish(true);
                  }}
                  style={{
                    border: isPublishedButtonDisabled() && "1px solid #0F40AE",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                  className="bg-[#F6F6F6]"
                  disabled={!isPublishedButtonDisabled()}
                >
                  <p
                    className={`${
                      !isPublishedButtonDisabled() ? "" : "text-primary"
                    }`}
                  >
                    Save As Draft
                  </p>
                </BaseButton>
              </div>
            )}
            <div>
              <BaseButton
                isLoading={isPublishLoading}
                variant="contained"
                color="primary"
                style={{
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                }}
                onClick={() => {
                  handleWorkOrderUpdateOrPublish(false);
                }}
                disabled={!isEdit && !isPublishedButtonDisabled()}
              >
                <p
                  className={
                    !isEdit && !isPublishedButtonDisabled() ? "" : "text-white"
                  }
                >
                  {isEdit ? "Update" : "Publish"}
                </p>
              </BaseButton>
            </div>
          </div>
        </div>
      </BaseWrapper>
    </div>
  );
};

export default WorkOrderHeader;
