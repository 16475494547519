import React, { useState } from "react";
import BaseTextField from "../../components/ui/BaseTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseButton from "../../components/ui/BaseButton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../services/api/auth";
import { useMutation } from "@tanstack/react-query";
import { showToast } from "../../store/toast/toastSlice";

import { useDispatch } from "react-redux";
import { setLoginModalOpen } from "../../store/modalsManagement/modalsManagementSlice";

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character (@, $, !, %, *, ?, &)"
    )
    .test(
      "no-spaces",
      "Password cannot contain spaces",
      (value) => !/\s/.test(value) // Rejects passwords with spaces
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTokenError, setIsTokenError] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const {
    isLoading: isResetPasswordLoading,
    error: isResetPasswordError,
    mutateAsync: resetPasswordMutation,
  } = useMutation({
    mutationFn: resetPassword,
  });
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      let payload = {
        newPassword: values.newPassword,
        resetToken: token,
      };
      loginButtonClicked(payload);
    },
    validateOnChange: true,
    validateOnBlur: false,
  });

  const loginButtonClicked = async (payload) => {
    try {
      const resetPasswordResponse = await resetPasswordMutation(payload);
      dispatch(
        showToast({
          style: {
            width: "350px",
          },
          position: "top-center",
          message: resetPasswordResponse?.message,
        })
      );

      navigate("/company-signup");

      dispatch(setLoginModalOpen(true));
    } catch (error) {
      setIsTokenError(true);
      dispatch(
        showToast({
          style: {
            width: "350px",
          },
          position: "top-center",
          message: error?.response?.data?.message,
        })
      );
    }
  };

  return (
    <form>
      <div className="bg-white rounded-lg py-8 px-20 shadow-lg">
        <h4 className="font-500 text-center mb-2">Create New Password</h4>
        <p className="text-base text-center mb-4">
          Please Enter your new password
        </p>
        <BaseTextField
          type="password"
          required
          label="New Password"
          id="newPassword"
          name="newPassword"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
        <div className="mt-4">
          <BaseTextField
            type="password"
            required
            label="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </div>
        <BaseButton
          isLoading={isResetPasswordLoading}
          loaderSize="22px"
          type="submit"
          className="mt-5 text-sm py-[10px] rounded-md"
          fullWidth={true}
          onClick={formik.handleSubmit}
        >
          Reset
        </BaseButton>

        {isTokenError && (
          <Link to="/forgotPassword">
            <p className="text-secondary text-center mt-4">
              Back to Forgot Password
            </p>
          </Link>
        )}
      </div>
    </form>
  );
};

export default ResetPasswordForm;
