import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import SidebarNav from "./SidebarNav";
import useAuth from "../hooks/useAuth";
import TopBar from "./TopBar";
import NotificationBar from "../components/NotificationBar";
import { LayoutProvider } from "../context/LayoutContext";

const excludeNavbarPaths = ["/forgotPassword", "/resetPassword", "/otp"];

export const Layout = () => {
  const { isAuthenticated } = useAuth();
  const [isBannerVisible, setBannerVisible] = useState(true); // Track notification bar state

  const location = useLocation();
  return (
    <LayoutProvider>
      <main className="bg-white ">
        {!isAuthenticated &&
          !excludeNavbarPaths.includes(location.pathname) && <Navbar />}

        {isAuthenticated ? (
          <div className="flex justify-center">
            <NotificationBar setBannerVisible={setBannerVisible} />
            <div className="relative ml-[96px] w-[95%]">
              <TopBar isBannerVisible={isBannerVisible} />
              {/* <div className="absolute top-14 mt-8"> */}
              <Outlet />
              {/* </div> */}
            </div>
            <SidebarNav />
          </div>
        ) : (
          <Outlet />
        )}
      </main>
    </LayoutProvider>
  );
};
