import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import useAuth from "./useAuth";
import { useSocket } from "../context/SocketContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { fetchFreelancerJobs, findJobs } from "../services/api/workOrder";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export function useWorkOrderSocket(workOrderId) {
  const { auth, authRole } = useAuth();
  const socket = useSocket();
  const [jobsFilters, setJobsFilters] = useState({
    status: "AVAILABLE",
    isCounter: false,
  });
  const queryClient = useQueryClient();
  const engineerFilters = useSelector((state) => state.filters.value);
  const [workOrderUpdates, setWorkOrderUpdates] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [userAllowedActionUpdates, setUserAllowedActionUpdates] =
    useState(null);
  const [accessRevoked, setAccessRevoked] = useState(false);

  const { mutateAsync: fetchFreelancerJobsMutation } = useMutation({
    mutationFn: fetchFreelancerJobs,
  });

  const {
    isPending: isAvailableJobsLoading,
    error: isWorkOrderError,
    data: jobsData,
    refetch: fetchJobs,
  } = useQuery({
    queryKey: ["findJobs"],
    queryFn: () =>
      findJobs({
        latitude: engineerFilters?.coverageAreaCoordinates?.latitude,
        longitude: engineerFilters?.coverageAreaCoordinates?.longitude,
        radius: engineerFilters?.coverageAreaRadius,
      }),
    enabled: !!engineerFilters?.coverageAreaCoordinates.latitude,
  });

  useEffect(() => {
    async function getFreelancerJobs() {
      try {
        const response = await fetchFreelancerJobsMutation({
          latitude: engineerFilters?.coverageAreaCoordinates?.latitude,
          longitude: engineerFilters?.coverageAreaCoordinates?.longitude,
          radius: engineerFilters?.coverageAreaRadius,
        });
        setJobs(response.data);
      } catch (error) {
        console.error("Error fetching freelancer jobs:", error);
      }
    }
    if (engineerFilters?.coverageAreaCoordinates?.latitude) {
    }

    if (jobsFilters.status !== "AVAILABLE") {
      getFreelancerJobs();
    }
  }, [, fetchFreelancerJobsMutation]);

  useEffect(() => {
    if (jobsData?.data) {
      setJobs(jobsData.data);
    }
  }, [jobsData]);

  useEffect(() => {
    if (!auth?.user?.id || !authRole || !workOrderId) return;

    socket.emit("subscribeWorkOrder", {
      engineerId: auth.user.id,
      workOrderId,
    });

    socket.on(`workOrder:update:${workOrderId}`, (workOrderData) => {
      console.log("🔄 Work Order Update Received:", workOrderData);

      setWorkOrderUpdates((prev) => ({
        ...prev,
        ...workOrderData.updatedWorkOrder,
      }));

      setUserAllowedActionUpdates((prev) => ({
        ...prev,
        ...workOrderData.updatedUserAllowedAction,
      }));
    });
  }, [auth, workOrderId]);

  useEffect(() => {
    if (!socket || !auth?.user?.id) return;

    // ✅ Subscribe user to their own WebSocket channel
    socket.emit("subscribeUser", { userId: auth.user.id });

    // Listen for updates on the jobs list.
    socket.on("workOrder:list:update", (updatePayload) => {
      const { id, status } = updatePayload;
      if (jobsFilters.status == "AVAILABLE") {
        setJobs((prevJobs) => prevJobs.filter((job) => job.id !== id));
      } else {
        setJobs((prevJobs) =>
          prevJobs.map((job) =>
            job.id === id ? { ...job, ...updatePayload } : job
          )
        );
      }

      console.log(jobsFilters);
      // Otherwise, merge the update into the existing job details.
    });

    socket.on("workOrder:list:remove", (workOrder) => {
      console.log("remove ", workOrder);
      setJobs((prevJobs) => prevJobs.filter((job) => job.id !== workOrder.id));
    });

    // ✅ Listen for work order updates
    socket.on("workOrder:published", (newWorkOrder) => {
      setJobs((prevJobs) => [newWorkOrder, ...prevJobs]);
    });

    return () => {
      socket.off("workOrder:list:remove");
      socket.off("workOrder:list:update");
      socket.off("workOrder:published");
    };
  }, [socket, auth]);

  return {
    workOrderUpdates,
    userAllowedActionUpdates,
    accessRevoked,
    socket,
    jobs,
    setJobsFilters,
    jobsFilters,
    isAvailableJobsLoading,
  };
}
