// features/toastSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid"; // Import UUID library

const toastSlice = createSlice({
  name: "toast",
  initialState: [],
  reducers: {
    showToast: (state, action) => {
      const { message, type, position, autoClose, hideProgressBar, style } =
        action.payload;
      const id = uuidv4(); // Generate a unique ID for each toast

      // Display the toast notification
      switch (type) {
        case "success":
          toast.success(message, {
            position,
            autoClose,
            hideProgressBar,
            toastId: id,
            style,
          });
          break;
        case "error":
          toast.error(message, {
            position,
            autoClose,
            hideProgressBar,
            toastId: id,
            style,
          });
          break;
        case "info":
          toast.info(message, {
            position,
            autoClose,
            hideProgressBar,
            toastId: id,
            style,
          });
          break;
        case "warning":
          toast.warn(message, {
            position,
            autoClose,
            hideProgressBar,
            toastId: id,
            style,
          });
          break;
        default:
          toast(message, {
            style,
            position,
            autoClose,
            hideProgressBar,
            toastId: id,
          });
      }

      // Optionally, store the toast details in the state
      state.push({
        id,
        style,
        message,
        type,
        position,
        autoClose,
        hideProgressBar,
      });
    },
    removeToast: (state, action) => {
      // Remove toast by ID if needed
      return state.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const { showToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;
