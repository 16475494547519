import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useParams } from "react-router-dom";
import BaseRating from "../../../components/ui/BaseRating";

const Reliability = () => {
  const { id: userId } = useParams();

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark font-normal">Reliability</h6>
      </div>

      <div className="grid grid-cols-2 gap-x-12">
        <BaseCard className="py-5 px-4 mt-2 rounded-lg" elevation={1}>
          <div className="flex">
            <i class="fa-solid fa-clock-rotate-left text-primary"></i>
            <div className="flex items-center w-full">
              <div className="ml-4 w-full">
                <div className="flex items-center ">
                  <p className="font-normal">Timeliness Rate (≥ 90% target)</p>
                  <div className="border-t border-dotted border-gray-500 flex-grow mx-6"></div>
                  <p className="font-normal">85%</p>
                </div>
                <p>11 of last 13 "Hard-Start" work orders checked in on-time</p>
                <p className="text-primary">See Check-in Details</p>
              </div>
            </div>
          </div>
        </BaseCard>
        <BaseCard className="py-5 px-4 mt-2 rounded-lg" elevation={1}>
          <div className="flex">
            <i class="fa-regular fa-circle-xmark text-primary"></i>
            <div className="flex items-center w-full">
              <div className="ml-3 w-full">
                <div className="flex items-center ">
                  <p className="font-normal">Cancellation Rate (≤ 5% target)</p>
                  <div className="border-t border-dotted border-gray-500 flex-grow mx-6"></div>
                  <p className="font-normal">13%</p>
                </div>
                <p>4 cancellations of last 30 assigned work orders</p>
                <p className="text-primary">See cancellations / removals</p>
              </div>
            </div>
          </div>
        </BaseCard>
      </div>
    </BaseCard>
  );
};

export default Reliability;
