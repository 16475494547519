import React, { useRef, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import BaseAutoSelect from "../../../components/ui/BaseAutoSelect";
import BaseTextField from "../../../components/ui/BaseTextField";
import BaseDatePicker from "../../../components/ui/BaseDatePicker";
import {
  fetchQualificationTypes,
  fetchSubQualificationTypes,
} from "../../../services/api/qualification";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

const AddLicenseAndCertificationModal = ({
  open,
  close,
  formik,
  isCreateCertificationLoading,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const {
    data: qualificationsData,
    isPending: isQualificationsLoading,
    error: isQualificationsError,
  } = useQuery({
    queryKey: ["qualifications"],
    queryFn: fetchQualificationTypes,
  });

  const licences = qualificationsData?.data?.find((q) => q.name === "Licences");
  const certifications = qualificationsData?.data?.find(
    (q) => q.name === "Certification"
  );

  const {
    data: licencesData,
    isPending: isLicencesLoading,
    error: isLicencesError,
  } = useQuery({
    queryKey: ["licences", licences?.id],
    queryFn: () => fetchSubQualificationTypes(licences?.id),
    enabled: !!licences?.id,
  });

  const {
    data: certificationsData,
    isPending: isCertificationsLoading,
    error: isCertificationsError,
  } = useQuery({
    queryKey: ["certifications", certifications?.id],
    queryFn: () => fetchSubQualificationTypes(certifications?.id),
    enabled: !!certifications?.id,
  });
  const mergedQualifications = [
    ...(Array.isArray(licencesData?.data) ? licencesData.data : []),
    ...(Array.isArray(certificationsData?.data) ? certificationsData.data : []),
  ];
  const formattedQualifications = mergedQualifications?.map((qualification) => {
    return {
      label: qualification?.name,
      value: qualification?.id,
    };
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target?.files[0];
    formik.setFieldValue("file", file);
    setSelectedFile([file?.name]);
  };

  return (
    <form>
      <BaseModal
        className="-top-[200px] overflow-scroll"
        open={open}
        close={close}
        style={{ width: "1000px" }}
      >
        <div className="py-7 px-12">
          <h6 className="text-dark font-normal border-b pb-1">
            Select License or Certification
          </h6>

          <BaseAutoSelect
            isDefaultField={false}
            placeholder="Search"
            className="mt-6"
            options={formattedQualifications}
            id="qualificationSubTypeId"
            name="qualificationSubTypeId"
            value={formik.values.qualificationSubTypeId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.qualificationSubTypeId &&
              Boolean(formik.errors.qualificationSubTypeId)
            }
            helperText={
              formik.touched.qualificationSubTypeId &&
              formik.errors.qualificationSubTypeId
            }
          />
        </div>

        <div className="px-12 grid grid-cols-2 gap-x-10 gap-y-4">
          <div className="col-span-2">
            <BaseTextField
              label="Certification Name"
              isDefaultField={false}
              labelShrink={false}
              optional={false}
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>

          <div>
            <p className="font-normal">Start Date</p>
            <BaseDatePicker
              className="border-none"
              type="date"
              id="startDate"
              name="startDate"
              value={dayjs(formik.values.startDate)}
              onChange={(event) => {
                const selectedDate = dayjs(event)
                  .utcOffset(0, true)
                  .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                formik.setFieldValue("startDate", selectedDate);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      border: "none !important",
                      boxShadow: "1px 1px 4px 1px #00000021",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      margin: "8px 1.5px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none !important",
                        boxShadow: "none",
                      },
                    "& .MuiInputBase-input": {
                      padding: "10px 14px",
                      fontSize: "13px",
                      height: "100% !important",
                      lineHeight: "1.5",
                    },

                    "& .MuiIconButton-root": {
                      backgroundColor: "white !important",
                      borderLeft: "none !important",
                    },
                  },
                },
              }}
            />
          </div>
          <div>
            <p className="font-normal">End Date</p>
            <BaseDatePicker
              className="border-none"
              type="date"
              id="endDate"
              name="endDate"
              value={dayjs(formik.values.endDate)}
              onChange={(event) => {
                const selectedDate = dayjs(event)
                  .utcOffset(0, true)
                  .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                formik.setFieldValue("endDate", selectedDate);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      border: "none !important",
                      boxShadow: "1px 1px 4px 1px #00000021",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      margin: "8px 1.5px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none !important",
                        boxShadow: "none",
                      },
                    "& .MuiInputBase-input": {
                      padding: "10px 14px",
                      fontSize: "13px",
                      height: "100% !important",
                      lineHeight: "1.5",
                    },

                    "& .MuiIconButton-root": {
                      backgroundColor: "white !important",
                      borderLeft: "none !important",
                    },
                  },
                },
              }}
            />
          </div>
          <div>
            <p className="font-normal mb-2">Upload JPG, PNG</p>
            <div className="w-full flex items-center">
              {/* Hidden File Input */}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file"
                name="file"
                accept=".pdf,.jpg,.png"
                onBlur={formik.handleBlur}
                error={formik.touched.file && Boolean(formik.errors.file)}
                helperText={formik.touched.file && formik.errors.file}
              />

              {/* File Display Section */}
              <div
                style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
                className="w-full flex justify-between items-center px-3 py-1 rounded-md"
              >
                <div className="w-full py-[6.25px] rounded-md flex flex-wrap">
                  {selectedFile ? (
                    selectedFile.map((file, index) => (
                      <p key={index} className="py-1 mr-2">
                        {file}
                      </p>
                    ))
                  ) : (
                    <p className="text-gray-400 py-1">No file selected</p>
                  )}
                </div>
                <BaseButton
                  variant="outlined"
                  color="primary"
                  className="w-[160px] py-[4px] ml-4"
                  onClick={handleButtonClick}
                >
                  Choose File
                </BaseButton>
              </div>
            </div>
            {formik.errors.file && (
              <p className="text-red-600 text-xs mt-1.5">
                {formik.errors.file}
              </p>
            )}
          </div>
        </div>

        <div
          className="d-flex content-center bg-white py-6 mt-6"
          style={{
            boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
          }}
        >
          <BaseButton
            className=" px-[28px] py-[5px] bg-light text-primary hover:text-primary"
            variant="outlined"
            color="primary"
            style={{
              marginRight: "16px",
            }}
            onClick={() => close(false)}
          >
            Cancel
          </BaseButton>

          <BaseButton
            isLoading={isCreateCertificationLoading}
            loaderSize={24}
            loaderColor="primary"
            className="px-[20px] py-[5px]"
            color="primary"
            onClick={() => {
              formik.handleSubmit();
              setSelectedFile(null);
            }}
          >
            Save Changes
          </BaseButton>
        </div>
      </BaseModal>
    </form>
  );
};

export default AddLicenseAndCertificationModal;
