import React, { useState } from "react";
import BaseTextField from "../../components/ui/BaseTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import BaseButton from "../../components/ui/BaseButton";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/api/auth";
import { useMutation } from "@tanstack/react-query";
import { showToast } from "../../store/toast/toastSlice";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie";
import SuccessCheck from "../../assets/animations/success-check.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessCheck,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [isRecoveryEmailSent, setIsRecoveryEmailSent] = useState(false);
  const {
    isPending: isForgotPasswordLoading,
    error: isForgotPasswordError,
    data: forgotPasswordData,
    mutateAsync: forgotPasswordMutation,
  } = useMutation({
    mutationFn: forgotPassword,
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendRecoveryButtonClicked(values);
    },
    validateOnChange: true,
    validateOnBlur: false,
  });

  const sendRecoveryButtonClicked = async (payload) => {
    try {
      const forgotPasswordResponse = await forgotPasswordMutation(payload);
      setIsRecoveryEmailSent(true);
      dispatch(
        showToast({
          // type:"success",
          style: {
            width: "350px",
          },
          position: "top-center",
          message: forgotPasswordResponse?.message,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form>
      <div className="bg-white rounded-lg py-8 px-20 shadow-lg">
        {isRecoveryEmailSent ? (
          <div>
            <Lottie options={defaultOptions} height={150} width={300} />

            <p className="text-base text-center mb-4 px-20">
              A password recovery email has been sent to your registered email
              address <span className="font-500">{formik.values.email}</span>,
              Please check your inbox and follow the instructions provided.
            </p>

            <div className="flex mt-5 justify-center">
              <Link to="/company-signup" className="text-secondary text-sm">
                Return to login
              </Link>

              <p
                className="text-secondary text-sm ml-8 font-400 cursor-pointer"
                onClick={() => setIsRecoveryEmailSent(false)}
              >
                Return to Recovery Link
              </p>
            </div>
          </div>
        ) : (
          <div>
            <h4 className="font-500 text-center mb-2">Forget Password</h4>
            <p className="text-base text-center mb-4">
              No worries, we'll send you reset instructions
            </p>
            <BaseTextField
              label="Enter your registered email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <div className="flex flex-col items-center">
              <BaseButton
                loaderSize="20px"
                isLoading={isForgotPasswordLoading}
                type="submit"
                className="mt-5 text-sm py-[10px] rounded-md"
                fullWidth={true}
                onClick={formik.handleSubmit}
              >
                Send recovery link
              </BaseButton>
              <Link
                to="/company-signup"
                className="text-secondary mt-5 text-sm"
              >
                Return to login
              </Link>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
