import React from "react";
import BaseDropdown from "../../../components/ui/BaseDropdown";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useNotificationsSocket } from "../../../hooks/useNotificationsSocket";

const MessageNotifications = ({
  isMessageNotificationDropdownOpen,
  setIsMessageNotificationDropdownOpen,
}) => {
  const navigate = useNavigate();
  const { chatNotifications, markAsRead } = useNotificationsSocket();

  return (
    <BaseDropdown
      setOpen={setIsMessageNotificationDropdownOpen}
      open={isMessageNotificationDropdownOpen}
      className="w-[526px] top-[40px] shadow-none !-left-[360px] !px-0 !py-0 "
      style={{
        boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="max-h-[500px] overflow-scroll relative rounded-lg">
        <div className="flex items-center justify-between border-b p-3 top-0 sticky bg-white z-10 ">
          <p className="text-base text-dark font-normal">Messages</p>
        </div>
        {chatNotifications?.map((notification) => (
          <MessageNotification
            key={notification?.id}
            type={notification.type}
            createdAt={dayjs(notification.createdAt).fromNow()}
            content={notification.content}
            isRead={notification.isRead}
            sender={notification?.sender}
            workOrderId={notification.workOrderId}
            onClick={() => {
              markAsRead.mutate(notification.id);
              navigate(notification?.url);
              setIsMessageNotificationDropdownOpen(false);
            }}
          />
        ))}
      </div>
    </BaseDropdown>
  );
};

export default MessageNotifications;

const MessageNotification = ({
  createdAt,
  content,
  isRead,
  onClick,
  sender,
  workOrderId,
}) => {
  return (
    <div
      className={`flex px-4 py-5 border-b cursor-pointer ${
        !isRead && "bg-light"
      }`}
      onClick={onClick}
    >
      <Avatar src="https://img.freepik.com/free-photo/i-know-exactly-what-i-want-headshot-attractive-young-african-american-student-stylish-glasses-having-serious-calm-face-expression-feeling-confident-about-his-future-plans-career_273609-179.jpg?t=st=1740046245~exp=1740049845~hmac=8171104a0a21c5b599db5a6b1b39a7d9cd9a79aa9c274aec793c80066b37467e&w=2000"></Avatar>
      <div className="ml-3 w-full">
        <div className="flex w-full justify-between">
          <div className="flex items-center">
            <p className="font-normal text-dark">
              {sender?.firstName} {sender?.lastName}{" "}
            </p>
            <p className="text-xs ml-2">WO-{workOrderId}</p>
          </div>
          <p className="text-[10px] text-[#A5A5A5]">{createdAt}</p>
        </div>
        <p className="text-xs">{content}</p>
      </div>
    </div>
  );
};
