export const EducationType = Object.freeze({
  HIGH_SCHOOL: "High School",
  BACHELORS_DEGREE: "Bachelor",
  MASTERS_DEGREE: "Master",
  CERTIFICATION: "Certification",
});

export const InsuranceType = Object.freeze({
  PUBLIC_LIABILITY_PROFESSIONAL_INDEMNITY:
    "Public Liability / Professional Indemnity",
  WORKER_COMPENSATION: "Worker Compensation",
  OTHER: "Other",
});
