import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useQuery } from "@tanstack/react-query";
import { fetchUserQualifications } from "../../../services/api/user";
import { useParams } from "react-router-dom";

const Equipments = () => {
  const { id: userId } = useParams();
  const [searchQuery, setSearchQuery] = useState(""); // ✅ Search state
  const [expanded, setExpanded] = useState(false); // ✅ Expand toggle state
  const MAX_ITEMS = 5; // ✅ Limit initial items shown

  const {
    isPending: isUserQualificationsLoading,
    error: isUserQualificationsError,
    refetch: refetchUserQualifications,
    data: userQualificationsData,
  } = useQuery({
    queryKey: ["userQualificationsData", userId],
    queryFn: () => fetchUserQualifications(userId),
    retry: 2,
    enabled: !!userId,
  });

  // ✅ Filtered Equipment Based on Search Query
  const filteredEquipments = userQualificationsData?.data?.filter((equipment) =>
    equipment?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  console.log(filteredEquipments);

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Equipments</h6>
      </div>

      {/* Search Input */}
      <TextField
        placeholder="Search Equipments"
        className="bg-light rounded-md py-1.5 w-64"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="fa-solid fa-search text-[#B0B0B0]"></i>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "transparent" },
            "&:hover fieldset": { borderColor: "transparent" },
            "&.Mui-focused fieldset": { borderColor: "transparent" },
          },
        }}
      />

      {/* Equipment List */}
      <div
        className={`flex flex-wrap mt-5 overflow-hidden ${
          expanded ? "h-auto" : ""
        }`}
      >
        {(expanded
          ? filteredEquipments
          : filteredEquipments?.slice(0, MAX_ITEMS)
        )?.map((equipment, index) => (
          <p
            key={index}
            className="bg-light py-1 px-4 rounded-md mr-4 mb-2 whitespace-nowrap cursor-pointer"
          >
            {equipment?.name}
          </p>
        ))}
      </div>

      {/* View More / View Less Button */}
      {filteredEquipments?.length > MAX_ITEMS && (
        <p
          className="text-primary text-sm cursor-pointer mt-2"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "View Less" : "View More"}
        </p>
      )}

      {/* No Equipment Found */}
      {filteredEquipments?.length === 0 && (
        <p className="text-gray-500 mt-2">No equipment found</p>
      )}
    </BaseCard>
  );
};

export default Equipments;
