import React from "react";

const BaseNotFound = ({ message }) => {
  return (
    <div className="flex justify-center flex-col items-center mt-28">
      <svg
        width="200"
        // height="697"
        viewBox="0 0 853 697"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M712.757 432.679C712.757 436.609 714.318 440.379 717.097 443.156C719.877 445.936 723.645 447.496 727.575 447.496C796.459 447.496 852.302 503.339 852.302 572.226C852.302 605.305 839.161 637.03 815.771 660.422C792.379 683.813 760.655 696.955 727.575 696.955H124.729C55.8446 696.955 0 641.112 0 572.226C0 539.146 13.1415 507.421 36.5331 484.029C59.9248 460.638 91.6494 447.496 124.729 447.496C128.659 447.496 132.429 445.936 135.207 443.156C137.986 440.377 139.547 436.609 139.547 432.679V431.433C139.547 419.328 133.709 407.966 123.869 400.916C119.395 397.718 115.163 394.139 111.223 390.199C92.3149 371.291 81.6934 345.649 81.6934 318.91C81.6934 292.171 92.3149 266.527 111.223 247.621C130.13 228.713 155.772 218.092 182.512 218.092H669.791C696.53 218.092 722.172 228.713 741.08 247.621C759.987 266.529 770.609 292.171 770.609 318.91C770.609 345.649 759.987 371.291 741.08 390.199C737.003 394.275 732.613 397.968 727.966 401.251C718.428 407.995 712.757 418.949 712.757 430.63C712.755 431.317 712.755 432.001 712.755 432.679H712.757Z"
          fill="#E4F0FC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M781.585 48.8901C820.64 48.8901 852.302 80.5518 852.302 119.607C852.302 138.362 844.852 156.349 831.589 169.612C818.327 182.873 800.339 190.326 781.583 190.326H439.788C400.73 190.326 369.071 158.664 369.071 119.609C369.071 100.853 376.521 82.8668 389.784 69.6036C403.045 56.3404 421.034 48.8901 439.79 48.8901H781.585Z"
          fill="#E4F0FC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M120.095 163.402C127.968 149.473 145.666 144.559 159.595 152.431C173.523 160.303 178.44 178.002 170.565 191.931C162.693 205.859 144.994 210.775 131.066 202.901C117.137 195.029 112.221 177.33 120.095 163.402ZM126.405 166.966C132.309 156.521 145.585 152.833 156.03 158.737C166.475 164.641 170.163 177.917 164.257 188.362C158.353 198.807 145.078 202.495 134.633 196.591C124.188 190.687 120.499 177.411 126.403 166.966H126.405Z"
          fill="#89A9C5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M803.794 417.691C802.96 432.786 790.027 444.366 774.932 443.534C759.837 442.7 748.258 429.767 749.09 414.672C749.923 399.577 762.856 387.997 777.951 388.829C793.047 389.663 804.626 402.596 803.794 417.691ZM796.954 417.314C796.329 428.635 786.631 437.319 775.308 436.694C763.986 436.069 755.3 426.371 755.925 415.047C756.55 403.726 766.248 395.04 777.572 395.667C788.893 396.292 797.577 405.99 796.952 417.314H796.954Z"
          fill="#89A9C5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.5287 268.008C46.4811 261.014 55.3699 258.545 62.3637 262.499C69.3574 266.451 71.8267 275.34 67.8723 282.334C63.9199 289.328 55.0311 291.797 48.0373 287.843C41.0436 283.89 38.5743 275.001 42.5287 268.008Z"
          fill="#89A9C5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M776.419 222.34C775.967 230.533 768.949 236.817 760.756 236.366C752.563 235.914 746.279 228.896 746.73 220.703C747.182 212.51 754.2 206.226 762.393 206.677C770.586 207.129 776.87 214.147 776.419 222.34Z"
          fill="#89A9C5"
        />
        <path
          d="M115.396 424.307C115.14 424.762 114.712 425.094 114.209 425.234C113.706 425.374 113.168 425.307 112.714 425.052C110.253 423.66 107.339 423.303 104.614 424.06C101.889 424.816 99.5765 426.624 98.1866 429.085C98.144 429.163 98.1014 429.236 98.0608 429.309C97.6206 430.086 96.6345 430.36 95.8574 429.921C95.2934 429.603 94.6441 429.236 94.078 428.915C93.3009 428.475 93.027 427.489 93.4653 426.712C93.4693 426.705 93.4734 426.699 93.4754 426.691C96.4356 421.453 94.5893 414.806 89.3506 411.845C88.5613 411.399 88.2834 410.401 88.7297 409.612C89.0523 409.041 89.4216 408.386 89.7442 407.816C90.1906 407.029 91.1908 406.751 91.9781 407.195C97.2168 410.155 103.864 408.309 106.824 403.072C106.828 403.066 106.832 403.06 106.836 403.052C107.276 402.275 108.262 402.001 109.039 402.439C109.603 402.758 110.253 403.125 110.819 403.446C111.596 403.886 111.87 404.872 111.432 405.649C111.391 405.722 111.348 405.795 111.306 405.872C109.914 408.333 109.559 411.247 110.314 413.972C111.07 416.697 112.878 419.008 115.339 420.399C115.794 420.657 116.127 421.083 116.267 421.586C116.407 422.09 116.34 422.627 116.084 423.082C115.859 423.481 115.617 423.905 115.392 424.305L115.396 424.307Z"
          fill="#89A9C5"
        />
        <path
          d="M409.716 14.8423C409.747 14.2783 410.003 13.7487 410.423 13.3714C410.845 12.994 411.398 12.7992 411.962 12.8317C415.022 13.0001 418.023 11.947 420.305 9.90389C422.588 7.86074 423.966 4.99385 424.134 1.93421C424.14 1.83885 424.144 1.74755 424.15 1.65625C424.203 0.690469 425.031 -0.0501004 425.997 0.00265212C426.699 0.041202 427.506 0.0858462 428.208 0.124396C429.174 0.177149 429.915 1.00495 429.862 1.97073C429.862 1.97884 429.862 1.98696 429.862 1.99508C429.503 8.50595 434.488 14.0734 440.999 14.4325C441.979 14.4873 442.729 15.3252 442.675 16.3052C442.636 17.0133 442.591 17.8269 442.551 18.535C442.496 19.515 441.658 20.2657 440.68 20.2109C434.169 19.8518 428.602 24.8369 428.243 31.3478C428.243 31.3559 428.243 31.364 428.243 31.3721C428.19 32.3379 427.362 33.0785 426.396 33.0257C425.694 32.9872 424.887 32.9425 424.185 32.904C423.219 32.8512 422.478 32.0234 422.531 31.0576C422.535 30.9684 422.541 30.875 422.545 30.7797C422.714 27.72 421.661 24.7192 419.618 22.4367C417.575 20.1541 414.708 18.7765 411.648 18.6081C411.084 18.5776 410.554 18.322 410.177 17.902C409.8 17.48 409.605 16.9261 409.637 16.362C409.666 15.8649 409.694 15.3374 409.721 14.8423H409.716Z"
          fill="#89A9C5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M250.583 696.869L651.956 696.953L306.772 636.665C305.368 675.975 289.212 692.809 250.583 696.869Z"
          fill="#98B6D2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M651.956 153.991V696.955L306.772 636.667V643.744C306.772 657.857 301.166 671.39 291.188 681.37C281.21 691.349 267.674 696.957 253.561 696.957C239.448 696.957 225.915 691.351 215.934 681.37C205.956 671.392 200.35 657.857 200.35 643.744V48.8901H546.855L651.956 153.991Z"
          fill="#B3CEE6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M651.956 153.991H546.855V48.8901L651.956 153.991Z"
          fill="#98B6D2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M610.856 188.887V194.402H241.448V188.887H610.856Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M610.856 309.739V315.254H241.448V309.739H610.856Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M610.856 430.589V436.104H241.448V430.589H610.856Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M610.856 551.441V556.956H241.448V551.441H610.856Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M527.326 401.281L606 517.562L645.705 490.698L567.029 374.418L527.326 401.281Z"
          fill="#99B3CA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M523.731 395.967L548.837 433.074C563.464 427.432 576.696 418.472 588.539 406.211L563.433 369.104L523.731 395.967Z"
          fill="#7C98B0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M389.115 161.139C318.157 209.15 299.525 305.736 347.536 376.694C395.547 447.652 492.132 466.284 563.091 418.273C634.049 370.262 652.681 273.675 604.67 202.716C556.659 131.758 460.073 113.126 389.115 161.137V161.139Z"
          fill="#89A9C5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M346.835 230.154C347.901 228.106 347.105 225.58 345.058 224.515C343.011 223.45 340.485 224.245 339.42 226.293C334.305 236.115 330.531 246.596 328.216 257.431C326.919 263.497 326.079 269.675 325.716 275.91C325.582 278.213 327.343 280.194 329.646 280.327C331.949 280.461 333.929 278.7 334.063 276.397C334.402 270.588 335.185 264.832 336.392 259.18C338.551 249.082 342.067 239.31 346.835 230.158V230.154Z"
          fill="#CFE5F8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M405.975 166.321C408.045 165.303 408.899 162.795 407.879 160.723C406.86 158.654 404.35 157.8 402.281 158.82C378.126 170.714 359.567 189.624 347.942 211.942C346.876 213.989 347.672 216.515 349.719 217.58C351.766 218.646 354.292 217.85 355.357 215.803C366.188 195.012 383.474 177.397 405.975 166.319V166.321Z"
          fill="#CFE5F8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M408.231 189.394C352.868 226.853 338.332 302.214 375.791 357.577C413.249 412.941 488.61 427.476 543.974 390.018C599.338 352.56 613.873 277.199 576.415 221.835C538.956 166.471 463.595 151.936 408.231 189.394Z"
          fill="#E9F3FB"
        />
        <path
          d="M403.583 326.587L433.301 358.894L476.377 319.273L512.698 358.762L541.866 331.933L505.546 292.446L548.622 252.825L518.906 220.516L475.828 260.137L439.509 220.65L410.341 247.477L446.661 286.966L403.583 326.587Z"
          fill="#F15858"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M579.744 501.975C578.149 503.055 577.048 504.723 576.682 506.614C576.317 508.505 576.719 510.462 577.798 512.057C597.051 540.511 678.854 661.414 698.105 689.868C699.184 691.462 700.852 692.564 702.743 692.929C704.634 693.295 706.592 692.893 708.186 691.813C709.781 690.734 711.741 690.332 713.63 690.698C715.521 691.063 717.191 692.162 718.27 693.759C719.35 695.354 721.017 696.456 722.908 696.821C724.799 697.186 726.757 696.784 728.352 695.705C737.819 689.3 756.088 676.939 765.555 670.534C767.149 669.454 768.251 667.787 768.616 665.896C768.982 664.005 768.58 662.047 767.5 660.452C766.421 658.857 766.019 656.897 766.385 655.006C766.75 653.115 767.851 651.446 769.446 650.368C771.041 649.289 772.143 647.621 772.508 645.73C772.873 643.839 772.471 641.881 771.392 640.286C752.139 611.833 670.336 490.93 651.086 462.476C650.006 460.881 648.339 459.779 646.448 459.414C644.557 459.049 642.599 459.451 641.004 460.53C639.409 461.61 637.449 462.011 635.56 461.646C633.669 461.281 632 460.181 630.922 458.584C629.843 456.99 628.175 455.888 626.282 455.523C624.391 455.158 622.433 455.559 620.838 456.639C611.371 463.044 593.103 475.404 583.636 481.81C582.041 482.889 580.939 484.557 580.574 486.448C580.209 488.339 580.61 490.297 581.69 491.894C582.769 493.488 583.171 495.448 582.806 497.339C582.441 499.23 581.341 500.898 579.744 501.977V501.975Z"
          fill="#89A9C5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M606.577 518.412C604.958 516.02 601.701 515.391 599.307 517.01C596.915 518.629 596.286 521.885 597.905 524.279L667.159 626.638C668.778 629.03 672.034 629.659 674.429 628.04C676.821 626.42 677.45 623.164 675.831 620.77L606.577 518.412Z"
          fill="#D7EBFC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M690.699 642.741C689.08 640.349 685.823 639.72 683.429 641.339C681.037 642.958 680.408 646.215 682.027 648.609L694.241 666.661C695.86 669.053 699.117 669.682 701.511 668.063C703.903 666.443 704.532 663.187 702.913 660.793L690.699 642.741Z"
          fill="#D7EBFC"
        />
      </svg>
      <h6 className="text-center mt-3 ml-4">
        {message ? message : "No Data Found"}
      </h6>
    </div>
  );
};

export default BaseNotFound;
