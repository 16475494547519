import axios from "../../lib/axiosInstance";

export function uploadProfilePicture(file) {
  const formData = new FormData();
  formData.append("file", file);

  return axios
    .post("digitalAssets/profile", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data);
}
export function uploadIdDocuments(formData) {
  return axios
    .post("digitalAssets/identity", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data);
}
export function uploadUserEducation(formData) {
  return axios
    .post("digitalAssets/education", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data);
}
export function uploadUserWorkingRights(formData) {
  return axios
    .post("digitalAssets/workingRights", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data);
}

export function deleteDigitalAsset(id) {
  return axios.delete(`digitalAssets/${id}`).then((res) => res.data);
}
