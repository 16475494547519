import React, { useEffect, useRef, useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";

import {
  uploadUserEducation,
  uploadUserWorkingRights,
} from "../../../services/api/digitalAsset";
import { useMutation } from "@tanstack/react-query";
import { showToast } from "../../../store/toast/toastSlice";
import { useDispatch } from "react-redux";

const AddBackgroundVerificationModal = ({
  open,
  close,
  userBackgroundVerificationData,
  refetchUserBackgroundVerificationDocs,
}) => {
  const educationFileInputRef = useRef(null);
  const workingRightsFileInputRef = useRef(null);
  const dispatch = useDispatch(null);
  const [selectedEducationFile, setSelectedEducationFile] = useState();
  const [selectedWorkingRightsFile, setSelectedWorkingRightsFile] = useState();

  const {
    isPending: isUploadingEducationLoading,
    error: isUploadingEducationError,
    data: uploadedEducationData,
    mutateAsync: uploadUserEducationMutation,
  } = useMutation({
    mutationFn: uploadUserEducation,
    enabled: false,
  });
  const {
    isPending: isUploadingWorkingRightsLoading,
    error: isUploadingWorkingRightsError,
    data: uploadedWorkingRightsData,
    mutateAsync: uploadUserWorkingRightsMutation,
  } = useMutation({
    mutationFn: uploadUserWorkingRights,
    enabled: false,
  });

  /*  useEffect(() => {
    if (userBackgroundVerificationData) {
      setSelectedEducationFile(userBackgroundVerificationData?.data?.education);
      setSelectedWorkingRightsFile(
        userBackgroundVerificationData?.data?.workingRights
      );
    }
  }, [userBackgroundVerificationData]); */

  const handleWorkingRightsFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedWorkingRightsFile(file);
    }
  };
  const handleEducationFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedEducationFile(file);
    }
  };

  const saveButtonClicked = async () => {
    try {
      const educationFormData = new FormData();
      const workingRightsFormData = new FormData();

      // Conditionally append the education file if it exists
      if (selectedEducationFile) {
        educationFormData.append("file", selectedEducationFile);
      }

      // Conditionally append the working rights file if it exists
      if (selectedWorkingRightsFile) {
        workingRightsFormData.append("file", selectedWorkingRightsFile);
      }

      // Only call the education upload API if the education file is selected
      let educationResponse;
      if (selectedEducationFile) {
        educationResponse = await uploadUserEducationMutation(
          educationFormData
        );
      }

      // Only call the working rights upload API if the working rights file is selected
      let workingRightsResponse;
      if (selectedWorkingRightsFile) {
        workingRightsResponse = await uploadUserWorkingRightsMutation(
          workingRightsFormData
        );
      }

      // Dispatch success toast message
      dispatch(
        showToast({
          message: "Will be verified in two working days.",
          type: "success",
        })
      );

      refetchUserBackgroundVerificationDocs();

      // Close the modal and reset the files
      close(false);
      setSelectedEducationFile(null);
      setSelectedWorkingRightsFile(null);
    } catch (error) {
      dispatch(
        showToast({
          message: "Please verify the file format",
          type: "error",
        })
      );
    }
  };

  return (
    <BaseModal
      className="-top-[200px] overflow-scroll"
      open={open}
      close={close}
      style={{ width: "1000px" }}
    >
      <div className="py-7 px-12">
        <h6 className="text-dark font-normal border-b pb-1">
          Background Verifications
        </h6>
        <div className="grid grid-cols-2 gap-10">
          <div className="my-3">
            <p className="font-normal text-base mb-2">Education</p>
            <div className="w-full flex items-center">
              {/* Hidden File Input */}
              <input
                type="file"
                ref={educationFileInputRef}
                onChange={handleEducationFileChange}
                style={{ display: "none" }}
              />

              {/* File Display Section */}
              <div
                style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
                className="w-full flex justify-between items-center px-3 py-1 rounded-md"
              >
                <div className="w-full py-[6.25px] rounded-md flex flex-wrap">
                  {selectedEducationFile ? (
                    <a
                      href={selectedEducationFile.url} // Directly use existing file URL
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className="py-1 cursor-pointer block text-blue-500 hover:underline font-300"
                    >
                      {selectedEducationFile?.name || "Download File"}
                    </a>
                  ) : (
                    <p className="text-gray-400 py-1 ml-2">No file selected</p>
                  )}
                </div>
                <BaseButton
                  variant="outlined"
                  color="primary"
                  className="w-[160px] py-[4px] ml-4"
                  onClick={() => educationFileInputRef.current.click()}
                >
                  Choose File
                </BaseButton>
              </div>

              {/* Choose File Button */}
            </div>
            <p className="mt-1">
              Please upload your education certificate (JPG, PNG, PDF)
            </p>
          </div>
          <div className="my-3">
            <p className="font-normal text-base mb-2">Working Rights</p>
            <div className="w-full flex items-center">
              {/* Hidden File Input */}
              <input
                type="file"
                ref={workingRightsFileInputRef}
                multiple
                onChange={handleWorkingRightsFileChange}
                style={{ display: "none" }}
              />

              <div
                style={{ boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.10)" }}
                className="w-full flex justify-between items-center px-3 py-1 rounded-md"
              >
                <div className="w-full py-[6.25px] rounded-md flex flex-wrap">
                  {selectedWorkingRightsFile ? (
                    <a
                      href={selectedWorkingRightsFile.url} // Directly use existing file URL
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className="py-1 cursor-pointer block text-blue-500 hover:underline font-300"
                    >
                      {selectedWorkingRightsFile?.name || "Download File"}
                    </a>
                  ) : (
                    <p className="text-gray-400 py-1 ml-2">No file selected</p>
                  )}
                </div>
                <BaseButton
                  variant="outlined"
                  color="primary"
                  className="w-[160px] py-[4px] ml-4"
                  onClick={() => workingRightsFileInputRef.current.click()}
                >
                  Choose File
                </BaseButton>
              </div>
            </div>
            <p className="mt-1">
              Please upload documents that shows your working rights in your
              country (JPG, PNG, PDF)
            </p>
          </div>
        </div>
      </div>
      <div
        className="d-flex content-center  bg-white py-6 "
        style={{
          boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className=" px-[28px] py-[5px] bg-light text-primary hover:text-primary"
          variant="outlined"
          color="primary"
          style={{
            marginRight: "16px",
          }}
          onClick={() => close(false)}
        >
          Cancel
        </BaseButton>

        <BaseButton
          isLoading={
            isUploadingEducationLoading || isUploadingWorkingRightsLoading
          }
          loaderSize={24}
          loaderColor="primary"
          className="px-[12px] py-[5px]"
          color="primary"
          onClick={saveButtonClicked}
        >
          Save Changes
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default AddBackgroundVerificationModal;
