import React, { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { navigateWithReload } from "./utils/helpers";

function isModuleAllowed(userModules, allowedModules) {
  // If allowedModules is empty, grant full access
  if (!allowedModules || allowedModules.length === 0) {
    return true;
  }

  // Extract module names from allowedModules
  const allowedModuleNames = allowedModules.map((mod) => mod.module);

  // Check if any userModule exists in allowedModuleNames
  return userModules?.some(
    (module) => allowedModuleNames.includes(module) || module === ""
  );
}

const RequireAuth = ({ allowedModules, children }) => {
  const { userModules, isAuthenticated, authRole } = useAuth();
  const location = useLocation();

  const result = isModuleAllowed(userModules, allowedModules);
  //Previous algorithm
  /* if (isAuthenticated) {
    if (result) {
      return <Outlet />;
    } else {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  } else {
    navigateWithReload("company-signup");
  } */

  useEffect(() => {
    if (!isAuthenticated) {
      navigateWithReload("company-signup");
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return null; // Prevent rendering while redirecting
  }

  if (isModuleAllowed(userModules, allowedModules)) {
    return location.pathname === "/" ? (
      <Navigate
        to={authRole === "FREELANCER" ? "jobs" : "/workOrders/workboard"}
        replace
      />
    ) : (
      <Outlet />
    );
  } else {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
