import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const BaseTooltip = ({
  content,
  children,
  placement = "top",
  interactive = false,
  delay = [0, 0],
  theme = "default",
}) => {
  return (
    <Tippy
      className=""
      content={content}
      placement={placement}
      interactive={interactive}
      delay={delay}
      theme={theme}
    >
      {children}
    </Tippy>
  );
};

export default BaseTooltip;
