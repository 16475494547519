import React, { useEffect, useState } from "react";
import BaseButton from "../../components/ui/BaseButton";
import { brandPrimaryColor } from "../../utils/variables";
import BaseWrapper from "../../components/ui/BaseWrapper";
import useAuth from "../../hooks/useAuth";
import { useMutation, useQuery } from "@tanstack/react-query";
import { declineOffer } from "../../services/api/offers";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../store/toast/toastSlice";
import CounterOfferModal from "./CounterOfferModal";
import RequestOfferModal from "./RequestOfferModal";
import MarkIncompleteModal from "./MarkIncompleteModal";
import {
  approveWorkOrder,
  draftWorkOrder,
  republishWorkOrderApi,
  updateWorkOrderApi,
} from "../../services/api/workOrder";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../hooks/useUpdateWorkOrder";
import CheckInModal from "./CheckInModal";
import CheckOutModal from "./CheckOutModal";
import CloseoutNotesModal from "./CloseoutNotesModal";
import WOConfirmationModal from "./WOConfirmationModal";
import OnTheWayModal from "./OnTheWayModal";
import RunningLateModal from "./RunningLateModal";
import CancelAssignmentModal from "./CancelAssignmentModal";
import EngineerPrivateFeedbackModal from "./EngineerPrivateFeedbackModal";
import FeedbackSuccessModal from "./FeedbackSuccessModal";
import EngineerPublicFeedbackModal from "./EngineerPublicFeedbackModal";
import WithdrawCounterOfferModal from "./WithdrawCounterOfferModal";
import UnpublishModal from "./UnpublishModal";
import CancelByClientModal from "./CancelByClientModal";
import { setCloseOutNotesModalOpen } from "../../store/modalsManagement/modalsManagementSlice";

const ActionBar = ({ userAllowedAction, refetchUserAllowedAction }) => {
  const { authRole, auth } = useAuth();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const params = useParams();
  const [counterOfferModalOpen, setCounterOfferModalOpen] = useState(false);
  const [requestOfferModalOpen, setRequestOfferModalOpen] = useState(false);
  const [counterOfferMode, setCounterOfferMode] = useState("");
  const [withdrawCounterOfferModalOpen, setWithdrawCounterOfferModalOpen] =
    useState(false);
  const [woConfirmationModalOpen, setWOConfirmationModalOpen] = useState(false);
  const [woConfirmationModalData, setWOConfirmationModalData] = useState({
    title: "",
    actionType: "",
  });

  const [
    engineerPrivateFeedbackModalOpen,
    setEngineerPrivateFeedbackModalOpen,
  ] = useState(false);
  const [engineerPublicFeedbackModalOpen, setEngineerPublicFeedbackModalOpen] =
    useState(false);
  const [markCompleteModalOpen, setMarkCompleteModalOpen] = useState(false);
  const [checkInModalOpen, setCheckInModalOpen] = useState(false);
  const [onTheWayModalOpen, setOnTheWayModalOpen] = useState(false);
  const [unpublishModalOpen, setUnpublishModalOpen] = useState(false);
  const [runningLateModalOpen, setRunningLateModalOpen] = useState(false);
  const [markIncompleteModalOpen, setMarkIncompleterModalOpen] =
    useState(false);
  const [checkOutModalOpen, setCheckOutModalOpen] = useState(false);
  const [feedbackSuccessModalOpen, setFeedbackSuccessModalOpen] =
    useState(false);
  const [cancelAssignmentModalOpen, setCancelAssignmentModalOpen] =
    useState(false);

  const [cancelByClientOpen, setCancelByClientOpen] = useState(false);

  const dispatch = useDispatch();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );
  const { closeOutNotesModalOpen } = useSelector(
    (state) => state.modalsManagement.value
  );

  const {
    isPending: isUpdatingLoading,
    error: isUpdatingError,
    mutateAsync: updateWorkOrderApiMutation,
  } = useMutation({
    mutationFn: updateWorkOrderApi,
    onSuccess: (data) => {},
  });

  const {
    isLoading: isWorkOrderRepublishLoading,
    error: isWorkOrderRepublishError,
    data: republishWorkOrderData,
    refetch: refetchRepublishWorkOrder,
  } = useQuery({
    queryKey: ["republishWorkOrder"],
    queryFn: () => republishWorkOrderApi(params.id),
    enabled: false,
  });

  const {
    // status,
    error: isDelineError,
    mutateAsync: declineOfferMutation,
  } = useMutation({
    mutationFn: declineOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Updated Successfully",
          type: "success",
        })
      );
    },
  });

  const {
    isLoading: isWorkOrderApprovedLoading,
    error: isWorkOrderApprovedError,
    data: approveWorkOrderData,
    refetch: reFetchApproveWorkOrder,
  } = useQuery({
    queryKey: ["approveWorkOrder"],
    queryFn: () => approveWorkOrder(params.id),
    enabled: false,
  });

  /* useEffect(() => {
    if (
      userAllowedAction &&
      workOrderDetail?.status === "APPROVED" &&
      !userAllowedAction?.isFeedbackGiven &&
      authRole === "FREELANCER"
    ) {
      setEngineerPrivateFeedbackModalOpen(true);
    }
  }); */

  async function handleNotInterestedButton(userId, workOrderId) {
    try {
      let payload = { workOrderId, status: "DECLINED" };
      await declineOfferMutation(payload);
      await updateWorkOrder();
      await refetchUserAllowedAction();
    } catch (error) {
      dispatch(
        showToast({
          message: isDelineError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  const handlePublishButton = async () => {
    const payload = {
      id: workOrderDetail.id,
      status: "PUBLISHED",
    };
    try {
      if (workOrderDetail.subStatus === "CANCELLED") {
        await refetchRepublishWorkOrder();
      } else {
        await updateWorkOrderApiMutation(payload);
      }

      updateWorkOrder();
      dispatch(
        showToast({
          message: "Work Order Published",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: error.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const approveWorkOrderApi = async () => {
    try {
      await reFetchApproveWorkOrder(params.id);
      dispatch(
        showToast({
          message: "Successfully Approved",
          type: "success",
        })
      );
      // updateWorkOrder();
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    }
  };

  const handleReadyToGo = async () => {
    setWOConfirmationModalOpen(true);
    setWOConfirmationModalData({
      title: "Ready to go",
      actionType: "Ready to go",
    });
  };

  const handleCheckIn = async (id) => {
    setCheckInModalOpen(true);
  };

  const handleOnTheWay = () => {
    setOnTheWayModalOpen(true);
  };

  const handleCheckOut = () => {
    setCheckOutModalOpen(true);
  };
  const handleCloseOutNotes = () => {
    dispatch(setCloseOutNotesModalOpen(true));
  };

  return (
    <>
      {workOrderDetail.status !== "CANCELLED" && (
        <div className="border-b border-zinc-300 py-[10px]  bg-white ">
          <BaseWrapper>
            <div className="grid grid-cols-9 gap-24">
              <div className="col-span-6 flex">
                {authRole === "CLIENT" && (
                  <BaseButton
                    style={{
                      border: `1px solid ${brandPrimaryColor}`,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    fullWidth={false}
                    className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary  min-w-[120px] mr-5 h-8"
                  >
                    Copy
                  </BaseButton>
                )}

                {authRole === "CLIENT" && (
                  <BaseButton
                    style={{
                      border: `1px solid ${brandPrimaryColor}`,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    fullWidth={false}
                    className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary  min-w-[160px] mr-5 h-8"
                  >
                    Save as Template
                  </BaseButton>
                )}
                {authRole === "CLIENT" && (
                  <BaseButton
                    style={{
                      border: `1px solid ${brandPrimaryColor}`,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    fullWidth={false}
                    className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary  min-w-[120px] mr-5 h-8"
                  >
                    Tags
                  </BaseButton>
                )}

                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: "6px",
                    paddingBottom: "6px",
                  }}
                  fullWidth={false}
                  className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary  min-w-[120px] max-w-[120px] mr-5 h-8"
                >
                  Print
                </BaseButton>

                {(workOrderDetail?.status === "ASSIGNED" ||
                  workOrderDetail?.status === "INPROGRESS" ||
                  workOrderDetail?.status === "INCOMPLETE") && (
                  <BaseButton
                    style={{
                      border: `1px solid ${brandPrimaryColor}`,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary min-w-[150px] max-w-[150px] mr-5 h-8"
                  >
                    Report problem
                  </BaseButton>
                )}

                {authRole === "CLIENT" &&
                  workOrderDetail?.status === "ASSIGNED" && (
                    <BaseButton
                      onClick={() => setCancelByClientOpen(true)}
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary min-w-[150px] max-w-[150px] mr-5 h-8"
                    >
                      Cancel
                    </BaseButton>
                  )}

                {authRole == "FREELANCER" &&
                  userAllowedAction?.isRouted &&
                  !userAllowedAction?.isWorkOrderAlreadyAssigned && (
                    <BaseButton
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary   max-w-[140px] min-w-[140px] mr-5 h-8"
                      onClick={() =>
                        handleNotInterestedButton(
                          auth.user.id,
                          workOrderDetail.id
                        )
                      }
                    >
                      Not Interested
                    </BaseButton>
                  )}

                {authRole === "FREELANCER" &&
                  (workOrderDetail?.subStatus === "NOT_CONFIRMED" ||
                    workOrderDetail?.subStatus === "CONFIRMED" ||
                    workOrderDetail?.subStatus === "READY_TO_GO") &&
                  workOrderDetail.status !== "CANCELLED" && (
                    <BaseButton
                      onClick={() => setCancelAssignmentModalOpen(true)}
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      fullWidth={false}
                      className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary   min-w-[120px] mr-5 h-8"
                    >
                      Cancel Assignment
                    </BaseButton>
                  )}
              </div>

              {authRole === "FREELANCER" && (
                <div className="col-span-3 flex justify-end">
                  {workOrderDetail?.status === "PUBLISHED" && (
                    <>
                      {(!workOrderDetail?.isCounterOffersDisable &&
                        userAllowedAction?.isRequested) ||
                        (!workOrderDetail?.isCounterOffersDisable &&
                          userAllowedAction?.isWorkOrderCancelByEngineer) ||
                        userAllowedAction?.isDeclined ||
                        userAllowedAction?.isWorkOrderAlreadyAssigned ||
                        (!workOrderDetail?.isCounterOffersDisable &&
                          !userAllowedAction?.isCounter && (
                            <BaseButton
                              disabled={userAllowedAction?.isDeclined}
                              onClick={() => {
                                setCounterOfferMode("add");
                                setCounterOfferModalOpen(true);
                              }}
                              style={{
                                border: `1px solid ${brandPrimaryColor}`,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                              className="bg-primary text-white  w-[150px] mr-5 h-8"
                            >
                              Counter Offer
                            </BaseButton>
                          ))}

                      {userAllowedAction?.isRouted &&
                        !userAllowedAction?.isWorkOrderAlreadyAssigned && (
                          <BaseButton
                            onClick={() => setRequestOfferModalOpen(true)}
                            style={{
                              border: `1px solid ${brandPrimaryColor}`,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            className="bg-primary text-white  w-[150px] mr-5 h-8"
                          >
                            Accept
                          </BaseButton>
                        )}
                      {userAllowedAction?.isDeclined ||
                        userAllowedAction?.isRouted ||
                        userAllowedAction?.isCounter ||
                        userAllowedAction?.isWorkOrderCancelByEngineer ||
                        (!userAllowedAction?.isRequested && (
                          <BaseButton
                            disabled={userAllowedAction?.isDeclined}
                            onClick={() => setRequestOfferModalOpen(true)}
                            style={{
                              border: `1px solid ${brandPrimaryColor}`,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            fullWidth={false}
                            className="bg-primary text-white  w-[150px] h-8"
                          >
                            Request
                          </BaseButton>
                        ))}

                      {userAllowedAction?.isRequested && (
                        <BaseButton
                          onClick={() => {
                            setWOConfirmationModalOpen(true);
                            setWOConfirmationModalData({
                              title: "Withdraw Request",
                              actionType: "Withdraw",
                            });
                          }}
                          style={{
                            border: `1px solid ${brandPrimaryColor}`,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          className="bg-primary text-white  w-[160px] h-8"
                        >
                          Withdraw Request
                        </BaseButton>
                      )}

                      {userAllowedAction?.isCounter && (
                        <BaseButton
                          style={{
                            border: `1px solid ${brandPrimaryColor}`,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          fullWidth={false}
                          className="bg-primary text-white mr-5"
                          onClick={() => {
                            setCounterOfferMode("edit");
                            setCounterOfferModalOpen(true);
                          }}
                        >
                          Edit Counter Offer
                        </BaseButton>
                      )}

                      {userAllowedAction?.isCounter && (
                        <BaseButton
                          onClick={() => {
                            setWithdrawCounterOfferModalOpen(true);
                          }}
                          style={{
                            border: `1px solid ${brandPrimaryColor}`,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          fullWidth={false}
                          className="bg-primary text-white"
                        >
                          Withdraw Counter Offer
                        </BaseButton>
                      )}
                    </>
                  )}
                  {workOrderDetail?.status === "ASSIGNED" && (
                    <>
                      {!userAllowedAction?.isWorkOrderCancelByEngineer &&
                        !userAllowedAction?.isWorkOrderConfirmByEngineer &&
                        !userAllowedAction?.isStartTime && (
                          <BaseButton
                            onClick={() => {
                              setWOConfirmationModalData({
                                title: "Confirm Work Order",
                                actionType: "Confirmed",
                              });
                              setWOConfirmationModalOpen(true);
                            }}
                            style={{
                              border: `1px solid ${brandPrimaryColor}`,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            className="bg-primary text-white  w-[120px] mr-5 h-8"
                          >
                            Confirm
                          </BaseButton>
                        )}
                      {userAllowedAction?.isWorkOrderConfirmByEngineer &&
                        !userAllowedAction?.isStartTime && (
                          <BaseButton
                            onClick={handleReadyToGo}
                            style={{
                              border: `1px solid ${brandPrimaryColor}`,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            className="bg-primary text-white  w-[120px] mr-5 h-8"
                          >
                            Ready to go
                          </BaseButton>
                        )}
                    </>
                  )}

                  {!userAllowedAction?.isFeedbackGiven &&
                    workOrderDetail?.status === "APPROVED" && (
                      <BaseButton
                        onClick={() =>
                          setEngineerPrivateFeedbackModalOpen(true)
                        }
                        style={{
                          border: `1px solid ${brandPrimaryColor}`,
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        fullWidth={false}
                        className="bg-primary text-white   min-w-[140px] ml-5 h-8"
                      >
                        Leave Feedback
                      </BaseButton>
                    )}

                  {userAllowedAction?.isStartTime && (
                    <div className="col-span-3 flex justify-end">
                      {(workOrderDetail?.status === "INCOMPLETE" ||
                        workOrderDetail?.status === "INPROGRESS") &&
                        userAllowedAction?.isCheckOut && (
                          <BaseButton
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                            loaderSize="24px"
                            loaderColor="primary"
                            className="bg-primary text-white min-w-[140px] mr-5 h-8"
                            fullWidth={false}
                            onClick={handleCloseOutNotes}
                          >
                            Mark Complete
                          </BaseButton>
                        )}

                      {workOrderDetail?.subStatus === "READY_TO_GO" && (
                        <BaseButton
                          onClick={handleOnTheWay}
                          style={{
                            border: `1px solid ${brandPrimaryColor}`,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          className="bg-primary text-white  w-[120px] mr-5 h-8"
                        >
                          On the Way
                        </BaseButton>
                      )}
                      {(workOrderDetail?.subStatus === "READY_TO_GO" ||
                        workOrderDetail?.subStatus === "ON_THE_WAY") && (
                        <BaseButton
                          onClick={() => setRunningLateModalOpen(true)}
                          style={{
                            border: `1px solid ${brandPrimaryColor}`,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          className="bg-primary text-white  w-[130px] mr-5 h-8"
                        >
                          Running Late
                        </BaseButton>
                      )}
                      {workOrderDetail?.status === "ASSIGNED" &&
                        workOrderDetail?.subStatus !== "READY_TO_GO" && (
                          <BaseButton
                            onClick={handleCheckIn}
                            style={{
                              border: `1px solid ${brandPrimaryColor}`,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            className="bg-primary text-white  w-[120px] mr-5 h-8"
                          >
                            Check In
                          </BaseButton>
                        )}
                      {userAllowedAction?.isCheckOut &&
                        (workOrderDetail?.status === "INCOMPLETE" ||
                          workOrderDetail?.status === "INPROGRESS") && (
                          <BaseButton
                            onClick={handleCheckIn}
                            style={{
                              border: `1px solid ${brandPrimaryColor}`,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            className="bg-primary text-white  w-[120px] mr-5 h-8"
                          >
                            Check In
                          </BaseButton>
                        )}
                      {userAllowedAction?.isCheckIn &&
                        (workOrderDetail?.status === "INCOMPLETE" ||
                          workOrderDetail?.status === "INPROGRESS") && (
                          <BaseButton
                            onClick={handleCheckOut}
                            style={{
                              border: `1px solid ${brandPrimaryColor}`,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                            className="bg-primary text-white  w-[120px] mr-5 h-8"
                          >
                            Check out
                          </BaseButton>
                        )}
                    </div>
                  )}

                  {/* </div> */}
                </div>
              )}

              {authRole === "CLIENT" && (
                <div className="col-span-3 flex justify-end">
                  {/* {workOrderDetail?.status === "PUBLISHED" && (
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  fullWidth={false}
                  className="bg-primary text-white ml-5 h-8 min-w-[150px]"
                >
                  Move to Draft
                </BaseButton>
              )} */}
                  {workOrderDetail?.status === "PUBLISHED" && (
                    <BaseButton
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      onClick={() => setUnpublishModalOpen(true)}
                      fullWidth={false}
                      className="bg-primary text-white  min-w-[150px] ml-5 h-8"
                    >
                      Unpublish
                    </BaseButton>
                  )}
                  {(workOrderDetail?.status === "ASSIGNED" ||
                    workOrderDetail?.status === "CONFIRM" ||
                    // workOrderDetail?.status === "INPROGRESS" ||
                    workOrderDetail?.status === "WORKDONE") && (
                    // || workOrderDetail?.status === "INCOMPLETE"
                    <BaseButton
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      fullWidth={false}
                      className="bg-primary text-white   min-w-[140px] ml-5 h-8"
                    >
                      Leave Feedback
                    </BaseButton>
                  )}
                  {(workOrderDetail?.status === "DRAFT" ||
                    workOrderDetail.subStatus === "CANCELLED") && (
                    <BaseButton
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      fullWidth={false}
                      className="bg-primary text-white  min-w-[150px] ml-5 h-8"
                      onClick={handlePublishButton}
                    >
                      Publish
                    </BaseButton>
                  )}
                  {workOrderDetail?.status === "WORKDONE" && (
                    <BaseButton
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      loaderSize="24px"
                      loaderColor="primary"
                      className="bg-primary text-white min-w-[140px] ml-5 h-8"
                      onClick={() => setMarkIncompleterModalOpen(true)}
                    >
                      Mark Incomplete
                    </BaseButton>
                  )}
                  {workOrderDetail?.status === "WORKDONE" && (
                    // || workOrderDetail?.status === "INCOMPLETE"
                    <BaseButton
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      fullWidth={false}
                      className="bg-primary text-white  min-w-[140px] ml-5 h-8"
                      disabled={isWorkOrderApprovedLoading}
                      isLoading={isWorkOrderApprovedLoading}
                      onClick={approveWorkOrderApi}
                    >
                      Approve
                    </BaseButton>
                  )}{" "}
                  {workOrderDetail?.status === "APPROVED" && (
                    <BaseButton
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      fullWidth={false}
                      className="bg-primary text-white  min-w-[140px] ml-5 h-8"
                    >
                      Modify Feedback
                    </BaseButton>
                  )}
                  {workOrderDetail?.status === "PAID" && (
                    <BaseButton
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      fullWidth={false}
                      className="bg-primary text-white  min-w-[140px] ml-5 h-8"
                    >
                      Rate Provider
                    </BaseButton>
                  )}
                  {/* {(workOrderDetail?.status === "ASSIGNED" ||
                workOrderDetail?.status === "CONFIRM") &&
                workOrderDetail?.status !== "INPROGRESS" && (
                  <BaseButton
                    style={{
                      border: `1px solid ${brandPrimaryColor}`,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    fullWidth={false}
                    className="bg-primary text-white  min-w-[150px] ml-5 h-8"
                  >
                    Add Hold
                  </BaseButton>
                )} */}
                </div>
              )}
            </div>
          </BaseWrapper>

          {requestOfferModalOpen && (
            <RequestOfferModal
              refetchUserAllowedAction={refetchUserAllowedAction}
              requestOfferModalOpen={requestOfferModalOpen}
              setRequestOfferModalOpen={setRequestOfferModalOpen}
              userAllowedAction={userAllowedAction}
            />
          )}
          {woConfirmationModalOpen && (
            <WOConfirmationModal
              refetchUserAllowedAction={refetchUserAllowedAction}
              woConfirmationModalOpen={woConfirmationModalOpen}
              setWOConfirmationModalOpen={setWOConfirmationModalOpen}
              woConfirmationModalData={woConfirmationModalData}
              tasks={workOrderDetail?.tasks}
            />
          )}
          {counterOfferModalOpen && (
            <CounterOfferModal
              refetchUserAllowedAction={refetchUserAllowedAction}
              counterOfferModalOpen={counterOfferModalOpen}
              setCounterOfferModalOpen={setCounterOfferModalOpen}
              mode={counterOfferMode}
            />
          )}
          {markIncompleteModalOpen && (
            <MarkIncompleteModal
              markIncompleteModalOpen={markIncompleteModalOpen}
              setMarkIncompleterModalOpen={setMarkIncompleterModalOpen}
            />
          )}

          {checkInModalOpen && (
            <CheckInModal
              refetchUserAllowedAction={refetchUserAllowedAction}
              checkInModalOpen={checkInModalOpen}
              setCheckInModalOpen={setCheckInModalOpen}
              tasks={workOrderDetail?.tasks}
            />
          )}

          {(closeOutNotesModalOpen || markCompleteModalOpen) && (
            <CloseoutNotesModal
              markCompleteModalOpen={markCompleteModalOpen}
              setMarkCompleteModalOpen={setMarkCompleteModalOpen}
              closeOutNotesModalOpen={closeOutNotesModalOpen}
              setEngineerPrivateFeedbackModalOpen={
                setEngineerPrivateFeedbackModalOpen
              }
              tasks={workOrderDetail?.tasks}
            />
          )}

          {checkOutModalOpen && (
            <CheckOutModal
              checkOutModalOpen={checkOutModalOpen}
              refetchUserAllowedAction={refetchUserAllowedAction}
              setCheckOutModalOpen={setCheckOutModalOpen}
              tasks={workOrderDetail?.tasks}
            />
          )}

          {onTheWayModalOpen && (
            <OnTheWayModal
              onTheWayModalOpen={onTheWayModalOpen}
              setOnTheWayModalOpen={setOnTheWayModalOpen}
              refetchUserAllowedAction={refetchUserAllowedAction}
              tasks={workOrderDetail?.tasks}
            />
          )}
          {unpublishModalOpen && (
            <UnpublishModal
              unpublishModalOpen={unpublishModalOpen}
              setUnpublishModalOpen={setUnpublishModalOpen}
              refetchUserAllowedAction={refetchUserAllowedAction}
              workOrderId={workOrderDetail?.id}
            />
          )}

          {runningLateModalOpen && (
            <RunningLateModal
              runningLateModalOpen={runningLateModalOpen}
              refetchUserAllowedAction={refetchUserAllowedAction}
              setRunningLateModalOpen={setRunningLateModalOpen}
              tasks={workOrderDetail?.tasks}
            />
          )}
          {cancelAssignmentModalOpen && (
            <CancelAssignmentModal
              cancelAssignmentModalOpen={cancelAssignmentModalOpen}
              setCancelAssignmentModalOpen={setCancelAssignmentModalOpen}
              refetchUserAllowedAction={refetchUserAllowedAction}
              tasks={workOrderDetail?.tasks}
            />
          )}

          {cancelByClientOpen && (
            <CancelByClientModal
              cancelByClientOpen={cancelByClientOpen}
              setCancelByClientOpen={setCancelByClientOpen}
              refetchUserAllowedAction={refetchUserAllowedAction}
              tasks={workOrderDetail?.tasks}
            />
          )}

          {engineerPrivateFeedbackModalOpen && (
            <EngineerPrivateFeedbackModal
              refetchUserAllowedAction={refetchUserAllowedAction}
              engineerPrivateFeedbackModalOpen={
                engineerPrivateFeedbackModalOpen
              }
              setEngineerPrivateFeedbackModalOpen={
                setEngineerPrivateFeedbackModalOpen
              }
              setFeedbackSuccessModalOpen={setFeedbackSuccessModalOpen}
              setEngineerPublicFeedbackModalOpen={
                setEngineerPublicFeedbackModalOpen
              }
            />
          )}
          {feedbackSuccessModalOpen && (
            <FeedbackSuccessModal
              feedbackSuccessModalOpen={feedbackSuccessModalOpen}
              setFeedbackSuccessModalOpen={setFeedbackSuccessModalOpen}
              setEngineerPublicFeedbackModalOpen={
                setEngineerPublicFeedbackModalOpen
              }
            />
          )}

          {engineerPublicFeedbackModalOpen && (
            <EngineerPublicFeedbackModal
              engineerPublicFeedbackModalOpen={engineerPublicFeedbackModalOpen}
              setEngineerPublicFeedbackModalOpen={
                setEngineerPublicFeedbackModalOpen
              }
            />
          )}

          {withdrawCounterOfferModalOpen && (
            <WithdrawCounterOfferModal
              withdrawCounterOfferModalOpen={withdrawCounterOfferModalOpen}
              setWithdrawCounterOfferModalOpen={
                setWithdrawCounterOfferModalOpen
              }
              workOrderDetail={workOrderDetail}
              refetchUserAllowedAction={refetchUserAllowedAction}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ActionBar;
