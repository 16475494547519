import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    loginModalOpen: false,
    signupModalOpen: false,
    profileModalOpen: false,
    settingsModalOpen: false,
    closeOutNotesModalOpen: false,
  },
};

const modalsManagementSlice = createSlice({
  name: "modalsManagementSlice",
  initialState,
  reducers: {
    setLoginModalOpen: (state, action) => {
      state.value.loginModalOpen = action.payload;
    },
    setSignupModalOpen: (state, action) => {
      state.value.signupModalOpen = action.payload;
    },
    setProfileModalOpen: (state, action) => {
      state.value.profileModalOpen = action.payload;
    },
    setSettingsModalOpen: (state, action) => {
      state.value.settingsModalOpen = action.payload;
    },
    setCloseOutNotesModalOpen: (state, action) => {
      state.value.closeOutNotesModalOpen = action.payload;
    },
    setAllModalsClose: (state) => {
      state.value = {
        loginModalOpen: false,
        signupModalOpen: false,
        profileModalOpen: false,
        settingsModalOpen: false,
        closeOutNotesModalOpen: false,
      };
    },
  },
});

export const {
  setLoginModalOpen,
  setSignupModalOpen,
  setProfileModalOpen,
  setSettingsModalOpen,
  setAllModalsClose,
  setCloseOutNotesModalOpen,
} = modalsManagementSlice.actions;

export default modalsManagementSlice.reducer;
