import React from "react";
import Overview from "../TabsCard/Overview";
import ServiceDescription from "../TabsCard/ServiceDescription";
import Tasks from "../TabsCard/Tasks";
import { useSelector } from "react-redux";
import WorkExperience from "../TabsCard/WorkExperience";
import Qualification from "../TabsCard/Qualification";
import ProviderAssessment from "../TabsCard/ProviderAssessment";
import Documents from "../TabsCard/Documents";
import CustomField from "../TabsCard/CustomField";
import Deliverables from "../TabsCard/Deliverables";
import Shipments from "../TabsCard/Shipments";
import Schedule from "../TabsCard/Schedule";
import LocationMap from "../TabsCard/LocationMap";
import Contacts from "../TabsCard/Contacts";

const DetailsTab = () => {
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="col-span-2">
        <Overview workOrderDetail={workOrderDetail} />
      </div>
      <div className="col-span-2">
        <ServiceDescription
          incidentId={workOrderDetail?.incidentId}
          description={workOrderDetail?.description}
          confidentialInformation={workOrderDetail?.confidentialInformation}
        />
      </div>

      <WorkExperience
        category={workOrderDetail?.category}
        vendor={workOrderDetail?.vendor}
        services={workOrderDetail?.services}
      />

      <Qualification qualifications={workOrderDetail?.qualificationSubTypes} />

      <Schedule
        schedule={{
          timeZone: workOrderDetail?.timeZone,
          scheduleType: workOrderDetail?.scheduleType,
          orderStartDate: workOrderDetail?.orderStartDate,
          orderStartTime: workOrderDetail?.orderStartTime,
          orderEndDate: workOrderDetail?.orderEndDate,
          orderEndTime: workOrderDetail?.orderEndTime,
          confirmedWorkOrderAt: workOrderDetail?.tasks?.find(
            (task) => task.taskType === "START_TIME"
          )?.completedTask?.text,
        }}
        minimumHours={workOrderDetail?.minimumHours}
      />
      <LocationMap location={workOrderDetail?.address} />
      <div className="col-span-2">
        <Documents />
      </div>

      <div className="col-span-2">
        <Deliverables />
      </div>
      <div className="col-span-2">
        <Tasks
          tasks={workOrderDetail?.tasks}
          engineer={workOrderDetail?.engineer}
        />
      </div>

      <div className="col-span-2">
        <Contacts
          contacts={workOrderDetail?.contacts}
          workOrderManager={workOrderDetail?.workOrderManager}
        />
      </div>
      <div className="col-span-2">
        <Shipments />
      </div>
      {/* <ProviderAssessment /> */}
      {/* <CustomField /> */}
    </div>
  );
};

export default DetailsTab;
