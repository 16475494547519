import React, { useState } from "react";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseCard from "../../../components/ui/BaseCard";
import BaseTextField from "../../../components/ui/BaseTextField";
import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addContact,
  removeContact,
  updateContact,
  updateWorkOrderForm,
} from "../../../store/workOrder/workOrderSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchCompanyUsers } from "../../../services/api/company";
import useAuth from "../../../hooks/useAuth";
import BaseSwitch from "../../../components/ui/BaseSwitch";

const contactFormat = {
  id: Date.now(),
  name: "",
  position: "",
  note: "",
  email: "",
  position: "",
};

const positionsOptions = [
  {
    label: "Project Manager",
    value: "Project Manager",
  },
  {
    label: "Location Contact",
    value: "Location Contact",
  },
  {
    label: "Resource Coordinator",
    value: "Resource Coordinator",
  },
  {
    label: "Emergency Contact",
    value: "Emergency Contact",
  },
  {
    label: "Techinal Help",
    value: "Techinal Help",
  },
  {
    label: "Check-in / Check-out",
    value: "Check-in / Check-out",
  },
];

const ContactsForm = () => {
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const contact = useSelector((state) => state.workOrder.scheduling.contacts);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const {
    isLoading: companyUsersLoading,
    error: companyUsersLoadingError,
    data: companyUsersData,
  } = useQuery({
    queryKey: ["companyUsers"],
    queryFn: () => fetchCompanyUsers(auth.user.company.id),
    enabled: !!auth.user,
  });

  const formattedCompanyUsers = companyUsersData?.map((user) => {
    return {
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    dispatch(
      updateWorkOrderForm({
        step: "scheduling",
        data: {
          contacts: {
            [name]: value,
          },
        },
      })
    );
  };

  const handleAddContact = () => {
    dispatch(addContact());
  };

  const handleContactChange = (index, event) => {
    const { name, value } = event.target;
    dispatch(updateContact({ index, field: name, value }));
  };

  const handleRemoveContact = (id) => {
    dispatch(removeContact(id));
  };

  return (
    <BaseCard className="pt-4 pb-6 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center">
        <h6 className="mb-2 text-dark">
          Contacts
          <span className="text-base italic text-[#686868]"> (Optional)</span>
        </h6>

        <BaseSwitch
          className="ml-4 mb-2"
          secondary={true}
          checked={isContactFormOpen}
          onChange={() => setIsContactFormOpen(!isContactFormOpen)}
        />
      </div>
      {isContactFormOpen && (
        <div className="grid grid-cols-2 gap-y-4">
          <div className="col-span-2">
            <BaseSelect
              fullHeight={false}
              fullWidth
              labelShrink={false}
              required={true}
              id="workOrderManagerId"
              name="workOrderManagerId"
              label="Work Order Manager"
              value={contact?.workOrderManagerId}
              onChange={handleChange}
              isDefaultField={false}
              options={formattedCompanyUsers}
            />
          </div>

          {contact?.contacts.map((contact, index) => (
            <div
              className="col-span-2 grid grid-cols-2 gap-y-4 bg-[#F6F6F6] p-5 rounded-lg"
              key={index}
            >
              <div className="col-span-2 text-right">
                <div
                  className="p-2 cursor-pointer inline-block"
                  onClick={() => handleRemoveContact(contact?.id)}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </div>
              </div>
              <div className="mr-10">
                <BaseTextField
                  fullWidth
                  fullHeight={false}
                  id="name"
                  name="name"
                  label="Name or Description"
                  optional={false}
                  labelShrink={false}
                  className="col-span-4"
                  onChange={(e) => handleContactChange(index, e)}
                  isDefaultField={false}
                  value={contact.name}
                  inputProps={{ maxLength: 50 }}
                />
              </div>

              <div>
                <BaseSelect
                  fullHeight={false}
                  fullWidth
                  label="Title"
                  labelShrink={false}
                  required={true}
                  options={positionsOptions}
                  name="position"
                  onChange={(e) => handleContactChange(index, e)}
                  isDefaultField={false}
                  value={contact.position}
                />
              </div>
              <div className="mr-10">
                <BaseTextField
                  fullWidth
                  fullHeight={false}
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone"
                  optional={false}
                  labelShrink={false}
                  className="col-span-4"
                  onChange={(e) => handleContactChange(index, e)}
                  isDefaultField={false}
                  value={contact.phoneNumber}
                  type="number"
                  inputProps={{ maxLength: 50 }}
                />
              </div>

              <div>
                <BaseTextField
                  fullHeight={false}
                  fullWidth
                  label="Email"
                  labelShrink={false}
                  required={false}
                  options={[]}
                  name="email"
                  onChange={(e) => handleContactChange(index, e)}
                  isDefaultField={false}
                  value={contact.email}
                  type="email"
                />
              </div>

              <div className="col-span-2">
                <BaseTextField
                  fullWidth
                  fullHeight={false}
                  id="note"
                  name="note"
                  label="Note"
                  required={false}
                  labelShrink={false}
                  className="col-span-4"
                  onChange={(e) => handleContactChange(index, e)}
                  isDefaultField={false}
                  value={contact.note}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
            </div>
          ))}

          <div
            className="flex items-center cursor-pointer"
            onClick={handleAddContact}
          >
            <PlusIcon />
            <p className="text-secondary ml-2"> Add Contact</p>
          </div>
        </div>
      )}
    </BaseCard>
  );
};

export default ContactsForm;
